import { Type } from "@angular/core";

import { ModalType } from "@hermes/states/modal";

import { ModalApplePayComponent } from "../components/applePay/modal-apple-pay.component";
import { ModalAddToCartComponent } from "../components/modal-add-to-cart/modal-add-to-cart.component";
import { ModalTermsAndConditionsComponent } from "../components/modal-terms-and-conditions/modal-terms-and-conditions.component";
import { ModalThreeDS2Component } from "../components/threeDS2/modal-three-d-s2.component";

export type ModalComponents =
    | ModalAddToCartComponent
    | ModalTermsAndConditionsComponent
    | ModalThreeDS2Component
    | ModalApplePayComponent;

export const MODAL_DECLARATIONS: Record<ModalType, Type<ModalComponents>> = {
    [ModalType.AddToCart]: ModalAddToCartComponent,
    [ModalType.TermsAndConditions]: ModalTermsAndConditionsComponent,
    [ModalType.ThreeDS2]: ModalThreeDS2Component,
    [ModalType.ApplePay]: ModalApplePayComponent,
};
