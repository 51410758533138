import { Action, createReducer, on } from "@ngrx/store";

import { MERCURY_ADYEN_ONE_CLICK } from "@hermes/utils/constants";

import * as PaymentMethodsActions from "../actions/payment-methods.actions";
import { AdyenOneClickPaymentMethod } from "../models/adyen-one-click-payment-method.model";
import { EcomError } from "../models/ecom-error.model";
import { PaymentMethodsResponseItemDTO } from "../models/payment-methods-response-item-dto.model";

export const paymentFeatureKey = "payment";

export type DeleteSavedCardState = "pending" | "success" | "failure";

export type PaymentMethod = PaymentMethodsResponseItemDTO;
export type PaymentMethods = Array<PaymentMethod>;
export interface State {
    methods: {
        data: PaymentMethods;
        error: {
            hasError: boolean;
            ecomError?: EcomError;
        };
        pending: boolean;
    };
}

export const initialState: State = {
    methods: {
        data: [],
        error: {
            hasError: false,
            ecomError: undefined,
        },
        pending: false,
    },
};

const paymentMethodsReducer = createReducer(
    initialState,

    on(PaymentMethodsActions.fetchPaymentMethods, (state) => ({
        ...state,
        methods: {
            ...state.methods,
            error: {
                hasError: !!state.methods?.error.hasError,
                ecomError: state.methods?.error.ecomError,
            },
            pending: true,
        },
    })),
    on(PaymentMethodsActions.fetchPaymentMethodsSuccess, (state, action) => ({
        ...state,
        methods: {
            data: [...action.methods],
            error: {
                hasError: false,
                ecomError: undefined,
            },
            pending: false,
        },
    })),
    on(PaymentMethodsActions.fetchPaymentMethodsFailure, (state, action) => ({
        ...state,
        methods: {
            data: [],
            error: {
                hasError: true,
                ecomError: action.error,
            },
            pending: false,
        },
    })),
    on(PaymentMethodsActions.removeCard, (state, action) => {
        const index = state.methods.data.findIndex(
            (pm): pm is AdyenOneClickPaymentMethod =>
                pm.code === MERCURY_ADYEN_ONE_CLICK && pm.id === action.id,
        );
        if (index !== -1) {
            state.methods.data.splice(index, 1);
        }
        return {
            ...state,
            methods: {
                ...state.methods,
                data: [...state.methods.data],
            },
        };
    }),
    on(PaymentMethodsActions.deleteSavedCard, (state, action) => {
        getAdyenOneClickPaymentMethodState(
            state,
            action.id,
            "delete action is pending",
        ).deleteStatus = "pending";
        return {
            ...state,
            methods: {
                ...state.methods,
                data: [...state.methods.data],
            },
        };
    }),
    on(PaymentMethodsActions.deleteSavedCardSuccess, (state, action) => {
        getAdyenOneClickPaymentMethodState(
            state,
            action.id,
            "delete action successful",
        ).deleteStatus = "success";
        return {
            ...state,
            methods: {
                ...state.methods,
                data: [...state.methods.data],
            },
        };
    }),
    on(PaymentMethodsActions.deleteSavedCardFailure, (state, action) => {
        getAdyenOneClickPaymentMethodState(
            state,
            action.id,
            "delete action failure",
        ).deleteStatus = "failure";
        return {
            ...state,
            methods: {
                ...state.methods,
                data: [...state.methods.data],
            },
        };
    }),
);

function getAdyenOneClickPaymentMethodState(
    state: State,
    cardId: string,
    actionLabel: string,
): AdyenOneClickPaymentMethod {
    const adyenOneClickPm = state.methods.data.find(
        (pm): pm is AdyenOneClickPaymentMethod =>
            pm.code === MERCURY_ADYEN_ONE_CLICK && pm.id === cardId,
    );
    if (!adyenOneClickPm) {
        throw new Error(`Saved card not found when ${actionLabel}`);
    }
    return adyenOneClickPm;
}

export const reducer = (state: State | undefined, action: Action): State =>
    paymentMethodsReducer(state, action);
