import { RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

import { RouterStoreState } from "../reducers/router.reducers";

/**
 * Custom router state serializer so that not all the full router snapshot object is in the state.
 *
 * @see https://ngrx.io/guide/router-store/configuration#custom-router-state-serializer
 */
export class ApolloRouterStateSerializer
    implements RouterStateSerializer<RouterStoreState>
{
    public serialize(routerState: RouterStateSnapshot): RouterStoreState {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;
        const { params } = route;

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url, params, queryParams, previousUrl: undefined };
    }
}
