import { Action, createReducer, on } from "@ngrx/store";

import * as BasketActions from "../actions/basket.actions";
import { EcomError, SerializedBasket } from "../models/basket-feature.model";

export const basketFeatureKey = "basket";

export interface State {
    // cart from ecom response
    cart: SerializedBasket;
    // ecom response when add to cart failed
    error: {
        hasError: boolean;
        ecomError: EcomError;
    };
    // request status
    pending: boolean;
}

export const initialState: State = {
    cart: { items: [] } as unknown as SerializedBasket,
    error: {
        hasError: false,
        ecomError: undefined as unknown as EcomError,
    },
    pending: false,
};

const basketReducer = createReducer(
    initialState,

    on(
        BasketActions.addToCart,
        BasketActions.resetCart,
        BasketActions.removeFromCart,
        BasketActions.setCartItem,
        (state, _action) => ({
            ...state,
            error: {
                hasError: false,
                ecomError: undefined as unknown as EcomError,
            },
            pending: true,
        }),
    ),
    on(
        BasketActions.addToCartSuccess,
        BasketActions.resetCartSuccess,
        BasketActions.setBasket,
        BasketActions.updateBasket,
        (_state, action) => ({
            cart: {
                ...action.basket,
            },
            error: {
                hasError: false,
                ecomError: undefined as unknown as EcomError,
            },
            pending: false,
        }),
    ),
    on(
        BasketActions.removeFromCartSuccess,
        BasketActions.setCartItemSuccess,
        BasketActions.persistBasketSuccess,
        (state, action) => ({
            cart: {
                ...action.basket,
                gift: state.cart
                    ? state.cart.gift
                    : (undefined as unknown as Record<string, unknown>),
                has_sample: state.cart ? state.cart.has_sample : false,
            },
            error: {
                hasError: false,
                ecomError: undefined as unknown as EcomError,
            },
            pending: false,
        }),
    ),
    on(
        BasketActions.addToCartFailure,
        BasketActions.resetCartFailure,
        BasketActions.removeFromCartFailure,
        BasketActions.setCartItemFailure,
        BasketActions.persistBasketFailure,
        (state, action) => ({
            ...state,
            error: {
                hasError: true,
                ecomError: action.error,
            },
            pending: false,
        }),
    ),
    on(BasketActions.clearData, (_state, _action) => initialState),
);

export function reducer(state: State | undefined, action: Action): State {
    return basketReducer(state, action);
}
