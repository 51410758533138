/**
 * This class build a valid scene 7 url from a list of optional parameters
 */
export class Scene7ImageUrlBuilder {
    private SCENE7_IMAGE_BACKGROUND_COLOR = "$product_item_grid_g$";

    constructor(private url: string, private config: ImageConfig) {}

    public build(applyBackground = false): string {
        if (
            this.config.sourceSet &&
            ((this.config.width && this.config.height) || this.config.imageSize)
        ) {
            return this.generateSourceSetUrl(applyBackground);
        }
        return this.getURLWithScene7Config(
            this.url,
            this.config,
            applyBackground,
        );
    }

    /**
     * Transforms the configuration into valid get parameters
     */
    private getURLWithScene7Config(
        urlWithoutScene7Config: string,
        config: ImageConfig,
        applyBackground = false,
    ): string {
        const url: URL = urlWithoutScene7Config.startsWith("//")
            ? new URL(`https:${urlWithoutScene7Config}`)
            : new URL(urlWithoutScene7Config);
        Object.entries(toScene7Config(config)).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });

        if (applyBackground === true) {
            return `${url.toString()}&${this.SCENE7_IMAGE_BACKGROUND_COLOR}`;
        }
        return url.toString();
    }

    /**
     * Generate a url for the sourceSet
     */
    private generateSourceSetUrl(applyBackground = false): string {
        const normalSizeUrl = this.getURLWithScene7Config(
            this.url,
            this.config,
        );
        const zoomSizeurl = this.getURLWithScene7Config(this.url, {
            ...this.config,
            ...(this.config.imageSize && {
                imageSize: this.config.imageSize * 2,
            }),
            ...(this.config.width && { width: this.config.width * 2 }),
            ...(this.config.height && { height: this.config.height * 2 }),
        });

        if (applyBackground === true) {
            return `${normalSizeUrl}&${this.SCENE7_IMAGE_BACKGROUND_COLOR} 1x, ${zoomSizeurl}&${this.SCENE7_IMAGE_BACKGROUND_COLOR} 2x`;
        }
        return `${normalSizeUrl} 1x, ${zoomSizeurl} 2x`;
    }
}

/**
 * https://experienceleague.adobe.com/docs/dynamic-media-developer-resources/image-serving-api/image-serving-api/http-protocol-reference/command-reference/c-command-reference.html?lang=en
 */
const toScene7Config = (config: ImageConfig): Record<string, string> => {
    let scene7config: Record<string, string> = {};
    if (config.width) {
        scene7config = {
            ...scene7config,
            wid: config.width.toString(),
        };
    }
    if (config.height) {
        scene7config = {
            ...scene7config,
            hei: config.height.toString(),
        };
    }
    if (config.imageSize) {
        scene7config = {
            ...scene7config,
            size: config.imageSize.toString(),
        };
    }
    if (config.quality) {
        scene7config = {
            ...scene7config,
            qlt: config.quality,
        };
    }
    if (config.opSharpen) {
        scene7config = {
            ...scene7config,
            op_sharpen: config.opSharpen.toString(),
        };
    }
    if (config.resmode) {
        scene7config = {
            ...scene7config,
            resMode: config.resmode,
        };
    }
    if (config.align) {
        scene7config = {
            ...scene7config,
            align: config.align,
        };
    }
    if (config.format) {
        scene7config = {
            ...scene7config,
            fmt: config.format,
        };
    }
    return scene7config;
};

export interface ImageConfig {
    width?: number;
    height?: number;
    imageSize?: number;
    quality?: string;
    sourceSet?: boolean;
    align?: string;
    opSharpen?: 0 | 1;
    format?: string;
    resmode?: "bilin" | "bicub" | "sharp2" | "bisharp" | "sharp";
}
