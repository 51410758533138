import { AnalyticsEvent } from "./analytics.event";

export interface GTMEventVirtualPageViewData {
    event: string;
    virtualpageuri: string;
}

/**
 * Absract class for call-to-action event
 */
export abstract class VirtualPageViewAnalyticsEvent
    implements AnalyticsEvent<GTMEventVirtualPageViewData>
{
    /**
     * Event's data from component to format correctly event
     */
    public eventData: any;

    /**
     * Event name expected for every uaevent event
     */
    public name: string = "virtualPageView";

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventVirtualPageViewData {
        return {
            event: this.name,
            virtualpageuri: "",
        };
    }
}
