import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { filter, first, map, withLatestFrom } from "rxjs/operators";

import { fetchMenu } from "../actions/menu.action";
import { FlatCategories } from "../model/menu.model";
import { State } from "../reducers/menu.reducer";
import {
    menuState,
    selectCategories,
    selectLinks,
    selectMenuEntry,
} from "../selectors/menu.selector";

@Injectable()
export class MenuFacade implements OnDestroy {
    public menuEntry$ = this.store.select(selectMenuEntry);
    public categories$ = this.store.select(selectCategories);
    public categoriesPaths$ = this.store.select(selectCategories).pipe(
        map((categories: FlatCategories | undefined) => {
            if (!categories) {
                return [];
            }
            const paths: string[] = [];
            Object.entries(categories).forEach(([_pimCode, categoryEntry]) => {
                paths.push(categoryEntry?.path);
            });
            return paths;
        }),
    );
    public links$ = this.store.select(selectLinks);

    private subscription = new Subscription();

    constructor(private store: Store<State>) {}

    public isFinalCategory = (pimCode: string): Observable<boolean> =>
        this.store.select(selectCategories).pipe(
            map((categories: FlatCategories | undefined) =>
                categories ? categories[pimCode] : categories,
            ),
            withLatestFrom(this.categoriesPaths$),
            map(
                ([category, categoriesPaths]) =>
                    // isFinalCategory if only one path found in all categories paths.
                    // e.g. "category/jddsii-jeu-de-donnees-sanctuarisees/jddsii-simpleproducts" found twice
                    // whereas "category/jddsii-jeu-de-donnees-sanctuarisees/jddsii-simpleproducts/jddsii-colorvariation" found once.
                    !!category &&
                    categoriesPaths.filter((path) =>
                        path.includes(category.path as string),
                    ).length === 1,
            ),
        );

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Trigger fetchMenu action if there is no menu data in state or pending menu
     */
    public fetchMenu(): void {
        this.subscription.add(
            this.store
                .select(menuState)
                .pipe(
                    first(),
                    filter((state) => !state.pending && !state.entry),
                )
                .subscribe(() => this.store.dispatch(fetchMenu())),
        );
    }
}
