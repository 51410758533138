import { Request, Response } from "express";

import { InjectionToken } from "@angular/core";

import type { EnvironmentConfiguration } from "@hermes/env-infra";
import { FeatureFlippingClient } from "@hermes/feature-flipping";
import { LocaleUrlPrefix } from "@hermes/locale";

/**
 * Injection token for the environment configuration.
 */
export const ENV_CONFIG = new InjectionToken<EnvironmentConfiguration>(
    "ENV_CONFIG",
);

/**
 * Injection token to provide APP_BASE_HREF to server
 */
export const ENV_CONFIG_FROM_SSR = new InjectionToken<EnvironmentConfiguration>(
    "ENV_CONFIG_FROM_SSR",
);

/**
 * Injection token to provide an Feature Flipping client (for SSR Only)
 */
export const FEATURE_FLIPPING_CLIENT_FROM_SSR =
    new InjectionToken<FeatureFlippingClient>(
        "FEATURE_FLIPPING_CLIENT_FROM_SSR",
    );

/**
 * Injection token for the request timeout.
 */
export const HTTP_REQUEST_TIMEOUT = new InjectionToken<number>(
    "HTTP_REQUEST_TIMEOUT",
);

/**
 * Injection token to provide APP_BASE_HREF to server
 */
export const APP_BASE_HREF_FROM_SSR = new InjectionToken<LocaleUrlPrefix>(
    "APP_BASE_HREF_FROM_SSR",
);

export const REQUEST = new InjectionToken<Request>("REQUEST");

export const RESPONSE = new InjectionToken<Response>("RESPONSE");
