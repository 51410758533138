import { HttpContextToken, HttpInterceptor } from "@angular/common/http";
import { InjectionToken, Type } from "@angular/core";

export const XSRF_TOKEN_NAME = "x-xsrf-token";
export const LOCALE_TOKEN_NAME = "x-hermes-locale";

export const APOLLO_INTERCEPTORS = new InjectionToken<
    Record<string, Array<Type<HttpInterceptor>>>
>("APOLLO_INTERCEPTORS");

export const APOLLO_DEFAULT_INTERCEPTORS = new InjectionToken<
    Array<Type<HttpInterceptor>>
>("APOLLO_DEFAULT_INTERCEPTORS");

export const SKIP_404_INTERCEPTOR = new HttpContextToken<boolean>(() => false);
