import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SvgModule, svgBackCurvedArrow } from "@hermes/aphrodite/svg";

import { BackToHomeButtonComponent } from "./components/back-to-home-button/back-to-home-button.component";

@NgModule({
    declarations: [BackToHomeButtonComponent],
    imports: [CommonModule, SvgModule.forChild([svgBackCurvedArrow])],
    exports: [BackToHomeButtonComponent],
})
export class BackToHomeButtonModule {}
