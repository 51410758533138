import { Injectable, Inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { map, tap } from "rxjs/operators";

import {
    closeAllOtherTrays,
    closeTray,
    closeTrayByName,
    openTray,
} from "../actions/tray.actions";
import {
    getTrayByPath,
    RouteLike,
    TRAY_MODULES,
} from "../helpers/tray.helpers";
import { TrayData } from "../models/tray-data.model";
import { TrayStackService } from "../services/tray-stack.service";

@Injectable()
export class TrayEffects {
    /**
     * Open a tray.
     */
    public openTray$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(openTray),
                map((tray: TrayData) => {
                    let pushedTray = tray;
                    const lazyLoadedTray = getTrayByPath(
                        this.trayList,
                        tray.name,
                    );
                    if (lazyLoadedTray) {
                        pushedTray = {
                            ...tray,
                            lazyModule: lazyLoadedTray.loadChildren,
                        };
                    }
                    this.trayStack.pushTray(pushedTray);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Close a tray
     */
    public closeTray$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(closeTray),
                map(() => {
                    this.trayStack.popTray();
                }),
            ),
        { dispatch: false },
    );

    /**
     * Close a specific tray with its name.
     */
    public closeTrayByName$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(closeTrayByName),
                map(({ name }) => {
                    const tray = this.trayStack.trays.find(
                        (t) => t.name === name,
                    );
                    if (tray) {
                        this.trayStack.closeTray(tray.uuid);
                    }
                }),
            ),
        { dispatch: false },
    );

    public closeAllOtherTrays$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(closeAllOtherTrays),
                map(({ name }) => {
                    this.trayStack.trays
                        .filter((openedTray) => openedTray.name !== name)
                        .forEach((openedTray) => {
                            this.trayStack.closeTray(openedTray.uuid);
                        });
                }),
            ),
        { dispatch: false },
    );

    public onNavigation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction),
                tap(() => {
                    this.trayStack.closeAllTrays();
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private trayStack: TrayStackService,
        @Inject(TRAY_MODULES) private trayList: RouteLike[],
    ) {}
}
