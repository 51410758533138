<ng-container *ngIf="isContactUsFromDiscovery$ | async; else oldContactUs">
    <span
        class="heading"
        role="heading"
        i18n-role
        aria-level="2"
        [attr.data-testid]="'footer-here-to-help'"
        i18n="@@hermes.customer-service.title"
        >Customer Service</span
    >
    <h-customer-service-contact [showEmailUs]="true"> </h-customer-service-contact
></ng-container>
<ng-template #oldContactUs>
    <span
        class="heading"
        role="heading"
        i18n-role
        aria-level="2"
        [attr.data-testid]="'footer-here-to-help'"
        *ngIf="title"
        >{{ title }}</span
    >
    <ul>
        <li *ngFor="let contact of customerServiceContacts">
            <p class="description-block">
                <span [innerHTML]="contact | openingHours" class="contact"> </span>
            </p>
            <div *ngIf="contact.phone" class="call-us" [attr.data-track-prefooter]="'call-us'">
                <a
                    href="tel:{{ contact.phone | phoneNumber }}"
                    (click)="sendFooterEvent($event, 'Contact', 'Call-us')"
                    >{{ contact.phone }}</a
                >
            </div>
        </li>
    </ul>
    <a
        class="email-us"
        *ngIf="!isChina"
        (click)="goToContactUsPage($event)"
        href="{{ baseHref }}/contact-us/"
        i18n="@@hermes_account.email-us">
        Email Us
    </a>
</ng-template>
