import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ExternalLibraryStateModule } from "@hermes/states/external-library";

import { LoadRiskifiedBeaconLibraryService } from "./services/load-riskified-beacon-library.service";
import { RiskifiedBeaconService } from "./services/riskified-beacon.service";

@NgModule({
    imports: [CommonModule, ExternalLibraryStateModule],
    providers: [RiskifiedBeaconService, LoadRiskifiedBeaconLibraryService],
})
export class RiskifiedBeaconServiceModule {}
