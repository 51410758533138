import { Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";

/**
 * Adds class="sr-only" and data-nosnippet attribute to element
 * Should only be used on div, span and section
 * (see : https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag?hl=fr#data-nosnippet-attr)
 */
@Directive({
    selector: "[hSrOnlyNoSnippet]",
    standalone: true,
})
export class SrOnlyNoSnippetDirective implements OnInit {
    constructor(private element: ElementRef, private renderer: Renderer2) {}

    public ngOnInit(): void {
        this.addSrOnlyClass();
        this.addDataNosnippet();
    }

    private addSrOnlyClass() {
        this.renderer.addClass(this.element.nativeElement, "sr-only");
    }

    private addDataNosnippet() {
        this.renderer.setAttribute(
            this.element.nativeElement,
            "data-nosnippet",
            "true",
        );
    }
}
