import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { CustomerService } from "@hermes/fragments/customer";
import { RecaptchaService } from "@hermes/utils-generic/services/client-api";

import { ApplePaySessionService } from "./services/apple-pay-session.service";
import { ApplePayService } from "./services/apple-pay.service";

@NgModule({
    imports: [CommonModule],
    providers: [
        ApplePayService,
        CustomerService,
        RecaptchaService,
        ApplePaySessionService,
    ],
})
export class ApplePayServiceModule {}
