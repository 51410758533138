import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from "@angular/router";

import type * as express from "express";
import { EMPTY, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Shop } from "@hermes/api-model-shop";
import { Context, RESPONSE } from "@hermes/app-core";
import { ShellFacade } from "@hermes/states/shell";
import { ShopsService } from "@hermes/utils/services/ui";

import { ShopAssetUrlPipe } from "../pipes/shop-asset-url.pipe";
import { SHELL_APPEARANCE_FULL } from "@hermes/utils-generic/constants";

@Injectable()
export class StorePageResolver {
    constructor(
        @Inject(RESPONSE) private response: express.Response,
        private shopsService: ShopsService,
        private shopAssetUrlPipe: ShopAssetUrlPipe,
        private context: Context,
        private router: Router,
        private shellFacade: ShellFacade,
    ) {}

    /**
     * @returns Observable store-page api response
     */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<Shop> {
        this.shellFacade.setShell(SHELL_APPEARANCE_FULL);
        const shopId = (route.params.id as string).split("-").pop();
        if (shopId) {
            return this.shopsService.findShopById(shopId).pipe(
                map(
                    (shop) =>
                        ({
                            ...shop,
                            assets: this.shopAssetUrlPipe.transform(
                                shop.assets,
                            ),
                        } as Shop),
                ),
                catchError(() => {
                    this.redirectToNotFound(state);
                    return EMPTY;
                }),
            );
        }

        this.redirectToNotFound(state);
        return EMPTY;
    }

    private redirectToNotFound(state: RouterStateSnapshot): void {
        if (this.context.isInServerMode()) {
            // on server rendering mode, send a 404 not found HTTP status
            this.response.status(404);
        }
        // redirect to 404-page without changing the url
        this.router.navigate(["/404-error"], {
            skipLocationChange: true,
            replaceUrl: false,
            queryParams: {
                originalUrl: state.url,
            },
        });
    }
}
