import { Pipe, PipeTransform } from "@angular/core";

import { SerializedBasket } from "@hermes/states/basket";

@Pipe({
    name: "cartLabel",
})
export class CartLabelPipe implements PipeTransform {
    public transform(basket: SerializedBasket | undefined | null): string {
        if (basket?.total_items === 1) {
            return $localize`:@@hermes.accessibility.cart_single:item`;
        } else if (basket?.total_items && basket.total_items > 1) {
            return $localize`:@@hermes.accessibility.cart_plural:items`;
        }

        return $localize`:@@hermes.accessibility.cart_empty:empty`;
    }
}
