// TODO: this lists of locale has been move to app-core to speed up modularization, but it should be in its own currency module

import { InjectionToken } from "@angular/core";

import { Country, Lang, Locale, LocaleId } from "@hermes/locale";

export const LOCALE = new InjectionToken<Locale>("LOCALE");
export const LANG = new InjectionToken<Lang>("LANG");
export const COUNTRY = new InjectionToken<Country>("COUNTRY");

// list of ANGULAR LOCALES constants (ISO)
export const DEAT: LocaleId = "de-AT";
export const DECH: LocaleId = "de-CH";
export const DE: LocaleId = "de";
export const ENAE: LocaleId = "en-AE";
export const ENAU: LocaleId = "en-AU";
export const ENBE: LocaleId = "en-BE";
export const ENCA: LocaleId = "en-CA";
export const ENCN: LocaleId = "en-CN";
export const ENCZ: LocaleId = "en-CZ";
export const ENDK: LocaleId = "en-DK";
export const ENFI: LocaleId = "en-FI";
export const ENGB: LocaleId = "en-GB";
export const ENHK: LocaleId = "en-HK";
export const ENIE: LocaleId = "en-IE";
export const ENMO: LocaleId = "en-MO";
export const ENMY: LocaleId = "en-MY";
export const ENNL: LocaleId = "en-NL";
export const ENNO: LocaleId = "en-NO";
export const ENPL: LocaleId = "en-PL";
export const ENPT: LocaleId = "en-PT";
export const ENSA: LocaleId = "en-SA";
export const ENSE: LocaleId = "en-SE";
export const ENSG: LocaleId = "en-SG";
export const ENTH: LocaleId = "en-TH";
export const EN: LocaleId = "en";
export const ESMX: LocaleId = "es-MX";
export const ES: LocaleId = "es";
export const FRBE: LocaleId = "fr-BE";
export const FRCA: LocaleId = "fr-CA";
export const FRCH: LocaleId = "fr-CH";
export const FR: LocaleId = "fr";
export const FRLU: LocaleId = "fr-LU";
export const IT: LocaleId = "it";
export const JA: LocaleId = "ja";
export const KO: LocaleId = "ko";
export const PT: LocaleId = "pt";
export const ZHHANS: LocaleId = "zh-Hans";
export const ZHHANT: LocaleId = "zh-Hant";

// list of CURRENCIES constants
export const EUR = "EUR";
export const GBP = "GBP";
export const CAD = "CAD";
export const CNY = "CNY";
export const CHF = "CHF";
export const DKK = "DKK";
export const USD = "USD";
export const SEK = "SEK";
export const NOK = "NOK";
export const CZK = "CZK";
export const AUD = "AUD";
export const JPY = "JPY";
export const SGD = "SGD";
export const MYR = "MYR";
export const HKD = "HKD";
export const MOP = "MOP";
export const TWD = "TWD";
export const KRW = "KRW";
export const PLN = "PLN";
export const AED = "AED";
export const SAR = "SAR";
export const THB = "THB";
export const BRL = "BRL";
export const MXN = "MXN";

export const localesCurrency: Array<[LocaleId, string, string, string]> = [
    [DEAT, EUR, "symbol", "full"],
    [DECH, CHF, "symbol", "full"],
    [DE, EUR, "symbol", "full"],
    [ENAE, AED, "AED ", "full"],
    [ENAU, AUD, "AU$", "full"],
    [ENBE, EUR, "symbol", "full"],
    [ENCA, CAD, "CA$", "full"],
    [ENCN, CNY, "symbol", "full"],
    [ENCZ, CZK, "symbol", "full"],
    [ENDK, DKK, "symbol", "full"],
    [ENFI, EUR, "symbol", "full"],
    [ENGB, GBP, "symbol", "full"],
    [ENHK, HKD, "HKD ", "full"],
    [ENIE, EUR, "symbol", "full"],
    [ENMO, MOP, "MOP ", "full"],
    [ENMY, MYR, "RM ", "full"],
    [ENNL, EUR, "symbol", "full"],
    [ENNO, NOK, "symbol", "full"],
    [ENPL, PLN, "zł ", "full"],
    [ENPT, EUR, "symbol", "full"],
    [ENSA, SAR, "SAR ", "full"],
    [ENSE, SEK, "symbol", "full"],
    [ENSG, SGD, "S$ ", "full"],
    [ENTH, THB, "฿ ", "full"],
    [EN, USD, "symbol", "full"],
    [ESMX, MXN, "MXN$ ", "full"],
    [ES, EUR, "symbol", "full"],
    [FRBE, EUR, "symbol", "full"],
    [FRCA, CAD, "$CA", "full"],
    [FRCH, CHF, "symbol", "full"],
    [FR, EUR, "symbol", "full"],
    [FRLU, EUR, "symbol", "full"],
    [IT, EUR, "symbol", "full"],
    [JA, JPY, "symbol", "short"],
    [KO, KRW, "₩ ", "full"],
    [PT, BRL, "R$ ", "full"],
    [ZHHANS, CNY, "symbol", "full"],
    [ZHHANT, TWD, "NT$ ", "full"],
];
