<ng-container *ngIf="customerServiceConctact$ | async as contacts">
    <div>
        <div *ngFor="let contact of contacts" class="contact">
            <p class="description-block" [innerHTML]="contact | openingHours"></p>
            <div *ngIf="contact.phone" class="call-us" [attr.data-track-prefooter]="'call-us'">
                <a href="tel:{{ contact.phone | phoneNumber }}" (click)="sendEventClickToCall()">{{ contact.phone }}</a>
            </div>
        </div>

        <div class="email-us">
            <a
                *ngIf="!isChina && showEmailUs"
                (click)="goToContactUsPage($event)"
                href="{{ baseHref }}/contact-us/"
                i18n="@@hermes_account.email-us">
                Email Us
            </a>
        </div>
    </div>
</ng-container>
