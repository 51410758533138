import { Component } from "@angular/core";
import { ActivatedRoute, Data, NavigationEnd, Router } from "@angular/router";

import { Observable, filter, map } from "rxjs";

import { LayoutMarginType } from "@hermes/utils-generic/constants";

/**
 * Main component displaying the rooter outlet
 */
@Component({
    selector: "h-main-content",
    styleUrls: ["./main-content.component.scss"],
    templateUrl: "./main-content.component.html",
})
export class MainContentComponent {
    public layoutTypeObservable$: Observable<LayoutMarginType> =
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.getRouteData(this.activatedRoute.root).layoutType),
        );

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}

    // We use recursive function to get active route data
    private getRouteData(route: ActivatedRoute): Data {
        if (route.firstChild) {
            return this.getRouteData(route.firstChild);
        }

        return route.snapshot.data;
    }
}
