import { persoAnalyticsConstant } from "./perso-analytics.constants";

export const SMLG_ANALYTICS_CATEGORY = "PmPage";

export const SMLG_EXPANDS_ANALYTICS: {
    [key: string]: { action: string; label?: string };
} = {
    leather: {
        action: persoAnalyticsConstant.leatherSelector,
        label: persoAnalyticsConstant.clickLeatherMenu,
    },
    exteriorColor: {
        action: persoAnalyticsConstant.exteriorSelector,
        label: persoAnalyticsConstant.clickLeatherMenu,
    },
    liningColor: {
        action: persoAnalyticsConstant.liningSelector,
        label: persoAnalyticsConstant.clickLeatherMenu,
    },
    marking: {
        action: persoAnalyticsConstant.markingSelector,
        label: persoAnalyticsConstant.clickMarkingMenu,
    },
    font: {
        action: persoAnalyticsConstant.typographySelector,
        label: persoAnalyticsConstant.clickTypographyMenu,
    },
    fontMarkingZone: { action: persoAnalyticsConstant.typographySelector },
    fontStyle: { action: persoAnalyticsConstant.typographySelector },
    fontSize: { action: persoAnalyticsConstant.typographySelector },
    fontColor: { action: persoAnalyticsConstant.typographySelector },
};

export const SMLG_PRODUCT_TYPE_ANALYTICS = "smallleathergoods";
