import { NONE } from "@hermes/aphrodite/dynamic-box";
import {
    BoxType,
    Product,
    ProductFinishVariant,
    ProductSizeVariant,
    ProductStrapVariant,
} from "@hermes/api-model-product";

import {
    hasStrapVariant,
    hasSizeVariant,
    hasFinishVariant,
} from "@hermes/utils/helpers";

import { ProductPageState } from "../../reducers/product-page.state";

/**
 * On fetch of AWH product :
 * - calculate wich variant is selected by default for strap leather and size
 * - calculate if product is OOS disable button and wich warning to display
 */
export const fetchAppleWatchProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => {
    // calculate selected variants
    const selectedStrap: ProductStrapVariant | undefined = hasStrapVariant(
        product,
    )
        ? product.variants?.straps.find(
              (variant) =>
                  variant.itemSku === product.appleWatchAttributes?.skuStrap,
          )
        : undefined;
    const selectedSize: ProductSizeVariant | undefined = hasSizeVariant(product)
        ? product.variants?.sizes.find(
              (variant) =>
                  variant.size ===
                  product.appleWatchAttributes?.modelSizeGuizmo,
          )
        : undefined;
    const selectedFinish: ProductFinishVariant | undefined = hasFinishVariant(
        product,
    )
        ? product.variants?.finishes.find(
              (variant) =>
                  variant.itemSku === product.appleWatchAttributes?.skuGuizmo,
          )
        : undefined;

    const variantSelectedBoxType: BoxType =
        (state.selectedVariants?.[0]?.strap?.boxType ||
            state.selectedVariants?.[0]?.size?.boxType ||
            state.selectedVariants?.[0]?.finish?.boxType ||
            state.selectedVariants?.[0]?.strapSize?.boxType) ??
        NONE;

    return {
        ...state,
        productSKU: product.sku,
        currentProduct: product,
        type: product.templateType,
        selectedVariants: [
            {
                strap: selectedStrap,
                size: selectedSize,
                finish: selectedFinish,
                // strap size is not selected by default
                strapSize: undefined,
            },
        ],
        isLoading: false,
        variantSelectedBoxType,
    };
};
