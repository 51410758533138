import { Action, createReducer, on } from "@ngrx/store";

import { Guest, User } from "@hermes/api-model-account";

import {
    clearData,
    fetchGuestSuccess,
    removeGuestSuccess,
    setUserFromStorage,
    setUserInStorageFailure,
    setUserInStorageSuccess,
    userSynchroWithStorageFailure,
} from "../actions/user.actions";

import { initialState, UserState } from "./user.state";

export const userFeatureKey = "user";

const fetchGuestSuccessReducer = (
    state: UserState,
    action: ReturnType<typeof fetchGuestSuccess>,
): UserState => ({
    ...state,
    guest: action.guest,
});

const removeGuestSuccessReducer = (state: UserState): UserState => ({
    ...state,
    guest: undefined as unknown as Guest,
});

const setUserFromStorageReducer = (
    state: UserState,
    action: ReturnType<typeof setUserFromStorage>,
): UserState => ({
    ...state,
    userStatus: "synchronized",
    details: {
        ...action.user,
        // user  must be decorrelated to basket and shippingAddresses
        basket: undefined,
        shippingAddresses: undefined,
    } as unknown as User,
});

const setUserInStorageSuccessReducer = (
    state: UserState,
    action: ReturnType<typeof setUserInStorageSuccess>,
): UserState => ({
    ...state,
    details: {
        ...action.user,
        basket: undefined,
        shippingAddresses: undefined,
    } as unknown as User,
});

const userFailureReducer = (
    state: UserState,
    action: ReturnType<
        typeof userSynchroWithStorageFailure | typeof setUserInStorageFailure
    >,
): UserState => ({
    ...state,
    userStatus: "error",
    userError: action.errorMessage,
});

const clearUserDataReducer = (): UserState => initialState;

const userReducer = createReducer(
    initialState,
    on(fetchGuestSuccess, fetchGuestSuccessReducer),
    on(removeGuestSuccess, removeGuestSuccessReducer),
    on(setUserFromStorage, setUserFromStorageReducer),
    on(setUserInStorageSuccess, setUserInStorageSuccessReducer),
    on(
        userSynchroWithStorageFailure,
        setUserInStorageFailure,
        userFailureReducer,
    ),
    on(clearData, clearUserDataReducer),
);

export function reducer(
    state: UserState | undefined,
    action: Action,
): UserState {
    return userReducer(state, action);
}
