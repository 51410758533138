// Global contants used by way too many entities to be elsewhere

import exportedSass from "@hermes/aphrodite/design-system-tools";
import {
    DEVICE_LARGE_NAME,
    DEVICE_MEDIUM_NAME,
    DeviceType,
} from "@hermes/utils-generic/constants";

export const EVENT_UAEVENT = "uaevent";
export const HERMES_BRAND = "hermes";
export const NODATA = "N/A";
export const NO_SIZE = "SANS_TAILLE";
export const PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY = "displayonly";
export const PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK =
    "displayonly_backinstock";
export const PRODUCT_AVAILABILITY_STATUS_ECOM = "ecom";
export const PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY = "ecom_displayonly";
export const PRODUCT_AVAILABILITY_STATUS_ECOM_STORE = "ecom_store";
export const PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY =
    "ecom_store_displayonly";
export const PRODUCT_AVAILABILITY_STATUS_STORE = "store";
export const PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY =
    "store_displayonly";
export const PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK =
    "store_displayonly_backinstock";
export const PRODUCT_IN_STOCK = "In stock";
export const PRODUCT_OUT_OF_STOCK = "Out of stock";
export const PRODUCT_DISPLAY_ONLY = "display only";
export const PAGEVIEW_EVENT = "pageview";
export const VIRTUAL_PAGEVIEW_EVENT = "virtualPageview";
export const HEROPRODUCT_VIDEO = "heroproduct_video";
export const HEROPRODUCT_IMAGE = "heroproduct_image";
export const THUMBNAIL_VIDEO = "thumbnail_video";
export const THUMBNAIL_IMAGE = "thumbnail_image";
export const YES = "yes";
export const NO = "no";

export const DISPLAY_ONLY_MODE = "oos_display";
export const BACK_IN_STOCK_MODE = "back_in_stock_display";

// keep only here, Design System deliberately doesn't want a tablet (it's in the rules defined for the project)
// but if Analytics needs tablet const for its stats, it can keep it here
const DEVICE_TABLET_NAME = "tablet";
const DEVICE_TABLET_WIDTH = 1024;
type DeviceTabletName = typeof DEVICE_TABLET_NAME;
type AnalyticsDeviceType = DeviceType | DeviceTabletName;

export type GTMBooleanType = typeof YES | typeof NO;

export type AvailabilityHitType =
    | typeof NODATA
    | typeof PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY
    | typeof PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK
    | typeof PRODUCT_AVAILABILITY_STATUS_ECOM
    | typeof PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY
    | typeof PRODUCT_AVAILABILITY_STATUS_ECOM_STORE
    | typeof PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY
    | typeof PRODUCT_AVAILABILITY_STATUS_STORE
    | typeof PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY
    | typeof PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK
    | typeof PRODUCT_DISPLAY_ONLY
    | undefined;

/**
 * Return the type of device depending on the width of the screen
 * Use only for analytics
 */
export const getAnalyticsDeviceType = (): AnalyticsDeviceType => {
    if (
        window.screen.width <
        Number.parseInt(
            exportedSass.breakpoints["breakpoint-medium"].replace("px", ""),
            10,
        )
    ) {
        return DEVICE_MEDIUM_NAME;
    }

    if (window.screen.width < DEVICE_TABLET_WIDTH) {
        return DEVICE_TABLET_NAME;
    }

    return DEVICE_LARGE_NAME;
};
