import { Component } from "@angular/core";

/**
 * This component is the error page shown when the error-handler intercepts a client error.
 *
 */
@Component({
    selector: "h-error-page",
    templateUrl: "./error-page.component.html",
    styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent {}
