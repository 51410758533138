import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { NavigationService } from "@hermes/utils-generic/services/user-interface";
import { FocusService } from "@hermes/utils-generic/services/focus";

import { OverlayComponent } from "./components/overlay/overlay.component";
import { OverlayErrorMessageComponent } from "./components/overlay-error-message/overlay-error-message.component";
import { LayoutEffects } from "./effects/layout.effects";
import { LayoutFacade } from "./facades/layout.facade";
import { layoutFeatureKey, reducer } from "./reducers/layout.reducer";
import { NotificationsService } from "./services/notifications.service";

@NgModule({
    declarations: [OverlayErrorMessageComponent, OverlayComponent],
    exports: [OverlayComponent],
    imports: [
        CommonModule,
        StoreModule.forFeature(layoutFeatureKey, reducer),
        EffectsModule.forFeature([LayoutEffects]),
    ],
    providers: [
        FocusService,
        LayoutFacade,
        NavigationService,
        NotificationsService,
    ],
})
export class LayoutStateModule {}
