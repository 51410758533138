import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";
import { Subscription } from "rxjs";

import { FeatureFlagName } from "../constants/feature-flags.constant";
import { FeatureFlagFacade } from "../facades/feature-flag.facade";

@Directive({
    selector: "[hFeatureFlag]",
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
    @Input()
    public hFeatureFlag!: FeatureFlagName;

    @Input()
    public hFeatureFlagElse!: TemplateRef<unknown>;

    private subscription: Subscription = new Subscription();

    constructor(
        private vcr: ViewContainerRef,
        private templateReference: TemplateRef<unknown>,
        private featureFlagFacade: FeatureFlagFacade,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.featureFlagFacade
                .isActivated(this.hFeatureFlag)
                .subscribe((isActivated: boolean) => {
                    this.displayTemplate(
                        isActivated
                            ? this.templateReference
                            : this.hFeatureFlagElse,
                    );
                }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * display template
     */
    protected displayTemplate(template: TemplateRef<unknown>): void {
        if (template) {
            this.vcr.createEmbeddedView(template);
            this.cdr.detectChanges();
        }
    }
}
