import { Component } from "@angular/core";

import { stopEventPropagation } from "@hermes/utils-generic/helpers";

import { LayoutFacade } from "../../facades/layout.facade";

/**
 * Overlay for Notifications an loader displaying
 */
@Component({
    selector: "h-overlay",
    templateUrl: "./overlay.component.html",
    styleUrls: ["./overlay.component.scss"],
})
export class OverlayComponent {
    constructor(public layoutFacade: LayoutFacade) {}

    /**
     * Function launched during a click or a keypress on the document, it prevent the default behavior and
     * stop it's propagation
     *
     * @param event Event lauched by the browser (could be a click, a touch or a keyboard event)
     */
    public lockApp(event: Event): void {
        stopEventPropagation(event);
    }
}
