import {
    AnalyticsEvent,
    EVENT_UAEVENT,
    GTMEventData,
} from "@hermes/utils/analytics";

/**
 * Abstract class for variation-click event
 */
export abstract class VariationClickAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = EVENT_UAEVENT;

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: "SingleProductPage",
        };
    }
}
