import { Component, Input } from "@angular/core";

import { ModalDataApplePay, ModalFacade } from "@hermes/states/modal";

import { modalAnimations } from "../../animations/modal.animation";

@Component({
    animations: modalAnimations,
    selector: "h-modal-terms-and-conditions",
    templateUrl: "./modal-terms-and-conditions.component.html",
    styleUrls: ["./modal-terms-and-conditions.component.scss"],
})
export class ModalTermsAndConditionsComponent {
    @Input()
    public modalData!: ModalDataApplePay;

    constructor(private modalFacade: ModalFacade) {}

    public onModalExit(): void {
        this.modalFacade.closeModal(false);
    }
}
