import { Inject, Injectable, InjectionToken } from "@angular/core";

import { InfraMap, InfraSettings } from "@hermes/env-infra";

import { Context } from "./context";

/**
 * This allow us to inject the current
 */
export const INFRA_SETTINGS: InjectionToken<InfraSettings> =
    new InjectionToken<InfraSettings>("ENV_SETTINGS");

/**
 * Injection token for all the env-infra config.
 */
export const INFRA_MAP: InjectionToken<InfraMap> = new InjectionToken<InfraMap>(
    "ENV_INFRA",
);

/**
 * InfraSettings Factory, extract value from EnvInfra map.
 * Values are extracted from the InfraMap depending to host.
 */
export function infraSettingsFactory(
    context: Context,
    environmentInfra: InfraMap,
): InfraSettings {
    let host = context.getCurrentHost();
    // In case the host contain a :port we keep only the domain
    // to avoid to define all mappings for browser serve
    if (host.match(":")) {
        [host] = host.split(":");
    }
    if (environmentInfra[host]) {
        return environmentInfra[host];
    }
    throw new Error(
        `No configuration found in environment vars for the host <${host}>`,
    );
}

/**
 * This class is used to declare some global constants that depends on environnment.
 */
@Injectable({ providedIn: "root" })
export class Settings {
    constructor(@Inject(INFRA_SETTINGS) private settings: InfraSettings) {}

    /**
     * Url for the content-manager api.
     */
    public get apiUrl(): string {
        return this.settings.backendCSR;
    }

    /**
     * Environment (ppd, prd, ts3, int1, etc...)
     */
    public get envKey(): string {
        return this.settings.environment;
    }

    /**
     * Base url for Edito assets.
     */
    public get assetsUrlEditoContent(): string {
        return `${this.settings.assets}/is/content/hermesedito/`;
    }

    /**
     * Base url for Edito images assets.
     */
    public get assetsUrlEditoImage(): string {
        return `${this.settings.assets}/is/image/hermesedito/`;
    }

    /**
     * Base url for product assets.
     */
    public get assetsUrlProduct(): string {
        return `${this.settings.assets}/is/image/hermesproduct/`;
    }

    /**
     * Base url for product assets on sandbox.
     */
    public get assetsUrlProductSandbox(): string {
        return `${this.settings.assets}/is/image/hermessandboxprep/`;
    }
}
