export function generateUrl(
    urlPrefix: string,
    slug: string,
    skuParts: string[],
): string {
    let link = `${urlPrefix}/product/${slug}-${skuParts.shift()}`;
    skuParts.forEach((skuPart) => {
        link = `${link}/${skuPart}`;
    });
    // slash at the end
    return `${link}/`;
}
