export enum ProductTemplateType {
    Simple = "simple",
    Beltkit = "beltkit",
    DoubleFragrance = "doublefragrance",
    AppleWatch = "apple-watch",
    Giftset = "giftset",
    PersoSMLG = "perso-smlg",
    PersoBeltkit = "perso-beltkit",
    PersoSilk = "perso-silk",
    Look = "look",
    Bikini = "bikini",
}
