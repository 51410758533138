import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ExternalLibraryFacade } from "@hermes/states/external-library";

const LIBRARY_KEY = "RISKIFIED_BEACON";
@Injectable()
export class LoadRiskifiedBeaconLibraryService {
    public isLoaded$: Observable<boolean> =
        this.externalLibraryFacade.isLoaded$(LIBRARY_KEY);

    public isError$: Observable<boolean> =
        this.externalLibraryFacade.isError$(LIBRARY_KEY);

    constructor(private externalLibraryFacade: ExternalLibraryFacade) {}

    public load(shop: string, id: string): void {
        this.externalLibraryFacade.load({
            url: `https://beacon.riskified.com?shop=${shop}&sid=${id}`,
            windowAttribute: "RISKX",
            key: LIBRARY_KEY,
        });
    }
}
