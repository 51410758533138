import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { NotificationsService } from "@hermes/aphrodite/layout";
import { Context, Settings } from "@hermes/app-core";
import { XSRF_TOKEN_NAME } from "@hermes/utils/constants";

@Injectable()
export class XsrfTokenResolver {
    constructor(
        private context: Context,
        private notificationsService: NotificationsService,
        private http: HttpClient,
        private settings: Settings,
        private cookieService: CookieService,
    ) {}

    public resolve(): Observable<undefined> {
        if (
            this.context.isInBrowserMode() &&
            !this.cookieService.get(XSRF_TOKEN_NAME)
        ) {
            return this.getXsrfToken();
        }
        // We want the resolver to always succeed in this case, hence undefined
        // instead of EMPTY.
        return of(undefined);
    }

    public getXsrfToken(): Observable<undefined> {
        return this.http
            .get<undefined>(`${this.settings.apiUrl}/sync-form`)
            .pipe(
                map(() => undefined),
                catchError(() => {
                    // In case of error, show technical error notification,
                    // but do not crash the resolve process
                    // (it must go through for the notification component to be loaded)
                    this.notificationsService.technicalError(
                        "Error fetching xsrf token",
                    );
                    return of(undefined);
                }),
            );
    }
}
