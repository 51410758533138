import {
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";

/**
 * A structural directive that recreates a template based on changes on the
 * input value.
 * When the input value changes, Angular destroys all views and
 * then instantiates an embedded view and inserts it.
 *
 * ⚠️ Mutations on value are not handled.
 *
 * Quick example :
 *
 * ```
 * <div *hRedraw="value">
 *  Content to render when "value" changes.
 * </div>
 * ```
 *
 * @usageNotes
 *
 *
 * The `*hRedraw` directive is most commonly used to recreate DOM elements
 * based on value changes. Instead of updating interpolations and property
 * bindings, you might want to regenerate the template.
 *
 * Some screenreaders do not handle atomic changes in DOM, this
 * directive helps screenreaders to detect changes.
 *
 * However, the directive should be avoided when possible and only used as a
 * workaround solution.
 */
@Directive({ selector: "[hRedraw]" })
export class RedrawDirective<T> implements OnChanges {
    /**
     * Value to observe
     */
    @Input()
    public hRedraw!: T;

    constructor(
        private templateReference: TemplateRef<T>,
        private viewContainer: ViewContainerRef,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes["hRedraw"].currentValue !== changes["hRedraw"].previousValue
        ) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateReference);
        }
    }
}
