import { QUICK_BUY } from "@hermes/states/add-to-cart";
import { getEventCategory } from "@hermes/states/product-page";
import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

import {
    CLICK_NOTIF_CLOSE_CART,
    CLICK_NOTIF_VIEW_CART,
} from "../constants/modal.constants";

/**
 * This class is used for notif analytics events
 */
export class ProductInfoNotifClickEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: Record<string, unknown>;

    constructor(data: Record<string, unknown>) {
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(
            this.eventData.templateType as string,
            this.eventData.context as string,
        );
        switch (this.eventData.eventType) {
            case CLICK_NOTIF_VIEW_CART: {
                return {
                    event: this.name,
                    eventcategory,
                    eventlabel: `ViewCartButton${
                        this.eventData.context === QUICK_BUY ? "_Quickbuy" : ""
                    }`,
                    eventaction: "Addtocart_Notification",
                };
            }
            case CLICK_NOTIF_CLOSE_CART: {
                return {
                    event: this.name,
                    eventcategory,
                    eventlabel: "Close",
                    eventaction: "Addtocart_Notification",
                };
            }
            default: {
                return {
                    event: this.name,
                    eventcategory: "SingleProductPage",
                };
            }
        }
    }
}
