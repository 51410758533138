<div [style]="textInputAreaStyle">
    <div class="textarea-input-item">
        <textarea
            #textArea
            [id]="id"
            [name]="name"
            [formControl]="control"
            [rows]="rows"
            [wrap]="'soft'"
            [readOnly]="readOnly"
            [required]="isRequired()"
            [placeholder]="placeholder || '&nbsp;'"
            [attr.maxlength]="maxLength"
            [attr.aria-multiline]="'true'"
            [attr.aria-readonly]="readOnly"
            [attr.aria-disabled]="isDisabled()"
            [attr.aria-invalid]="(errorManagement || enableAriaInvalid) && controlHaveErrors()"
            [attr.data-testid]="dataTestId"
            [attr.aria-describedby]="ariaDescribedby | ariaDescribedbyPipe : id"
            class="textarea-input"
            [class.textarea-input-error]="(errorManagement || enableAriaInvalid) && controlHaveErrors()"
            [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
            [class.center-text]="centerText"
            [class.disable-resize]="disableResize"
            [style.font-size]="customFontSize"
            (keydown.enter)="forceBlur($event)">
        </textarea>
        <label [for]="id" class="textarea-label">
            {{ label }}
            <!-- '*' is an universal identifier for required input fields, no translation is used then -->
            <span *ngIf="label && isRequired()" [attr.aria-hidden]="'true'">{{ " *" }}</span>
            <span hSrOnlyNoSnippet *ngIf="describe">{{ describe }}</span>
        </label>
    </div>
    <div class="length-box" *ngIf="maxLength && formControlDirective.value.length > 0">
        <span [attr.aria-live]="'polite'">
            {{ maxLength - formControlDirective.value.length + "/" + maxLength }}
        </span>
    </div>
    <div [id]="'input-error-' + id" *ngIf="errorManagement">
        <div #content>
            <!-- Required message -->
            <h-message-block
                *ngIf="controlHaveErrors() && control.errors?.required"
                [showBackground]="false"
                [autoAddPadding]="false"
                [ariaRole]="undefined"
                type="error">
                <ng-container i18n="@@hermes.common.required_information_input"> Required information</ng-container>
            </h-message-block>
            <ng-content></ng-content>
        </div>
        <span *ngIf="content.children.length !== 0" hSrOnlyNoSnippet>{{ label }}</span>
    </div>
</div>
