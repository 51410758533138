import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ModelObject } from "@hermes/api-model-core";
import { SearchSuggestion } from "@hermes/api-model-shell";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { FlatCategories } from "@hermes/states/menu";

import {
    formatAndFilterCategorySuggestions,
    formatProductSuggestions,
} from "../helpers/header-search.helper";
import { Suggestion } from "../model/suggestions";

@Injectable()
export class HeaderSearchService {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private httpClient: HttpClient,
        private settings: Settings,
    ) {}

    public getSuggestions(
        term: string,
        categories: FlatCategories,
    ): Observable<Suggestion[]> {
        return this.httpClient
            .get(`${this.settings.apiUrl}/search/suggest`, {
                params: {
                    terms: term,
                    locale: this.locale.code,
                },
            })
            .pipe(
                map((response) => {
                    const suggestions = ModelObject.fromJsonData(
                        response,
                        SearchSuggestion,
                    );
                    const categorySuggestion =
                        formatAndFilterCategorySuggestions(
                            suggestions.categories,
                            categories,
                        );
                    return [
                        ...categorySuggestion,
                        ...formatProductSuggestions(
                            this.locale.urlPrefix,
                            suggestions.products,
                            categorySuggestion.length,
                        ),
                    ];
                }),
            );
    }
}
