import { CountryCode } from "@hermes/locale";

export interface CustomerDisplayedFieldsType {
    dateOfBirth?: boolean;
    cpf?: boolean;
    pipa?: boolean;
    gtc?: boolean;
    gtcs?: boolean;
    newsletter?: boolean;
    communicationConsent?: boolean;
    katakanaAndKanjiFirstLastName?: boolean;
    extraPhoneInformation?: boolean;
    extraNameInformation?: boolean;
    gender?: boolean;
    sama?: boolean;
}

export const defaultCustomerDisplayedFields: CustomerDisplayedFieldsType = {
    newsletter: true,
};

export const customerDisplayedFields: Partial<{
    [key in CountryCode]: CustomerDisplayedFieldsType;
}> = {
    br: {
        cpf: true,
        newsletter: true,
    },
    cn: {
        communicationConsent: true,
        gtc: true,
    },
    hk: {
        newsletter: true,
        extraNameInformation: true,
    },
    jp: {
        dateOfBirth: true,
        newsletter: true,
        katakanaAndKanjiFirstLastName: true,
        extraPhoneInformation: true,
        gender: true,
        sama: true,
    },
    kr: {
        dateOfBirth: true,
        gender: true,
        pipa: true,
        newsletter: true,
    },
    mo: {
        newsletter: true,
        extraNameInformation: true,
    },
    tw: {
        gtcs: true,
        newsletter: true,
    },
};

export const ageOfMajority: Partial<Record<CountryCode, number>> = {
    kr: 19,
};
