import { NgModule } from "@angular/core";

import { FetchCountriesService } from "./fetch-countries.service";

@NgModule({
    imports: [],
    providers: [FetchCountriesService],
    exports: [],
})
export class FetchCountriesModule {}
