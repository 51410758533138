import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { MenuEntry } from "@hermes/api-model-shell";
import { LOCALE, StorageService } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { CMSService } from "@hermes/utils/services/api-clients";

// the categories lifetime in minutes
export const CATEGORIES_VALIDITY_PERIOD = 5;

@Injectable()
export class MenuService {
    private menuStorageKey = `menu_${this.locale.code}`;
    private localStorage = this.storageService.getLocalStorageInstance();

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private cmsService: CMSService,
        private storageService: StorageService,
    ) {}

    public fetchMenu(): Observable<MenuEntry> {
        const storedMenu: MenuEntry | undefined = this.getMenuFromStorage();

        if (storedMenu) {
            return of(storedMenu);
        }

        return this.cmsService.fetchCategories().pipe(
            tap((menu) => {
                if (menu.tid) {
                    this.putMenuInStorage(menu);
                }
            }),
        );
    }

    private getMenuFromStorage(): MenuEntry | undefined {
        return this.localStorage?.getItem(this.menuStorageKey);
    }

    private putMenuInStorage(menu: MenuEntry): void {
        this.localStorage?.setItem(
            this.menuStorageKey,
            menu,
            CATEGORIES_VALIDITY_PERIOD,
        );
    }
}
