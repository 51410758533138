import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "prefixWith",
})
export class PrefixWithPipe implements PipeTransform {
    public transform(value: string, prefix: string): string {
        if (!value) {
            return prefix || "";
        }
        if (!prefix) {
            return value;
        }
        return prefix + value;
    }
}
