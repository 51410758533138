import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { BannersData } from "@hermes/api-model-shell";
import {
    Context,
    StorageManager,
    StorageService,
    WINDOW,
} from "@hermes/app-core";
import { MenuFacade } from "@hermes/states/menu";

import { TrayStackService } from "@hermes/states/tray";
import { ScrollType } from "@hermes/utils-generic/constants";
import { WindowWithResizeObserver } from "@hermes/utils-generic/helpers";

import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

export interface HeaderInnerState {
    scroll: ScrollType;
    banners: BannersData;
}

/**
 * Angular full header.
 */
@Component({
    selector: "h-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Reference to the wrapper of entire header (headerLinksContainer + headerContainer).
     */
    @ViewChild("headerContainer", { static: true })
    public headerContainerEl!: ElementRef<HTMLElement>;
    /**
     * Reference to the wrapper of the section containing links (country selector, find store, contact us).
     */
    @ViewChild("headerLinksContainer")
    public headerLinksContainerEl!: ElementRef<HTMLElement>;
    /**
     * Reference to the wrapper of main header content (menu, search, account, basket).
     */
    @ViewChild("headerMainContainer", { static: true })
    public headerMainContainerEl!: ElementRef<HTMLElement>;

    /**
     * State of the component.
     */
    public state!: HeaderInnerState;

    /**
     * Inputs for account creation banner
     */
    public isAccountCreated: boolean = false;
    public isAccountCreatedByWechat: boolean = false;

    public subscription: Subscription = new Subscription();

    private sessionStorage: StorageManager | undefined;

    constructor(
        @Inject(WINDOW) private window: WindowWithResizeObserver,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private zone: NgZone,
        private context: Context,
        private cdr: ChangeDetectorRef,
        private layoutFacade: LayoutFacade,
        private trayStack: TrayStackService,
        public menuFacade: MenuFacade,
        public breakpointService: BreakpointService,
        storageService: StorageService,
    ) {
        this.sessionStorage = storageService.getSessionStorageInstance();
    }

    /**
     * scroll handler, in an arrow function to be able to cancel it.
     * And to treat it outside angular.
     */
    public scrollHandler = (): void => {
        // Header links + Header notification banner
        const scrollLimit =
            this.headerLinksContainerEl.nativeElement.clientHeight +
            this.headerLinksContainerEl.nativeElement.offsetTop;

        const oldState = this.state.scroll;
        const newState =
            this.window && this.window.scrollY > scrollLimit
                ? ScrollType.Fixed
                : ScrollType.Pinned;
        if (oldState !== newState) {
            this.zone.run(() => {
                this.state.scroll = newState;
                this.cdr.markForCheck();
            });
            this.layoutFacade.updateHeaderHeight({
                offsetHeight:
                    this.headerMainContainerEl.nativeElement.offsetHeight,
                innerHeight:
                    this.headerMainContainerEl.nativeElement.clientHeight,
                offsetTop: this.headerMainContainerEl.nativeElement.offsetTop,
            });
        }
    };

    public ngOnInit(): void {
        this.state = {
            scroll: ScrollType.Pinned,
            banners: undefined as unknown as BannersData,
        };
        if (this.context.isInBrowserMode()) {
            this.getAccountCreationBanner();
        }
        this.menuFacade.fetchMenu();

        let scrollBarWidth = 0;

        setTimeout(() => {
            scrollBarWidth =
                this.window.innerWidth -
                this.document.documentElement.clientWidth;
        });

        this.subscription.add(
            this.trayStack.trays$
                .pipe(
                    filter(
                        () =>
                            this.state.scroll === ScrollType.Fixed &&
                            this.breakpointService.mediumBreakpointMatcher(),
                    ),
                    map((trays) => trays.some((tray) => tray.isOpen)),
                    distinctUntilChanged(),
                )
                .subscribe((isOpen) => {
                    isOpen
                        ? this.renderer.setStyle(
                              this.headerMainContainerEl.nativeElement,
                              "right",
                              `${scrollBarWidth}px`,
                          )
                        : this.renderer.removeStyle(
                              this.headerMainContainerEl.nativeElement,
                              "right",
                          );
                }),
        );
    }

    public ngAfterViewInit(): void {
        if (this.context.isInBrowserMode()) {
            this.zone.runOutsideAngular(() => {
                this.window.addEventListener("scroll", this.scrollHandler);
            });
        }
    }

    public ngOnDestroy(): void {
        if (this.context.isInBrowserMode()) {
            this.window.removeEventListener("scroll", this.scrollHandler);
        }

        this.subscription.unsubscribe();
    }

    public closeAccountCreationBanner(): void {
        this.isAccountCreated = false;
        this.isAccountCreatedByWechat = false;
    }

    private getAccountCreationBanner() {
        this.isAccountCreated = this.checkAccountCreationBannerVisibility();
        if (this.isAccountCreated) {
            this.isAccountCreatedByWechat = !!this.sessionStorage?.getItem(
                "accountCreatedByWechat",
            );
            this.sessionStorage?.deleteItem("accountCreated");
            this.sessionStorage?.deleteItem("accountCreatedByWechat");
        }
    }

    private checkAccountCreationBannerVisibility(): boolean {
        return !!this.sessionStorage?.getItem("accountCreated");
    }
}
