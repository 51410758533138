/* eslint-disable rxjs-angular/prefer-composition */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";

import type * as express from "express";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Context, LOCALE, REQUEST, RESPONSE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { redirectResponse } from "@hermes/utils/services/response";
import { LOGGER } from "@hermes/web-logger";

import { InterceptorService } from "./interceptor.service";

/**
 * This interceptor redirects to homepage if intercept a 410 http error (ex: product is gone)
 */
@Injectable()
export class RedirectOn410GoneInterceptor implements HttpInterceptor {
    constructor(
        private interceptorService: InterceptorService,
        @Optional() @Inject(REQUEST) private request: express.Request,
        @Optional() @Inject(RESPONSE) private response: express.Response,
        private context: Context,
        @Inject(LOGGER) private logger: Logger,
        @Inject(LOCALE) private locale: Locale,
        @Inject(WINDOW) private window: Window,
    ) {}

    /**
     * Angular HTTP interceptor
     */
    public intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        // If the current interceptor is disabled for current route,
        // we only call next.handle without any custom process
        if (!this.interceptorService.isEnabled(this)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                if (error.status === 410) {
                    // in server mode, redirect to home page
                    if (this.context.isInServerMode()) {
                        this.logger.info(error.message);
                        redirectResponse(
                            this.request,
                            this.response,
                            this.locale.urlPrefix,
                            301,
                        );
                        return EMPTY;
                    }
                    // in browser mode, we don't use router service and leave
                    // traffic switcher redirects to cms or rdr home page
                    this.window.location.href = `${this.locale.urlPrefix}/`;
                    return EMPTY;
                }

                // default case : technical error, rethrow the error so that it can be catched by the susbscribers or ErrorHandler if there is no error management on call
                return throwError(() => error);
            }),
        );
    }
}
