import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import {
    load,
    LoadingExternalScriptAction,
} from "../actions/external-library.actions";
import { ExternalLibraryState } from "../reducer/external-library.state";
import {
    selectIsError,
    selectIsLoaded,
    selectIsLoading,
} from "../selectors/external-library.selectors";

@Injectable()
export class ExternalLibraryFacade {
    constructor(private store: Store<ExternalLibraryState>) {}

    /**
     * Launch the download of the script in parameter (url) with the key in parameter (key).
     *
     * The windowAttribute allows to validate the validity of the script in the WINDOW object
     *
     * The timeout (optional) allows you to choose a maximum time from when the script loading is in error.
     *
     * The callbackParameterName (optional) allows you to add an efficient management of libraries
     * that ask for a callback to be called when it has finished initializing (as Google or Baidu for example).
     */
    public load(parameters: LoadingExternalScriptAction): void {
        this.store.dispatch(load(parameters));
    }

    /**
     * With the key used for load(), get the loaded state of the script
     */
    public isLoaded$(key: string): Observable<boolean> {
        return this.store.select(selectIsLoaded(key));
    }

    /**
     * With the key used for load(), get the loading state of the script
     */
    public isLoading$(key: string): Observable<boolean> {
        return this.store.select(selectIsLoading(key));
    }

    /**
     * With the key used for load(), get the `hasError` state of the script
     */
    public isError$(key: string): Observable<boolean> {
        return this.store.select(selectIsError(key));
    }
}
