<div class="search-input">
    <div *ngIf="showSearchIcon" class="search-button">
        <h-svg-icon [name]="'search'"></h-svg-icon>
    </div>
    <h-input
        [formControl]="control"
        [controlName]="controlName"
        [id]="id"
        [label]="label"
        [name]="name"
        [role]="'textbox'"
        [placeholder]="placeholder"
        [describe]="describe"
        [ariaAutoComplete]="ariaAutoComplete"
        [ariaControls]="
            (showSuggestions$ | async) === true && searchInputSuggestions.length > 0 ? ariaControls : undefined
        "
        [ariaActiveDescendant]="
            (showSuggestions$ | async) === true && searchInputSuggestions.length > 0
                ? 'search-suggestion-item-' + currentSelectedSuggestion
                : undefined
        "
        [autocomplete]="autocomplete"
        [contentSquareMaskEnabled]="contentSquareMaskEnabled"
        [errorManagement]="errorManagement"
        [labelWidth]="'full'"
        [hasLeftIcon]="showSearchIcon"
        [hasRightIcon]="showClearButton || hasRightIcon"
        [ariaDescribedby]="ariaDescribedby"
        [mask]="mask"
        [maskPattern]="maskPattern"
        [maskSpecialCharacters]="maskSpecialCharacters"
        [dataTestId]="dataTestId ? dataTestId + '-search' : 'input-search'"
        [setMinHeight]="setMinHeight"
        [minLength]="minLength"
        [maxLength]="maxLength"
        (eventOnBlur)="eventOnBlur.emit()">
        <ng-content select="[errors]"></ng-content>
    </h-input>
    <button #clearButton *ngIf="showClearButton" type="reset" (click)="clearButtonClick()" class="clear-button">
        <h-svg-icon [name]="'cross-rounded'" class="icon-button"></h-svg-icon>
        <span hSrOnlyNoSnippet i18n="@@hermes_checkout.modules.filters.clear.label"> Clear </span>
    </button>

    <ul
        [id]="ariaControls"
        *ngIf="searchInputSuggestions.length > 0 && (showSuggestions$ | async) === true"
        [attr.role]="'listbox'"
        class="search-suggestions"
        [attr.aria-labelledby]="'search-suggestion-label'">
        <li
            *ngFor="let suggestionTemplate of searchInputSuggestions; let i = index"
            [id]="suggestionItemBaseName + i"
            class="search-suggestion-item"
            [attr.role]="'option'"
            [class.focused]="currentSelectedSuggestion === i"
            [attr.aria-selected]="currentSelectedSuggestion === i"
            (mousedown)="selectNewSuggestion.emit(i)">
            <span class="search-suggestion-item-text">
                <ng-container [ngTemplateOutlet]="suggestionTemplate"></ng-container>
            </span>
        </li>
    </ul>
</div>
