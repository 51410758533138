import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import {
    fetchCustomerServiceContacts,
    fetchCustomerServiceContactsFailure,
    fetchCustomerServiceContactsSuccess,
} from "../actions/customer-service-contact.actions";
import { CustomerServiceContactService } from "../services/customer-service-contact.service";

@Injectable()
export class CustomerServiceContactEffects {
    public fetchCustomerServiceContacts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCustomerServiceContacts),
            switchMap(() =>
                this.customerServiceContactService
                    .fetchCustomerServiceContacts()
                    .pipe(
                        map((response) =>
                            fetchCustomerServiceContactsSuccess({
                                customerServiceContacts: response,
                            }),
                        ),
                        catchError((error) =>
                            of(fetchCustomerServiceContactsFailure({ error })),
                        ),
                    ),
            ),
        ),
    );

    public fetchCustomerServiceContactsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchCustomerServiceContactsFailure),
                tap((action) => {
                    this.logger.error(
                        "Error fetch customer service contacts informations",
                        action.error,
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(
        @Inject(LOGGER) private logger: Logger,
        private actions$: Actions,
        private customerServiceContactService: CustomerServiceContactService,
    ) {}
}
