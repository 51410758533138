import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";

import { closeModal, openModal } from "../actions/modal.actions";
import { ModalData } from "../model/modal.model";
import {
    selectIsModalOpened,
    selectModalState,
    selectModalSuccess,
} from "../selectors/modal.selectors";
import { ModalState, OpenedModalState } from "../state/modal.state";

@Injectable()
export class ModalFacade {
    /**
     * Observable that emits once when the modal opens
     */
    public modalOpened$ = this.store.select(selectIsModalOpened).pipe(
        distinctUntilChanged(),
        filter((isOpened) => isOpened === true),
        switchMap(() => this.store.select(selectModalState)),
        filter((data): data is OpenedModalState => data.isOpened),
        map((data) => data.data),
    );

    /**
     * Observable that emits once when the modal closes
     */
    public modalClosed$ = this.store.select(selectIsModalOpened).pipe(
        distinctUntilChanged(),
        filter((isOpened) => isOpened === false),
        switchMap(() => this.store.select(selectModalSuccess)),
    );

    public modalState$ = this.store.select(selectModalState);

    constructor(private store: Store<ModalState>) {}

    public openModal(data: ModalData): void {
        this.store.dispatch(openModal({ data }));
    }

    public closeModal(success?: boolean): void {
        this.store.dispatch(closeModal({ success }));
    }
}
