import { HttpInterceptor } from "@angular/common/http";
import { Inject, Injectable, OnDestroy, Type } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Router,
    RoutesRecognized,
    UrlSegment,
} from "@angular/router";
import { filter, map, Subscription } from "rxjs";

import { Logger } from "@hermes/logger";
import {
    APOLLO_DEFAULT_INTERCEPTORS,
    APOLLO_INTERCEPTORS,
} from "@hermes/utils/constants";
import { LOGGER } from "@hermes/web-logger";

@Injectable()
export class InterceptorService implements OnDestroy {
    private enabledInterceptors: Array<Type<HttpInterceptor>> = [];

    private subscription = new Subscription();

    constructor(
        private router: Router,
        @Inject(APOLLO_INTERCEPTORS)
        private interceptors: Record<string, Array<Type<HttpInterceptor>>>,
        @Inject(APOLLO_DEFAULT_INTERCEPTORS)
        private defaultInterceptors: Array<Type<HttpInterceptor>>,
        @Inject(LOGGER) private logger: Logger,
    ) {}

    public init(): Promise<boolean> {
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof RoutesRecognized),
                    map((event) => {
                        const route = (event as RoutesRecognized).state.root
                            .children[0].children[0];
                        return this.getRawUrl(route);
                    }),
                )
                .subscribe({
                    next: (url) => {
                        this.enabledInterceptors = this.interceptors[url] ?? [];

                        // Fallback adding trailing slash to URL
                        if (this.enabledInterceptors.length === 0) {
                            this.enabledInterceptors =
                                this.interceptors[`${url}/`] ?? [];
                        }
                    },
                    error: (error) => {
                        this.logger.warn(
                            "Unable to enable domain interceptors",
                            error,
                        );
                        this.enabledInterceptors = this.defaultInterceptors;
                    },
                }),
        );
        return Promise.resolve(true);
    }

    public isEnabled<T extends HttpInterceptor>(interceptor: T): boolean {
        return this.enabledInterceptors.some(
            (key) => key.name === interceptor.constructor.name,
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * @returns the raw URL as specified in app routing module (e.g `mypath/:mypathparam1/:pathparam2`)
     */
    public getRawUrl(routeSnapshot: ActivatedRouteSnapshot): string {
        const segments: UrlSegment[] = [];

        const addSegments = (snapshot: ActivatedRouteSnapshot) => {
            if (snapshot.parent) {
                addSegments(snapshot.parent);
            }

            snapshot.url.forEach((segment, index) => {
                if (snapshot.routeConfig?.path) {
                    const pathSegments = snapshot.routeConfig.path.split("/");
                    if (pathSegments[index]) {
                        segments.push(new UrlSegment(pathSegments[index], {}));
                    }
                } else {
                    segments.push(segment);
                }
            });
        };

        addSegments(routeSnapshot);
        return `${segments.map((segment) => segment.path).join("/")}`;
    }
}
