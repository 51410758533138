import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { Logger } from "@hermes/logger";
import { CMSService } from "@hermes/utils/services/api-clients";
import { LOGGER } from "@hermes/web-logger";

import * as ShellActions from "../actions/shell.actions";
import { BannerService } from "../services/banner.service";
/**
 * Banners side effects.
 */
@Injectable()
export class ShellEffects {
    public fetchBanners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShellActions.fetchBanners),
            switchMap(() =>
                this.bannerService.fetchBannersData().pipe(
                    map((response) =>
                        ShellActions.fetchBannersSuccess({ data: response }),
                    ),
                    catchError((error) => {
                        this.logger.debug(error);
                        return of(ShellActions.fetchBannersFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    public fetchFooterItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShellActions.fetchFooterItems),
            switchMap(() =>
                this.cmsService.fetchFooterData().pipe(
                    map((response) =>
                        ShellActions.fetchFooterItemsSuccess({
                            data: response,
                        }),
                    ),
                    catchError((error) =>
                        of(ShellActions.fetchFooterItemsFailure({ error })),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private bannerService: BannerService,
        private cmsService: CMSService,
        @Inject(LOGGER) private logger: Logger,
    ) {}
}
