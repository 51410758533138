import { Injectable } from "@angular/core";

import { AnalyticsService, EVENT_UAEVENT } from "@hermes/utils/analytics";

const LEGAL_CONTACT_US = "Help";

@Injectable()
export class CustomerServiceContactAnalyticsService {
    constructor(private analyticsService: AnalyticsService) {}
    public sendClickContactUs(action: string): void {
        this.analyticsService.sendRawData({
            event: EVENT_UAEVENT,
            eventcategory: LEGAL_CONTACT_US,
            eventaction: "Contact",
            eventlabel: action,
        });
    }
}
