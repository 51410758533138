import { Component, Input } from "@angular/core";

import { AnalyticsService } from "@hermes/utils/analytics";

import { HeaderClickEvent } from "../../events/header-click.event";

@Component({
    selector: "h-header-light",
    templateUrl: "./header-light.component.html",
    styleUrls: ["./header-light.component.scss"],
})
export class HeaderLightComponent {
    @Input()
    public displayBackToHome: boolean = false;

    constructor(private analyticsService: AnalyticsService) {}

    public onLogoClick(): void {
        this.analyticsService.sendData(
            new HeaderClickEvent({
                eventaction: "Logo",
            }),
        );
    }
}
