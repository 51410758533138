import exportedSass from "@hermes/aphrodite/design-system-tools";

import { Expend } from "../types/product-personalization.type";

/**
 * Type of blocks
 */
export enum BlockType {
    Configurator = "configurator",
    Save = "save",
}

export const SEPARATOR: string = "/";
export const DOUBLE_PRODUCT_SEPARATOR: string = "-";

// LoadingProgress
export const LOADING = "loading";
export const LOADED = "loaded";

// ProductCategory
export const SMALL_LEATHER_GOODS = "smallleathergoods";
export const SILK = "silk";
export const BELTKIT = "beltkits";
export const NOT_SUPPORTED = "unsupported";

export const PRODUCT_LEATHER_CATEGORY = [SMALL_LEATHER_GOODS, BELTKIT];

// CapaPersoNames
export const SOIE = "SOIE";
export const SMLG = "SMLG";
export const BK = "BK";

// PlatformE configuration
export const PLATFORME_ENDPOINT_CONFIG =
    "config/info?domain=hermes&guess=0&sku=";
export const BRAND = "hermes";
export const PLATFORME_DEFAULT_SIZE = ".$s:17";
export const LOSSLESS_FORMAT = "lossless";
export const EMPTY_PLATFORME_MESSAGE = "$empty";
export const PLATFORME_SIZE_KEY = "one_size:female";
export const PLATFORME_DEFAULT_SCARF_SIZE = "17";

/**
 * URLs of error pages
 */
export const PERSO_SILK_ERROR_PAGE_PATH = "/personalization-silk-error-page";
export const PERSO_LANDING_PAGE_PATH = "/personalization-landing-page";
export const ERROR_PAGE_404_PATH = "/404-error";

export const NO_RIPE = "no ripe instance";

/**
 * parameters to send to the personalization API
 */
export const personalizeConstants = {
    BRAND: "hermes",
    USEPRICE: false,
    FORMAT: "jpg",
    CONFIG_ENDPOINT_INFO: "config/info",
    FACES: ["zoom", "front", "product_wearing_view"],
    RIPE_FACES: ["zoom", "front"],
    ZOOM_KEY: ":zoom",
    FRONT_KEY: ":front",
    MAIN_PICTURE_SIZE: 850,
    THUMBNAIL_SIZE: 250,
    CROP: true,
    WEARING_VIEW_KEY: "worn",
    FACE_TO_SEND_TO_EMAIL: ["zoom"],
    SKU: "h003415sv10",
    BACKGROUND: exportedSass.secondaryColors["color-secondary-light"]?.replace(
        "#",
        "",
    ),
    EMPTY: "$empty",
    SILK_SIZE: ".$s:17",
    SILK_SIZE_PARAM: ".$s:",
    GAVROCHE_FAMILY_CODE: "S10",
};

/**
 * All the expends for the small leather good configurator
 */
export const SMALLLEATHERGOODS_EXPANDS: Expend = {
    leather: "leather",
    liningColor: "interiorColor",
    fontColor: "fontColor",
    fontMarkingZone: "fontMarkingZone",
    fontStyle: "fontStyle",
    fontSize: "fontSize",
    exteriorColor: "exteriorColor",
    updatedMessage: "updatedMessage",
};

/**
 * All the expends for the belt kit configurator
 */
export const BELTKITS_EXPANDS: Expend = {
    buckle: "buckle",
    beltSize: "beltSize",
    interiorColor: "interiorColor",
    fontColor: "fontColor",
    fontStyle: "fontStyle",
    updatedMessage: "updatedMessage",
    exteriorColor: "exteriorColor",
};

/**
 * All the expends for the silk configurator
 */
export const SILK_EXPANDS: Expend = {
    fontColor: "fontColor",
    fontStyle: "fontStyle",
    fontSize: "fontSize",
    marking: "marking",
};

/**
 * Show more button types
 */
export const EXPAND_TYPES: Expend = {
    liningColor: "liningColor",
    interiorColor: "interiorColor",
    exteriorColor: "exteriorColor",
    beltSize: "beltSize",
    buckle: "buckle",
};
