import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "strong",
})
export class StrongPipe implements PipeTransform {
    /**
     * Put strong tag around a term found
     *
     * @param value input string
     * @param term value to set strong
     * @param role optional HTML role attribute
     */
    public transform(value: string, term: string, role?: string): string {
        if (!term) {
            return value;
        }

        const regexTerm = new RegExp(`(${term})`, "gi");
        if (role) {
            return value.replace(
                regexTerm,
                (match) => `<strong role="${role}">${match}</strong>`,
            );
        }
        return value.replace(regexTerm, (match) => `<strong>${match}</strong>`);
    }
}
