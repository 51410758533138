import { createAction, props } from "@ngrx/store";

import { FeatureFlagDefinition } from "../constants/feature-flags.constant";
import { FeatureFlags } from "../reducers/feature-flag.state";

export const fetchFeatureFlags = createAction(
    "[Flipper] Fetch Feature Flags",
    props<{ flags: FeatureFlagDefinition[] }>(),
);

export const fetchFeatureFlagSuccess = createAction(
    "[Flipper] Fetch Feature Flags Success",
    props<{ flags: FeatureFlags }>(),
);

export const fetchFeatureFlagFailure = createAction(
    "[Flipper] Fetch Feature Flags Failure",
    props<{ error: string }>(),
);

export const fetchFeatureFlagsSSR = createAction(
    "[Flipper] Fetch Feature Flags from the server side",
    props<{ flags: FeatureFlagDefinition[] }>(),
);

export const fetchFeatureFlagsCSR = createAction(
    "[Flipper] Fetch Feature Flags from the client side",
    props<{ flags: FeatureFlagDefinition[] }>(),
);
