<h-modal
    (keydown.esc)="onModalExit()"
    ariaLabelledby="terms-and-conditions-title"
    class="no-padding"
    i18n-ariaLabelledby
    id="modal-terms-conditions"
    role="dialog">
    <div h-modal-body>
        <ng-container [ngTemplateOutlet]="modalData.templateRef || null"></ng-container>
    </div>
</h-modal>
<div (click)="onModalExit()" class="modal-background" id="modal-terms-and-conditions-background"></div>
