/* eslint-disable rxjs-angular/prefer-composition */
import { DOCUMENT } from "@angular/common";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { EMPTY, Observable, Subscription, throwError } from "rxjs";
import { catchError, filter } from "rxjs/operators";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

import { InterceptorService } from "./interceptor.service";

/**
 * This interceptor displays a not found page when a backend returns a 404 response
 */
@Injectable()
export class RedirectOn404NotFoundInterceptor implements HttpInterceptor {
    /**
     * Destination URL at each navigationStart of angular routing.
     */
    private navigationStartUrl?: string;

    private subscription = new Subscription();

    constructor(
        private router: Router,
        private interceptorService: InterceptorService,
        @Inject(LOCALE) private locale: Locale,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.subscription.add(
            this.router.events
                .pipe(
                    filter(
                        (event: unknown): event is NavigationStart =>
                            event instanceof NavigationStart,
                    ),
                    // Do not accept url for 404, it's fake URL for display not-found-page component
                    filter(
                        (event: NavigationStart) =>
                            !event.url.startsWith("/404-error"),
                    ),
                )
                .subscribe((event: NavigationStart) => {
                    this.navigationStartUrl = event.url;
                }),
        );
    }

    /**
     * Angular HTTP interceptor
     */
    public intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        // If the current interceptor is disabled for current route
        // or if we set a skip interceptor for a specific service
        // we only call next.handle without any custom process
        if (
            !this.interceptorService.isEnabled(this) ||
            request.context.get(SKIP_404_INTERCEPTOR)
        ) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                if (error.status === 404) {
                    this.redirect404();
                    return EMPTY;
                }

                // default case : technical error, rethrow the error so that it can be catched by the susbscribers or ErrorHandler if there is no error management on call
                return throwError(() => error);
            }),
        );
    }

    private redirect404(): void {
        // Redirect to 404-page without changing the url
        this.router.navigate(["/404-error"], {
            skipLocationChange: true,
            queryParams: {
                originalUrl:
                    this.navigationStartUrl ??
                    this.removeLocalePrefix(this.document.location.pathname),
            },
        });
    }

    private removeLocalePrefix(url: string): string {
        const prefix = `${this.locale.urlPrefix}/`;
        return url.replace(prefix, "");
    }
}
