import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import {
    MISSING_BASKET_ERROR,
    MISSING_CART_ERROR_MESSAGE,
    MISSING_NOVA_CART_ERROR_MESSAGE,
} from "@hermes/fragments/conversion-funnel";
import { BasketFacade } from "@hermes/states/basket";

@Injectable()
export class CartErrorInterceptor implements HttpInterceptor {
    constructor(private basketFacade: BasketFacade) {}

    public intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                if (
                    error.error?.message === MISSING_BASKET_ERROR ||
                    error.error?.message === MISSING_CART_ERROR_MESSAGE ||
                    error.error?.message === MISSING_NOVA_CART_ERROR_MESSAGE
                ) {
                    this.basketFacade.clearData();
                }
                return throwError(() => error);
            }),
        );
    }
}
