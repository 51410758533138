import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
    FeatureFlagState,
    FeatureFlagStatus,
} from "../reducers/feature-flag.state";

export const selectFeature =
    createFeatureSelector<FeatureFlagState>("featureFlags");

export const selectIsLoaded = createSelector(
    selectFeature,
    (state: FeatureFlagState) => state.status === FeatureFlagStatus.Loaded,
);

export const selectFlags = createSelector(
    selectFeature,
    (state) => state.flags,
);
