<div itemscope [attr.itemtype]="'http://schema.org/Organization'" class="header-hermes-container">
    <a [attr.itemprop]="'url'" class="header-hermes-logo" data-track-logo [href]="homeUrl">
        <h-svg-icon class="icon-hermes-logo-small" [name]="'hermes-logo-small'"></h-svg-icon>
        <h-svg-icon class="icon-hermes-logo" [name]="'hermes-logo'"></h-svg-icon>
        <span id="logo-accessibility" hSrOnlyNoSnippet i18n="@@hermes-global-translation-homepage-hermes-paris">
            Homepage Hermès Paris
        </span>
        <img
            class="icon-hermes-seo-only"
            [attr.itemprop]="'logo'"
            [attr.alt]="'logo'"
            [attr.src]="hermesLogoPath"
            loading="lazy" />
    </a>
</div>
