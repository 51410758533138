import { Injectable } from "@angular/core";
import type { Metric } from "web-vitals";

import { WebVitalsEnvironment } from "./web-vitals.environment";
import { WebVitalsThreshold, WEBVITALS_THRESHOLDS } from "./web-vitals.model";
@Injectable()
export class WebVitalsService {
    async init(webVitalsEnvironment: WebVitalsEnvironment) {
        if (webVitalsEnvironment.core) {
            // Lazy-load to reduce bundle
            const { getCLS, getFID, getLCP } = await import("web-vitals");
            getCLS(metricHandler(WEBVITALS_THRESHOLDS.CLS));
            getFID(metricHandler(WEBVITALS_THRESHOLDS.FID));
            getLCP(metricHandler(WEBVITALS_THRESHOLDS.LCP));
        }

        if (webVitalsEnvironment.other) {
            // Lazy-load to reduce bundle
            const { getFCP, getTTFB } = await import("web-vitals");
            getFCP(metricHandler(WEBVITALS_THRESHOLDS.FCP));
            getTTFB(metricHandler(WEBVITALS_THRESHOLDS.TTFB));
        }
    }
}

export function metricHandler({
    title,
    error,
    warn,
    unit,
}: WebVitalsThreshold) {
    return (metric: Metric): void => {
        if (metric.value > error) {
            console.error(
                `Web Vitals Error: ${title} value is above ${error}${unit} (received ${metric.value}${unit})`,
            );
            return;
        }

        if (metric.value > warn) {
            console.warn(
                `Web Vitals Warning: ${title} value is above ${warn}${unit} (received ${metric.value}${unit})`,
            );
        }
    };
}
