<div [class.set-min-height]="setMinHeight">
    <div class="container">
        <label
            [id]="labelId"
            [class.focused]="control.value"
            [class.disabled]="control.disabled"
            [for]="selectId"
            [class.searchable-label]="isSearchable"
            class="generic-label"
            >{{ label }}
            <!-- '*' is an universal identifier for required input fields, no translation is used then -->
            <span *ngIf="fieldIsRequired" [attr.aria-hidden]="'true'">{{ " *" }}</span>
            <span *ngIf="describe" hSrOnlyNoSnippet>{{ describe }}</span>
        </label>
        <h-svg-icon
            [class.disabled]="control.disabled"
            *ngIf="isSearchable"
            class="search-icon"
            id="search-icon"
            [name]="'search'">
        </h-svg-icon>
        <div
            #select
            *ngIf="!isSearchable"
            tabindex="0"
            [id]="selectId"
            [attr.aria-haspopup]="'listbox'"
            [attr.aria-labelledby]="labelId + ' ' + selectId"
            [attr.aria-expanded]="isDropdownOpen"
            [attr.aria-invalid]="controlHaveErrors()"
            [attr.aria-describedby]="errorId ? errorId : '' + ' ' + ariaDescribedby"
            [attr.aria-controls]="id + '-list'"
            [attr.aria-activedescendant]="isDropdownOpen ? getOptionId(selectedOptionIndex) : undefined"
            [attr.aria-required]="fieldIsRequired"
            [attr.required]="fieldIsRequired"
            [attr.role]="'combobox'"
            [attr.data-cs-mask]="contentSquareMaskEnabled ? '' : undefined"
            [attr.data-testid]="dataTestId ? dataTestId + '-select' : ''"
            [class.generic-input-error]="controlHaveErrors()"
            [class.disabled]="control.disabled"
            [attr.aria-disabled]="control.disabled"
            [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
            class="generic-input"
            (focusout)="control.markAsTouched()">
            {{ options[selectedOptionIndex]?.text }}
        </div>
        <h-input
            *ngIf="isSearchable"
            [formControl]="control"
            [controlName]="controlName"
            [id]="selectId"
            [label]="label"
            [name]="name"
            [role]="'textbox'"
            [placeholder]="placeholder"
            [describe]="describe"
            [ariaControls]="id + '-list'"
            [ariaActiveDescendant]="isDropdownOpen ? getOptionId(selectedOptionIndex) : undefined"
            [autocomplete]="autocomplete"
            [contentSquareMaskEnabled]="contentSquareMaskEnabled"
            [errorManagement]="false"
            [labelWidth]="'full'"
            [ariaDescribedby]="ariaDescribedby"
            [dataTestId]="dataTestId ? dataTestId + '-select' : ''"
            (input)="filterSelection($any($event.target).value)"
            (keyup)="inputEvent($event)"
            (eventOnBlur)="handleBlur()"
            [hasLeftIcon]="true">
        </h-input>
        <h-svg-icon *ngIf="!isDropdownOpen" id="arrow-down-rounded" name="arrow-rounded"></h-svg-icon>
        <h-svg-icon *ngIf="isDropdownOpen" id="arrow-up-rounded" name="arrow-rounded"></h-svg-icon>

        <ng-template #dropdown>
            <ul
                [id]="id + '-list'"
                [attr.role]="'listbox'"
                [attr.aria-labelledby]="labelId"
                [attr.data-cs-mask]="contentSquareMaskEnabled ? '' : undefined"
                [attr.data-testid]="dataTestId ? dataTestId + '-options' : ''"
                (keydown)="eventManagment($event)">
                <li
                    *ngFor="let option of isSearchable ? filteredOptions : options; let i = index; trackBy: trackByText"
                    [id]="getOptionId(i)"
                    [attr.role]="'option'"
                    [attr.aria-selected]="selectedOptionIndex === i"
                    tabIndex="-1"
                    (click)="optionChange(option)"
                    (focus)="control.markAsUntouched()">
                    {{ option.text }}
                </li>
            </ul>
        </ng-template>
    </div>

    <div [id]="errorId">
        <div *ngIf="errorManagement && controlHaveErrors()">
            <!-- Required message -->
            <h-message-block
                *ngIf="control.errors?.required"
                [showBackground]="false"
                [autoAddPadding]="false"
                [ariaRole]="undefined"
                type="error"
                class="error">
                <ng-container i18n="@@hermes.common.required_information_input"> Required information </ng-container>
            </h-message-block>
        </div>
        <ng-content></ng-content>
        <span hSrOnlyNoSnippet>{{ label }}</span>
    </div>
</div>
