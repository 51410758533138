import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { Product } from "@hermes/api-model-product";

import {
    addGiftSetItem,
    askForItemRemoval,
    expandGiftSetItem,
    removeGiftSetItem,
} from "../actions/giftset-product-page.actions";
import {
    selectAddToCartGiftSetParameters,
    selectAvailableGiftSetItems,
    selectCurrentGiftsetProductReference,
    selectCurrentSeoGiftsetProductReference,
    selectExpandGiftSetItem,
    selectGiftSetBoxItems,
    selectGiftSetBoxItemsTotalPrice,
    selectGiftSetBoxItemToDelete,
    selectGiftSetItemQuantity,
    selectIsHeterogeneousGiftSet,
} from "../selectors/giftset-product-page.selectors";

import { GiftSetState } from "./../reducers/giftset-product-page.state";

@Injectable()
export class GiftsetProductPageFacade {
    public currentGiftsetProductReference$ = this.store.select(
        selectCurrentGiftsetProductReference,
    );
    public currentSeoGiftsetProductReference$ = this.store.select(
        selectCurrentSeoGiftsetProductReference,
    );
    public giftSetBoxItemsTotalPrice$ = this.store.select(
        selectGiftSetBoxItemsTotalPrice,
    );

    public giftSetBoxItemToDelete$ = this.store.select(
        selectGiftSetBoxItemToDelete,
    );
    public giftSetBoxItems$ = this.store.select(selectGiftSetBoxItems);
    public availableGiftSetItems$ = this.store.select(
        selectAvailableGiftSetItems,
    );

    public isHeterogeneousGiftSet$ = this.store.select(
        selectIsHeterogeneousGiftSet,
    );
    public addToCartGiftSetParameters$ = (product: Product) =>
        this.store.select(selectAddToCartGiftSetParameters(product));

    public giftSetItemExpanded = (sku: string): Observable<boolean> =>
        this.store.select(selectExpandGiftSetItem(sku));

    public giftSetItemQuantity = (sku: string): Observable<number> =>
        this.store.select(selectGiftSetItemQuantity(sku));

    public removeGiftSetItem(sku: string, id: string): void {
        this.store.dispatch(
            removeGiftSetItem({
                id,
                sku,
            }),
        );
    }
    public askForItemRemoval(sku: string): void {
        this.store.dispatch(
            askForItemRemoval({
                sku,
            }),
        );
    }
    public addGiftSetItem(sku: string): void {
        this.store.dispatch(
            addGiftSetItem({
                sku,
            }),
        );
    }
    public expandGiftSetItem(sku: string): void {
        this.store.dispatch(
            expandGiftSetItem({
                sku,
            }),
        );
    }
    constructor(private store: Store<GiftSetState>) {}
}
