export const BRANDS_NAME: Record<string, string> = {
    visa: "Visa",
    amex: "American Express",
    mc: "Master card",
    maestro: "Maestro",
    jcb: "JCB",
    cup: "China Union Pay",
    discover: "Discover",
    diners: "Diners Club",
    cartebancaire: "Carte bancaire",
    paypal: "Paypal",
    pix: "Pix",
    alipay: "Alipay",
    wechat: "Wechat",
    applePay: "Apple Pay",
    kcpCreditCard: "KCP",
    kcpPayco: "PAYCO",
    kcpNaverpay: "Naverpay",
    hsbc: "China Union Pay",
    kakaopay: "Kakaopay",
    dankort: "Dankort",
    eftpos_au: "EFTPOS",
};
