import { NgModule, Pipe, PipeTransform } from "@angular/core";

import { CountryCode } from "@hermes/locale";

const hermesToIsoCodes = {
    UK: "GB",
    DH: "AE",
    RI: "SA",
    uk: "gb",
    dh: "ae",
    ri: "sa",
};

/**
 * @description
 *
 * Transform country code (case sensitive) into an ISO code (same case)
 *
 * Quick explanation : Some country code are different from ISO code (compatibility with other apps)
 *
 * The `isoCountryCode` pipe is most commonly used to ensure that country codes are ISO codes.
 *
 * @usageNotes
 *
 * Provide ISO code based on country code
 * ```
 * <span>{{ address.countryCode | isoCountryCode }}</span>
 * ```
 *
 * Does no transformation when the country code is already an ISO code.
 *
 */
@Pipe({
    name: "isoCountryCode",
})
export class IsoCountryCodePipe implements PipeTransform {
    /**
     * Transform country code (case insensitive) into an ISO code (capitalize)
     */
    public transform(
        hermesCountryCode: CountryCode | Uppercase<CountryCode>,
    ): string {
        if (!hermesCountryCode || this.isIsoCode(hermesCountryCode)) {
            return hermesCountryCode;
        }
        // Transform to ISO
        return hermesToIsoCodes[hermesCountryCode];
    }

    /**
     * Identify if the country code (Hermes format) equals the country code in ISO
     */
    private isIsoCode(
        hermesCountryCode: CountryCode | Uppercase<CountryCode>,
    ): hermesCountryCode is Exclude<
        CountryCode | Uppercase<CountryCode>,
        keyof typeof hermesToIsoCodes
    > {
        return !Object.keys(hermesToIsoCodes).includes(hermesCountryCode);
    }
}

@NgModule({
    declarations: [IsoCountryCodePipe],
    providers: [IsoCountryCodePipe],
    exports: [IsoCountryCodePipe],
})
export class IsoCountryCodePipeModule {}
