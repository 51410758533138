export enum StripeCcBrand {
    AMEX = "amex",
    DINERS = "diners",
    DISCOVER = "discover",
    EFTPOS_AU = "eftpos_au",
    JCB = "jcb",
    MASTERCARD = "mastercard",
    UNIONPAY = "unionpay",
    VISA = "visa",
    CARTES_BANCAIRES = "cartes_bancaires",
}
