import { createAction, props } from "@ngrx/store";

import { CustomOptions, PersistRequest } from "@hermes/api-model-basket";

import {
    AddToCartFailureProperties,
    AddToCartPayload,
    AddToCartSuccessProperties,
    EcomError,
    SerializedBasket,
} from "../models/basket-feature.model";

export const addToCart = createAction(
    "[Basket] Add To Cart",
    props<{ addToCartPayload: AddToCartPayload }>(),
);

export const addToCartSuccess = createAction(
    "[Basket] Add To Cart Success",
    props<AddToCartSuccessProperties>(),
);

export const addToCartFailure = createAction(
    "[Basket] Add To Cart Failure",
    props<AddToCartFailureProperties>(),
);

export const removeFromCart = createAction(
    "[Basket] Remove From Cart",
    props<{ sku: string; qty: number; customOptions?: CustomOptions }>(),
);

export const removeFromCartSuccess = createAction(
    "[Basket] Remove From Cart Success",
    props<{ basket: SerializedBasket }>(),
);

export const removeFromCartFailure = createAction(
    "[Basket] Remove From Cart Failure",
    props<{ error: EcomError }>(),
);

export const resetCart = createAction("[Basket] Reset Cart");

export const resetCartSuccess = createAction(
    "[Basket] Reset Cart Success",
    props<{ basket: SerializedBasket }>(),
);

export const resetCartFailure = createAction(
    "[Basket] Reset Cart Failure",
    props<{ error: EcomError }>(),
);

export const setBasket = createAction(
    "[Basket] Init Basket",
    props<{ basket: SerializedBasket }>(),
);

export const updateBasket = createAction(
    "[Basket] Update Basket",
    props<{ basket: SerializedBasket }>(),
);

export const persistBasket = createAction(
    "[Basket] Persist Basket",
    props<{ persistRequest: PersistRequest }>(),
);

export const persistBasketSuccess = createAction(
    "[Basket] Update Basket Success",
    props<{ basket: SerializedBasket }>(),
);

export const persistBasketFailure = createAction(
    "[Basket] Update Basket Failure",
    props<{ error: EcomError }>(),
);

export const setCartItem = createAction(
    "[Basket] Set Cart Item",
    props<{ sku: string; qty: number; customOptions?: CustomOptions }>(),
);

export const setCartItemSuccess = createAction(
    "[Basket] Set Cart Item Success",
    props<{ basket: SerializedBasket }>(),
);

export const setCartItemFailure = createAction(
    "[Basket] Set Cart Item Failure",
    props<{ error: EcomError }>(),
);

export const clearData = createAction("[Basket] Clear data");

export const clearSessionData = createAction("[Basket] Clear session data");
export const clearSessionDataSuccess = createAction(
    "[Basket] Clear session data Success",
);
