import { ProductGiftSet, ProductGiftSetItem } from "@hermes/api-model-product";
import {
    CartAnalyticsEvent,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    PRODUCT_IN_STOCK,
    getStockTypeAndDisplayOnly,
} from "@hermes/utils/analytics";

/**
 * This class is used for add-to-cart event in cart page for a product set
 */
export class AddToCartProductSetEvent extends CartAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.product,
                            this.eventData.setItems,
                        ),
                    ],
                },
            },
        };
    }

    /**
     * Formatter for product
     *
     * @param product product clicked
     * @param setItems
     */
    private getProductData(
        product: ProductGiftSet,
        setItems: ProductGiftSetItem[],
    ): GTMEcommerceItemData {
        return {
            brand: "hermes",
            category: "V/V",
            id: product.sku,
            name: product.title,
            price: this.getTotalPrice(setItems),
            quantity: this.eventData.quantity,
            variant: this.getConcatenateItemSku(setItems),
            dimension5: `${product.giftSetAttributes?.slot}`,
            dimension6: product.departmentCode,
            dimension7: product.familyCode,
            dimension8: this.getProductCode(setItems),
            dimension9: this.getDivisionCode(setItems),
            dimension10: this.getConcatenateItemSku(setItems),
            dimension37: PRODUCT_IN_STOCK,
            dimension52: "no",
            dimension53: getStockTypeAndDisplayOnly(
                product.stock,
                product.displayMode,
            ),
            dimension54: "no",
        };
    }

    private getConcatenateItemSku(setItems: ProductGiftSetItem[]): string {
        return setItems.map((item) => item.sku).join("|");
    }

    private getProductCode(setItems: ProductGiftSetItem[]): string {
        return setItems
            .map((item: ProductGiftSetItem) => item.productCode)
            .join("|");
    }

    private getDivisionCode(setItems: ProductGiftSetItem[]): string {
        return setItems
            .map((item: ProductGiftSetItem) => item.divisionCode)
            .join("|");
    }

    private getTotalPrice(setItems: ProductGiftSetItem[]): string {
        return setItems
            .reduce(
                (price: number, item: ProductGiftSetItem) =>
                    price + Number(item.price),
                0,
            )
            .toFixed(2);
    }
}
