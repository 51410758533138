import { Location } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AlternatesPaths } from "@hermes/api-model-category";
import {
    CountrySelectorCountry,
    CountrySelectorData,
} from "@hermes/api-model-country";
import { INFRA_SETTINGS } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";
import { FetchCountriesService } from "@hermes/fragments/fetch-countries";
import {
    Hreflang,
    isChina,
    Locale,
    localeFromHrefLang,
    localeFromUrlPrefixUnsafe,
} from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { HeadService } from "@hermes/utils-generic/services/head";
import { LOGGER } from "@hermes/web-logger";

const X_DEFAULT_KEY = "x-default";
const X_DEFAULT_LOCALE = "/us/en";

@Injectable()
export class AlternateService implements OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(INFRA_SETTINGS) private settings: InfraSettings,
        @Inject(LOGGER) private logger: Logger,
        private headService: HeadService,
        private location: Location,
        public fetchCountriesService: FetchCountriesService,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Add alternates urls to head tag
     *
     * @param alternates Alternates urls to add
     */
    public addAlternatesUrls(alternates: AlternatesPaths): void {
        this.cleanAlternatesUrls();
        for (const [hreflang, href] of Object.entries(alternates)) {
            this.headService.addTag({
                rel: "alternate",
                href,
                hreflang,
            });
        }
    }

    /**
     * Remove existing alternates urls from head tags
     */
    public cleanAlternatesUrls(): void {
        this.headService.removeLinks("alternate");
    }

    /**
     * Transform alternates paths into urls and add them in head tag
     *
     * @param alternatesPaths Alternates paths to add
     */
    public addAlternatesPaths(alternatesPaths: AlternatesPaths): void {
        const frontendDotCom = this.settings.frontend.replace(".cn", ".com");
        const frontendDotCn = this.settings.frontend.replace(".com", ".cn");
        const relevantFrontend = (hreflang: Hreflang) =>
            isChina(localeFromHrefLang(hreflang))
                ? frontendDotCn
                : frontendDotCom;

        const alternatesUrls: AlternatesPaths = {};

        if (alternatesPaths && Object.keys(alternatesPaths).length > 0) {
            (Object.keys(alternatesPaths) as Hreflang[]).forEach((hreflang) => {
                try {
                    alternatesUrls[hreflang] = `${relevantFrontend(hreflang)}${
                        alternatesPaths[hreflang]
                    }`;
                } catch {
                    this.logger.warn(
                        `Cannot set alternate for hreflang ${hreflang}`,
                    );
                }
            });
        }

        this.addAlternatesUrls(alternatesUrls);
    }

    /**
     * Put base url as alternate for every locale.
     *
     * @param path add path after the generic url
     */
    public addGenericAlternatesUrls(path?: string): void {
        const cleanedPath = path?.split("?")[0];
        this.subscription.add(
            this.getAlternatesHomepage().subscribe(
                (alternates: AlternatesPaths) => {
                    if (cleanedPath) {
                        const alternatesWithPath: AlternatesPaths = {};
                        for (const [hreflang, href] of Object.entries(
                            alternates,
                        )) {
                            alternatesWithPath[hreflang] = href + cleanedPath;
                        }
                        this.addAlternatesUrls(alternatesWithPath);
                    } else {
                        this.addAlternatesUrls(alternates);
                    }
                },
            ),
        );
    }

    /**
     * Get available locales from country selector data
     */
    public getAllLocales(): Observable<Locale[]> {
        return this.fetchCountriesService.fetchCountries().pipe(
            map((countryData) =>
                this.getLinks(countryData)
                    .map(this.getLocaleFromLink)
                    // remove links that doesn't match a locale
                    .filter((locale): locale is Locale => !!locale),
            ),
        );
    }

    /**
     * Fetch all existing locales from country selector data and compute alternate homepages
     */
    private getAlternatesHomepage(): Observable<AlternatesPaths> {
        return this.getAllLocales().pipe(
            map((locales) => this.getAlternatesHomepageFromLocales(locales)),
        );
    }

    /**
     * Compute alternate homepages from a list of available locales
     *
     * @param locales Available locales
     */
    private getAlternatesHomepageFromLocales(
        locales: Array<Locale | undefined>,
    ): AlternatesPaths {
        const alternatesPaths = {} as AlternatesPaths;

        for (const locale of locales) {
            if (locale === undefined) {
                continue;
            }
            const href = this.getLocaleHomepage(locale);
            alternatesPaths[locale.hreflang] = href;
        }

        alternatesPaths[X_DEFAULT_KEY] = `${this.settings.frontend.replace(
            ".cn",
            ".com",
        )}${this.location.path() === "/" ? "" : X_DEFAULT_LOCALE}/`;

        return alternatesPaths;
    }

    /**
     * Compute homepage url for locale
     *
     * @param locale Locale to transform into homepage url
     */
    private getLocaleHomepage(locale: Locale) {
        const baseUrl = isChina(locale)
            ? this.settings.frontend.replace(".com", ".cn")
            : this.settings.frontend.replace(".cn", ".com");

        return `${baseUrl}${locale.urlPrefix}/`;
    }

    /**
     * Get all country links in country selector
     *
     * @param countryData Country selector's data
     */
    private getLinks(countryData: CountrySelectorData) {
        const links: string[] = [];
        if (countryData?.areas && countryData.areas.length > 0) {
            for (const area of countryData.areas) {
                links.push(...this.getCountryLinks(area.areaCountries));
            }
        }
        return links;
    }

    /**
     * Get countries and sub-countries links (recursive)
     *
     * @param countries List of countries to parse
     */
    private getCountryLinks(countries: CountrySelectorCountry[]): string[] {
        const links: string[] = [];
        for (const country of countries) {
            links.push(country.countryLink);
        }
        return links;
    }

    /**
     * Compute locale from link (`/us/en` or `/us/en/` => Locale object)
     *
     * @param link Link to transform into Locale
     */
    private getLocaleFromLink(link: string): Locale | undefined {
        const linkWithoutTrailingSlash = link.endsWith("/")
            ? link.slice(0, -1)
            : link;
        return localeFromUrlPrefixUnsafe(linkWithoutTrailingSlash);
    }
}
