import {
    Inject,
    Injectable,
    Optional,
    TransferState,
    makeStateKey,
} from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";

import {
    COUNTRY,
    Context,
    FEATURE_FLIPPING_CLIENT_FROM_SSR,
} from "@hermes/app-core";
import {
    FeatureFlippingClient,
    FeatureFlippingContext,
} from "@hermes/feature-flipping";
import { Country, CountryCode } from "@hermes/locale";

import {
    fetchFeatureFlagFailure,
    fetchFeatureFlagSuccess,
    fetchFeatureFlags,
    fetchFeatureFlagsCSR,
    fetchFeatureFlagsSSR,
} from "../actions/feature-flag-actions";
import { FeatureFlags } from "../reducers/feature-flag.state";
import { FeatureFlagService } from "../services/feature-flag.service";

export const FEATURE_FLIPPING_KEY = makeStateKey<FeatureFlags>("feature-flags");

@Injectable()
export class FeatureFlagEffects {
    /**
     * Depending on the execution context, the acquisition is not the same.
     *
     * In SSR mode, we prefer to use the FeatureFlipping SDK.
     *
     * In CSR mode, we prefer read the transfer-state or, call the API to retrieve flags.
     */
    public fetchFeatureFlags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFeatureFlags),
            map(({ flags }) =>
                this.context.isInServerMode()
                    ? fetchFeatureFlagsSSR({ flags })
                    : fetchFeatureFlagsCSR({ flags }),
            ),
        ),
    );

    /**
     * When rendering on server side, we take advantage of the fact that the Feature Flipping client is injected.
     *
     * We can therefore directly consult it to build the NgRX Store
     */
    public fetchFeatureFlagsSSR$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFeatureFlagsSSR),
            switchMap(({ flags }) => {
                const context: FeatureFlippingContext = {
                    countryCode:
                        this.country.countryCode.toUpperCase() as Uppercase<CountryCode>,
                };

                return this.featureFlippingClient.getSeveralFeatureFlagValues(
                    flags,
                    context,
                ) as Promise<FeatureFlags>;
            }),
            map((flags) => fetchFeatureFlagSuccess({ flags })),
            catchError((error) =>
                of(fetchFeatureFlagFailure({ error: error.message })),
            ),
        ),
    );

    /**
     * On client side rendering, we first check if the flags are not written in the TransferState.
     *
     * If not, we make the API call to the BCK (should only happen on localhost)
     */
    public fetchFeatureFlagsCSR$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchFeatureFlagsCSR),
            switchMap(({ flags }) => {
                const flagsInTransferState = this.transferState.get(
                    FEATURE_FLIPPING_KEY,
                    undefined,
                );
                if (flagsInTransferState) {
                    return of(flagsInTransferState);
                }
                return this.featureFlagService.featureFlagCall(flags);
            }),
            map((flags) => fetchFeatureFlagSuccess({ flags })),
            catchError((error) =>
                of(
                    fetchFeatureFlagFailure({
                        error: error.message,
                    }),
                ),
            ),
        ),
    );

    /**
     * During a server rendering, we save the flags in the TransferState to transmit them to the front.
     */
    public setFlagsInTransferState$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchFeatureFlagSuccess),
                filter(() => this.context.isInServerMode()),
                tap(({ flags }) => {
                    this.transferState.set(FEATURE_FLIPPING_KEY, flags);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private featureFlagService: FeatureFlagService,
        private context: Context,
        @Optional()
        @Inject(FEATURE_FLIPPING_CLIENT_FROM_SSR)
        private featureFlippingClient: FeatureFlippingClient,
        @Inject(COUNTRY) private country: Country,
        private transferState: TransferState,
    ) {}
}
