import { CountryCode } from "@hermes/locale";

import {
    BAIDU_ENGINE_KEY,
    GOOGLE_ENGINE_KEY,
    MapEngines,
    MAP_ENGINE_BY_COUNTRIES,
} from "./map-engine.constant";

/**
 * Retrieve the map engine according to the country in parameter
 */
export const getMapEngine = (countryCode: CountryCode): MapEngines =>
    MAP_ENGINE_BY_COUNTRIES[countryCode] ?? MAP_ENGINE_BY_COUNTRIES.default;

/**
 * Returns `true` if the country should use Google as map engine.
 * Else, `false`.
 */
export const isGoogleEngine = (countryCode: CountryCode): boolean =>
    getMapEngine(countryCode) === GOOGLE_ENGINE_KEY;

/**
 * Returns `true` if the country should use Baidu as map engine.
 * Else, `false`.
 */

export const isBaiduEngine = (countryCode: CountryCode): boolean =>
    getMapEngine(countryCode) === BAIDU_ENGINE_KEY;
