import {
    CHECKOUT_DELIVERY_ADD_NEW_ADDRESS,
    CHECKOUT_DELIVERY_COLLECT_IN_STORE,
    CHECKOUT_DELIVERY_EDIT_ADDRESS,
    CHECKOUT_DELIVERY_EDIT_ORIGINAL_ADDRESS,
    CHECKOUT_DELIVERY_FIND_A_STORE,
    CHECKOUT_DELIVERY_KEEP_ORIGINAL_ADDRESS,
    CHECKOUT_DELIVERY_SELECT_A_STORE,
    CHECKOUT_DELIVERY_SHIP_TO_ADDRESS,
    CHECKOUT_DELIVERY_SHOW_MORE_STORES,
    CHECKOUT_DELIVERY_UPDATE_NEW_ADDRESS,
    EVENT_ACTION_RNVP,
    EVENT_ACTION_RNVP_OK,
    EVENT_ACTION_RNVP_SEARCH,
    EVENT_CATEGORY_CHECKOUT_DELIVERY,
} from "../constants/conversion-funnel-common.constants";
import { GTMEventData } from "../types/gtm.type";

import { CheckoutAnalyticsEvent } from "./checkout.event";

export class CheckoutDeliveryAnalyticsEvent extends CheckoutAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CHECKOUT_DELIVERY_SHIP_TO_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "ShipToAddress",
                };
            }
            case CHECKOUT_DELIVERY_EDIT_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "EditAddress",
                };
            }
            case CHECKOUT_DELIVERY_ADD_NEW_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "AddNewAddress",
                };
            }
            case CHECKOUT_DELIVERY_COLLECT_IN_STORE: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "CollectInStore",
                };
            }
            case CHECKOUT_DELIVERY_FIND_A_STORE: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "FindAStore",
                    eventlabel: this.eventData.researchedLocation,
                };
            }
            case CHECKOUT_DELIVERY_SELECT_A_STORE: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "SelectAStore",
                    eventlabel: this.eventData.selectedStoreName,
                };
            }
            case CHECKOUT_DELIVERY_SHOW_MORE_STORES: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "ShowMoreStores",
                };
            }
            case CHECKOUT_DELIVERY_UPDATE_NEW_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: "Rnvp",
                    eventlabel: "Update new address",
                };
            }
            case CHECKOUT_DELIVERY_EDIT_ORIGINAL_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: EVENT_ACTION_RNVP,
                    eventlabel: "Edit original address",
                };
            }
            case CHECKOUT_DELIVERY_KEEP_ORIGINAL_ADDRESS: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: EVENT_ACTION_RNVP,
                    eventlabel: "Keep original address",
                };
            }
            case EVENT_ACTION_RNVP_OK:
            case EVENT_ACTION_RNVP_SEARCH: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: EVENT_CATEGORY_CHECKOUT_DELIVERY,
                    eventaction: EVENT_ACTION_RNVP,
                    eventlabel: this.eventData.eventType,
                };
            }
            default: {
                return {
                    ...super.formatForGTM(),
                };
            }
        }
    }
}
