import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";

import { Context, WINDOW } from "@hermes/app-core";
import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

import { LayoutFacade } from "../../facades/layout.facade";
import { Message, MessageOption } from "../../model/notification.model";

/**
 * Notifications an loader handler
 */
@Component({
    selector: "h-overlay-error-message",
    templateUrl: "./overlay-error-message.component.html",
    styleUrls: ["./overlay-error-message.component.scss"],
})
export class OverlayErrorMessageComponent implements OnInit, OnDestroy {
    /** Message to display in the DOM */
    public message?: Message;

    private subscription: Subscription = new Subscription();

    constructor(
        private layoutFacade: LayoutFacade,
        private cd: ChangeDetectorRef,
        private context: Context,
        private urlUtils: UrlUtils,
        @Inject(WINDOW) private windowRefrence: Window,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.layoutFacade.notificationMessage$.subscribe(
                (messageOption: MessageOption) => {
                    if (messageOption) {
                        this.showNotification(messageOption);
                    } else {
                        this.clear();
                    }
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Public function to display the notification.
     * If it's a loader type, we display a Loader, else we deliver the message
     *
     * @param apolloEvent apolloEvent throw by the notificationsService
     */
    public showNotification(messageOption: MessageOption): void {
        this.clear();
        this.message = messageOption.message;
        this.cd.detectChanges();
    }

    /**
     * Allow to reload current path
     */
    public reloadCurrentPath(): void {
        if (this.context.isInBrowserMode()) {
            this.windowRefrence.location.reload();
        }
    }

    /**
     * Sends the user back to the homePage
     */
    public backToHome(): void {
        this.urlUtils.redirectToRootPath();
    }

    /**
     * Private Function to clear all messages when we close the Overlay
     */
    private clear(): void {
        this.message = undefined;
    }
}
