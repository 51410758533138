import { APP_BASE_HREF, Location } from "@angular/common";
import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { Observable } from "rxjs";

import {
    Context,
    LOCALE,
    StorageManager,
    StorageService,
} from "@hermes/app-core";
import { ACCOUNT_PATH, LOGIN_PATH } from "@hermes/fragments/customer";
import { Locale } from "@hermes/locale";
import {
    ACCOUNT_PAGE_ANGULAR,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { UserFacade } from "@hermes/states/user";
import { AnalyticsService } from "@hermes/utils/analytics";
import { RouterService } from "@hermes/utils-generic/services/router";

import { MenuFooterClickEvent } from "./category-institutional-click.event";

@Component({
    selector: "h-category-institutional",
    templateUrl: "./category-institutional.component.html",
    styleUrls: ["./category-institutional.component.scss"],
})
export class CategoryInstitutionalComponent {
    @ViewChild("transFindastore")
    public transFindastore!: ElementRef;
    @ViewChild("transAccount")
    public transAccount!: ElementRef;
    @ViewChild("transHelp")
    public transHelp!: ElementRef;

    public findAStoreUrl = `${this.locale.urlPrefix}/find-store/`;
    public accountUrl = ACCOUNT_PATH;
    public contactUsUrl = "/contact-us/";
    public loginUrl = LOGIN_PATH;
    public isAccountPageAngularEnabled$: Observable<boolean> =
        this.featureFlagFacade.isActivated(ACCOUNT_PAGE_ANGULAR);
    public isLoggedIn$: Observable<boolean> = this.userFacade.isLoggedIn$;

    private sessionStorage?: StorageManager;

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(LOCALE) private locale: Locale,
        private featureFlagFacade: FeatureFlagFacade,
        private routerService: RouterService,
        private userFacade: UserFacade,
        private location: Location,
        private context: Context,
        private analyticsService: AnalyticsService,
        private readonly storageService: StorageService,
    ) {
        this.sessionStorage = this.storageService.getSessionStorageInstance();
    }

    public sendAccountEvent($event: Event): void {
        this.sendMenuEvent(
            $event,
            "Account",
            this.transAccount?.nativeElement.textContent,
        );
    }

    public sendContactUsEvent($event: Event): void {
        this.sendMenuEvent(
            $event,
            "ContactUs",
            this.transHelp?.nativeElement.textContent,
        );
    }

    public navigateOnShopPage($event: Event): void {
        $event.preventDefault();

        this.sendFindAStoreEvent($event);

        this.routerService.navigate(this.findAStoreUrl);
    }

    public sendFindAStoreEvent($event: Event): void {
        this.sendMenuEvent(
            $event,
            "FindaStore",
            this.transFindastore?.nativeElement.textContent,
        );
    }

    public sendMenuEvent(
        $event: Event,
        label: string,
        translation: string,
    ): void {
        $event.stopPropagation();
        this.sendMenuFooterEvent({ label, translation });
    }

    public goToPage(event: MouseEvent, path: string): void {
        event.preventDefault();
        if (this.location.path() === path) {
            // If we already are on page, we must reload it, so we use nativeRedirect
            this.routerService.nativeRedirect(
                `${this.locale.urlPrefix}${path}`,
            );
            return;
        }
        // else we use spaRedirect
        this.routerService.spaRedirect(path);
    }

    /**
     * function sendMenuFooterEvent
     *
     * send analytics data for main menu
     */
    public sendMenuFooterEvent(params: {
        noLabel?: boolean;
        label?: string;
        translation?: string;
        action?: string;
    }): void {
        if (this.context.isInBrowserMode()) {
            const navtoStore = params.noLabel
                ? params.label
                : `${params.translation}/${params.translation}`;
            const label = params.noLabel ? undefined : params.label;
            const action =
                params.action === "aftersales"
                    ? "Aftersales"
                    : "Maison_Hermes_footer";
            this.sessionStorage?.setItem("nav", navtoStore);
            this.analyticsService.sendData(
                new MenuFooterClickEvent({ label, action }),
            );
        }
    }
}
