import { ElementRef, Injectable } from "@angular/core";

@Injectable()
export class FormHelper {
    /**
     * Get the first invalid input from a Form
     * @param el The current form need to be the elementRef
     */
    public focusInvalidInput(el: ElementRef): void {
        setTimeout(() => {
            el.nativeElement
                .querySelector("input.ng-invalid:not([disabled])")
                .focus();
        }, 300);
    }
}
