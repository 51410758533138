import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { OrderCollectFieldConfig } from "@hermes/api-model-order";
import { Settings } from "@hermes/app-core";

/**
 * Service for order collect form configuration
 */
@Injectable()
export class FormConfigService {
    constructor(private http: HttpClient, private settings: Settings) {}

    /**
     * get order collect form configuration
     */
    public getFormConfig(
        localeCode: string,
    ): Observable<OrderCollectFieldConfig> {
        return this.http.get<OrderCollectFieldConfig>(
            `${this.settings.apiUrl}/form-order-collect-field-config`,
            {
                params: new HttpParams().set("locale", localeCode),
            },
        );
    }
}
