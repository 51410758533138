import { AnimationEvent } from "@angular/animations";
import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";

import exportedSass from "@hermes/aphrodite/design-system-tools";
import { ScrollType } from "@hermes/utils-generic/constants";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";

import { trayContainerAnimations } from "../../components/tray-container/tray-container.animations";
import { TrayData, TrayPosition } from "../../models/tray-data.model";
import { TrayStackService } from "../../services/tray-stack.service";

/**
 * This components manages the tray and its animations.
 * It displays opened trays and hides when the stack is empty..
 */
@Component({
    animations: trayContainerAnimations,
    selector: "h-tray-container",
    templateUrl: "./tray-container.component.html",
    styleUrls: ["./tray-container.component.scss"],
})
export class TrayContainerComponent implements OnInit, OnDestroy {
    /**
     * List of trays (title and content) to display.
     */
    public trays: TrayData[] = [];

    /**
     * true if the tray container is open
     */
    public isOpen: boolean = false;

    /**
     * true if the overlay needs to be shown
     */
    public hasOverlay: boolean = false;

    /**
     * true if the map overlay needs to be shown
     */
    public hasMapOverlay: boolean = false;

    /**
     * true if the footer "Category Institutional" needs to be shown
     */
    public hasCategoryInstitutional: boolean = false;

    /**
     * "left" or "right"
     */
    public position: TrayPosition = "right";

    /**
     * Indicate if we show the container (linked to isOpen, but delayed after the animations)
     */
    public showContainer: boolean = false;

    public noPaddingBottom: boolean = false;

    /**
     * subscription to the trayStack observable
     */
    private subscriptions: Subscription = new Subscription();

    public scroll!: ScrollType;
    public defaultTrayColor = exportedSass.miscellanousColors["color-white"];

    constructor(
        private trayStack: TrayStackService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Close the tray.
     */
    @HostListener("document:keydown.escape", ["$event"])
    public closeTray(event: Event): void {
        this.stopPropagation(event);
        this.trayStack.popTray();
    }

    public ngOnInit(): void {
        this.subscriptions.add(
            this.trayStack.trays$.subscribe((value: TrayData[]) => {
                this.trays = value;
                this.deriveContainerData(this.trays);
                this.changeDetector.detectChanges();
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * This is needed for performance of the *ngFor and to keep old trays in the dom.
     * Without it, angular compares array content by reference and recreates all the trays.
     */
    public trackByUuid(_index: number, item: TrayData): string {
        return item.uuid;
    }

    /**
     * Prevents the scroll to go to the page.
     */
    public stopPropagation(event: Event): void {
        stopEventPropagation(event);
        event.stopImmediatePropagation();
    }

    /**
     * Sets parameters of the tray from the last in the stack.
     */
    public deriveContainerData(data: TrayData[]): void {
        if (!data || data.length === 0) {
            this.isOpen = false;
            this.hasOverlay = false;
            this.hasMapOverlay = false;
            this.position = "right";
            return;
        }
        const lastTray: TrayData = data[data.length - 1];

        this.hasOverlay = Boolean(lastTray.hasOverlay);
        this.hasMapOverlay = Boolean(lastTray.hasMapOverlay);
        this.isOpen = Boolean(lastTray.isOpen);
        this.position = lastTray.position ?? "right";
        this.noPaddingBottom = Boolean(lastTray.noPaddingBottom);
    }

    public handleOverlayClick(): void {
        this.trayStack.closeAllTrays();
    }

    public onAnimationStart(): void {
        this.showContainer = true;
    }

    public onAnimationEnd(event: AnimationEvent): void {
        if (event.toState === "void") {
            this.showContainer = false;
        }
    }
}
