import { Injectable, OnDestroy } from "@angular/core";
import {
    Event as NavigationEvent,
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
    UrlTree,
} from "@angular/router";
import { filter, Observable, Subscription, take } from "rxjs";

import { ShellFacade } from "@hermes/states/shell";
import {
    SHELL_APPEARANCE_FULL,
    ShellAppearanceType,
} from "@hermes/utils-generic/constants";

@Injectable({
    providedIn: "root",
})
export class ShellFacadeGuard implements OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(private router: Router, private shellFacade: ShellFacade) {}

    public canActivateChild(
        route: ActivatedRouteSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.watchNavigationEndToSetFacadeShell(route);
    }

    public watchNavigationEndToSetFacadeShell(
        route: ActivatedRouteSnapshot,
    ): boolean {
        // This guard always returns true, it is only used to correctly set the shellfacade's header and footer on every page after SPA navigation
        this.subscription.add(
            this.router.events
                .pipe(
                    filter(
                        (event: NavigationEvent) =>
                            event instanceof NavigationEnd,
                    ),
                    take(1),
                )
                .subscribe(() => {
                    const shellMode: ShellAppearanceType =
                        route.data?.["shell"] ?? SHELL_APPEARANCE_FULL;
                    this.shellFacade.setShell(shellMode);
                }),
        );
        return true;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
