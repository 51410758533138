import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { AddToCartAnalyticsEffects } from "./effects/add-to-cart-analytics.effects";
import { AddToCartFacade } from "./facades/add-to-cart.facade";
import { addToCartReducer } from "./reducers";
import { addToCartFeatureKey } from "./selectors/add-to-cart.selectors";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(addToCartFeatureKey, addToCartReducer),
        EffectsModule.forFeature([AddToCartAnalyticsEffects]),
    ],
    providers: [AddToCartFacade],
})
export class AddToCartStateModule {}
