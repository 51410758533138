import { Inject, Injectable } from "@angular/core";

import { Meta } from "@angular/platform-browser";

import { CurrencyService } from "@hermes/aphrodite/price";
import { Asset } from "@hermes/api-model-editorial";
import { CrossSellingProduct, Product } from "@hermes/api-model-product";
import { INFRA_SETTINGS, LOCALE } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";
import { AlternateService } from "@hermes/fragments/alternates";
import { Locale, isUsa } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import {
    ContentsSizesConstants,
    ProductTemplateType,
} from "@hermes/utils/constants";
import { isAvailable } from "@hermes/utils/helpers";
import { AssetMediaType } from "@hermes/utils-generic/constants";
import {
    HeadService,
    MetatagBuilder,
    encodingHtmlCharacters,
} from "@hermes/utils-generic/services/head";
import { LOGGER } from "@hermes/web-logger";

import { ProductImageSeoUrlPipe } from "../../pipes/product-image-seo-url/product-image-seo-url.pipe";

import { CrossSell } from "./../../types/product-page.types";
import { ProductMicroDatas, SimilarProduct } from "./product-page-head.model";

enum StockAvailability {
    IN_STOCK = "http://schema.org/InStock",
    OUT_OF_STOCK = "http://schema.org/OutOfStock",
}

const IMAGE_SIZE_MICRODATA = 320; // pixels
const IMAGE_SIZE_SHARING = 1000; // pixels
const DEFAULT_GIFTSET_QUALITY = 80; // Scene7 RuleSet parameter
const PERFUME_FAMILY_CODE = "V50";

@Injectable()
export class ProductPageHeadService {
    constructor(
        @Inject(INFRA_SETTINGS) private settings: InfraSettings,
        @Inject(LOGGER) private logger: Logger,
        @Inject(LOCALE) private locale: Locale,
        private headService: HeadService,
        private currencyService: CurrencyService,
        private productImageSeoUrlPipe: ProductImageSeoUrlPipe,
        private alternateService: AlternateService,
        private meta: Meta,
    ) {}
    /**
     * Insert SEO data inside head tag for product page
     *
     * @param product Displayed product
     * @param productReference Displayed product reference SKU
     * @param crossSellInformations displayed CrossSell product
     */
    public initHeadTag(
        product: Product,
        productReference: string,
        crossSellInformations?: CrossSell,
    ): void {
        if (!product) {
            return;
        }
        const currentUrl = `${this.settings.frontend}${product.url}`;

        const title = this.getTitle(product);
        if (title) {
            this.headService.setTitle(title);
        }
        if (product.path) {
            const canonicalUrl = `${this.settings.frontend}${product.path}`;
            this.headService.addCanonicalUrl(canonicalUrl);
        } else {
            this.logger.warn(
                `SEO - no canonical url for product : ${product.sku}`,
            );
        }

        this.alternateService.addAlternatesPaths(product.altPaths);
        // AppleWatch got animations of two diferent images, we cannot preload a default image.
        if (product.templateType !== ProductTemplateType.AppleWatch) {
            this.addPreloadImageLink(product);
        }
        this.addProductMetaDatas({
            product,
            productUrl: currentUrl,
            productReference,
        });
        this.addProductMicroDatas({
            product,
            productUrl: currentUrl,
            productReference,
            crossSellInformations,
        });
        this.headService.removeNoIndexMetaInformation();
    }

    public getUrlFromAsset(asset: Asset): string {
        return asset.type === AssetMediaType.Video && asset.posterUrl
            ? asset.posterUrl
            : asset.url;
    }

    /**
     * Put Product metadata in head tag
     *
     * @param productId Product unique identifier
     * @param price Product price
     */
    public addProductMetaInformations(
        productId?: string,
        price?: string,
    ): void {
        if (price) {
            const currency = this.currencyService.getCurrencyMap()?.[1];

            this.cleanProductMetaInformations();

            this.meta.addTags(
                new MetatagBuilder()
                    .addTag("property", "product:retailer_item_id", productId)
                    .addTag("property", "product:price:amount", price)
                    .addTag("property", "product:price:currency", currency)
                    .build(),
            );
        } else {
            // Do not add currency when no price
            this.meta.addTags(
                new MetatagBuilder()
                    .addTag("property", "product:retailer_item_id", productId)
                    .build(),
            );
        }
    }

    /**
     * Remove existing product metadata from head tag
     */
    public cleanProductMetaInformations(): void {
        this.headService.removeTags([
            "property='product:retailer_item_id'",
            "property='product:price:amount'",
            "property='product:price:currency'",
        ]);
    }

    /**
     * Add volume information if fragrance.
     */
    public getTitle(product: Product): string {
        if (!product.title) {
            return "";
        }

        const metaTitle = product.simpleAttributes?.metaTitle ?? product.title;

        // If it is not a volume variant, we just need the title.
        // If productSetType, like FRAGRANCE_GIFT, no no addition to title
        if (
            product.familyCode !== PERFUME_FAMILY_CODE ||
            product.productSetType ||
            !product.massVolume
        ) {
            return metaTitle;
        }

        return `${metaTitle} - ${product.massVolume} ${
            product.simpleAttributes?.measureUnit || this.getMeasureUnit()
        }`;
    }

    /**
     * Retrieves the meta description of a product.
     * @param product Displayed product
     * @returns PIM description | default description | undefined
     */
    public getMetaDescription(product: Product): string | undefined {
        return (
            product?.simpleAttributes?.metaDescription ??
            product.attributes.description
        );
    }
    /**
     * Inject metadata in head tag
     *
     * @param product Displayed product
     * @param productUrl Complete url to product page
     */
    private addProductMetaDatas(params: {
        product: Product;
        productUrl: string;
        productReference: string;
    }): void {
        const sharingImageUrl = this.getImagesUrl(
            params.product,
            IMAGE_SIZE_SHARING,
        ).shift(); // First item

        const metaDescription = this.getMetaDescription(params.product);

        this.headService.addDescriptionMetaInformations(metaDescription);
        this.headService.addItemPropMetaInformations(
            params.product.title,
            params.product.attributes.description,
            sharingImageUrl,
        );
        this.headService.addOgMetaInformations(
            "og:product",
            params.product.title,
            params.product.attributes.description,
            params.productUrl,
            sharingImageUrl,
        );
        this.headService.addTwitterMetaInformations(
            "summary",
            params.product.title,
            params.product.attributes.description,
            params.productUrl,
            sharingImageUrl,
        );
        this.addProductMetaInformations(
            params.productReference,
            String(params.product.price),
        );
    }

    /**
     * Insert microdata in head tag
     *
     * @param product Displayed product
     * @param productUrl Complete url to product page
     * @param productReference Refactored product SKU
     */
    private addProductMicroDatas(params: {
        product: Product;
        productUrl: string;
        productReference: string;
        crossSellInformations: CrossSell | undefined;
    }): void {
        let similarProducts: CrossSellingProduct[] = [];

        if (params.crossSellInformations?.keepExploring) {
            similarProducts = params.crossSellInformations.keepExploring.items;
        }
        const microDatas = this.getProductMicroDatas({
            ...params,
            similarProducts,
        });
        this.addScriptInHead(microDatas);
    }

    /**
     * Build microdata from Product
     *
     * @param product Displayed product
     * @param productUrl Complete url to product page
     * @param similarProduct Array of similar product
     */
    private getProductMicroDatas(params: {
        product: Product;
        productUrl: string;
        productReference: string;
        similarProducts: CrossSellingProduct[];
    }): ProductMicroDatas {
        const productJson = new ProductMicroDatas();
        const productRange = this.headService.cleanHtmlEncoding(
            params.product.displayNameList,
            encodingHtmlCharacters,
        );
        const productTitle = this.headService.cleanHtmlEncoding(
            params.product.title,
            encodingHtmlCharacters,
        );
        productJson.name = productRange
            ? `${productRange}, ${productTitle}`
            : productTitle;
        productJson.image = this.getImagesUrl(
            params.product,
            IMAGE_SIZE_MICRODATA,
        );
        productJson.description = this.headService.cleanHtmlEncoding(
            params.product.attributes.description,
            encodingHtmlCharacters,
        );
        productJson.mpn = params.productReference;
        productJson.sku = params.productReference;
        productJson.url = params.productUrl;

        if (params.product.templateType !== ProductTemplateType.Look) {
            productJson.offers.priceCurrency =
                this.currencyService.getCurrencyMap()?.[1];
            productJson.offers.price = String(params.product.price);
            productJson.offers.availability = this.getProductAvailability(
                params.product,
            );
        }

        productJson.isSimilarTo = this.getSimilarProductsInfos(
            params.similarProducts,
        );
        return productJson;
    }

    /**
     * Build an array of product image urls with a precise sizing
     *
     * @param product Displayed product
     * @param size Image size
     */
    private getImagesUrl(product: Product, size: number): string[] {
        if (product?.assets) {
            return product.assets.map((asset: Asset) =>
                this.getImageUrl(asset, product, size),
            );
        }
        return [];
    }

    /**
     * Build a product image url based on product's asset and size
     *
     * @param asset Product's asset
     * @param product Displayed product
     * @param imageSize Image size
     */
    private getImageUrl(
        asset: Asset,
        product: Product,
        imageSize: number,
    ): string {
        const url = this.getUrlFromAsset(asset);
        const urlImageSeo = this.productImageSeoUrlPipe.transform(url, {
            quality:
                product.templateType === ProductTemplateType.Giftset
                    ? DEFAULT_GIFTSET_QUALITY
                    : undefined,
            slug: product.slug,
            imageSize,
            templateType: product.templateType,
        });

        return `https:${urlImageSeo}`;
    }

    private getProductAvailability(product: Product) {
        return product.stock && isAvailable(product.stock)
            ? StockAvailability.IN_STOCK
            : StockAvailability.OUT_OF_STOCK;
    }

    private getSimilarProductsInfos(
        similarProducts: CrossSellingProduct[],
    ): SimilarProduct[] {
        if (!similarProducts || similarProducts.length === 0) {
            return [];
        }
        const type = "@type";
        return similarProducts.map((similarProduct) => ({
            [type]: "Product",
            name: similarProduct.title,
            url: [
                this.settings.frontend,
                this.locale.urlPrefix,
                similarProduct.url,
            ].join(""),
        }));
    }

    private addScriptInHead(product: ProductMicroDatas): void {
        this.headService.addScript(
            JSON.stringify(product),
            "application/ld+json",
            "microdata",
        );
    }

    /**
     * Add preload link for the main product image.
     */
    private addPreloadImageLink(product: Product): void {
        const imageUrl = this.getImagesUrl(
            product,
            // All products, except Giftset have same generic size.
            product.templateType === ProductTemplateType.Giftset
                ? ContentsSizesConstants.GIFTSET_PAGE
                : ContentsSizesConstants.PRODUCT_PAGE,
        ).shift(); // First item

        if (imageUrl) {
            this.headService.cleanImagePreloadUrls();
            this.headService.addPreloadImageLink({ href: imageUrl });
        }
    }

    private getMeasureUnit(): string {
        return isUsa(this.locale) ? "fl.oz" : "ml";
    }
}
