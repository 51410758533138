import { Injectable } from "@angular/core";
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
    public parse(url: string): UrlTree {
        const dus = new DefaultUrlSerializer();
        // Encode parenthese
        const urlParenthesesEncoded = url
            .replace(/\(/g, "%28")
            .replace(/\)/g, "%29");
        return dus.parse(urlParenthesesEncoded);
    }

    public serialize(tree: UrlTree): string {
        const dus = new DefaultUrlSerializer();
        const path = dus.serialize(tree);
        return path
            .replace(/%7C/g, "|")
            .replace(/%28/g, "(")
            .replace(/%29/g, ")");
    }
}
