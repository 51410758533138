import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import { ShellFacade } from "@hermes/states/shell";
import { TrayStackService } from "@hermes/states/tray";
import {
    HermesRoutes,
    SHELL_APPEARANCE_FULL,
    SHELL_APPEARANCE_LIGHT,
    SHELL_APPEARANCE_NONE,
    ShellAppearanceType,
} from "@hermes/utils-generic/constants";

/**
 * Usage : add shell param on a route in app-routing.module.ts to change appearance
 * ```
 * { path: "/test-page", data: {shell: SHELL_APPEARANCE_LIGHT} }
 * ```
 *
 * Or change appearance by NGRX Shell State (see shell facade)
 */
export interface ShellRouteData extends HermesRoutes {
    /** style of backButton */
    backButton: "home";
}

/**
 * Shell component is the main wrapper of the site
 *
 * It is in charge of :
 *
 * Displaying header and footer independently of environment, it should take DrupalFeatures in consideration for angular first rollout.
 * Displaying light versions of header footer if we're on a "light" page ()
 * Making it so that every page has the same basic structure.
 */
@Component({
    selector: "h-shell",
    templateUrl: "./shell.component.html",
    styleUrls: ["./shell.component.scss"],
})
export class ShellComponent implements OnInit, OnDestroy {
    public trayOpened$ = this.trayStack.trays$.pipe(
        map((trays) => trays.length > 0),
    );

    /** On wechat payment pages  */
    public isBackButtonHome: boolean = false;

    public isFull$ = this.shellFacade.shell$.pipe(
        map((mode: ShellAppearanceType) => mode === SHELL_APPEARANCE_FULL),
    );

    public isLight$ = this.shellFacade.shell$.pipe(
        map((mode: ShellAppearanceType) => mode === SHELL_APPEARANCE_LIGHT),
    );

    public noShell$ = this.shellFacade.shell$.pipe(
        map((mode: ShellAppearanceType) => mode === SHELL_APPEARANCE_NONE),
    );

    private subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private trayStack: TrayStackService,
        private shellFacade: ShellFacade,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map(() => this.route),
                    // read the route tree and aggregate route data
                    map((route: ActivatedRoute) => {
                        let data: Partial<ShellRouteData> = {
                            ...route.snapshot.data,
                        };
                        while (route.firstChild) {
                            data = { ...data, ...route.snapshot.data };
                            route = route.firstChild;
                        }
                        return data;
                    }),
                )
                .subscribe((data: Partial<ShellRouteData>) => {
                    this.isBackButtonHome = data.backButton === "home";
                    this.changeDetector.markForCheck();
                }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
