import { Params } from "@angular/router";
import {
    RouterReducerState,
    routerReducer as defaultRouterReducer,
    BaseRouterStoreState,
} from "@ngrx/router-store";
import { createReducer, on } from "@ngrx/store";

import { setPreviousUrl } from "../actions/router.action";
import { concatReducers } from "../helpers/concat-reducer.helper";

export const routerFeatureKey = "router";

export type RouterStoreState = {
    params: Params;
    queryParams: Params;
    previousUrl?: string;
} & BaseRouterStoreState;

export type CustomRouterReducerState = RouterReducerState<RouterStoreState>;

export const initialState: CustomRouterReducerState = {
    state: {
        url: "",
        params: {},
        queryParams: {},
    },
    navigationId: 0,
};

const customRouterStoreReducer = createReducer(
    initialState,
    on(setPreviousUrl, ({ state, navigationId }, { previousUrl }) => ({
        state: {
            ...state,
            previousUrl,
        },
        navigationId,
    })),
);

export const routerReducer = concatReducers(
    defaultRouterReducer,
    customRouterStoreReducer,
);
