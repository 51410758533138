import { DatePipe } from "@angular/common";

import { Locale } from "@hermes/locale";
import { DAY, MONTH, OF, YEAR } from "@hermes/utils-generic/constants";

/**
 * Create a date object with the year, month and day values
 *
 * @param year Year of the date
 * @param month Month of the date
 * @param day Day of the date
 */
export const createDate = (year: number, month: number, day: number): Date => {
    const date = new Date(Date.now());
    // Order is crucial, should be year then month+day
    date.setUTCFullYear(year);
    date.setUTCMonth(month, day);

    return date;
};

/**
 * Returns milisecond between a date and now
 *
 * @param date number
 *
 */
export const getMsBetweenDateAndNow = (date: number): number =>
    Math.max(date - Date.now(), 0);

/**
 * Returns true if the given date is valid
 *
 * @param year Year of the date
 * @param month Month of the date
 * @param day Day of the date
 */
export const isValidDate = (
    year: number,
    month: number,
    day: number,
): boolean => {
    // Check the ranges of month and year
    if (year < 1900 || year > 3000 || month > 11) {
        return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month];
};

export const isDatePassed = (date: Date): boolean => {
    const now = new Date(Date.now()).setHours(0, 0, 0, 0);

    return date.getTime() < now;
};

export const isDateOverAgeLimit = (date: Date, ageLimit: number): boolean => {
    const currentDate = new Date();
    const ageDifference = currentDate.getFullYear() - date.getFullYear();
    const isAgeLimitPassed =
        currentDate.getMonth() > date.getMonth() ||
        (currentDate.getMonth() === date.getMonth() &&
            currentDate.getDate() >= date.getDate());

    return (
        ageDifference > ageLimit ||
        (ageDifference === ageLimit && isAgeLimitPassed)
    );
};

export const getFormattedDate =
    (locale: Locale, datePipe: DatePipe) => (dateString: string) => {
        let dateFormatString: string;
        switch (locale.langCode) {
            case "en": {
                dateFormatString = "EEEE MMMM d, yyyy";
                break;
            }
            case "es": {
                dateFormatString = `EEEE d '${OF}' MMMM '${OF}' yyyy`;
                break;
            }
            case "pt": {
                dateFormatString = `EEEE, d '${OF}' MMMM '${OF}' yyyy`;
                break;
            }
            case "de": {
                dateFormatString = "EEEE, d. MMMM yyyy";
                break;
            }
            case "ko": {
                dateFormatString = `yyyy'${YEAR}' M'${MONTH}' d'${DAY}' EEEE`;
                break;
            }
            case "zh-Hans":
            case "zh-Hant":
            case "ja": {
                dateFormatString = `yyyy'${YEAR}'M'${MONTH}'d'${DAY}'EEEE`;
                break;
            }
            default: {
                dateFormatString = "EEEE d MMMM yyyy";
            }
        }

        return datePipe.transform(dateString, dateFormatString) ?? "";
    };
