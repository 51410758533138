import { TranslateService } from "@ngx-translate/core";
import { filter, Observable, take } from "rxjs";

import { Locale } from "@hermes/locale";
import { FeatureFlagFacade, FEATURE_FLAGS } from "@hermes/states/flipper";

/**
 * This function will trigger :
 * - feature flag fetching
 * - current locale for ngx-translate
 *
 * at the start of the application
 *
 * This function is injected at application startup and executed during app initialization.
 * The function is executed during the application bootstrap process, and the needed data is available on startup.
 *
 * Warning : initialization does not complete until the Observable is completed.
 *
 * Put here what is considered blocking for the whole application.
 *
 * @see https://angular.io/api/core/APP_INITIALIZER for more details
 */
export const initializeApplication =
    (
        featureFlagFacade: FeatureFlagFacade,
        translateService: TranslateService,
        locale: Locale,
    ): (() => Observable<boolean>) =>
    () => {
        // eslint-disable-next-line rxjs/no-ignored-observable
        translateService.use(locale.id);

        featureFlagFacade.fetchFeatureFlags([...FEATURE_FLAGS]);
        return featureFlagFacade.isLoaded$.pipe(
            filter((isLoaded) => isLoaded),
            take(1),
        );
    };
