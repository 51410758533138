<div class="tray-container" [ngClass]="{ hidden: !isOpen && !showContainer }">
    <div
        class="tray-wrapper tray-wrapper-{{ position }}"
        *ngIf="isOpen"
        [@tray]="isOpen ? position : 'void'"
        (@tray.start)="onAnimationStart()"
        (@tray.done)="onAnimationEnd($event)"
        (scroll)="stopPropagation($event)">
        <ng-container *ngFor="let tray of trays; trackBy: trackByUuid; let idx = index">
            <div [cdkTrapFocus]="true" [cdkTrapFocusAutoCapture]="true">
                <ng-container *ngIf="!tray.isDrupalTray; else drupalTray">
                    <div
                        class="tray-slide"
                        [style.background-color]="tray.backgroundColor"
                        [attr.role]="'dialog'"
                        aria-modal="true"
                        [attr.aria-labelledby]="'tray-title-' + tray.uuid"
                        [@trayContent]="idx < trays.length - 1"
                        [id]="tray.uuid">
                        <h-tray-title
                            [tray]="tray"
                            ngClass="tray-title-fixed"
                            [style.background-color]="
                                tray.titleBackgroundColor ?? tray.backgroundColor ?? defaultTrayColor
                            "
                            (closeTray)="closeTray($event)">
                        </h-tray-title>
                        <div class="tray-content" [class.tray-content-no-padding-bottom]="noPaddingBottom">
                            <h-tray-content [data]="tray"></h-tray-content>
                        </div>
                        <h-category-institutional *ngIf="tray.hasCategoryInstitutional"></h-category-institutional>
                    </div>
                </ng-container>
                <!-- Drupal tray handles it's own title/content and does not need all the style -->
                <ng-template #drupalTray>
                    <div class="drupal-tray-slide" [@trayContent]="idx < trays.length - 1" [id]="tray.uuid">
                        <h-tray-content [data]="tray"></h-tray-content>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>

    <h-tray-overlay [shown]="hasOverlay && isOpen" (overlayClick)="handleOverlayClick()"></h-tray-overlay>
    <h-tray-overlay-map *ngIf="hasMapOverlay"></h-tray-overlay-map>
</div>
