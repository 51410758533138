<ng-container [ngSwitch]="state">
    <div class="loading-container" *ngSwitchCase="'validation'">
        <h1 class="redirection-title" i18n="@@hermes-global-translations.redirect.paypal.title"> Redirecting...</h1>
        <span class="redirection-text" i18n="@@hermes-global-translations.redirect.paypal.text"
            >We finalize your payment, you will be directed to your order confirmation page.</span
        >
        <h-loader-spinner></h-loader-spinner>
    </div>
    <div class="loading-container" *ngSwitchCase="'error'">
        <h-message-block [type]="'error'">
            <span *ngIf="errorMessage; else globalError">
                {{ errorMessage }}
            </span>
        </h-message-block>

        <a (click)="urlUtils.redirectToRootPath()" href="" i18n="@@hermes-global-translations.home">
            Go back to home
        </a>
    </div>
</ng-container>

<ng-template #globalError>
    <span i18n="@@hermes-checkout-payment-global-error"> Oops, something's gone wrong. Please try again later. </span>
</ng-template>
