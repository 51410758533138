// Checkout Pages
export const CART_PAGE = "cart";
export const CHECKOUT_PAGE = "checkout";
export const LOGIN_PAGE = "login";
export const CONFIRMATION_PAGE = "order";

export type CheckoutPages =
    | typeof CART_PAGE
    | typeof CHECKOUT_PAGE
    | typeof LOGIN_PAGE
    | typeof CONFIRMATION_PAGE;
