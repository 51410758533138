import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, filter, tap } from "rxjs";

import { BannersData, FooterData } from "@hermes/api-model-shell";

import { ShellAppearanceType } from "@hermes/utils-generic/constants";

import {
    fetchBanners,
    fetchBannersFailure,
    fetchBannersSuccess,
    fetchFooterItems,
    fetchFooterItemsFailure,
    fetchFooterItemsSuccess,
    setShell,
    updateBasket,
} from "../actions/shell.actions";
import {
    selectBanners,
    selectBasket,
    selectBasketTotalItems,
    selectFooterItems,
    selectshell,
} from "../selectors/shell.selectors";
import { ShellState } from "../state/shell.state";

@Injectable()
export class ShellFacade {
    public shell$: Observable<ShellAppearanceType> =
        this.store.select(selectshell);
    /**
     * Selectors
     */
    public basket$ = this.store.select(selectBasket);

    public basketTotalItems$ = this.store.select(selectBasketTotalItems);

    public banners$ = this.store.select(selectBanners);

    public footerItems$ = this.store.select(selectFooterItems);

    /**
     * Actions
     */

    public getBanners(): Observable<BannersData> {
        return this.banners$.pipe(
            tap((banners: BannersData) => {
                if (!banners) {
                    this.fetchBanners();
                }
            }),
            filter(Boolean),
        );
    }

    public setShell(shell: ShellAppearanceType): void {
        this.store.dispatch(setShell({ shell }));
    }

    public updateBasket(amount: number): void {
        this.store.dispatch(updateBasket({ amount }));
    }

    public fetchBanners(): void {
        this.store.dispatch(fetchBanners());
    }

    public fetchBannersSuccess(data: BannersData): void {
        this.store.dispatch(fetchBannersSuccess({ data }));
    }

    public fetchBannersFailure(error: Error): void {
        this.store.dispatch(fetchBannersFailure({ error }));
    }

    public fetchFooterItems(): void {
        this.store.dispatch(fetchFooterItems());
    }

    public fetchFooterItemsSuccess(data: FooterData): void {
        this.store.dispatch(fetchFooterItemsSuccess({ data }));
    }

    public fetchFooterItemsFailure(error: Error): void {
        this.store.dispatch(fetchFooterItemsFailure({ error }));
    }

    constructor(private store: Store<ShellState>) {}
}
