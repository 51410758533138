import { GTMPageViewBase } from "../types/gtm.type";

import { AnalyticsEvent } from "./analytics.event";

export type PageViewAnalyticsEventData = Omit<GTMPageViewBase, "event">;
/**
 * Abstract class for pageview event
 */
export class PageViewAnalyticsEvent implements AnalyticsEvent<GTMPageViewBase> {
    /**
     * Event name expected for every impression event
     */
    public name: string = "pageview";

    /**
     * Event's data from component to format correctly impression event
     */
    public eventData: PageViewAnalyticsEventData;

    constructor(data: PageViewAnalyticsEventData) {
        this.eventData = data;
    }

    /**
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMPageViewBase {
        return {
            event: this.name,
            pagename: this.eventData.pagename,
            languagecode: this.eventData.languagecode,
            countrycode: this.eventData.countrycode,
            buildversion: this.eventData.buildversion,
            devicetype: this.eventData.devicetype,
            userId: this.eventData.userId,
            clienttype: this.eventData.clienttype,
            clientgender: this.eventData.clientgender,
            pageTranslate: this.eventData.pageTranslate,
            pagetype: this.eventData.pagetype,
            previousPage: this.eventData.previousPage,
        };
    }
}
