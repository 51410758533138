import { Inject, Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subscription } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

import { Context, WINDOW } from "@hermes/app-core";
import { Logger } from "@hermes/logger";
import { FeatureFlagFacade, RISKIFIED_BEACON } from "@hermes/states/flipper";
import { LOGGER } from "@hermes/web-logger";

import { LoadRiskifiedBeaconLibraryService } from "./load-riskified-beacon-library.service";

@Injectable()
export class RiskifiedBeaconService implements OnDestroy {
    private subscription: Subscription = new Subscription();
    private sessionId = this.cookieService.get("correlation_id");

    constructor(
        private router: Router,
        private loadRiskifiedBeaconLibraryService: LoadRiskifiedBeaconLibraryService,
        private cookieService: CookieService,
        private featureFlagFacade: FeatureFlagFacade,
        private context: Context,
        @Inject(LOGGER) private logger: Logger,
        @Inject(WINDOW)
        private window: Window & {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            RISKX: {
                setSid: (argument0: string) => void;
                go: (argument0: string) => void;
            };
        },
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public executeService(): void {
        if (!this.context.isInBrowserMode()) {
            return;
        }
        this.subscription.add(
            this.featureFlagFacade
                .isActivated(RISKIFIED_BEACON)
                .pipe(
                    filter((isActived) => isActived),
                    tap(() =>
                        this.loadRiskifiedBeaconLibraryService.load(
                            this.window.location.hostname,
                            this.sessionId,
                        ),
                    ),
                    switchMap(
                        () => this.loadRiskifiedBeaconLibraryService.isLoaded$,
                    ),
                    filter((isLoaded) => isLoaded),
                    switchMap(() => this.sendClientData()),
                )
                .subscribe(),
        );

        this.subscription.add(
            this.loadRiskifiedBeaconLibraryService.isError$
                .pipe(filter((isError) => isError))
                .subscribe(() => {
                    this.logger.error("Riskified Beacon can't be loaded");
                }),
        );
    }

    private sendClientData(): Observable<void> {
        return this.router.events.pipe(
            filter(
                (event): event is NavigationEnd =>
                    event instanceof NavigationEnd,
            ),
            map((data: NavigationEnd) => {
                const newSessionId = this.cookieService.get("correlation_id");
                if (this.sessionId !== newSessionId) {
                    this.window.RISKX.setSid(newSessionId);
                    this.sessionId = newSessionId;
                }
                this.window.RISKX.go(data.url);
            }),
        );
    }
}
