import { DOCUMENT } from "@angular/common";
import {
    Inject,
    Injectable,
    OnDestroy,
    Renderer2,
    RendererFactory2,
} from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { Context } from "@hermes/app-core";

/**
 * @deprecated It is depreciated in favor of the State NgRx : ExternalLibraryState.
 * The adventage is more predictable and optimizes the loading of libs on the SSR side.
 */
@Injectable()
export class LoadExternalUrlService implements OnDestroy {
    public scriptsLoaded: { [url: string]: BehaviorSubject<boolean> } = {};

    public subscription: Subscription = new Subscription();
    public renderer: Renderer2;

    constructor(
        private context: Context,
        protected rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        // eslint-disable-next-line unicorn/no-null
        this.renderer = rendererFactory.createRenderer(this.document, null);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Ensure the script is loaded
     *
     * Only use this as a failsafe, otherwise use {@link loadExternalLibrary}
     */
    public isLibraryLoaded(url: string): boolean {
        const subject$ = this.scriptsLoaded[url];
        return subject$ ? subject$.getValue() : false;
    }

    /**
     * This function will remove from DOM a script only if the script is already loaded.
     */
    public removeExternalLibrary(url: string): void {
        if (this.isLibraryLoaded(url)) {
            const scriptList = document.querySelectorAll<HTMLScriptElement>(
                "script[type='text/javascript']",
            );
            scriptList.forEach((script) => {
                if (script.src === url) {
                    script.remove();
                }
            });
            this.scriptsLoaded[url].next(false);
            this.scriptsLoaded[url].complete();
            delete this.scriptsLoaded[url];
        }
    }

    /**
     * This function will load the required script
     *
     * If the script is already loaded,
     * the returned observable will immediately emit
     *
     * If the script is already loading, this fuction will not
     * load the script twice, and the returned observable
     * will emit when the script is loaded
     */
    public loadExternalLibrary(url: string): Observable<void> {
        return new Observable((observer) => {
            // If script is already Loaded or in loading
            if (this.scriptsLoaded[url]) {
                const scriptLoaded$: BehaviorSubject<boolean> =
                    this.scriptsLoaded[url];
                this.subscription.add(
                    scriptLoaded$.subscribe((isLoaded) => {
                        if (isLoaded) {
                            observer.next();
                            observer.complete();
                        }
                    }),
                );
            } else {
                const head: HTMLHeadElement = this.renderer.selectRootElement(
                    "head",
                    true,
                );
                const script: HTMLScriptElement =
                    this.renderer.createElement("script");
                script.setAttribute("type", "text/javascript");
                script.src = url;
                this.renderer.appendChild(head, script);
                this.scriptsLoaded[url] = new BehaviorSubject<boolean>(false);

                // manage load and error in a promise :
                this.renderer.listen(script, "load", () => {
                    if (this.context.isInBrowserMode()) {
                        this.scriptsLoaded[url].next(true);
                        observer.next();
                        observer.complete();
                    }
                });
                this.renderer.listen(script, "onerror", (error) => {
                    observer.error(error);
                });
            }
        });
    }
}
