import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { NavigationService } from "@hermes/utils-generic/services/user-interface";

import { hideNotification, showNotification } from "../actions/layout.actions";

@Injectable()
export class LayoutEffects {
    public showNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(showNotification),
                tap((action) => {
                    if (action.displayOverlay) {
                        this.navigationService.disableScrollandKeys();
                    } else {
                        this.navigationService.enableScrollandKeys();
                    }
                }),
            ),
        {
            dispatch: false,
        },
    );

    public hideNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(hideNotification),
                tap(() => this.navigationService.enableScrollandKeys()),
            ),
        {
            dispatch: false,
        },
    );

    constructor(
        private actions$: Actions,
        private navigationService: NavigationService,
    ) {}
}
