import { createAction, props } from "@ngrx/store";

import { MenuEntry } from "@hermes/api-model-shell";

export const fetchMenu = createAction("[Menu] Fetch menu");

export const fetchMenuSuccess = createAction(
    "[Menu] Fetch menu success",
    props<{ entry: MenuEntry }>(),
);

export const fetchMenuFailure = createAction(
    "[Menu] Fetch menu failure",
    props<{ error: Error }>(),
);
