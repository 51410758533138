import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { ProductUtilsService } from "@hermes/fragments/product-utils";
import { addToCartSuccess } from "@hermes/states/basket";
import {
    fetchProductFailure,
    ProductPageFacade,
    fetchProductSuccess,
} from "@hermes/states/product-page";
import { ProductTemplateType } from "@hermes/utils/constants";

import {
    fetchGiftsetProductSuccess,
    emptySelectedItems,
} from "../actions/giftset-product-page.actions";

/**
 * Effects on giftset product page
 */
@Injectable()
export class GiftsetProductPageEffects {
    public emptyItemsOnAddToCartSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addToCartSuccess),
            withLatestFrom(this.productPageFacade.templateType$),
            filter(
                ([_, templateType]) =>
                    templateType === ProductTemplateType.Giftset,
            ),
            map(() => emptySelectedItems()),
        ),
    );

    // If AddToCart from giftset page (allIds array populated), ATC button must be disabled (till new set complete)
    // If fetchProductSuccess or fetchProductFailure from giftset page ATC button must be disabled (till set is complete)
    public changeButtonEnabledState$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    addToCartSuccess,
                    fetchProductSuccess,
                    fetchProductFailure,
                ),
                withLatestFrom(this.productPageFacade.templateType$),
                filter(
                    ([_, templateType]) =>
                        templateType === ProductTemplateType.Giftset,
                ),
                map(() =>
                    this.productUtilsService.changeButtonEnabledState(0, false),
                ),
            ),
        { dispatch: false },
    );

    public fetchProductSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchProductSuccess),
            withLatestFrom(this.productPageFacade.templateType$),
            filter(
                ([_, templateType]) =>
                    templateType === ProductTemplateType.Giftset,
            ),
            map(([{ data }]) => fetchGiftsetProductSuccess({ product: data })),
        ),
    );

    constructor(
        private actions$: Actions,
        private productPageFacade: ProductPageFacade,
        private productUtilsService: ProductUtilsService,
    ) {}
}
