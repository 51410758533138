import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { LOCALE_TOKEN_NAME } from "@hermes/utils/constants";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
    constructor(@Inject(LOCALE) private locale: Locale) {}

    public intercept<T, U>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<U>> {
        return next.handle(
            request.clone({
                headers: request.headers.set(
                    LOCALE_TOKEN_NAME,
                    this.locale.code,
                ),
            }),
        );
    }
}
