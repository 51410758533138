import {
    addToCart,
    addToCartFailure,
    addToCartSuccess,
} from "@hermes/states/basket";
import { ProductTemplateType } from "@hermes/utils/constants";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import {
    changeButtonEnabledState,
    changeEcomErrorState,
    closeNotificationDisplayed,
    initAddToCartCallToAction,
    setAddToCartCallToActionsToDefault,
} from "../actions/add-to-cart.actions";
import { AddToCartCallToAction, AddToCartState } from "../add-to-cart.state";
import { getProductAddToCartCallToActions } from "../helpers/add-to-cart.helpers";

export const addToCartProductReducer = (
    state: AddToCartState,
    action: ReturnType<typeof addToCart>,
): AddToCartState => {
    const callToActions = [...state.addToCartCallToActions];
    callToActions[action.addToCartPayload.itemPosition] = {
        ecomError: {
            enabled: false,
        },
        button: {
            enabled: true,
        },
    };
    return {
        ...state,
        loading: true,
        addToCartPositionClicked: action.addToCartPayload.itemPosition,
        addToCartCallToActions: callToActions,
    };
};

export const addToCartProductSuccessReducer = (
    state: AddToCartState,
    action: ReturnType<typeof addToCartSuccess>,
): AddToCartState => {
    const callToActions = [...state.addToCartCallToActions];
    callToActions[action.itemPosition] = {
        ecomError: {
            errorCode: undefined,
            enabled: false,
        },
        button: {
            enabled: true,
        },
    };

    return {
        ...state,
        loading: false,
        basket: {
            notifDisplayed: action.displayNotifOnSuccess,
            hasComplementaryDelivery: action.basket.has_complimentary_delivery,
            totalItems: action.basket.total_items,
        },
        addToCartCallToActions: callToActions,
    };
};

export const changeButtonEnabledStateReducer = (
    state: AddToCartState,
    action: ReturnType<typeof changeButtonEnabledState>,
): AddToCartState => {
    const callToActions = [...state.addToCartCallToActions];
    callToActions[action.itemPosition] = {
        ecomError: {
            errorCode: undefined,
            enabled: false,
        },
        button: {
            enabled: action.enabled,
        },
    };

    return {
        ...state,
        addToCartCallToActions: callToActions,
    };
};
export const changeEcomErrorStateReducer = (
    state: AddToCartState,
    action: ReturnType<typeof changeEcomErrorState>,
): AddToCartState => {
    const callToActions = [...state.addToCartCallToActions];
    callToActions[action.itemPosition] = {
        ecomError: {
            errorCode:
                action.ecomErrorCode ??
                state.addToCartCallToActions[action.itemPosition]?.ecomError
                    .errorCode,
            enabled:
                action.ecomErrorEnabled ??
                state.addToCartCallToActions[action.itemPosition]?.ecomError
                    .enabled,
        },
        button: {
            ...state.addToCartCallToActions[action.itemPosition]?.button,
        },
    };
    return {
        ...state,
        addToCartCallToActions: callToActions,
    };
};
export const addToCartProductFailureReducer = (
    state: AddToCartState,
    action: ReturnType<typeof addToCartFailure>,
): AddToCartState => {
    let errorCode = EcomErrorCodes.OUT_OF_STOCK;
    let addToCartButtonEnabled = false;
    // personalization mixed item message
    if (
        action.error.internal_code ===
            EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS ||
        action.error.internal_code ===
            EcomErrorCodes.ERROR_CODE_PERSONALIZATION_QUOTE
    ) {
        errorCode = EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS;
    }
    // max child quantity
    if (action.error.internal_code === EcomErrorCodes.MAX_CHILD_QUANTITY) {
        errorCode = EcomErrorCodes.MAX_CHILD_QUANTITY;
    }
    // Response http error from ecom
    if (action.error.internal_code === EcomErrorCodes.TECHNICAL_ERROR) {
        errorCode = EcomErrorCodes.TECHNICAL_ERROR;
        addToCartButtonEnabled = true;
    }
    const callToActions = [...state.addToCartCallToActions];
    callToActions[action.itemPosition] = {
        ecomError: {
            errorCode,
            enabled: true,
        },
        button: {
            enabled: addToCartButtonEnabled,
        },
    };
    return {
        ...state,
        loading: false,
        addToCartCallToActions: callToActions,
    };
};

export const closeNotificationDisplayedReducer = (
    state: AddToCartState,
    _action: ReturnType<typeof closeNotificationDisplayed>,
): AddToCartState => ({
    ...state,
    basket: {
        ...state.basket,
        notifDisplayed: false,
    },
});

export const setAddToCartCallToActionsToDefaultReducer = (
    state: AddToCartState,
    action: ReturnType<typeof setAddToCartCallToActionsToDefault>,
): AddToCartState => {
    // On variant selection, remove errors that are on the CTA form
    const addToCartCallToActions = [...state.addToCartCallToActions];

    // Base case is to reset the addToCart error where the selected variant is changed
    const indexToRemoveError = action.position ?? 0;

    addToCartCallToActions[indexToRemoveError] = {
        ecomError: {
            errorCode: undefined,
            enabled: false,
        },
        button: {
            enabled: true,
        },
    };

    return {
        ...state,
        addToCartCallToActions,
    };
};
export const initCallToActionReducer = (
    state: AddToCartState,
    action: ReturnType<typeof initAddToCartCallToAction>,
): AddToCartState => {
    const product = action.data;

    switch (product.templateType) {
        case ProductTemplateType.Simple:
        case ProductTemplateType.Beltkit:
        case ProductTemplateType.AppleWatch:
        case ProductTemplateType.DoubleFragrance:
        case ProductTemplateType.Giftset: {
            return {
                ...state,
                loading: false,
                addToCartCallToActions: [
                    getProductAddToCartCallToActions(product),
                ],
            };
        }
        case ProductTemplateType.Bikini: {
            return {
                ...state,
                addToCartCallToActions: [
                    fuseAddToCartCallToAction(
                        product.bikiniAttributes?.items
                            ? product.bikiniAttributes.items.map((item) =>
                                  getProductAddToCartCallToActions(item),
                              )
                            : [],
                    ),
                ],
            };
        }
        case ProductTemplateType.Look: {
            return {
                ...state,
                addToCartCallToActions: product.lookAttributes?.items
                    ? product.lookAttributes.items.map((item) =>
                          getProductAddToCartCallToActions(item),
                      )
                    : [],
            };
        }
        default: {
            return { ...state };
        }
    }
};

const fuseAddToCartCallToAction = (
    ctas: AddToCartCallToAction[],
): AddToCartCallToAction =>
    // eslint-disable-next-line unicorn/no-array-reduce
    ctas.reduce(
        (accumulator, item) => ({
            ecomError: {
                errorCode:
                    accumulator.ecomError.errorCode || item.ecomError.errorCode,
                enabled:
                    accumulator.ecomError.enabled || item.ecomError.enabled,
            },
            button: {
                enabled: accumulator.button.enabled && item.button.enabled,
            },
        }),
        {
            ecomError: {
                errorCode: undefined,
                enabled: false,
            },
            button: {
                enabled: true,
            },
        },
    );
