import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { CartService } from "@hermes/utils/services/api-clients";

import { BasketEffects } from "./effects/basket.effects";
import { BasketFacade } from "./facades/basket.facade";
import * as fromBasket from "./reducers/basket.reducer";
import { BasketService } from "./services/basket.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromBasket.basketFeatureKey, fromBasket.reducer),
        EffectsModule.forFeature([BasketEffects]),
    ],
    providers: [BasketFacade, BasketService, CartService],
})
export class BasketStateModule {}
