import { InjectionToken, Type } from "@angular/core";

export const SHOW_TRAY_ANIMATION = ".35s cubic-bezier(.215, .610, .355, 1)";
export const HIDE_TRAY_ANIMATION = ".35s cubic-bezier(.165, .84, .44, 1)";

export const HIDDEN_LEFT_TRANSLATION = "translate3d(-100%, 0, 0)";
export const HIDDEN_RIGHT_TRANSLATION = "translate3d(100%, 0, 0)";
export const SHOWN_TRANSLATION = "translateZ(0)";
export const CONTENT_RIGHT_TRANSLATION = "translate3d(50%, 0, 0)";
export const CONTENT_LEFT_TRANSLATION = "translate3d(-50%, 0, 0)";
export const NO_TRANSLATION = "translate3d(0,0,0)";

export const OVERLAY_ANIMATION = ".5s cubic-bezier(.77, 0, .175, 1)";
export const OVERLAY_OPACITY = "0.45";

// injection token so that every tray module can provide its main component class
export const TRAY_COMPONENT: InjectionToken<Type<unknown>> = new InjectionToken<
    Type<unknown>
>("TRAY_COMPONENT");
