import { APP_BASE_HREF } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";

import { CustomerService } from "@hermes/api-model-editorial";
import { FooterHereToHelpColumnContacts } from "@hermes/api-model-shell";
import { COUNTRY } from "@hermes/app-core";
import { footerHereToHelpColumnContactsToCustomerServiceMapper } from "@hermes/fragments/customer-service-contact";
import { Country, isChina } from "@hermes/locale";
import {
    ENABLE_CONTACT_US_FROM_DISCOVERY,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { AnalyticsService } from "@hermes/utils/analytics";
import { RouterService } from "@hermes/utils-generic/services/router";

import { FooterClickEvent } from "../../events/click.event";
@Component({
    selector: "h-footer-here-to-help",
    templateUrl: "./footer-here-to-help.component.html",
    styleUrls: ["./footer-here-to-help.component.scss"],
})
export class FooterHereToHelpComponent {
    @Input()
    public title?: string;

    public customerServiceContacts: CustomerService[] | undefined = undefined;

    public isContactUsFromDiscovery$ = this.featureFlagFacade.isActivated(
        ENABLE_CONTACT_US_FROM_DISCOVERY,
    );

    public isChina = isChina(this.country);

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(COUNTRY) private country: Country,
        private analyticsService: AnalyticsService,
        private routerService: RouterService,
        private featureFlagFacade: FeatureFlagFacade,
    ) {}

    @Input()
    public set contacts(
        footerHereToHelpColumnContacts:
            | FooterHereToHelpColumnContacts[]
            | undefined,
    ) {
        if (footerHereToHelpColumnContacts) {
            this.customerServiceContacts =
                footerHereToHelpColumnContactsToCustomerServiceMapper(
                    footerHereToHelpColumnContacts,
                );
        }
    }

    public sendFooterEvent($event: Event, action: string, label: string): void {
        $event.stopPropagation();
        this.analyticsService.sendData(
            new FooterClickEvent({
                action,
                label,
            }),
        );
    }

    public goToContactUsPage(event: MouseEvent): void {
        event.preventDefault();
        this.routerService.spaRedirect("/contact-us/");
    }
}
