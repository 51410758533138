import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { svgExclamation, svgInfo, SvgModule } from "@hermes/aphrodite/svg";

import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

import { MessageBlockComponent } from "./components/message-block.component";

@NgModule({
    imports: [
        CommonModule,
        SvgModule.forChild([svgExclamation, svgInfo]),
        SrOnlyNoSnippetDirective,
    ],
    declarations: [MessageBlockComponent],
    exports: [MessageBlockComponent],
})
export class MessageBlockModule {}
