import {
    CartAnalyticsEvent,
    EVENT_UAEVENT,
    GTMEventData,
} from "@hermes/utils/analytics";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import { QUICK_BUY } from "../types/add-to-cart.types";

export class AddToCartErrorEvent extends CartAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        return {
            ...super.formatForGTM(),
            event: EVENT_UAEVENT,
            eventcategory: QUICK_BUY,
            eventaction: "error",
            eventlabel: this.getEventLabel(this.eventData.eventlabel),
        };
    }

    private getEventLabel(errorCode: string): string {
        const eventLabelByEcomErrorCode: { [key: string]: string } = {
            [EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS]:
                "product_personalised_in_cart",
            [EcomErrorCodes.MAX_CHILD_QUANTITY]: "maximum_quantity",
            [EcomErrorCodes.TECHNICAL_ERROR]: "technical_error",
            [EcomErrorCodes.OUT_OF_STOCK]: "product_not_available",
            [EcomErrorCodes.INSUFFICIENT_STOCK]: "product_not_available",
        };
        return eventLabelByEcomErrorCode[errorCode];
    }
}
