import { Product } from "@hermes/api-model-product";

import {
    BlockType,
    LOADING,
    NOT_SUPPORTED,
} from "../constant/product-personalization.constant";
import {
    NOT_LOADED,
    NO_CONFIGURATION,
    NO_ENGRAVING,
    PersoConfiguratorState,
    PlatformeInitialConfiguration,
    ConfigParts,
    EngravingConfiguration,
    KeyboardTap,
    LoadingProgress,
    PersoParsedUrl,
    ProductCategory,
    SmallLeatherGoodsLeather,
} from "../types/product-personalization.type";

export interface ProductPersonalizationState {
    productCategory: ProductCategory;
    block: BlockType;
    collapsedToggles: { [key: string]: boolean }; // Contains small leather goods configurator collapsed toggles
    configuration: ConfigParts;
    keyboardTap?: KeyboardTap; // keyboard entry on Luckysigns keyboard
    loadingProgress: LoadingProgress; // loading of configurator.
    parentProduct?: Product;
    firstProduct?: Product;
    secondProduct?: Product;
    urlParsedParameters: PersoParsedUrl; // Init in guard or resolver, used in InitPlatformeService
    isDacProductMissing: boolean;
    configurator: PersoConfiguratorState;
    engravingConfiguration: EngravingConfiguration | typeof NO_ENGRAVING;
    platformeInitialConfiguration:
        | PlatformeInitialConfiguration
        | typeof NO_CONFIGURATION;
    isCallingPlatforme: boolean;
    smallLeatherGoodsLeathers: SmallLeatherGoodsLeather[];
    colorsHexaCodes?: Record<string, string>;
    translations?: Record<string, string>;
    isNoBuckleSelected: boolean;
}

export const initialState: ProductPersonalizationState = {
    productCategory: NOT_SUPPORTED,
    block: BlockType.Configurator,
    collapsedToggles: {
        leather: true,
        exteriorColor: true,
        liningColor: true,
        interiorColor: true,
        font: true,
        marking: true,
        buckle: true,
        beltSize: true,
        fontStyle: true,
        fontSize: true,
        fontColor: true,
    },
    configuration: {},
    loadingProgress: LOADING,
    urlParsedParameters: {
        requireConfCall: false,
        originalUrl: "",
    },
    isDacProductMissing: true,
    configurator: NOT_LOADED,
    engravingConfiguration: NO_ENGRAVING,
    platformeInitialConfiguration: NO_CONFIGURATION,
    isCallingPlatforme: false,
    smallLeatherGoodsLeathers: [],
    isNoBuckleSelected: true,
};
