<span
    role="heading"
    i18n-role
    aria-level="2"
    hSrOnlyNoSnippet
    i18n="@@footer-legal-links.component.html"
    [attr.data-testid]="'legal-links'"
    >Legal links</span
>

<ul>
    <li *ngFor="let legalLink of legalLinks; let i = index">
        <a [href]="legalLink.linkHref" target="_blank" rel="noopener noreferrer">
            <img
                *ngIf="i === 0"
                [src]="chinaLicenseLogo"
                width="9"
                height="11"
                [alt]="legalLink.linkText"
                loading="lazy" />
            <img
                *ngIf="i === 2"
                [src]="chinaNetworkSecurityLogo"
                width="9"
                height="11"
                [alt]="legalLink.linkText"
                loading="lazy" />
            <ng-container *ngIf="i !== 0">{{ legalLink.linkText }}</ng-container>
        </a>
    </li>
</ul>
