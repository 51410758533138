import { createFeatureSelector, createSelector } from "@ngrx/store";

import { personalizationFeatureKey } from "./../reducers/product-personalization.reducer";
import { ProductPersonalizationState } from "./../reducers/product-personalization.state";

export const selectPersonalizationPage =
    createFeatureSelector<ProductPersonalizationState>(
        personalizationFeatureKey,
    );

export const selectBlock = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.block,
);

export const selectCollapsedToggles = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.collapsedToggles,
);

export const selectConfiguration = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.configuration,
);

export const selectKeyboardTap = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.keyboardTap,
);

export const selectLoadingProgress = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.loadingProgress,
);

export const selectParentProduct = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.parentProduct,
);

export const selectFirstProduct = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage?.firstProduct,
);

export const selectSecondProduct = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.secondProduct,
);

export const selectUrlParsedParameters = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.urlParsedParameters,
);

export const selectIsDacProductMissing = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.isDacProductMissing,
);

export const selectProductCategory = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.productCategory,
);

export const selectConfigurator = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.configurator,
);

export const selectEngravingConfiguration = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.engravingConfiguration,
);

export const selectPlatformeInitialConfiguration = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.platformeInitialConfiguration,
);

export const selectIsCallingPlatforme = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.isCallingPlatforme,
);

export const selectSmallLeatherGoodsLeathers = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.smallLeatherGoodsLeathers,
);

export const selectPlateformeExtraInformation = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => ({
        translations: personalizationPage.translations,
        colorsHexaCodes: personalizationPage.colorsHexaCodes,
    }),
);

export const selectIsNoBuckleSelected = createSelector(
    selectPersonalizationPage,
    (personalizationPage) => personalizationPage.isNoBuckleSelected,
);
