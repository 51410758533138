import { Action, createReducer, on } from "@ngrx/store";

import {
    closeAllOtherTrays,
    closeTray,
    closeTrayByName,
    openTray,
} from "../actions/tray.actions";
import { TrayData } from "../models/tray-data.model";

export const trayFeatureKey = "tray";

export interface State {
    loading: boolean;
    trayOpened: boolean; // FIXME create a selector for this.
    trayStack: TrayData[];
}

export const initialState: State = {
    loading: false,
    trayOpened: false,
    trayStack: [],
};

const trayReducer = createReducer(
    initialState,
    on(openTray, (state) => state),
    on(closeTrayByName, (state) => state),
    on(closeTray, (state) => state),
    on(closeAllOtherTrays, (state) => state),
);

export function reducer(state: State | undefined, action: Action): State {
    return trayReducer(state, action);
}
