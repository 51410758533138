import { createAction, props } from "@ngrx/store";

import { Product } from "@hermes/api-model-product";

export const closeNotificationDisplayed = createAction(
    "[Add To Cart] Reset Notification Displayed",
);
export const changeEcomErrorState = createAction(
    "[Add To Cart] Change Ecom Error State",
    props<{
        itemPosition: number;
        ecomErrorEnabled: boolean;
        ecomErrorCode: string;
    }>(),
);
export const changeButtonEnabledState = createAction(
    "[Add To Cart] Change Button Enabled State",
    props<{ itemPosition: number; enabled: boolean }>(),
);
export const initAddToCartCallToAction = createAction(
    "[Add To Cart] Init AddToCartCallToAction",
    props<{ data: Product; isVariant: boolean }>(),
);
export const setAddToCartCallToActionsToDefault = createAction(
    "[Add To Cart] Set AddToCartCallToActions To Default",
    props<{
        position: number;
    }>(),
);
