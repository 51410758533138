import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { tap } from "rxjs/operators";

import { ModalFacade } from "../facades/modal.facade";

@Injectable()
export class ModalEffects {
    public onNavigation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction),
                tap(() => {
                    this.modalFacade.closeModal();
                }),
            ),
        { dispatch: false },
    );

    constructor(private actions$: Actions, private modalFacade: ModalFacade) {}
}
