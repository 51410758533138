import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { JsonConvert } from "json2typescript";

import {
    Basket,
    CustomOptions,
    PersistRequest,
} from "@hermes/api-model-basket";

import * as BasketActions from "../actions/basket.actions";
import {
    AddToCartPayload,
    SerializedBasket,
} from "../models/basket-feature.model";
import { State } from "../reducers/basket.reducer";
import { basketQuery } from "../selectors/basket.selectors";

@Injectable()
export class BasketFacade {
    /**
     * Selectors
     */
    public cart$ = this.store.select(basketQuery.selectCart);
    public totalItems$ = this.store.select(basketQuery.selectTotalItems);
    public billingAddressFromCart$ = this.store.select(
        basketQuery.selectBillingAddress,
    );
    public sagawaDeliveryTimeslots$ = this.store.select(
        basketQuery.selectSagawaTimeslots,
    );
    public error$ = this.store.select(basketQuery.selectError);
    public hasErrors$ = this.store.select(basketQuery.selectHasErrors);
    public pending$ = this.store.select(basketQuery.selectPending);

    constructor(private store: Store<State>) {}

    /**
     * Actions
     */
    public addToCart(addToCartPayload: AddToCartPayload): void {
        addToCartPayload.sendAnalyticsEvent =
            addToCartPayload.sendAnalyticsEvent ?? true;
        this.store.dispatch(BasketActions.addToCart({ addToCartPayload }));
    }

    public removeFromCart(
        sku: string,
        qty: number,
        customOptions?: CustomOptions,
    ): void {
        this.store.dispatch(
            BasketActions.removeFromCart({ sku, qty, customOptions }),
        );
    }

    public resetCart(): void {
        this.store.dispatch(BasketActions.resetCart());
    }

    public setBasket(basket: SerializedBasket): void {
        this.store.dispatch(BasketActions.setBasket({ basket }));
    }

    public updateBasket(basket: SerializedBasket): void {
        this.store.dispatch(BasketActions.updateBasket({ basket }));
    }

    public setCartItem(
        sku: string,
        qty: number,
        customOptions?: CustomOptions,
    ): void {
        this.store.dispatch(
            BasketActions.setCartItem({ sku, qty, customOptions }),
        );
    }

    public persistBasket(persistRequest: PersistRequest): void {
        this.store.dispatch(BasketActions.persistBasket({ persistRequest }));
    }

    public clearData(): void {
        this.store.dispatch(BasketActions.clearData());
    }

    public clearSessionData(): void {
        this.store.dispatch(BasketActions.clearSessionData());
    }

    /**
     * return the deserialized basket
     *
     * @param : basket : the serialized basket to convert
     */
    public deserializeBasket(basket: SerializedBasket): Basket {
        return new JsonConvert().deserializeObject(basket, Basket);
    }
}
