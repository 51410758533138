import { createReducer, on } from "@ngrx/store";

import {
    fetchFooterItemsSuccess,
    fetchFooterItemsFailure,
} from "../actions/shell.actions";
import { FooterItemsState } from "../state/shell.state";

// initial state for automatic typings
const initialFooterState: FooterItemsState =
    undefined as unknown as FooterItemsState;

export const footerItemsReducer = createReducer(
    initialFooterState,
    on(fetchFooterItemsSuccess, (state, { data }) => ({
        ...state,
        ...(data as unknown as FooterItemsState),
    })),
    on(fetchFooterItemsFailure, (state) => ({ ...state })),
);
