import { GTMEventPromotionData } from "../types/gtm.type";

import { AnalyticsEvent } from "./analytics.event";

/**
 * Abstract class for promotion event
 */
export abstract class PromotionImpressionAnalyticsEvent
    implements AnalyticsEvent<GTMEventPromotionData>
{
    /**
     * Event name expected for every promotion event
     */
    public name: string = "promotionImpression";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventPromotionData {
        return {
            event: this.name,
            ecommerce: {},
        };
    }
}
