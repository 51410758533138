import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";

import {
    CustomerAddressRequest,
    CustomerAddressResponse,
    CustomerEditAddressResponse,
} from "@hermes/api-model-account";
import {
    AddToCartRequest,
    AddToCartResponse,
    Basket,
    DeliveryAddressRequest,
    DeliveryAddressResponse,
    DeliveryShippingRequest,
    DeliveryShippingResponse,
    ErrorItem,
    RemoveFromCartRequest,
    RemoveFromCartResponse,
    SetCartItemRequest,
    SetCartItemResponse,
    CustomOptions,
    BasketItemRequest,
} from "@hermes/api-model-basket";
import { LOCALE, Settings, StorageService } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { ENABLE_NOVA_CART, FeatureFlagFacade } from "@hermes/states/flipper";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

import { HTTP_JSON_CONTENT_TYPE_HEADER } from "@hermes/utils-generic/constants";

@Injectable()
export class CartService implements OnDestroy {
    private novaCartEnabled = false;
    private subscription = new Subscription();

    constructor(
        private featureFlagFacade: FeatureFlagFacade,
        private storageService: StorageService,
        private http: HttpClient,
        private settings: Settings,
        @Inject(LOCALE) private locale: Locale,
    ) {
        this.subscription.add(
            this.featureFlagFacade
                .isActivated(ENABLE_NOVA_CART)
                .subscribe(
                    (isActivated) => (this.novaCartEnabled = isActivated),
                ),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public addToCart(
        addToCartRequest: AddToCartRequest,
    ): Observable<AddToCartResponse> {
        return this.http
            .post<AddToCartResponse>(
                `${this.settings.apiUrl}/add-to-cart`,
                addToCartRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError((error) => {
                    if (this.novaCartEnabled) {
                        throw error;
                    }
                    throw new Error(`Add to cart error : ${error.error}`);
                }),
            );
    }

    /**
     * call add to cart request from skus
     *
     * @param sku product's sku to add to cart
     * @param skuSet optionnal. product's skuSet for double product
     * @param dku   optionnal Dku of the personalized product used only for perso products
     * @param urlImage optionnal Url image from pf used only for perso products
     *
     */
    public addProductToCart(
        sku: string,
        skuSet?: string,
        dku?: string,
        urlImage?: string,
        customOptions?: CustomOptions,
    ): Observable<AddToCartResponse> {
        const backInStockSku: string | undefined =
            this.storageService.getSessionStorageItem("productSkuBackInStock");

        const request: AddToCartRequest = {
            locale: this.locale.code,
            items: new JsonConvert().serializeArray(
                [
                    {
                        category: "direct",
                        container_id: skuSet,
                        sku,
                        image: urlImage,
                        dku,
                        customOptions,
                    },
                ],
                BasketItemRequest,
            ),
            ...(backInStockSku && {
                trackingInformation: {
                    feature: "back_in_stock",
                    information: backInStockSku,
                },
            }),
        };

        return this.addToCart(request);
    }

    public updateShippingMethod(
        deliveryShippingRequest: DeliveryShippingRequest,
    ): Observable<DeliveryShippingResponse> {
        return this.http
            .post<DeliveryShippingResponse>(
                `${this.settings.apiUrl}/delivery/shipping`,
                deliveryShippingRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                map((data: { basket: Basket; error?: ErrorItem }) =>
                    this.deserializeBasketFromData<DeliveryShippingResponse>(
                        data,
                    ),
                ),
                catchError(this.errorHandler("update shipping error")),
            );
    }

    public addAddressMethod(
        addAddressRequest: CustomerAddressRequest,
    ): Observable<CustomerAddressResponse> {
        addAddressRequest.locale = this.locale.code;
        const customerAddressRequest = new CustomerAddressRequest();

        Object.assign(customerAddressRequest, addAddressRequest);

        const url = `${this.settings.apiUrl}/address/add`;

        const requestObject = new JsonConvert().serializeObject(
            customerAddressRequest,
        );

        return this.http
            .post(url, requestObject, {
                headers: new HttpHeaders({
                    ...HTTP_JSON_CONTENT_TYPE_HEADER,
                }),
                context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
            })
            .pipe(
                map((data: unknown) =>
                    new JsonConvert().deserializeObject(
                        data,
                        CustomerAddressResponse,
                    ),
                ),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    public editAddressMethod(
        addAddressRequest: CustomerAddressRequest,
    ): Observable<CustomerEditAddressResponse> {
        addAddressRequest.locale = this.locale.code;
        let customerAddressRequest = new CustomerAddressRequest();
        customerAddressRequest = Object.assign(
            customerAddressRequest,
            addAddressRequest,
        );

        return this.http
            .post(
                `${this.settings.apiUrl}/address/edit`,
                new JsonConvert().serializeObject(customerAddressRequest),
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                map((data: unknown) =>
                    new JsonConvert().deserializeObject(
                        data,
                        CustomerEditAddressResponse,
                    ),
                ),
                catchError((error) => {
                    throw error;
                }),
            );
    }

    public updateAddress(
        deliveryAddressRequest: DeliveryAddressRequest,
    ): Observable<DeliveryAddressResponse> {
        return this.http
            .post<DeliveryAddressResponse>(
                `${this.settings.apiUrl}/delivery/address`,
                deliveryAddressRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                map((data: { basket: Basket; error?: ErrorItem }) =>
                    this.deserializeBasketFromData<DeliveryAddressResponse>(
                        data,
                    ),
                ),
                catchError(this.errorHandler("update shipping address error")),
            );
    }

    public deserializeBasketFromData<
        T extends { basket: Basket; error?: ErrorItem },
    >(data: { basket: Basket; error?: ErrorItem }): T {
        if (data.basket) {
            data.basket = new JsonConvert().deserializeObject(
                data.basket,
                Basket,
            );
        }
        if (data.error) {
            data.error = new JsonConvert().deserializeObject(
                data.error,
                ErrorItem,
            );
        }
        return data as unknown as T;
    }

    /**
     * call set cart item request from skus
     *
     * @param sku product's sku to set to cart
     * @param qty new quantity of the product in cart
     * @param customOptions product's engraving information
     */
    public setCartItemProduct(
        sku: string,
        qty: number,
        customOptions?: CustomOptions,
    ): Observable<SetCartItemResponse> {
        const request: SetCartItemRequest = {
            locale: this.locale.code,
            items: new JsonConvert().serializeArray(
                [
                    {
                        qty,
                        sku,
                        customOptions,
                    },
                ],
                BasketItemRequest,
            ),
        };
        return this.setCartItem(request);
    }

    /**
     * call remove from cart request from skus
     *
     * @param sku product's sku to set to cart
     * @param qty new quantity of the product in cart
     * @param customOptions product's engraving information
     */
    public removeProductsFromCart(
        items: Array<{
            sku: string;
            qty: number;
            customOptions?: CustomOptions;
        }>,
    ): Observable<RemoveFromCartResponse> {
        const request: RemoveFromCartRequest = {
            locale: this.locale.code,
            items: new JsonConvert().serializeArray(items, BasketItemRequest),
        };
        return this.removeFromCart(request);
    }

    private errorHandler(message: string) {
        return (error: { error: unknown }) => {
            throw new Error(`${message} : ${error.error}`);
        };
    }

    private setCartItem(
        setCartItemRequest: SetCartItemRequest,
    ): Observable<SetCartItemResponse> {
        return this.http
            .post<SetCartItemResponse>(
                `${this.settings.apiUrl}/set-cart-item`,
                setCartItemRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(catchError(this.errorHandler("Set cart item error")));
    }

    private removeFromCart(
        removeFromCartRequest: RemoveFromCartRequest,
    ): Observable<RemoveFromCartResponse> {
        return this.http
            .post<RemoveFromCartResponse>(
                `${this.settings.apiUrl}/remove-from-cart`,
                removeFromCartRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(catchError(this.errorHandler("Remove from cart error")));
    }
}
