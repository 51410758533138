import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ExternalLibraryStateModule } from "@hermes/states/external-library";

import { LoadGeoLibrariesService } from "./load-geo-libraries.service";

@NgModule({
    imports: [CommonModule, ExternalLibraryStateModule],
    providers: [LoadGeoLibrariesService],
})
export class LoadGeoLibrariesServiceModule {}
