export const datadome = `
    window.ddjskey = '{datadomeJsKey}';
    window.ddoptions = {
    	ajaxListenerPath: ['hermes.c'],
        endpoint: 'https://dd.hermes.com/js/'
    }`;
export const encodingHtmlCharacters: Array<[RegExp | string, string]> = [
    [/<br\s*\/?>/gi, " "], // brTags : <br/> <br /> <br>
    [/(<([^>]+)>)/gi, ""], // htmlTags
    [/\u00a0/g, " "], // Unicode version of &nbsp;
];
