import { GTMEventData } from "@hermes/utils/analytics";

import { HeaderAnalyticsEvent } from "./header.event";

/**
 * This class is used for click on links in header
 */
export class HeaderClickEvent extends HeaderAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMEventData {
        const eventaction = this.eventData.eventaction;
        const eventlabel = this.eventData.eventlabel;
        return {
            ...super.formatForGTM(),
            eventaction: eventaction || undefined,
            eventlabel: eventlabel || undefined,
        };
    }
}
