import { createAction, props } from "@ngrx/store";

/**
 * Router Store provides five navigation actions which are dispatched in a specific order
 *
 * @see https://ngrx.io/guide/router-store/actions
 *
 * But, we need to have actions dedicated to our project.Indeed, we use a custom reducer for the RouterState.
 */
export const setPreviousUrl = createAction(
    "[Router] Set previous URL",
    props<{ previousUrl: string }>(),
);
