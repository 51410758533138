import { CommonModule } from "@angular/common";
import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from "@angular/core";

import { LoadExternalUrlService } from "./load-external-url.service";

@NgModule({
    imports: [CommonModule],
})
export class LoadExternalUrlStateModule {
    constructor(
        @Optional() @SkipSelf() parentModule?: LoadExternalUrlStateModule,
    ) {
        if (parentModule) {
            throw new Error(
                "LoadExternalUrlStateModule is already loaded. Import it in the AppModule only",
            );
        }
    }

    public static forRoot(): ModuleWithProviders<LoadExternalUrlStateModule> {
        return {
            ngModule: LoadExternalUrlStateModule,
            providers: [LoadExternalUrlService],
        };
    }
}
