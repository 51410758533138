import { Response, Request } from "express";

export const FAKE_DOMAIN = "http://fake";

/**
 * Redirect response with cache-control header
 * @param request Request
 * @param response Response
 * @param newPath string
 * @param statusCode 301 | 302
 */
export function redirectResponse(
    request: Request,
    response: Response,
    newPath: string,
    statusCode: 301 | 302 = 302,
    forceCleanQueryParameters: boolean = false,
): void {
    if (forceCleanQueryParameters) {
        request.url = request.url.split("?")[0];
    }

    const requestUrl = new URL(request.url, FAKE_DOMAIN);
    const destinationUrl = new URL(newPath, FAKE_DOMAIN);

    const newSearchQueryParameter = new URLSearchParams();

    // Usage of URLSearchParams.set() is intended, we want to keep only one query param
    // order of the addition is also intentional, this is the priority we set
    // X-Stripped-Query < Request URL < Destination URL

    // Add query param from header X-Stripped-Query
    // (compatibility with Varnish Traffic Switcher, must be remove after switching du HD infrastructure)
    if (request.header("X-Stripped-Query")) {
        const strippednewSearchQueryParameter = new URLSearchParams(
            request.header("X-Stripped-Query"),
        );

        createNewSearchQueryParameters(
            newSearchQueryParameter,
            strippednewSearchQueryParameter,
        );
    }

    // Add query param from request URL (compatibility with new Nginx)
    createNewSearchQueryParameters(
        newSearchQueryParameter,
        requestUrl.searchParams,
    );

    // Add query param from redirection URL target
    createNewSearchQueryParameters(
        newSearchQueryParameter,
        destinationUrl.searchParams,
    );

    // Copy back new query to destination URL
    createNewSearchQueryParameters(
        destinationUrl.searchParams,
        newSearchQueryParameter,
    );

    response.header(
        "cache-control",
        statusCode === 301
            ? "public, max-age=300"
            : "public, max-age=10, no-cache, no-store",
    );
    addTrailingSlash(destinationUrl);
    response.redirect(
        statusCode,
        `${destinationUrl.pathname}${destinationUrl.search}`,
    );
    response.end();
}

function createNewSearchQueryParameters(
    newSearchQueryParameter: URLSearchParams,
    oldSearchQueryParameter: URLSearchParams,
): void {
    for (const [key, value] of oldSearchQueryParameter.entries()) {
        newSearchQueryParameter.set(key, value);
    }
}

/**
 * Method used to create redirection url with trailing slash when missing.
 *
 * @param url url to rebuild with trailing slash
 */
function addTrailingSlash(url: URL): void {
    if (url.pathname.slice(-1) !== "/") {
        url.pathname = `${url.pathname}/`;
    }
}
