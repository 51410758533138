import { Inject, Injectable, Optional } from "@angular/core";
import type * as express from "express";

import { Context, ENV_CONFIG, REQUEST } from "@hermes/app-core";
import { EnvironmentConfiguration } from "@hermes/env-infra";
import { AbstractLogger, LogConfig, Logger } from "@hermes/logger";

import { LOGGER_CONFIG, LOGGER_NAME } from "../constants/logger.constants";

/**
 * This is an injectable logger that can be configured per injection context.
 *
 * The logger name can be overloaded with a provider in the @Component or @Service where Logger is injected.
 * ```{ provide: "LOGGER_NAME", useValue: "MySuperComponent" }```
 */
@Injectable()
export class LoggerService extends AbstractLogger implements Logger {
    private color: string;

    /**
     * @param source human readable name of the composant, service or module using this Logger.
     */
    constructor(
        context: Context,
        @Inject(LOGGER_CONFIG) logConfig: LogConfig,
        @Inject(REQUEST) request: express.Request,
        @Optional()
        @Inject(LOGGER_NAME)
        source: string = "",
        @Inject(ENV_CONFIG) environmentConfig: EnvironmentConfiguration,
    ) {
        super(logConfig);

        if (context.isInServerMode()) {
            // set context info about application
            this.setAppLogAttributes(
                "RDR",
                environmentConfig.appVersion,
                process.env["APP_ENV"] || "dev",
            );

            // set context info about current request
            this.setRequestLogAttributes(
                String(request.headers["request-id"]),
                String(request.headers["correlation-id"]),
            );
        }

        // valeur aléatoire de 20 à 160.
        const randomColor = () => 20 + Math.floor(Math.random() * 140);
        // random color per instance, only in browser mode
        this.color = `rgb(${randomColor()},${randomColor()},${randomColor()})`;
        if (context.isInBrowserMode()) {
            this.prefix = `${this.color}[${source}]`;
        }
    }
}
