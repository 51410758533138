import { Injectable } from "@angular/core";

import { Observable, delay, map, tap } from "rxjs";

import { LINK } from "@hermes/aphrodite/dynamic-box";

import { ProductPageFacade } from "../facades/product-page.facade";

@Injectable()
export class ProductPageVariantService {
    public resetToFirstSlide$: Observable<number | undefined> =
        this.productPageFacade.variantSelectedBoxType$.pipe(
            map((variantSelectedBoxType) =>
                variantSelectedBoxType === LINK ? 0 : undefined,
            ),
            delay(100),
            tap(() => this.productPageFacade.resetVariantSelectedBoxType()),
        );

    constructor(private productPageFacade: ProductPageFacade) {}
}
