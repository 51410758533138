import { Component, Input, ViewChild } from "@angular/core";
import {
    AbstractControl,
    UntypedFormControl,
    ValidationErrors,
    Validators,
} from "@angular/forms";

import { BaseInputComponent } from "../base-input/base-input.component";
import { InputComponent } from "../input/input.component";

/**
 * This component implements a global Email Input Component. It also implements a floating label (like a mat-label - Material Angular).
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * * Email format error.
 * You can deactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-email-input
 *      [controlName]="'email'"
 *      [id]="'email-id'"
 *      [name]="'email'"
 *      [autocomplete]="'email'"
 *      label="'My label translated into english'"
 *      i18n-label="@@my.key.translate"
 *      placeholder="'My placeholder translated into english'"
 *      i18n-placeholder="@@my.key.translate"
 *      describe="'My describe'"
 *      i18n-describe="@@my.key.translate"
 *      [errorManagement]="true"
 *      [setMinHeight]="false"
 *      [ariaDescribedby]="id-label">
 *
 *      <h-message-block *ngIf="serviceError" [type]="'error'"">
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *
 *
 *  </h-email-input>
 * ```
 *
 */

@Component({
    selector: "h-email-input",
    templateUrl: "./email-input.component.html",
})
export class EmailInputComponent extends BaseInputComponent {
    /**
     * Direct reference to the "autocomplete" attribute of the input. Default is "username".
     */
    @Input()
    public override autocomplete: string = "username";

    @Input()
    public dataTestId?: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    @ViewChild(InputComponent)
    public input!: InputComponent;

    public validate(control: AbstractControl): ValidationErrors | null {
        return new UntypedFormControl(control.value, Validators.email).errors;
    }

    public override focusInput(): void {
        this.input.focusInput();
    }

    public setDisabledState(isDisabled: boolean): void {
        this.input.setDisabledState(isDisabled);
    }
}
