import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BannerItem } from "@hermes/api-model-shell";
import { BannerService } from "@hermes/states/shell";

@Component({
    selector: "h-cookie-banner",
    templateUrl: "./cookie-banner.component.html",
    styleUrls: ["./cookie-banner.component.scss", "../banner-common.scss"],
})
export class CookieBannerComponent implements OnInit {
    /**
     * Banner data
     */
    @Input()
    public cookieBanner!: BannerItem;

    @Output()
    public closeBanner: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Link label inside cookie banner
     */
    public cookieLink = $localize`:@@hermes.header_footer.cookie_banner.uri.label:click here`;
    public cookieMessage =
        $localize`:@@hermes.header_footer.cookie_banner.text:By continuing to browse this site, you are agreeing to the use of cookies for statistical and advertising purposes, as well as to facilitate information sharing on social networks. To find out more and change your cookie settings, !link.`.replace(
            "!link.",
            "",
        );
    public privacyPolicyMessage =
        $localize`:@@hermes.header_footer.cookie_banner.privacy_policy:`.replace(
            "!link.",
            "",
        );

    constructor(private bannerService: BannerService) {}

    public ngOnInit(): void {
        this.saveCookieTimestamp();
    }

    /**
     * Trigger relevant closing method and emit output event
     */
    public close(): void {
        this.saveCookieTimestamp();
        this.closeBanner.emit();
    }

    /**
     * Update timestamp in cookie
     */
    private saveCookieTimestamp(): void {
        this.bannerService.saveCookieBanner(this.cookieBanner.cookieTimestamp);
    }
}
