import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormControlDirective,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { merge, Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";

import { BaseInputComponent } from "../base-input/base-input.component";
import { InputComponent } from "../input/input.component";

export interface OptionItem {
    value: string;
    text: string;
}

/**
 * This component implements a global Dropdown Component. It also implements a floating label (like a mat-label - Material Angular).
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * You can desactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-select-input
 *      [controlName]="'field'"
 *      [id]="'field-id'"
 *      [name]="'field'"
 *      label="'My label translated into english'"
 *      i18n-label="@@my.key.translate"
 *      describe="'My describe'"
 *      i18n-describe="@@my.key.translate"
 *      [contentSquareMaskEnabled]="true"
 *      [options]="optionItems"
 *      [selectedItem]="selectedItem"
 *      [errorManagement]="true"
 *      [ariaDescribedby]="id-other-label">
 *
 *      <h-message-block *ngIf="serviceError" type='error'>
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *
 *  </h-select-input>
 * ```
 */
@Component({
    selector: "h-select-input",
    templateUrl: "./select-input.component.html",
    styleUrls: ["./select-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true,
        },
    ],
})
export class SelectInputComponent
    extends BaseInputComponent
    implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
    @ViewChild("dropdown")
    public dropdownTemplate!: TemplateRef<HTMLUListElement>;

    @ViewChild("select")
    public selectElement!: ElementRef<HTMLDivElement>;

    @ViewChild(forwardRef(() => InputComponent))
    public searchableSelectElement!: InputComponent;

    /**
     * Item to display in select list
     */
    @Input()
    public options!: OptionItem[];

    /**
     * Selected option by default
     */
    @Input()
    public selectedOptionValue: string = "";

    @Input()
    public dataTestId?: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    // When true, the select dropdown allows the user to filter by searching for a value
    @Input()
    public isSearchable: boolean = false;

    @ViewChild(FormControlDirective, { static: true })
    private formControlDirective!: FormControlDirective;

    /**
     * Position in the list of options of the selected option
     */
    public selectedOptionIndex: number = -1;

    /**
     * Flag to display or not the drop-down list
     */
    public isDropdownOpen: boolean = false;

    /**
     * Return the id attribut for select element
     */
    public selectId!: string;

    /**
     * Return the id attribut for label element
     */
    public labelId!: string;

    /**
     * Return the id attribut for error field
     */
    public errorId!: string;
    public fieldIsRequired: boolean = false;
    public filteredOptions!: OptionItem[];

    private overlayRef?: OverlayRef;

    private dropdownClosingActionsSub = Subscription.EMPTY;

    constructor(
        protected override controlContainer: ControlContainer,
        public layoutFacade: LayoutFacade,
        private overlay: Overlay,
        private viewContainerReference: ViewContainerRef,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(controlContainer);
    }

    @HostListener("keydown.enter", ["$event"])
    @HostListener("keydown.arrowdown", ["$event"])
    @HostListener("keydown.arrowup", ["$event"])
    @HostListener("keydown.escape", ["$event"])
    @HostListener("keydown.tab", ["$event"])
    @HostListener("click", ["$event"])
    public eventManagment(event: Event | KeyboardEvent): void {
        // If control is disable no interaction with the list is allowed.
        if (this.control.disabled) {
            return;
        }

        if (this.isKeyboardEvent(event)) {
            this.keyDownManagment(event);
            return;
        }
        this.clickManagment();
    }

    public ngOnInit(): void {
        this.selectId = this.id;
        this.labelId = `${this.id}-label`;
        this.errorId = `${this.id}-error`;
        this.fieldIsRequired = this.isRequired();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            this.options &&
            this.isSearchable &&
            this.filteredOptions !== this.options &&
            this.searchableSelectElement
        ) {
            // If the postcode has changed, we must reset the filtered options and reset the input value
            this.filteredOptions = this.options;
            this.control.setValue("");
        }
        // If it is not required then we add a first empty field to allow the user to select nothing if he needs it.
        if (changes.options && !this.isRequired()) {
            this.options.unshift({
                value: "",
                text: "",
            });
        }

        if (
            changes.selectedOptionValue?.currentValue !==
            changes.selectedOptionValue?.previousValue
        ) {
            this.selectedOptionIndex = this.options.findIndex(
                (option) =>
                    option.value === changes.selectedOptionValue.currentValue,
            );
        }
    }

    public ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    public registerOnTouched(onTouchedFunction: void): void {
        this.formControlDirective?.valueAccessor?.registerOnTouched(
            onTouchedFunction,
        );
    }

    public registerOnChange(onChangeFunction: void): void {
        this.formControlDirective?.valueAccessor?.registerOnChange(
            onChangeFunction,
        );
    }

    public writeValue(value: string): void {
        this.selectedOptionIndex = this.options.findIndex(
            (option) => option.value === value,
        );
        this.formControlDirective?.valueAccessor?.writeValue(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.formControlDirective?.valueAccessor?.setDisabledState?.(
            isDisabled,
        );
    }

    public trackByText(_index: number, item: OptionItem): string {
        return item.text;
    }

    public getOptionId(index: number): string {
        return `${this.id}-option-${index}`;
    }

    public optionChange(selectedOption: OptionItem): void {
        this.setValue(selectedOption);
        this.destroyDropdown();
    }

    public override focusInput(): void {
        this.document.getElementById(this.selectId)?.focus();
    }

    public toggleDropdown(): void {
        if (this.isDropdownOpen) {
            this.destroyDropdown();
            return;
        }
        this.openDropdown();
    }

    public filterSelection(value: string): void {
        this.filteredOptions = this.options.filter((option) => {
            return option.text.toLowerCase().includes(value.toLowerCase());
        });
    }

    public inputEvent(event: KeyboardEvent): void {
        switch (event.key || event.code) {
            case "Delete":
            case "Backspace":
            case "Tab": {
                if (!this.isDropdownOpen) {
                    this.openDropdown();
                    this.cd.detectChanges();
                    break;
                }
                break;
            }
        }
    }

    public handleBlur(): void {
        if (this.isDropdownOpen) {
            this.searchableSelectElement.focusInput();
        }
        const exists = this.filteredOptions.some(
            (option) => option.value === this.control.value,
        );
        if (!exists) {
            this.control.setValue("");
            this.filteredOptions = this.options;
        }
    }

    private openDropdown(): void {
        this.isDropdownOpen = true;

        const element =
            this.selectElement && this.selectElement.nativeElement
                ? this.selectElement.nativeElement
                : this.searchableSelectElement.input.nativeElement;
        this.ngZone.run(() => {
            this.overlayRef = this.overlay.create({
                width: element.getBoundingClientRect().width,
                hasBackdrop: true,
                backdropClass: "cdk-overlay-transparent-backdrop",
                scrollStrategy: this.overlay.scrollStrategies.reposition({
                    autoClose: true,
                }),
                positionStrategy: this.overlay
                    .position()
                    .flexibleConnectedTo(element)
                    .withPositions([
                        {
                            originX: "start",
                            originY: "bottom",
                            overlayX: "start",
                            overlayY: "top",
                        },
                    ])
                    .withFlexibleDimensions(false),
            });
            const templatePortal = new TemplatePortal(
                this.dropdownTemplate,
                this.viewContainerReference,
            );
            this.overlayRef.attach(templatePortal);

            this.dropdownClosingActionsSub = merge(
                this.overlayRef.backdropClick(),
                this.overlayRef.detachments(),
            ).subscribe(() => this.destroyDropdown());

            requestAnimationFrame(() => {
                this.document
                    .getElementById(this.getOptionId(this.selectedOptionIndex))
                    ?.focus();
            });
        });
    }

    private destroyDropdown(): void {
        if (!this.overlayRef || !this.isDropdownOpen) {
            return;
        }
        this.isDropdownOpen = false;
        this.dropdownClosingActionsSub.unsubscribe();
        this.overlayRef.detach();

        this.control.markAsTouched();

        if (this.isSearchable) {
            this.searchableSelectElement.focusInput();
        } else {
            this.focusInput();
        }
        this.cd.detectChanges();
    }

    private setValue(selectedOption: OptionItem): void {
        this.selectedOptionIndex = this.options.findIndex(
            (option) => option.value === selectedOption?.value,
        );

        this.control.markAsTouched();
        this.control.setValue(selectedOption?.value);

        this.document
            .getElementById(this.getOptionId(this.selectedOptionIndex))
            ?.focus();
    }

    private focusNext(): void {
        let options = this.options;
        if (this.isSearchable) {
            options = this.filteredOptions;
        }
        let position = options.indexOf(this.options[this.selectedOptionIndex]);
        const next = position === options.length - 1 ? 0 : position + 1;

        this.setValue(options[next]);
    }

    private focusPrevious(): void {
        let options = this.options;
        if (this.isSearchable) {
            options = this.filteredOptions;
        }

        let position = options.indexOf(this.options[this.selectedOptionIndex]);
        const previous = position === 0 ? options.length - 1 : position - 1;

        this.setValue(options[previous]);
    }

    /**
     * If someone click on the component, we open or close the list.
     */
    private clickManagment(): void {
        this.toggleDropdown();
    }

    private keyDownManagment(event: KeyboardEvent): void {
        // Browser must handle "Tab" event for keyboard focus movement.
        if (event.key !== "Tab") {
            event.preventDefault();
        }
        switch (event.key) {
            // If the focus is on the select (list is collapsed), expands the listbox and places focus on the currently selected option in the list.
            // If focus is in the listbox, collapses the listbox and keeps the currently selected option as the select label.
            case "Enter": {
                this.toggleDropdown();
                break;
            }
            // Moves focus to and selects the next option.
            // If the listbox is collapsed, also expands the list.
            case "ArrowDown": {
                if (!this.isDropdownOpen) {
                    this.openDropdown();
                    break;
                }
                this.focusNext();
                break;
            }
            // Moves focus to and selects the previous option.
            // If the listbox is collapsed, also expands the list.
            case "ArrowUp": {
                if (!this.isDropdownOpen) {
                    this.openDropdown();
                    break;
                }
                this.focusPrevious();
                break;
            }
            // If the listbox is displayed, "Escape" collapses the listbox and moves focus to the select
            // If the listbox is displayed, "Tab" collapses the listbox and moves focus to the next focusable element
            case "Escape":
            case "Tab": {
                this.destroyDropdown();
                break;
            }
            default:
            // Do nothing
        }
    }

    private isKeyboardEvent(
        event: Event | KeyboardEvent | FocusEvent,
    ): event is KeyboardEvent {
        return (event as KeyboardEvent).type === "keydown";
    }
}
