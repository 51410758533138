import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

/**
 * This class is used for toggle-click event like open or close a toggle
 */
export class ProductInfoToggleClickEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(this.eventData.templateType);

        switch (this.eventData.eventType) {
            case "color": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Color_Selector_Expand",
                };
            }
            case "volume": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Volume_Selector_Expand",
                };
            }
            case "buckle": {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "BeltKitPage",
                    eventaction: "Buckle_Selector_Expand",
                };
            }
            case "leather": {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "BeltKitPage",
                    eventaction: "Color_Selector_Expand",
                };
            }
            case "size":
            case "strapSize":
            case "watchSize": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Size_Selector",
                    eventlabel: "Click_SizeMenu",
                };
            }
            case "finish": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Finish_Selector",
                    eventlabel: "Click_FinishMenu",
                };
            }
            case "strap": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Bracelet_Selector",
                    eventlabel: "Click_BraceletMenu",
                };
            }
            case "productDetail": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "product_details_toggle",
                };
            }
            case "moreInformation": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "more_information_toggle",
                };
            }
            case "moreInformationLookItem":
            case "moreInformationBikiniItem": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter_Product",
                    eventlabel: "more_information_toggle",
                };
            }
            case "careInstructions": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "care_instructions_toggle",
                };
            }
            case "deliveryAndReturns": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "delivery_and_returns_toggle",
                };
            }
            case "gift": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "gifting_toggle",
                };
            }
            case "applicationAdvice": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "application_advice_toggle",
                };
            }
            case "ingredients": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "openFilter",
                    eventlabel: "ingredients_toggle",
                };
            }
            case "fragranceSelector": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Fragrance_Selector_Expand",
                    eventlabel: this.eventData.sku,
                };
            }
            default: {
                return {
                    ...super.formatForGTM(),
                };
            }
        }
    }
}
