<span
    role="heading"
    i18n-role
    aria-level="2"
    *ngIf="column"
    [attr.data-testid]="column.columnLabel | sanitize | prefixWith : 'column-label-'"
    [attr.id]="column.columnLabel | sanitize | prefixWith : 'column-label-'">
    <button
        class="column-toggle"
        [attr.aria-expanded]="column.opened || false"
        [attr.aria-controls]="column.columnLabel | sanitize | prefixWith : 'column-list-'"
        (click)="handleClickOnColumnLabel()">
        <span>{{ column.columnLabel }}</span>

        <h-svg-icon *ngIf="!column.opened" [name]="'plus'"> </h-svg-icon>

        <h-svg-icon *ngIf="column.opened" [name]="'minus'"> </h-svg-icon>
    </button>
</span>

<ul
    *ngIf="column"
    [attr.id]="column.columnLabel | sanitize | prefixWith : 'column-list-'"
    [attr.aria-labelledby]="column.columnLabel | sanitize | prefixWith : 'column-label-'"
    [attr.aria-hidden]="!column.opened"
    [class.opened]="column.opened">
    <li *ngFor="let item of column.columnItems" [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async">
        <!-- Basic links -->
        <a
            *ngIf="item.itemType === 'link'"
            [tabIndex]="column.opened ? 0 : -1"
            [attr.target]="item.itemNewTab ? '_blank' : undefined"
            [attr.rel]="item.itemNewTab ? 'noopener noreferrer' : undefined"
            [href]="item.itemData"
            (click)="handleClickOnColumnItem($event, item)">
            {{ item.itemLabel }}
            <span *ngIf="item.itemNewTab" hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-tab"> New tab </span>
        </a>
        <a
            *ngIf="item.itemData === 'tray-help'; else contactUsButton"
            [tabIndex]="column.opened ? 0 : -1"
            (click)="goToContactUsPage($event)"
            href="{{ baseHref }}/contact-us/">
            {{ item.itemLabel }}
        </a>

        <!-- Encapsulated trays -->
        <ng-template #contactUsButton>
            <button
                *ngIf="item.itemType === 'button'"
                [hTrayOpener]="item.itemData"
                [trayOverlay]="true"
                [isDrupalTray]="true"
                [tabIndex]="column.opened ? 0 : -1"
                [attr.aria-haspopup]="'dialog'"
                [attr.aria-expanded]="'false'"
                [attr.aria-controls]="item.itemData"
                (click)="handleClickOnColumnItem($event, item)">
                {{ item.itemLabel }}
            </button>
        </ng-template>
    </li>
</ul>
