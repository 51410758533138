/**
 * This configuration file is used only for angular build to define
 * if we build with the configuration dev or production (minify, optimise the build ...)
 * This file has no relation with deployment.
 */
export const environment = {
    production: true,
    webVitals: {
        core: false,
        other: false,
    },
    broadcaster: {
        debug: false,
    },
    ngrx: {
        debug: false,
        runtimeChecks: false,
    },
};
