export enum CcBrand {
    AMEX = "amex",
    VISA = "visa",
    MASTER_CARD = "mc",
    DISCOVER = "discover",
    DINERS = "diners",
    MAESTRO = "maestro",
    CHINA_UNION_PAY = "cup",
    CARTE_BANCAIRE = "cartebancaire",
    JCB = "jcb",
    ELO = "elo",
    HIPERCARD = "hipercard",
    TROY = "troy",
    DANKORT = "dankort",
    EFTPOS_AU = "eftpos",
}
