import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

export const CLICK_BACK_HOME = "ClickBackHome";
export const CLICK_TO_SEARCH = "ClickToSearch";

type GTMNotFoundPageEventData = GTMEventData & {
    eventType: typeof CLICK_BACK_HOME | typeof CLICK_TO_SEARCH;
};

/**
 * This class is used for click event in not found page
 */
export class NotFoundPageClickEvent implements AnalyticsEvent<GTMEventData> {
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: GTMNotFoundPageEventData;

    constructor(data: Record<string, string>) {
        this.eventData = data as unknown as GTMNotFoundPageEventData;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for each key
     */
    public formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CLICK_BACK_HOME:
                return {
                    event: this.name,
                    eventcategory: "Page404",
                    eventaction: "Click",
                    eventlabel: "BackHome",
                };
            case CLICK_TO_SEARCH:
                return {
                    event: this.name,
                    eventcategory: "Page404",
                    eventaction: "Click",
                    eventlabel: "Search",
                };
            default:
                return {
                    event: this.name,
                    eventcategory: "SingleProductPage",
                };
        }
    }
}
