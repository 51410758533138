import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApolloError } from "@hermes/api-model-core";
import { Shop, ShopList } from "@hermes/api-model-shop";
import { LOCALE, Settings } from "@hermes/app-core";
import { CountryCode, LangCode, Locale, LocaleCode } from "@hermes/locale";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

export interface FindShopsParameters {
    countryCode?: CountryCode;
    language?: LocaleCode;
    lang?: LangCode;
    cegidIdHcare?: string;
    shopId?: string;
    cegidId?: string;
    productLocator?: boolean;
}
interface FindShopParameters {
    countryCode?: CountryCode;
    language?: LocaleCode;
    lang?: LangCode;
}
@Injectable()
export class ShopsService {
    constructor(
        private http: HttpClient,
        private settings: Settings,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public findShopById(
        shopId: string,
        locale = this.locale,
    ): Observable<Shop> {
        return this.findShop(shopId, { lang: locale.langCode });
    }

    public findShop(
        shopId: string,
        params: FindShopParameters,
    ): Observable<Shop> {
        return this.http
            .get<Shop | ApolloError>(
                `${this.settings.apiUrl}/store/${shopId}`,
                {
                    params: { ...params },
                },
            )
            .pipe(map((response) => this.mapResponse<Shop>(response)));
    }

    public findShops(params: FindShopsParameters): Observable<Shop[]> {
        // Skipping 404 interceptor because the feature handle 404 with business rules
        const context = new HttpContext().set(SKIP_404_INTERCEPTOR, true);

        return this.http
            .get<ShopList | ApolloError>(`${this.settings.apiUrl}/stores`, {
                params: { ...params },
                context,
            })
            .pipe(
                map((response) => this.mapResponse<ShopList>(response)),
                map((response) => response.shops),
            );
    }

    private mapResponse<T>(response: T | ApolloError): T {
        // If it's an ApolloError, throw this error.
        if (this.isApolloError<T>(response)) {
            throw response;
        }
        return response;
    }

    /**
     * Using type predicates to determine if response is a custom ApolloError or a ShopList
     *
     * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
     */
    private isApolloError<T>(object: T | ApolloError): object is ApolloError {
        return (object as ApolloError).status !== undefined;
    }
}
