import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { FeatureFlagDirective } from "./directives/feature-flag.directive";
import { FeatureFlagEffects } from "./effects/feature-flag.effects";
import { FeatureFlagFacade } from "./facades/feature-flag.facade";
import { reducer } from "./reducers/feature-flag.reducer";
import { FeatureFlagService } from "./services/feature-flag.service";

@NgModule({
    imports: [
        StoreModule.forFeature("featureFlags", reducer),
        EffectsModule.forFeature([FeatureFlagEffects]),
    ],
    providers: [FeatureFlagService, FeatureFlagFacade],
    declarations: [FeatureFlagDirective],
    exports: [FeatureFlagDirective],
})
export class FlipperStateModule {}
