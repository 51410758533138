/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import {
    filter,
    from,
    interval,
    map,
    Observable,
    of,
    startWith,
    switchMap,
} from "rxjs";

import { delay } from "rxjs/operators";

import { MessageType, NotificationsService } from "@hermes/aphrodite/layout";

import {
    PaymentStatus,
    PaymentValidationResponse,
} from "@hermes/api-model-payment";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import {
    HTTP_JSON_CONTENT_TYPE_HEADER,
    HTTP_NO_CACHE_CONTROL_HEADER,
} from "@hermes/utils-generic/constants";
import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

const VALIDATE_URL = "payment/validation";
const GLOBAL_OOPS = $localize`:@@hermes-global-translations.oops:Oops!`;
const PAYMENT_DECLINED_ERROR = $localize`:@@hermes-checkout-payment-declined-error:Oops ! Your payment was declined`;

@Injectable()
export class ValidationService {
    constructor(
        private http: HttpClient,
        private settings: Settings,
        @Inject(LOCALE) private locale: Locale,
        private notificationsService: NotificationsService,
        private urlUtils: UrlUtils,
    ) {}

    public callVerifyWs(
        params: Record<string, string | undefined>,
    ): Observable<PaymentValidationResponse> {
        // Alipay
        const alipayId = params["trade_no"]
            ? params["out_trade_no"]
            : params["order"];
        if (alipayId) {
            const alipayResponse = new PaymentValidationResponse();
            alipayResponse.orderId = alipayId;
            alipayResponse.validationStatus = "VALIDATED";
            return of(alipayResponse);
        }

        const httpOptions = {
            headers: new HttpHeaders({
                ...HTTP_JSON_CONTENT_TYPE_HEADER,
            }),
            withCredentials: true,
        };

        return this.http.post<PaymentValidationResponse>(
            `${this.settings.apiUrl}/${VALIDATE_URL}`,
            {
                locale: this.locale.code,
                ...params,
            },
            httpOptions,
        );
    }

    /**
     * Poll mercury to get the status of a Payment
     */
    public pollForPaymentStatus(): Observable<PaymentStatus> {
        return interval(5000).pipe(
            startWith(0),
            switchMap(() => this.checkStatus()),
            map((response) => response.paymentStatus),
        );
    }

    public getPaymentStatus(): Observable<
        Record<"paymentStatus", PaymentStatus>
    > {
        return this.http.get<Record<"paymentStatus", PaymentStatus>>(
            `${this.settings.apiUrl}/payment-status?cacheBuster=${Date.now()}`,
            {
                params: new HttpParams().append("locale", this.locale.code),
                withCredentials: true,
                headers: new HttpHeaders({
                    ...HTTP_NO_CACHE_CONTROL_HEADER,
                }),
            },
        );
    }

    /**
     * Manage error and redirect in case of direct access to QRCode page
     */
    public showGlobalError() {
        return from(
            this.notificationsService.showNotification({
                message: {
                    title: GLOBAL_OOPS,
                    description: PAYMENT_DECLINED_ERROR,
                },
                type: MessageType.SuperCritical,
                isTemporary: false,
            }),
        ).pipe(
            delay(5000),
            map(() => this.urlUtils.redirectToRootPath()),
        );
    }

    private checkStatus(): Observable<Record<"paymentStatus", PaymentStatus>> {
        return this.getPaymentStatus().pipe(
            filter((response) => response.paymentStatus !== "PENDING"),
        );
    }
}
