import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { CartErrorInterceptor } from "./cart-error.interceptor";

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CartErrorInterceptor,
            multi: true,
        },
    ],
})
export class CartErrorInterceptorModule {}
