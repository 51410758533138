import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ModelObject } from "@hermes/api-model-core";
import { BannersData } from "@hermes/api-model-shell";
import { Context, LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

import { COOKIEBANNER, COOKIEBANNER_EXPIRES } from "./banner.constants";

@Injectable()
export class BannerService {
    public secureFlag: boolean = true;
    public sameSite: "Lax" | "None" | "Strict" = "Lax";
    public cookieDomain?: string;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private http: HttpClient,
        private context: Context,
        private settings: Settings,
        private cookieService: CookieService,
    ) {}

    /**
     * Fetch banner items from Drupal
     */
    public fetchBannersData(): Observable<BannersData> {
        return this.http.get(`${this.settings.apiUrl}/editorial/banners`).pipe(
            // convert json to banner data
            map((result: unknown) =>
                ModelObject.fromJsonData(result, BannersData),
            ),
        );
    }

    public saveCookieBanner(cookieValue: number): void {
        if (this.context.isInBrowserMode()) {
            const domainArray = this.context.getCurrentHost().split(".");
            if (domainArray.length > 1) {
                const domain = `.${domainArray[domainArray.length - 2]}.${
                    domainArray[domainArray.length - 1]
                }`;
                this.cookieDomain = domain;
                this.deleteCookieBanner();
                this.cookieService.set(
                    COOKIEBANNER,
                    `${cookieValue}`,
                    COOKIEBANNER_EXPIRES,
                    "/",
                    domain,
                    this.secureFlag,
                    this.sameSite,
                );
            }
        }
    }

    public deleteCookieBanner(): void {
        this.cookieService.delete(
            COOKIEBANNER,
            "/",
            this.cookieDomain,
            this.secureFlag,
            this.sameSite,
        );
    }

    /**
     * Fetch geo location
     */
    public fetchGeoLocation(): Observable<string | undefined | null> {
        // If there is a 404 call to "/geo/" service we don"t want to redirect to a 404 page
        // The added context tells the interceptor not to redirect to 404
        const context = new HttpContext().set(SKIP_404_INTERCEPTOR, true);
        return this.http
            .get<string>(`${this.locale.urlPrefix}/geo/`, {
                observe: "response",
                context,
            })
            .pipe(
                catchError(() => of(undefined)),
                map((resp) =>
                    resp && resp.headers.get("x-geo-country")
                        ? resp.headers.get("x-geo-country")
                        : undefined,
                ),
            );
    }
}
