import { Inject, Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";
import { BehaviorSubject } from "rxjs";

import { INFRA_SETTINGS, LOCALE } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";
import { Locale } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import {
    base64UrlDecode,
    base64UrlEncode,
} from "@hermes/utils-generic/helpers";
import { LOGGER } from "@hermes/web-logger";

import {
    BELTKIT,
    BRAND,
    LOSSLESS_FORMAT,
    NO_RIPE,
    PLATFORME_DEFAULT_SIZE,
    SILK,
    SMALL_LEATHER_GOODS,
} from "../../constant/product-personalization.constant";
import { validateSchema } from "../../schema-validator/ajv-schema-validator";
import platformeInitSdkResponseBeltkit from "../../schemas/platforme-init-sdk-response-beltkit.json";
import platformeInitSdkResponseSilk from "../../schemas/platforme-init-sdk-response-silk.json";
import platformeInitSdkResponseSmlg from "../../schemas/platforme-init-sdk-response-smlg.json";
import platformeInitialConfigResponseBeltkit from "../../schemas/platforme-initial-config-response-beltkit.json";
import platformeInitialConfigResponseSmlg from "../../schemas/platforme-initial-config-response-smlg.json";
import {
    PlatformeConfigResponse,
    PlatformeInitialConfiguration,
    NO_CONFIGURATION,
    PersoParsedUrl,
    ProductCategory,
} from "../../types/product-personalization.type";

// Silk case prove that if only declared in module, might be instantiated twice between product and perso page.
@Injectable({
    providedIn: "root",
})
export class InitPlatformeService {
    public ripeInstance$: BehaviorSubject<Ripe | typeof NO_RIPE> =
        new BehaviorSubject(NO_RIPE);
    public initiateConfiguratorMapping$: BehaviorSubject<
        PlatformeConfigResponse | typeof NO_CONFIGURATION
    > = new BehaviorSubject(NO_CONFIGURATION);
    public ripeInstance: Ripe;

    public productCategory: ProductCategory = "unsupported";

    constructor(
        @Inject(INFRA_SETTINGS) private settings: InfraSettings,
        @Inject(LOCALE) private locale: Locale,
        @Inject(LOGGER) private logger: Logger,
    ) {}

    /**
     * If PlatformeInitialConfiguration has been needed, we fetch DKU from this source and update State as well as URL.
     */
    public fetchInitialDkuForPlatforme(
        urlParsedParameters: PersoParsedUrl,
        platformeInitialConfiguration:
            | PlatformeInitialConfiguration
            | typeof NO_CONFIGURATION,
        productCategory: ProductCategory,
    ): string | undefined {
        this.productCategory = productCategory;
        // silk case
        let encodedDku = urlParsedParameters.productDku;
        if (
            urlParsedParameters.requireConfCall &&
            !urlParsedParameters.productDku &&
            platformeInitialConfiguration !== NO_CONFIGURATION
        ) {
            switch (productCategory) {
                case BELTKIT: {
                    validateSchema(
                        platformeInitialConfigResponseBeltkit,
                        platformeInitialConfiguration,
                        "Platforme Initial Config Call response Beltkit",
                        this.logger,
                    );
                    break;
                }
                case SMALL_LEATHER_GOODS: {
                    validateSchema(
                        platformeInitialConfigResponseSmlg,
                        platformeInitialConfiguration,
                        "Platforme Initial Config Call response Smlg",
                        this.logger,
                    );
                    break;
                }
            }
            encodedDku =
                productCategory === SMALL_LEATHER_GOODS
                    ? base64UrlEncode(
                          platformeInitialConfiguration.dku +
                              PLATFORME_DEFAULT_SIZE,
                      )
                    : base64UrlEncode(platformeInitialConfiguration.dku);
        }
        return encodedDku;
    }

    /**
     * initPlatformeSdk
     */
    public initPlatformeSdk(model: string, encodedDku: string): void {
        const ripeStatic = require("ripe-sdk").Ripe;
        this.ripeInstance = new ripeStatic({
            url: `${this.settings.personalizationPlatformeUrl}/`,
            brand: BRAND,
            format: LOSSLESS_FORMAT,
            model,
            dku: base64UrlDecode(encodedDku),
            usePrice: false,
            useBundles: false,
            guess: false,
            country:
                this.productCategory === BELTKIT ? this.locale.countryCode : "",
        });
        this.ripeInstance.bind("config", this.setConfig.bind(this));
    }

    /**
     * bind config
     */
    public setConfig(configuration: PlatformeConfigResponse): void {
        // Validate the Platforme response
        switch (this.productCategory) {
            case BELTKIT: {
                validateSchema(
                    platformeInitSdkResponseBeltkit,
                    configuration,
                    "Platforme init SDK response for Beltkit",
                    this.logger,
                );
                break;
            }
            case SMALL_LEATHER_GOODS: {
                validateSchema(
                    platformeInitSdkResponseSmlg,
                    configuration,
                    "Platforme init SDK response for SMLG",
                    this.logger,
                );
                break;
            }
            case SILK: {
                validateSchema(
                    platformeInitSdkResponseSilk,
                    configuration,
                    "Platforme Initial Config Call response Silk",
                    this.logger,
                );
            }
        }

        this.ripeInstance.version = configuration.version;
        this.ripeInstance$.next(this.ripeInstance);
        this.initiateConfiguratorMapping$.next(configuration);
    }
}
