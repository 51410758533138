import { Component, Inject, Input } from "@angular/core";

import { COUNTRY, LOCALE } from "@hermes/app-core";
import {
    Country,
    isCanada,
    isChina,
    isEurope,
    isKorea,
    isMexico,
    Locale,
} from "@hermes/locale";
import { AnalyticsService } from "@hermes/utils/analytics";

import {
    CLICK_ACCESSIBILITY,
    CLICK_CGU,
    CLICK_CGV,
    CLICK_LEGAL,
    CLICK_PRIVACY,
    CLICK_TERMS,
    FooterLightClickEvent,
} from "../footer-light/events/footer-click.event";

export enum FooterLink {
    CGV,
    CGU,
    TERMS,
    PRIVACY,
    LEGAL,
    A11Y,
}

@Component({
    selector: "h-footer-light",
    templateUrl: "./footer-light.component.html",
    styleUrls: ["./footer-light.component.scss"],
})
export class FooterLightComponent {
    @Input()
    public hasLightCopyright = false;

    public countryCode = this.country.countryCode;
    public isEurope = isEurope(this.country);
    public isChina = isChina(this.country);
    public isKorea = isKorea(this.country);
    public year = new Date().getFullYear();
    public linkTypes = FooterLink;

    public urlAccessibility = isCanada(this.locale)
        ? `http://accessibility.hermes.com${this.locale.urlPrefix}/`
        : "http://accessibility.hermes.com/";
    public urlElectronicBusinessLicense =
        "http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020170912143911000002054562-SAIC_SHOW_310000-20190411150609212537&signData=MEUCIQCD26DfP1PSSxcPnq2wT11yNHB2Nz7EYZilcdqufpzXGwIgbZjwSVOj1xm9GN4lGd5fjqY7Inh0rI4IhUo4bVB5AEk=";
    public urlImageElectronicBusinessLicense = `${this.locale.urlPrefix}/assets/images/china-license.png`;
    public urlShanghaiICP = "http://sh.beian.miit.gov.cn/";
    public urlPublicNetworkSecurity =
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602002693";
    public urlImagePublicNetworkSecurity = `${this.locale.urlPrefix}/assets/images/china-network-security.png`;

    public isMexico: boolean = false;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(COUNTRY) private country: Country,
        private analyticsService: AnalyticsService,
    ) {
        this.isMexico = isMexico(this.locale);
    }

    public handleFooterLinkClick(link: FooterLink): void {
        const eventType = {
            [FooterLink.CGV]: CLICK_CGV,
            [FooterLink.CGU]: CLICK_CGU,
            [FooterLink.TERMS]: CLICK_TERMS,
            [FooterLink.PRIVACY]: CLICK_PRIVACY,
            [FooterLink.LEGAL]: CLICK_LEGAL,
            [FooterLink.A11Y]: CLICK_ACCESSIBILITY,
        }[link];
        this.analyticsService.sendData(
            new FooterLightClickEvent({ eventType }),
        );
    }
}
