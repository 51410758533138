import { createAction, props } from "@ngrx/store";

import { TrayData } from "../models/tray-data.model";

/**
 * Open a tray and add it to the tray stack.
 */
export const openTray = createAction("[Tray] Open Tray", props<TrayData>());

/**
 * Close the last tray of the stack.
 */
export const closeTray = createAction("[Tray] Close Tray");

/**
 * Close all trays except the one in parameter
 */
export const closeAllOtherTrays = createAction(
    "[Tray] Close All Other Trays",
    props<{ name: string }>(),
);

/**
 * Close and remove a tray with name.
 */
export const closeTrayByName = createAction(
    "[Tray] Close a tray with its name",
    props<{ name: string }>(),
);
