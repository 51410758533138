import { Component, Input } from "@angular/core";

import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

@Component({
    selector: "h-redirect-message",
    templateUrl: "./redirect-message.component.html",
    styleUrls: ["./redirect-message.component.scss"],
})
export class RedirectMessageComponent {
    @Input({ required: true })
    public state!: "validation" | "error" | undefined;

    @Input()
    public errorMessage?: string;

    constructor(public urlUtils: UrlUtils) {}
}
