<div class="accessibility-wrapper" [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async">
    <h-shell>
        <h-main-content> </h-main-content>
    </h-shell>
    <h-overlay></h-overlay>
    <h-loader></h-loader>
    <h-tray-container></h-tray-container>
    <h-modal-container></h-modal-container>
    <h-svg-symbol></h-svg-symbol>
</div>
