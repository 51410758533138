import { Component, Inject, Input } from "@angular/core";

import { WINDOW } from "@hermes/app-core";
import { BannerService } from "@hermes/states/shell";

@Component({
    selector: "h-cookie-link",
    templateUrl: "./cookie-link.component.html",
    styleUrls: ["./cookie-link.component.scss"],
})
export class CookieLinkComponent {
    @Input()
    public link!: string;

    @Input()
    public linkLabel!: string;

    constructor(
        private bannerService: BannerService,
        @Inject(WINDOW) private window: Window,
    ) {}

    public handleCookieLinkClick(): void {
        this.bannerService.deleteCookieBanner();
        this.window.location.href = this.link;
    }
}
