import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "suggestionItem",
})
export class SuggestionItemPipe implements PipeTransform {
    /**
     * Transform index into a SuggestionItem identifier
     *
     * @param index SuggestionItem index
     */
    public transform(index: number | undefined): string | undefined {
        if (index !== undefined && index >= 0) {
            return `search-suggestion-item-${index}`;
        }
        return undefined;
    }
}
