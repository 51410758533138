import { createAction, props } from "@ngrx/store";

import { Guest, User } from "@hermes/api-model-account";

// Guest Actions
export const fetchGuest = createAction("[User] Fetch guest data");

export const fetchGuestSuccess = createAction(
    "[User] Fetch guest data success",
    props<{ guest: Guest }>(),
);

export const removeGuest = createAction("[User] Remove guest data");
export const removeGuestSuccess = createAction(
    "[User] Remove guest data success",
);

export const updateGuest = createAction(
    "[User] Update guest data",
    props<{ guest: Guest }>(),
);

// User Actions
export const launchUserSynchroWithStorage = createAction(
    "[User] Launch the synchronization of the user with the session storage",
);

export const setUserFromStorage = createAction(
    "[User] New user in the session storage",
    props<{ user: User }>(),
);

export const userSynchroWithStorageFailure = createAction(
    "[User] Error during user synchronization from session storage",
    props<{ errorMessage: string }>(),
);

export const setUserInStorage = createAction(
    "[User] Set a new user in session storage",
    props<{ user: User }>(),
);

export const setUserInStorageSuccess = createAction(
    "[User] Set a new user in session storage success",
    props<{ user: User }>(),
);

export const setUserInStorageFailure = createAction(
    "[User] Error during user set in storage",
    props<{ errorMessage: string }>(),
);

export const clearData = createAction("[User] Clear data");
export const clearDataSuccess = createAction("[User] Clear data success");
