import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";

import { SvgModule, svgDiagonal } from "@hermes/aphrodite/svg";

import { ImageUrlPipeModule } from "@hermes/utils-generic/pipes/image-url";

import { DynamicBoxComponent } from "./components/dynamic-box.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SvgModule.forChild([svgDiagonal]),
        ImageUrlPipeModule,
    ],
    declarations: [DynamicBoxComponent],
    exports: [DynamicBoxComponent],
})
export class DynamicBoxModule {}
