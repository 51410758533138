import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

/**
 * Abstract class for variation-click event
 */
export abstract class SearchBarAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: GTMEventData;

    constructor(data?: Record<string, unknown>) {
        this.eventData = data as unknown as GTMEventData;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: "Search",
        };
    }
}
