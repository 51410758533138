import { createAction, props } from "@ngrx/store";

import { EcomError } from "../models/ecom-error.model";
import { FetchPaymentMethodsOptions } from "../models/fetch-payment-methods-options.model";
import { PaymentMethodsResponseDTO } from "../models/payment-methods-response-dto.model";

export const fetchPaymentMethods = createAction(
    "[PaymentMethods] Fetch paymentMethods",
    props<{ options?: FetchPaymentMethodsOptions }>(),
);

export const fetchPaymentMethodsSuccess = createAction(
    "[PaymentMethods] Fetch paymentMethods Success",
    props<{ methods: PaymentMethodsResponseDTO }>(),
);

export const fetchPaymentMethodsFailure = createAction(
    "[PaymentMethods] Fetch paymentMethods Failure",
    props<{ error: EcomError }>(),
);

export const removeCard = createAction(
    "[SavedCards] remove card from state",
    props<{ id: string }>(),
);

export const deleteSavedCard = createAction(
    "[DeleteSavedCard] Delete saved card",
    props<{ id: string }>(),
);

export const deleteSavedCardSuccess = createAction(
    "[DeleteSavedCard] Delete saved card Success",
    props<{ id: string }>(),
);

export const deleteSavedCardFailure = createAction(
    "[DeleteSavedCard] Delete saved cards Failure",
    props<{ id: string; error: string }>(),
);
