import {
    CHROME_USER_AGENT,
    QQBROWER_USER_AGENT,
    WECHAT_USER_AGENT,
} from "@hermes/utils-generic/constants";

/**
 * Return true if user agent is wechat
 */
export const isWeChatAgent = (userAgent: string): boolean =>
    userAgent.includes(WECHAT_USER_AGENT);

export const isQQBrowerAgent = (userAgent?: string): boolean =>
    userAgent?.includes(QQBROWER_USER_AGENT) ?? false;

export const isChromeAgent = (userAgent: string): boolean =>
    userAgent?.includes(CHROME_USER_AGENT) ?? false;
