import { NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "phoneNumber",
})
export class PhoneNumberPipe implements PipeTransform {
    public transform(phoneNumber: string): string {
        const regexSpecialChar = /[ !()*,?-]+/g;
        return phoneNumber.replace(regexSpecialChar, "");
    }
}

@NgModule({
    declarations: [PhoneNumberPipe],
    exports: [PhoneNumberPipe],
    providers: [PhoneNumberPipe],
})
export class PhoneNumberPipeModule {}
