<div
    (document:keyup.escape)="onClose()"
    @fadeAnimation
    [@easeAnimation]="isMobile ? 'active' : 'disabled'"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.role]="role || 'alertdialog'"
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    class="modal-container">
    <div class="modal-header">
        <ng-content select="[h-modal-header]"></ng-content>
    </div>
    <div class="modal-body">
        <ng-content select="[h-modal-body]"></ng-content>
    </div>
    <div class="modal-footer">
        <ng-content select="[h-modal-footer]"></ng-content>
    </div>
</div>
