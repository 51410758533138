/** Interface for call-to-action button state */
export interface ErrorCodeError {
    // magento response when add to cart failed
    errorCode?: string;
    enabled: boolean;
}
export interface ButtonEnabled {
    enabled: boolean;
}

export interface AddToCartCallToAction {
    ecomError: ErrorCodeError;
    button: ButtonEnabled;
}
export interface AddToCartStateBasket {
    notifDisplayed: boolean;
    hasComplementaryDelivery: boolean;
    totalItems: number;
}

export type AddToCartStateLoading = boolean;
export type AddToCartStateAction = AddToCartCallToAction[];

export interface AddToCartState {
    basket: AddToCartStateBasket;
    loading: AddToCartStateLoading;
    addToCartPositionClicked: number;
    addToCartCallToActions: AddToCartStateAction;
}

/** initial product page state */
export const initialAddToCartState: AddToCartState = {
    basket: {
        notifDisplayed: false,
        hasComplementaryDelivery: false,
        totalItems: 0,
    },
    loading: false,
    addToCartPositionClicked: 0,
    addToCartCallToActions: [
        {
            ecomError: {
                errorCode: undefined,
                enabled: true,
            },
            button: {
                enabled: true,
            },
        },
    ],
};
