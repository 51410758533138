<span
    role="heading"
    i18n-role
    aria-level="2"
    i18n="@@hermes--footer-wechat-social.follow-us"
    [attr.data-testid]="'footer-social-network'"
    class="heading"
    >Follow us</span
>

<ul>
    <ng-container *ngFor="let socialNetwork of socialNetworks; trackBy: trackBySocialNetwork">
        <li class="wechat-grey" *ngIf="socialNetwork.machineName === 'wechat-grey'">
            <!-- Custom template for Wechat -->
            <button (click)="handleClickOnWechat($event, 'wechat-grey')">
                <span hSrOnlyNoSnippet>
                    {{ socialNetwork.machineName }}
                    <ng-container i18n="@@hermes.footer.social.new-window">(new window)</ng-container>
                </span>

                <h-svg-icon [name]="'wechat-alternate'" class="wechat-icon"></h-svg-icon>
            </button>

            <div id="tooltip-wechat" class="arrow-box" *ngIf="showWeChatTootip">
                <div class="tooltip-wechat-wrapper">
                    <button id="close-tooltip" class="button-icon icon-close-wechat" (click)="hideWeChatTooltip()">
                        <h-svg-icon [name]="'cross'" class="icon-close-tooltip"></h-svg-icon>
                    </button>

                    <div class="wechat-tooltip-text-wrapper">
                        <p class="follow-us-title" i18n="@@hermes--footer-wechat-social.follow-us">Follow us</p>
                        <img
                            [src]="weChatImageUrl"
                            alt="Hermes Official Wechat account"
                            i18n-alt="@@block--hermes-general-info--help-tray.wechat-QR-code-alt"
                            class="qr-code"
                            loading="lazy" />
                        <p class="follow-us-description" i18n="@@hermes--footer.wechat-social.scan-in-wechat">
                            Scan in Wechat
                        </p>
                        <p
                            class="follow-us-description-mobile"
                            i18n="@@block--hermes-general-info--help-tray.wechat-description-mobile">
                            Wechat ID : Hermes_Official
                        </p>
                    </div>
                </div>
            </div>
        </li>

        <li class="{{ socialNetwork.machineName }}" *ngIf="socialNetwork.machineName !== 'wechat-grey'">
            <!-- Common template for social networks -->
            <a
                href="{{ socialNetwork.href }}"
                target="_blank"
                rel="noopener noreferrer"
                (click)="handleClickOnSocialNetwork($event, socialNetwork.machineName)">
                <span hSrOnlyNoSnippet>
                    {{ socialNetwork.name }}
                    <ng-container i18n="@@hermes.footer.social.new-window">(new window)</ng-container>
                </span>

                <h-svg-icon
                    [name]="socialNetwork.machineName"
                    [class]="socialNetwork.machineName"
                    class="social-network-icon"></h-svg-icon>
            </a>
        </li>
    </ng-container>
</ul>
