<span class="price" [ngClass]="size" [class.price-bold]="weight === 'bold'" [class.price-color]="hasPrimaryColor">
    <span
        *ngIf="isIndicative; else isNotIndicative"
        class="indicative-price-label"
        i18n="@@hermes.product-grid.indicative-price">
        {{ price | hCurrency : format }} From
    </span>
    <ng-template #isNotIndicative>
        {{ price | hCurrency : format }}
    </ng-template>
</span>
