import { FeatureFlagName } from "../constants/feature-flags.constant";

export enum FeatureFlagStatus {
    Uninitialized = "uninitialized",
    Loading = "loading",
    Loaded = "loaded",
    Error = "error",
}
export const NO_FLAGS = "NO_FLAGS" as const;

export type FeatureFlags = Record<FeatureFlagName, boolean>;
export type FeatureFlagState =
    | {
          status:
              | typeof FeatureFlagStatus.Uninitialized
              | typeof FeatureFlagStatus.Loading;
          flags: typeof NO_FLAGS;
      }
    | {
          status: typeof FeatureFlagStatus.Loaded;
          flags: FeatureFlags;
      }
    | {
          status: typeof FeatureFlagStatus.Error;
          flags: typeof NO_FLAGS;
          error: string;
      };

export const initialFeatureFlagState: FeatureFlagState = {
    status: FeatureFlagStatus.Uninitialized,
    flags: NO_FLAGS,
};
