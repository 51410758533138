import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import exportedSass from "@hermes/aphrodite/design-system-tools";
import {
    DEVICE_LARGE_NAME,
    DEVICE_MEDIUM_NAME,
    DeviceType,
} from "@hermes/utils-generic/constants";

const DEVICE_SMALLEST_MATCHER = `(min-width: ${exportedSass.breakpoints["breakpoint-smallest"]})`;
const DEVICE_MEDIUM_MATCHER = `(min-width: ${exportedSass.breakpoints["breakpoint-medium"]})`;
const DEVICE_LARGE_MATCHER = `(min-width: ${exportedSass.breakpoints["breakpoint-large"]})`;

@Injectable({
    providedIn: "root",
})
export class BreakpointService {
    constructor(private breakpointObserver: BreakpointObserver) {}

    /**
     * Return the type of device depending on the width of the screen
     *
     * @deprecated Use the method in the BreakpointService instead : BreakpointMatcher or BreakpointObserver
     */
    public static getDeviceType(): DeviceType {
        if (
            window.screen.width <
            Number.parseInt(
                exportedSass.breakpoints["breakpoint-medium"].replace("px", ""),
                10,
            )
        ) {
            return DEVICE_MEDIUM_NAME;
        }

        return DEVICE_LARGE_NAME;
    }

    public smallestBreakpointMatcher(): boolean {
        return this.breakpointObserver.isMatched(DEVICE_SMALLEST_MATCHER);
    }

    public mediumBreakpointMatcher(): boolean {
        return this.breakpointObserver.isMatched(DEVICE_MEDIUM_MATCHER);
    }

    public largeBreakpointMatcher(): boolean {
        return this.breakpointObserver.isMatched(DEVICE_LARGE_MATCHER);
    }

    public smallestBreakpointObserver(): Observable<BreakpointState> {
        return this.breakpointObserver.observe(DEVICE_SMALLEST_MATCHER);
    }

    public mediumBreakpointObserver(): Observable<BreakpointState> {
        return this.breakpointObserver.observe(DEVICE_MEDIUM_MATCHER);
    }

    public largeBreakpointObserver(): Observable<BreakpointState> {
        return this.breakpointObserver.observe(DEVICE_LARGE_MATCHER);
    }

    public severalBreakpointObserver(
        queries = [DEVICE_MEDIUM_MATCHER, DEVICE_LARGE_MATCHER],
    ): Observable<BreakpointState> {
        return this.breakpointObserver.observe(queries);
    }
}
