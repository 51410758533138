import { createAction, props } from "@ngrx/store";

import { NotificationState } from "../model/notification.model";
import { IHeaderMainContainerProperties } from "../reducers/layout.reducer";

export const updateHeaderHeight = createAction(
    "[Layout] Header height change",
    props<{
        header: IHeaderMainContainerProperties;
    }>(),
);

/**
 * Show the loader.
 */
export const showLoader = createAction("[Layout] show Loader");

/**
 * Hide the loader.
 */
export const hideLoader = createAction("[Layout] hide Loader");

/**
 * Show the Notification.
 */
export const showNotification = createAction(
    "[Layout] Show Notification",
    props<NotificationState>(),
);

/**
 * Hide the Notification.
 */
export const hideNotification = createAction("[Layout] Hide Notification");

export const keyboardNavigation = createAction("[Layout] Keyboard Navigation");

export const mouseNavigation = createAction("[Layout] Mouse Navigation");
