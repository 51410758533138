import { DisplayMode, Product, ProductStock } from "@hermes/api-model-product";

import { ProductTemplateType } from "@hermes/utils/constants";

import {
    PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_ECOM_STORE,
    PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_ECOM,
    PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_STORE,
    AvailabilityHitType,
    NODATA,
    DISPLAY_ONLY_MODE,
    BACK_IN_STOCK_MODE,
    PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK,
    PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK,
} from "../../constants/analytics.constant";
import { BK_ANALYTICS_PRODUCT_TYPE } from "../constants/beltkit-analytics.constants";

export function getStockTypeAndDisplayOnly(
    productStock: ProductStock | undefined,
    displayMode?: DisplayMode,
): AvailabilityHitType | undefined {
    if (productStock?.ecom && productStock.retail) {
        return displayMode === DISPLAY_ONLY_MODE
            ? PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY
            : PRODUCT_AVAILABILITY_STATUS_ECOM_STORE;
    } else if (productStock?.ecom) {
        return displayMode === DISPLAY_ONLY_MODE
            ? PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY
            : PRODUCT_AVAILABILITY_STATUS_ECOM;
    } else if (productStock?.retail) {
        if (displayMode === DISPLAY_ONLY_MODE) {
            return PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY;
        } else if (displayMode === BACK_IN_STOCK_MODE) {
            return PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK;
        }

        return PRODUCT_AVAILABILITY_STATUS_STORE;
    }

    if (displayMode === DISPLAY_ONLY_MODE) {
        return PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY;
    } else if (displayMode === BACK_IN_STOCK_MODE) {
        return PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK;
    }

    return undefined;
}

export function getAvailabilityHit(
    product: Product,
): AvailabilityHitType | undefined {
    if (
        product.templateType === ProductTemplateType.Look ||
        product.templateType === ProductTemplateType.Bikini
    ) {
        return PRODUCT_AVAILABILITY_STATUS_ECOM;
    }
    if (
        product.persoProductType &&
        product.persoProductType === BK_ANALYTICS_PRODUCT_TYPE
    ) {
        return NODATA;
    }

    return getStockTypeAndDisplayOnly(product.stock, product.displayMode);
}
