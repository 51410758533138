import {
    CHECKOUT_ORANGE_BOX_EXCEPTIONS,
    CHECKOUT_SUMMARY_CLOSE,
    CHECKOUT_SUMMARY_OPEN,
} from "../constants/conversion-funnel-common.constants";
import { GTMEventData } from "../types/gtm.type";

import { AnalyticsEvent } from "./analytics.event";
export class CheckoutAnalyticsEvent implements AnalyticsEvent<GTMEventData> {
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    public formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CHECKOUT_SUMMARY_OPEN: {
                return {
                    event: this.name,
                    eventcategory: "Checkout",
                    eventaction: "Summary",
                    eventlabel: "Open",
                };
            }
            case CHECKOUT_SUMMARY_CLOSE: {
                return {
                    event: this.name,
                    eventcategory: "Checkout",
                    eventaction: "Summary",
                    eventlabel: "Close",
                };
            }
            case CHECKOUT_ORANGE_BOX_EXCEPTIONS: {
                return {
                    event: this.name,
                    eventcategory: "Checkout",
                    eventaction: "OrangeBoxExceptions",
                };
            }
            default: {
                return {
                    event: this.name,
                    eventcategory: "Checkout_TMP", // Will be replaced by child class overload
                };
            }
        }
    }
}
