import { Inject, Injectable } from "@angular/core";
import newrelicNamespace from "newrelic";
import type { Ripe } from "ripe-sdk/src/js";

import { WINDOW } from "@hermes/app-core";
import { Logger } from "@hermes/logger";
import { base64UrlEncode } from "@hermes/utils-generic/helpers";
import { LOGGER } from "@hermes/web-logger";

import {
    BELTKIT,
    PLATFORME_DEFAULT_SIZE,
    SMALL_LEATHER_GOODS,
} from "../../constant/product-personalization.constant";
import { ProductPersonalizationFacade } from "../../facades/product-personalization.facade";
import { ProductCategory } from "../../types/product-personalization.type";

@Injectable()
export class PlatformeDkuService {
    constructor(
        private productPersonalizationFacade: ProductPersonalizationFacade,
        @Inject(LOGGER) private logger: Logger,
        @Inject(WINDOW)
        private window: Window & {
            newrelic: typeof newrelicNamespace & {
                setCustomAttribute: (
                    name: string,
                    value: string | number | boolean | null,
                    persist?: boolean,
                ) => void;
            };
        },
    ) {}

    /**
     * Update the current encoded DKU and the DKU from the URL
     * size used in beltkit and silk case
     */
    public updateDkuFromPlatforme(
        ripeInstance: Ripe,
        productCategory: ProductCategory,
        productSize?: string,
    ): void {
        const parameter = productSize
            ? { params: { size: productSize } }
            : undefined;

        ripeInstance
            .configInfoP(parameter)
            .then((response: { dku: string; query: string }) => {
                const encodedDku =
                    productCategory === SMALL_LEATHER_GOODS
                        ? base64UrlEncode(response.dku + PLATFORME_DEFAULT_SIZE)
                        : base64UrlEncode(response.dku);
                if (productCategory === BELTKIT) {
                    this.window.newrelic?.setCustomAttribute(
                        "logPersoQuery",
                        response.query,
                    );
                    this.window.newrelic?.setCustomAttribute(
                        "logPersoDku",
                        response.dku,
                    );
                }
                this.productPersonalizationFacade.updateDku(encodedDku);
                this.productPersonalizationFacade.updateDisplayedUrl();
                this.productPersonalizationFacade.hideLoader();
                this.productPersonalizationFacade.updateIsCallingPlatforme(
                    false,
                );
            })
            .catch((error: Error) => {
                this.logger.error(
                    "Unexpected error while calling platformE",
                    error,
                );
                this.productPersonalizationFacade.updateIsCallingPlatforme(
                    false,
                );
            });
    }
}
