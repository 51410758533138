import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromMenu from "../reducers/menu.reducer";

export const menuState = createFeatureSelector<fromMenu.State>(
    fromMenu.menuFeatureKey,
);

export const selectMenuEntry = createSelector(
    menuState,
    (state: fromMenu.State) => state.entry,
);

export const selectCategories = createSelector(
    menuState,
    (state: fromMenu.State) => state.categories,
);

export const selectLinks = createSelector(
    menuState,
    (state: fromMenu.State) => state.links,
);
