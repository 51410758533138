import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "ariaDescribedbyPipe",
    standalone: true,
})
export class AriaDescribedbyPipe implements PipeTransform {
    public transform(ariaDescribedby: string | undefined, id: string): string {
        ariaDescribedby = ariaDescribedby ? ` ${ariaDescribedby}` : "";
        return `input-error-${id}${ariaDescribedby}`;
    }
}
