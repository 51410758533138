import { createAction, props } from "@ngrx/store";

import { Asset } from "@hermes/api-model-editorial";
import { AbstractProductVariant, Product } from "@hermes/api-model-product";

import { CrossSell } from "../types/product-page.types";

export const BUCKLE = "buckle";
export const LEATHER = "leather";
export const SIZE = "size";
export const COLOR = "color";
export const VOLUME = "volume";
export const STRAP = "strap";
export const FINISH = "finish";
export const STRAP_SIZE = "strapSize";

export type ProductVariantType =
    | typeof BUCKLE
    | typeof LEATHER
    | typeof SIZE
    | typeof COLOR
    | typeof VOLUME
    | typeof STRAP
    | typeof FINISH
    | typeof STRAP_SIZE;

export interface ToggleZoomEvent {
    zoomStatus: boolean;
    currentIndex?: number;
    zoomAssets?: Asset[];
    isTopLevelProduct?: boolean;
    templateType?: string;
}

export const fetchProduct = createAction(
    "[Product Page] Fetch Product",
    props<{ sku: string; isVariant: boolean }>(),
);

export const fetchProductSuccess = createAction(
    "[Product Page] Fetch Product Success",
    props<{ data: Product; isVariant: boolean }>(),
);

export const fetchProductFailure = createAction(
    "[Product Page] Fetch Product Failure",
    props<{ error: Error; sku: string }>(),
);

export const selectVariant = createAction(
    "[Product Page] Select Variant",
    props<{
        variantType: ProductVariantType;
        variantItem: AbstractProductVariant;
        position?: number;
    }>(),
);

export const resetSizeSelectedVariants = createAction(
    "[Product Page] Reset Size Selected Variants",
);

export const resetVariant = createAction(
    "[Product Page] Reset Variant",
    props<{ position: number }>(),
);

export const showMoreVariant = createAction(
    "[Product Page] Switch show more variant",
    props<{ data: { id: string; isExpanded: boolean; sku?: string } }>(),
);

export const openSelectorToggle = createAction(
    "[Product Page] Open selector toggle",
    props<{ toggleKey: string }>(),
);

export const goBack = createAction("[Product Page] goBack button click");

export const toggleZoom = createAction(
    "[Product Page] Toggle zoom image",
    props<ToggleZoomEvent>(),
);

export const fetchCrossSellProduct = createAction(
    "[Product Page] Fetch Cross Sell Product",
);

export const fetchCrossSellProductSuccess = createAction(
    "[Product Page] Fetch Cross Sell Product Success",
    props<{ data: CrossSell }>(),
);

export const fetchCrossSellProductFailure = createAction(
    "[Product Page] Fetch Cross Sell Product Failure",
    props<{ error: Error }>(),
);

export const updateProductHeadTag = createAction(
    "[Product Page] update Product Head Tag",
    props<{ product: Product; productReference: string }>(),
);

export const sendPageviewAnalytics = createAction(
    "[Product Page] Send Pageview Analytics",
    props<{ product: Product }>(),
);

export const cleanProductState = createAction(
    "[Product Page] Clean Product State",
);
export const defaultImageGalleryPosition = createAction(
    "[Product Page] Default image gallery position",
);
export const resetVariantSelectedBoxType = createAction(
    "[Product Page] Reset boxType variant selected",
);
