import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ExternalLibraryEffects } from "./effects/external-library.effects";
import { ExternalLibraryFacade } from "./facade/external-library.facade";
import {
    EXTERNALLIBRARY_FEATURE_KEY,
    reducer,
} from "./reducer/external-library.reducer";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(EXTERNALLIBRARY_FEATURE_KEY, reducer),
        EffectsModule.forFeature([ExternalLibraryEffects]),
    ],
    providers: [ExternalLibraryFacade],
})
export class ExternalLibraryStateModule {}
