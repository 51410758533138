<h-input
    [formControl]="control"
    [controlName]="controlName"
    [describe]="describe"
    [id]="id"
    [label]="label"
    [name]="name"
    [placeholder]="placeholder"
    [inputType]="'email'"
    [autocomplete]="autocomplete"
    [errorManagement]="errorManagement"
    [customValidatorFunction]="validate"
    [dataTestId]="dataTestId"
    [ariaDescribedby]="ariaDescribedby"
    [setMinHeight]="setMinHeight">
    <p
        *ngIf="!control.disabled"
        extra-information
        class="extra-information font-information-text"
        i18n="@@hermes.accessibility.email-fields-example"
        >Expected format: yourname&#64;domain.com</p
    >
    <div *ngIf="errorManagement && controlHaveErrors()">
        <!-- Email control error  -->
        <h-message-block
            *ngIf="control.errors?.email"
            [showBackground]="false"
            [autoAddPadding]="false"
            [ariaRole]="undefined"
            type="error">
            <ng-container i18n="@@hermes-global-translations.validation.email">
                Oops, this doesn't seem quite right! Please enter a valid email, e.g. name&#64;domain.com
            </ng-container>
        </h-message-block>
    </div>
    <ng-content></ng-content>
</h-input>
