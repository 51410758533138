import { ProductGiftSetItem } from "@hermes/api-model-product";

import { GiftSetBoxState } from "./../reducers/giftset-product-page.state";

export const giftsetReference = (box: GiftSetBoxState): string => {
    const { allIds, byId } = box.selectedItems;
    return allIds.map((id) => byId[id].sku).join(" | ");
};
export const seoGiftsetReference = (
    availableGiftsetItems: ProductGiftSetItem[],
): string => {
    const GIFTSET_MICRODATA_MAX_COUNT = 4; // SEO : display only first four items in microdata
    const size = Math.min(
        availableGiftsetItems.length,
        GIFTSET_MICRODATA_MAX_COUNT,
    );

    return availableGiftsetItems
        .slice(0, size)
        .map((item) => item.sku)
        .join(" | ");
};
