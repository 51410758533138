import { GTMEventData } from "@hermes/utils/analytics";

import { SearchBarAnalyticsEvent } from "./search-bar.event";

/**
 * This class is used in order to send analytics events from search bar in header
 */
export class SearchEvent extends SearchBarAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMEventData {
        const eventaction = this.eventData.eventaction;
        const eventlabel = this.eventData.eventlabel;
        const eventvalue = this.eventData.eventvalue;
        return {
            ...super.formatForGTM(),
            eventaction: eventaction || undefined,
            eventlabel: eventlabel || undefined,
            eventvalue: eventvalue || undefined,
        };
    }
}
