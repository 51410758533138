import type { Ripe } from "ripe-sdk/src/js";

import { EMPTY_PLATFORME_MESSAGE } from "../constant/product-personalization.constant";
import { DkuConfiguration } from "../types/product-personalization.type";

/**
 * Init the DKU configuration generic attributes and return it
 */
export const initializeDkuConfiguration = (
    ripeInstance: Ripe,
    dkuConfiguration: DkuConfiguration,
): DkuConfiguration => {
    if (ripeInstance.initialsExtra && ripeInstance.initialsExtra.main) {
        if (ripeInstance.initialsExtra.main.initials) {
            dkuConfiguration.message =
                ripeInstance.initialsExtra.main.initials ===
                EMPTY_PLATFORME_MESSAGE
                    ? ""
                    : ripeInstance.initialsExtra.main.initials;
        }

        if (ripeInstance.initialsExtra.main.engraving !== "null") {
            return splitEngraving(
                ripeInstance.initialsExtra.main.engraving,
                dkuConfiguration,
            );
        }
    }
    return dkuConfiguration;
};

/**
 * Allow to clean and read the engraving
 */
export const splitEngraving = (
    mainEngraving: string,
    dkuConfiguration: DkuConfiguration,
): DkuConfiguration => {
    const splittedEngraving: string[] = mainEngraving
        .replace("::", ":")
        .split(".");

    splittedEngraving.forEach((element) => {
        if (element.startsWith("position:")) {
            dkuConfiguration.position = element.split(":").pop();
        }
        if (element.startsWith("font:")) {
            dkuConfiguration.font = element.split(":").pop();
        }
        if (element.startsWith("font_size:")) {
            dkuConfiguration.fontSize = element.split(":").pop();
        }
        if (element.startsWith("style:")) {
            dkuConfiguration.style = element.split(":").pop();
        }
    });
    return dkuConfiguration;
};
