import { trigger, transition, style, animate } from "@angular/animations";
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { Subscription } from "rxjs";

import { UserStateService } from "@hermes/states/user";
import { RouterService } from "@hermes/utils-generic/services/router";

@Component({
    selector: "h-account-creation-banner",
    templateUrl: "./account-creation-banner.component.html",
    styleUrls: [
        "./account-creation-banner.component.scss",
        "../banner-common.scss",
    ],
    animations: [
        trigger("slideDown", [
            transition(":enter", [
                style({ transform: "translateY(-100%)" }),
                animate("900ms ease-in", style({ transform: "translateY(0)" })),
            ]),
        ]),
    ],
})
export class AccountCreationBannerComponent implements OnInit, OnDestroy {
    @Input()
    public isAccountCreatedByWeChat: boolean = false;

    @Output()
    public closeBanner: EventEmitter<void> = new EventEmitter<void>();

    /**
     * User infos
     */
    public prefix?: string;
    public firstname?: string;
    public lastname?: string;

    private subscription = new Subscription();

    constructor(
        private routerService: RouterService,
        private userService: UserStateService,
    ) {}

    public ngOnInit(): void {
        const user = this.userService.getUser();
        this.prefix = user?.prefix;
        this.firstname = user?.firstname;
        this.lastname = user?.lastname;

        this.subscription.add(
            this.routerService.pageChanged$.subscribe(() => this.close()),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Trigger relevant closing method and emit output event
     */
    public close(): void {
        this.closeBanner.emit();
    }
}
