import { Inject, Injectable } from "@angular/core";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

@Injectable()
export class PlatformeUrlService {
    constructor(@Inject(LOCALE) private locale: Locale) {}

    // Allow to format the locale in the expected platforme format en_de
    public getPlatformeLocale(): string {
        return `${this.locale.langCode}_${this.locale.countryCode}`;
    }
}
