import { EnvironmentConfiguration, InfraMap, InfraSettings } from "./env-infra";

// in environment vars, urls are be separated by commas
const URL_SPLITTER = ",";

/**
 * The goal of this method is to provide the same "map" of backend hosts per frontend hosts as was initially setup.
 * But by extracting data from environment variables instead of
 */
export const createInfraMapFromEnv = (env: NodeJS.ProcessEnv): InfraMap => {
    const frontendHosts = env["RDR_FRONTEND_HOSTS"] || "http://localhost";
    const backendHosts = env["RDR_BACKEND_HOSTS"] || "/api";
    const backendSSRHosts = env["RDR_SSR_BACKEND_HOSTS"] || backendHosts;
    const assetsHosts = env["RDR_ASSETS_HOSTS"] || "//assets.hermes.com";

    const personalizationPlatformeUrl =
        env["RDR_PERSO_PLATEFORME_URL"] ||
        "https://ripe-core-stage.platforme.com/api";

    const environment = env["APP_ENV"] || "localhost";

    const datadomeKey =
        env["RDR_DATADOME_KEY"] || "028ED961A95E7551C859E3B2E0A5EF";

    const frontendArray = frontendHosts.split(URL_SPLITTER);
    const backendCSRArray = backendHosts.split(URL_SPLITTER);
    const backendSSRHostsArray = backendSSRHosts.split(URL_SPLITTER);
    const assetsArray = assetsHosts.split(URL_SPLITTER);

    // eslint-disable-next-line unicorn/no-array-reduce
    return frontendArray.reduce(
        (
            accumulator: Record<string, InfraSettings>,
            currentValue: string,
            currentIndex: number,
        ) => {
            const hostKey = currentValue.replace(/https?:\/\//, "");
            return {
                ...accumulator,
                [hostKey]: {
                    frontend: frontendArray[currentIndex],
                    backendCSR: backendCSRArray[currentIndex],
                    backendSSR: backendSSRHostsArray[currentIndex],
                    assets: assetsArray[currentIndex],
                    personalizationPlatformeUrl,
                    environment,
                    datadomeKey,
                } as InfraSettings,
            };
        },
        {},
    );
};

export const envConfigFactory = (
    env: NodeJS.ProcessEnv,
): EnvironmentConfiguration => ({
    appName: "RDR",
    appVersion: env["APP_VERSION"],
    environmentKey: env["APP_ENV"],
    gitBranch: env["BRANCH_NAME"] || "develop",
    gitCommit: env["GIT_COMMIT"] || "xxxxxxxx",
    hosts: createInfraMapFromEnv(env),
});
