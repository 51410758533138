import { Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";

import { Product } from "@hermes/api-model-product";

import { initializeDkuConfiguration } from "../../mappers/platforme-dku.mapper";
import {
    getRadioButtonModelFontColor,
    getRadioButtonModelFontSize,
    getRadioButtonModelFontStyle,
} from "../../mappers/product-personalization.mapper";
import {
    MarkingRule,
    RadioButtonItemModel,
    SilkConfigurator,
    PlatformeConfigResponse,
    BaseConfigurator,
    DkuConfiguration,
    RulesDescription,
} from "../../types/product-personalization.type";

import { MapPlatformeResponseService } from "./map-platforme-response.service";

@Injectable()
export class SilkMapPlatformeResponseService {
    private silkRegExp: RegExp =
        /[\w !"#$&'()*+,./:<=>?@[\\\]{|}~¡£¥«°´»¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïñòóôõö÷øùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİĲĳĴĵĶķĹĺĻļĿŀŁłŃńŅņŇňŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽž̇ẞ‘’“”„…‹›€−\-]/g;
    private gavrocheFamilyCode: string = "S10";
    private gavrocheMaxMarkingCharLength: number = 15;
    private silkMaxMarkingCharLength: number = 24;
    private fontStyleDisplayValue = $localize`:@@hermes-personalization-police-abc:ABC`;

    constructor(
        private mapPlatformeResponseService: MapPlatformeResponseService,
    ) {}

    /**
     * Construct Silk configurator
     */
    public initSilkConfigurator(
        platformeInitialConfiguration: PlatformeConfigResponse,
        ripeInstance: Ripe,
        firstProduct: Product | undefined,
    ): BaseConfigurator {
        let mappedSilkConfigurator: BaseConfigurator = {
            markingConfiguration: {
                message: "",
                markingRules: [],
            },
            engravingConfigurator: {
                fontConf: [],
                fontSizeConf: [],
                positionConf: [],
                styleConf: [],
            },
        };

        const dkuConfiguration = this.initConfigSilk(ripeInstance);

        // Generic mapping
        mappedSilkConfigurator.markingConfiguration.message =
            dkuConfiguration?.message ? dkuConfiguration.message : "";
        mappedSilkConfigurator =
            this.mapPlatformeResponseService.mapPlatformeProperties(
                dkuConfiguration,
                platformeInitialConfiguration.initials.properties,
                mappedSilkConfigurator,
            );
        mappedSilkConfigurator = this.mapPlatformeResponseService.mapLuckySigns(
            platformeInitialConfiguration.meta.lucky_signs,
            mappedSilkConfigurator,
        );

        // Silk mapping
        if (firstProduct) {
            mappedSilkConfigurator.markingConfiguration.markingRules =
                this.getSilkMarkingRules(firstProduct.familyCode);
        }

        return mappedSilkConfigurator;
    }

    /**
     * Allow to generate Silk marking rules
     */
    public getSilkMarkingRules(familyCode: string): MarkingRule[] {
        return [
            {
                ruleDescription: RulesDescription.GlobalPattern,
                maxOccurrence:
                    familyCode === this.gavrocheFamilyCode
                        ? this.gavrocheMaxMarkingCharLength
                        : this.silkMaxMarkingCharLength,
                regExp: this.silkRegExp,
                partielPattern: false,
            },
        ];
    }

    /**
     * Init the DKU configuration for silk
     */
    public initConfigSilk(ripeInstance: Ripe): DkuConfiguration {
        return initializeDkuConfiguration(ripeInstance, {});
    }
    /**
     * Allow to create a conf object per input
     */
    public convertMappedSilkConfiguratorToSilkConfigurator(
        mappedPlateformeConfigurator: BaseConfigurator,
    ): SilkConfigurator {
        const silkConfigurator = {} as SilkConfigurator;

        // MarkingConfiguration
        silkConfigurator.markingConfiguration =
            mappedPlateformeConfigurator.markingConfiguration;
        const engraving = mappedPlateformeConfigurator.engravingConfigurator;

        // Font style
        if (engraving?.styleConf && engraving.styleConf.length > 0) {
            engraving.styleConf = this.setStyleFontDefaultValue(
                engraving.styleConf,
                this.fontStyleDisplayValue,
            );
            silkConfigurator.fontStyle = getRadioButtonModelFontStyle(
                engraving.styleConf,
            );
        }

        // Font size
        if (engraving?.fontSizeConf && engraving.fontSizeConf.length > 0) {
            silkConfigurator.fontSize = getRadioButtonModelFontSize(
                engraving.fontSizeConf,
            );
        }

        // Font color
        if (engraving?.fontConf && engraving.fontConf.length > 0) {
            silkConfigurator.fontColor = getRadioButtonModelFontColor(
                engraving.fontConf,
                true,
            );
        }

        return silkConfigurator;
    }

    private setStyleFontDefaultValue(
        styleConfig: RadioButtonItemModel[],
        displayValue: string,
    ): RadioButtonItemModel[] {
        styleConfig.forEach((style: RadioButtonItemModel) => {
            style.name = displayValue || "";
        });
        return styleConfig;
    }
}
