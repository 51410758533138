import { HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";

import exportedSass from "@hermes/aphrodite/design-system-tools";
import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import { initializeDkuConfiguration } from "../../mappers/platforme-dku.mapper";
import {
    getRadioButtonModelElement,
    getRadioButtonModelFontColor,
    getRadioButtonModelFontSize,
    getRadioButtonModelFontStyle,
    mapColorsForSpecificLeather,
} from "../../mappers/product-personalization.mapper";
import {
    Exterior,
    Lining,
    Part,
    PlatformeConfigResponse,
    BeltkitsConfigurator,
    RadioButtonItemModel,
    RadioButtonType,
    BeltSizes,
    Buckles,
    Color,
    DkuConfigurationBeltkit,
    MappedBeltkitConfigurator,
    PartMaterial,
} from "../../types/product-personalization.type";

import { MapPlatformeResponseService } from "./map-platforme-response.service";

@Injectable()
export class BeltkitMapPlatformeResponseService {
    private defaultHexaColor = "#00459c";

    constructor(
        private mapPlatformeResponseService: MapPlatformeResponseService,
        @Inject(LOGGER) private logger: Logger,
    ) {}

    /**
     * Construct Beltkit Configurator
     */
    public initBeltkitConfigurator(
        platformeInitialConfiguration: PlatformeConfigResponse,
        ripeInstance: Ripe,
    ): MappedBeltkitConfigurator {
        let mappedBeltkitConfigurator: MappedBeltkitConfigurator = {
            leatherSku: "",
            markingConfiguration: {
                message: "",
                markingRules: [],
            },
            exteriorBeltColor: [],
            interiorBeltColor: [],
            buckles: [],
            sizes: [],
            engravingConfigurator: {
                fontConf: [],
                fontSizeConf: [],
                styleConf: [],
                positionConf: [],
            },
            luckySigns: {
                details: [],
            },
        };

        const dkuConfigurationBeltkit = this.initConfigBeltkit(ripeInstance);

        // Generic mapping
        mappedBeltkitConfigurator.markingConfiguration.message =
            dkuConfigurationBeltkit?.message
                ? dkuConfigurationBeltkit.message
                : "";
        mappedBeltkitConfigurator =
            this.mapPlatformeResponseService.mapPlatformeProperties(
                dkuConfigurationBeltkit,
                platformeInitialConfiguration.initials.properties,
                mappedBeltkitConfigurator,
            ) as MappedBeltkitConfigurator;
        mappedBeltkitConfigurator =
            this.mapPlatformeResponseService.mapLuckySigns(
                platformeInitialConfiguration.meta.lucky_signs,
                mappedBeltkitConfigurator,
            ) as MappedBeltkitConfigurator;

        // Beltkit specific mapping
        if (platformeInitialConfiguration?.defaults?.exterior?.material) {
            mappedBeltkitConfigurator.leatherSku =
                platformeInitialConfiguration.meta.sku_generic[
                    platformeInitialConfiguration.defaults.exterior.material
                ];
        }

        mappedBeltkitConfigurator = this.mapBeltKitColors(
            dkuConfigurationBeltkit.exterior
                ? dkuConfigurationBeltkit.exterior
                : platformeInitialConfiguration.defaults.exterior,
            dkuConfigurationBeltkit.lining
                ? dkuConfigurationBeltkit.lining
                : platformeInitialConfiguration.defaults.interior,
            platformeInitialConfiguration.parts,
            mappedBeltkitConfigurator,
        );

        mappedBeltkitConfigurator = this.mapBeltkitSizes(
            platformeInitialConfiguration.sizes,
            mappedBeltkitConfigurator,
            dkuConfigurationBeltkit,
        );

        mappedBeltkitConfigurator = this.mapBeltKitBuckles(
            platformeInitialConfiguration.meta.buckles,
            mappedBeltkitConfigurator,
            dkuConfigurationBeltkit,
        );

        return mappedBeltkitConfigurator;
    }

    /**
     * Allow to map beltkit colors
     */
    public mapBeltKitColors(
        defaultExterior: Exterior,
        defaultInterior: Lining | undefined,
        parts: Part[],
        configurator: MappedBeltkitConfigurator,
    ): MappedBeltkitConfigurator {
        parts.forEach((part: Part) => {
            if (defaultExterior?.color && part.name === PartMaterial.exterior) {
                configurator.exteriorBeltColor = mapColorsForSpecificLeather(
                    part.materials[0].colors,
                    defaultExterior.color,
                    true,
                    [],
                );
            }
            if (defaultInterior?.color && part.name === PartMaterial.interior) {
                configurator.interiorBeltColor = mapColorsForSpecificLeather(
                    part.materials[0].colors,
                    defaultInterior.color,
                    true,
                    [],
                );
            }
        });
        return configurator;
    }

    /**
     * Allow to map beltkit sizes { "one_size:female": [17], "one_size:male": [17] },
     */
    public mapBeltkitSizes(
        plateformefBucklesSizes: Record<string, number[]>,
        mappedBeltkitConfigurator: MappedBeltkitConfigurator,
        dkuConfig: DkuConfigurationBeltkit,
    ): MappedBeltkitConfigurator {
        if (Array.isArray(plateformefBucklesSizes["fr:belt:female"])) {
            plateformefBucklesSizes["fr:belt:female"].forEach((element) => {
                const beltSize: BeltSizes = {};
                beltSize.size = element.toString();
                beltSize.default = element.toString() === dkuConfig.beltSize;
                if (mappedBeltkitConfigurator.sizes) {
                    mappedBeltkitConfigurator.sizes.push(beltSize);
                }
            });
        } else {
            this.logger.warn(
                "please check platforme mapping, beltsizes not found !",
            );
        }
        return mappedBeltkitConfigurator;
    }

    /**
     * Allow to map beltkit buckles
     */
    public mapBeltKitBuckles(
        plateformefBuckles: Record<string, Record<string, string>>,
        configurator: MappedBeltkitConfigurator,
        dkuConfig: DkuConfigurationBeltkit,
    ): MappedBeltkitConfigurator {
        for (const property in plateformefBuckles) {
            if (
                configurator.buckles &&
                Object.prototype.hasOwnProperty.call(
                    plateformefBuckles,
                    property,
                )
            ) {
                const buckleValues: string[] = property.split(":");
                const dkuConfigBk: DkuConfigurationBeltkit = dkuConfig;
                const buckles: Buckles = {};

                buckles.id = property;
                buckles.name = plateformefBuckles[property]["name"];
                buckles.color = plateformefBuckles[property]["color"];
                buckles.sku = plateformefBuckles[property]["sku"];
                buckles.url = this.appendBgColorToURL(
                    plateformefBuckles[property]["url"],
                );
                buckles.default =
                    buckleValues[1] === dkuConfigBk?.buckle?.material &&
                    buckleValues[2] === dkuConfigBk?.buckle?.color;
                configurator.buckles.push(buckles);
            }
        }
        return configurator;
    }

    /**
     * Append a query param to an URL
     */
    public appendBgColorToURL(url: string): string {
        const separator = url.includes("?") ? "&" : "?";
        const httpParameters: HttpParams = new HttpParams();

        return `${url}${separator}${httpParameters
            .set(
                "bgColor",
                exportedSass.secondaryColors["color-secondary-light"]?.replace(
                    "#",
                    "",
                ),
            )
            .toString()}`;
    }

    /**
     * Init the DKU configuration for beltkits specific attributes and return it
     */
    public initConfigBeltkit(ripeInstance: Ripe): DkuConfigurationBeltkit {
        const size: RegExpExecArray | null = /\$s:(\d*)/.exec(ripeInstance.dku);
        const dkuConfiguration: DkuConfigurationBeltkit = {
            lining: ripeInstance.parts.interior,
            exterior: ripeInstance.parts.exterior,
            buckle: ripeInstance.parts.buckle,
            beltSize: size ? size[1] : undefined,
        };

        return initializeDkuConfiguration(ripeInstance, dkuConfiguration);
    }

    /**
     * Allow to create a conf object per input
     */
    public convertMappedBeltkitConfiguratorToBeltkitsConfigurator(
        mappedPlateformeConfigurator: MappedBeltkitConfigurator,
    ): BeltkitsConfigurator {
        const configurator: BeltkitsConfigurator = {};

        // Current band leather
        configurator.leatherSku = mappedPlateformeConfigurator.leatherSku;

        // MarkingConfiguration
        configurator.markingConfiguration =
            mappedPlateformeConfigurator.markingConfiguration;

        // Exterior color
        configurator.exteriorColor = getRadioButtonModelElement(
            true,
            RadioButtonType.Color,
            "exteriorColor",
            this.mapItems(
                mappedPlateformeConfigurator.exteriorBeltColor,
                RadioButtonType.Color,
                PartMaterial.exterior,
            ),
            undefined,
        );

        // Interior color
        configurator.interiorColor = getRadioButtonModelElement(
            true,
            RadioButtonType.Color,
            "interiorColor",
            this.mapItems(
                mappedPlateformeConfigurator.interiorBeltColor,
                RadioButtonType.Color,
                PartMaterial.lining,
            ),
            undefined,
        );

        // Buckles
        if (mappedPlateformeConfigurator.buckles) {
            configurator.buckle = getRadioButtonModelElement(
                true,
                RadioButtonType.Image,
                "buckle",
                this.mapItems(
                    mappedPlateformeConfigurator.buckles,
                    RadioButtonType.Image,
                ),
                undefined,
            );
        }

        // Belt size
        if (mappedPlateformeConfigurator.sizes) {
            configurator.beltSize = getRadioButtonModelElement(
                true,
                RadioButtonType.Text,
                "beltSize",
                this.mapItems(
                    mappedPlateformeConfigurator.sizes,
                    RadioButtonType.Text,
                ),
                undefined,
                false,
            );
        }

        // Font style
        if (
            mappedPlateformeConfigurator.engravingConfigurator?.styleConf &&
            mappedPlateformeConfigurator.engravingConfigurator?.styleConf
                .length > 0
        ) {
            configurator.fontStyle = getRadioButtonModelFontStyle(
                mappedPlateformeConfigurator.engravingConfigurator.styleConf,
            );
        }

        // Font size
        if (
            mappedPlateformeConfigurator.engravingConfigurator?.fontSizeConf &&
            mappedPlateformeConfigurator.engravingConfigurator.fontSizeConf
                .length > 0
        ) {
            configurator.fontSize = getRadioButtonModelFontSize(
                mappedPlateformeConfigurator.engravingConfigurator.fontSizeConf,
            );
        }

        // Font color
        if (
            mappedPlateformeConfigurator.engravingConfigurator?.fontConf &&
            mappedPlateformeConfigurator.engravingConfigurator.fontConf.length >
                0
        ) {
            configurator.fontColor = getRadioButtonModelFontColor(
                mappedPlateformeConfigurator.engravingConfigurator.fontConf,
            );
        }

        // Lucky signs
        configurator.luckySigns = mappedPlateformeConfigurator.luckySigns;
        return configurator;
    }

    /**
     * Allow to map configurator items colors, buckles and sizes
     */
    public mapItems(
        items: Color[] | Buckles[] | BeltSizes[],
        elementType: RadioButtonType,
        part?: PartMaterial,
    ): RadioButtonItemModel[] {
        const result: RadioButtonItemModel[] = [];

        items.forEach((item: Color | Buckles | BeltSizes) => {
            const radioButtonItem: RadioButtonItemModel =
                {} as RadioButtonItemModel;

            switch (elementType) {
                case RadioButtonType.Color: {
                    const currenColor: Color = item as Color;
                    radioButtonItem.id = `config_${part}_${currenColor.label}`;
                    radioButtonItem.value = currenColor.label;
                    radioButtonItem.name = currenColor.label;
                    radioButtonItem.color = this.defaultHexaColor;
                    break;
                }
                case RadioButtonType.Image: {
                    const currenImage: Buckles = item as Buckles;
                    radioButtonItem.id = currenImage.id ?? "";
                    radioButtonItem.value = currenImage.id ?? "";
                    radioButtonItem.src = currenImage.url;
                    radioButtonItem.name = currenImage.name ?? "";
                    radioButtonItem.color = currenImage.color;
                    radioButtonItem.sku = currenImage.sku;
                    break;
                }
                case RadioButtonType.Text: {
                    const currentSize: BeltSizes = item as BeltSizes;
                    radioButtonItem.id = currentSize.size ?? "";
                    radioButtonItem.value = currentSize.size ?? "";
                    radioButtonItem.name = currentSize.size ?? "";
                    break;
                }

                default: {
                    this.logger.warn(
                        `not have to map this property : ${elementType}`,
                    );
                }
            }
            radioButtonItem.disabled = false;
            radioButtonItem.default = item.default || false;
            result.push(radioButtonItem);
        });
        return result;
    }
}
