import {
    BuildVersion,
    DEVELOPMENT_VERSION,
    PRODUCTION_VERSION,
} from "@hermes/utils-generic/constants";

/**
 * @returns the build version (prod or dev)
 */
export const getBuildVersion = (environment: string): BuildVersion =>
    environment === "prd" ? PRODUCTION_VERSION : DEVELOPMENT_VERSION;
