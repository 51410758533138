export interface BoxSelectedItem {
    sku: string;
    image: string;
    title: string;
    hasError: boolean;
    status: BoxItemStatus;
    price: string;
}

export enum BoxItemStatus {
    /** Initialisation state do nothing and don't prevent fade in animation like FIXED */
    VOID = "void",
    /** First status of the item on it's creation */
    CREATION = "creation",
    /** Default item status occured at the end of all animation */
    FIXED = "fixed",
    /** When item change position, start the animation to exit the box */
    SLIDE_TO_EXIT = "slideToExit",
    /** Intermediate status when item change position and is not in is box anymore */
    EXITED = "exited",
    /** When item change position, start the animation to enter the box */
    SLIDE_TO_ENTER = "slideToEnter",
    /** Trigger deletion animation for this item */
    DELETED = "deleted",
    /** make deletion at the end of animation */
    DELETED_DONE = "deletedDone",
}

export interface GiftSetBoxSelectedItems {
    byId: {
        [id: string]: BoxSelectedItem;
    };
    allIds: string[];
}
