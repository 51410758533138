<header #headerContainer class="header-container" [attr.role]="'banner'">
    <ngx-loading-bar [color]="'#444444'" [height]="'5px'" [includeSpinner]="false" [fixed]="true"></ngx-loading-bar>
    <h-banners-top-container></h-banners-top-container>
    <h-seo-links *ngIf="menuFacade.links$ | async as seoLinks" [seoLinks]="seoLinks"></h-seo-links>

    <section #headerLinksContainer class="header-links-container">
        <h-header-links></h-header-links>
    </section>

    <!-- Background equal to scrolling part (fixed) -->
    <div class="header-background-fixed" [hidden]="state.scroll !== 'fixed'"></div>

    <!-- Header : scrolling part -->
    <div
        #headerMainContainer
        class="header-main-container"
        [ngClass]="{ 'header-main-container-fixed': state.scroll === 'fixed' }">
        <div class="header-main">
            <div class="header-main-content">
                <h-header-bar></h-header-bar>
            </div>
        </div>
    </div>
    <h-account-creation-banner
        *ngIf="isAccountCreated"
        [isAccountCreatedByWeChat]="isAccountCreatedByWechat"
        (closeBanner)="closeAccountCreationBanner()">
    </h-account-creation-banner>
</header>
