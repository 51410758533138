import { Product } from "@hermes/api-model-product";

export const appleWatchReference = (product: Product): string =>
    `${product.appleWatchAttributes?.skuGuizmo} | ${product.appleWatchAttributes?.skuStrap}`;
export const beltkitReference = (product: Product): string =>
    `${product.beltkitAttributes?.skuBuckle} | ${product.beltkitAttributes?.skuLeather}`;
export const bikiniReference = (product: Product): string => product.sku;
export const doublefragranceReference = (product: Product): string =>
    `${product.doubleFragranceAttributes?.skuPerfume} | ${product.doubleFragranceAttributes?.skuAccessory}`;
export const lookReference = (product: Product): string => product.sku;
export const simpleReference = (product: Product): string => product.sku;
