import {
    Product,
    ProductItemBikini,
    ProductItemLook,
    ProductStock,
} from "@hermes/api-model-product";

export const isOutOfStock = (stock: ProductStock): boolean =>
    !stock?.ecom && !stock?.retail;

export const isOnlineOnlyStock = (stock: ProductStock): boolean =>
    stock?.ecom && !stock?.retail;

export const isRetailOnlyStock = (stock: ProductStock): boolean =>
    !stock?.ecom && stock?.retail;

export const isAvailable = (stock: ProductStock): boolean => stock?.ecom;
export const isOnlineAndRetailStock = (stock: ProductStock): boolean =>
    stock?.ecom && stock?.retail;

/**
 * Return true if there is no stock at all. If sent from BCK anyway, it means that displayOnly attribute is true.
 */
export const isDisplayOnly = (stock: ProductStock): boolean =>
    stock.displayOnly === true && !stock.retail;

export function hasStrapVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.straps &&
        product.variants.straps.length > 0
    );
}

export function hasColorVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.colors &&
        product.variants.colors.length > 0
    );
}

export function hasSizeVariant(
    product: Product | ProductItemLook | ProductItemBikini,
): boolean {
    return (
        product.variants !== undefined &&
        product.variants.sizes &&
        product.variants.sizes.length > 0
    );
}

export function hasStrapSizeVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.strapSizes &&
        product.variants.strapSizes.length > 0
    );
}

export function hasVolumeVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.volumes &&
        product.variants.volumes.length > 0
    );
}

export function hasBuckleVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.buckles &&
        product.variants.buckles.length > 0
    );
}

export function hasLeatherVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.leathers &&
        product.variants.leathers.length > 0
    );
}

export function hasFinishVariant(product: Product): boolean {
    return (
        product.variants !== undefined &&
        product.variants.finishes &&
        product.variants.finishes.length > 0
    );
}
export function hasVariants(
    product: Product | ProductItemLook | ProductItemBikini,
): boolean {
    return product.variants !== undefined;
}

export function areAllSizeVariantsOutOfStock(
    product: Product | ProductItemLook | ProductItemBikini,
): boolean {
    return (
        product.variants !== undefined &&
        product.variants.sizes &&
        product.variants.sizes.every(
            (variant) =>
                variant.stock && !variant.stock.ecom && !variant.stock.retail,
        )
    );
}
