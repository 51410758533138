import { Inject, Injectable } from "@angular/core";

import { Context, WINDOW } from "@hermes/app-core";

import { AnalyticsEvent } from "../events/analytics.event";

/**
 * Send analytics event to google analytics.
 * The dataLayer is provided on window by the google tag manager script.
 */
@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    constructor(
        private context: Context,
        @Inject(WINDOW) private window: Window & { dataLayer: unknown[] },
    ) {}

    /**
     * If it's in not SSR, format then send event to GTM
     * If more provider is needed, event must be send here
     *
     * @param event event to format and send to each provider
     */
    public sendData<T>(event: AnalyticsEvent<T>): void {
        if (this.context.isInBrowserMode() && this.window.dataLayer) {
            // GTM Provider
            this.window.dataLayer.push(event.formatForGTM());
        }
    }

    public sendRawData<T>(analytics: T): void {
        if (this.context.isInBrowserMode() && this.window.dataLayer) {
            this.window.dataLayer.push(analytics);
        }
    }
}
