import { Inject, Injectable, OnDestroy } from "@angular/core";

import {
    Observable,
    map,
    switchMap,
    firstValueFrom,
    catchError,
    Subscription,
    Subject,
    combineLatest,
    of,
} from "rxjs";

import { COUNTRY, LOCALE } from "@hermes/app-core";
import { Country, Locale, isUnitedKingdom } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { AdyenApplePayPaymentMethod } from "@hermes/states/payment-methods";
import { ProcessingChannel } from "@hermes/utils/services/payment";
import { LOGGER } from "@hermes/web-logger";

import { ApplePayService } from "./apple-pay.service";

@Injectable()
export class ApplePaySessionService implements OnDestroy {
    public token$ = new Subject<ApplePayJS.ApplePayPayment>();

    private isUserConnected = false;
    private subscription = new Subscription();
    private processingChannel?: ProcessingChannel;

    constructor(
        @Inject(LOGGER) private logger: Logger,
        @Inject(LOCALE) private locale: Locale,
        @Inject(COUNTRY) private country: Country,
        private applePayService: ApplePayService,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public doApplePayWorkflow(
        processingChannel: ProcessingChannel,
    ): Observable<void> {
        this.logger.trace('"doApplePayWorkflow" started');
        this.processingChannel = processingChannel;

        return this.getApplePaySession().pipe(
            map((applePaySession) => applePaySession.begin()),
            catchError((error) => {
                this.logger.error(
                    "Apple Pay workflow failed",
                    error.message ?? error,
                );
                return of(undefined);
            }),
        );
    }

    public doApplePayLitePayment(): Observable<string> {
        return this.getLightApplePaySession().pipe(
            switchMap((applePaySession) =>
                this.prepareLightApplePaySession(applePaySession),
            ),
        );
    }

    private prepareLightApplePaySession(
        applePaySession: ApplePaySession,
    ): Observable<string> {
        const token$ = new Subject<string>();

        applePaySession.onpaymentauthorized = (
            event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
        ) => token$.next(btoa(JSON.stringify(event.payment.token.paymentData)));

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        applePaySession.oncancel = (event: ApplePayJS.Event) => {
            event.cancelBubble = true;
            token$.error(new Error("Apple Pay Session canceled"));
            applePaySession.abort();
        };

        applePaySession.begin();

        return token$;
    }

    private getLightApplePaySession(): Observable<ApplePaySession> {
        return this.initApplePayPaymentRequest().pipe(
            map((applePayPaymentRequest) =>
                this.createLightApplePaySession(applePayPaymentRequest),
            ),
        );
    }

    private getApplePaySession(): Observable<ApplePaySession> {
        return this.initApplePayPaymentRequest().pipe(
            map((applePayPaymentRequest) =>
                this.createFullApplePaySession(applePayPaymentRequest),
            ),
        );
    }

    private createLightApplePaySession(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest & {
            version: number;
        },
    ): ApplePaySession {
        const applePaySession = new ApplePaySession(
            applePayPaymentRequest.version,
            {
                ...applePayPaymentRequest,
                shippingMethods: undefined,
                total: { ...applePayPaymentRequest.total, type: "final" },
            },
        );

        applePaySession.onvalidatemerchant = (
            event: ApplePayJS.ApplePayValidateMerchantEvent,
        ) => this.openApplePayMerchantSession(event, applePaySession);

        return applePaySession;
    }

    private createFullApplePaySession(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest & {
            version: number;
        },
    ): ApplePaySession {
        this.addRequiredFieldsForExpressCheckout(applePayPaymentRequest);

        const applePaySession = new ApplePaySession(
            applePayPaymentRequest.version,
            applePayPaymentRequest,
        );

        applePaySession.onvalidatemerchant = (
            event: ApplePayJS.ApplePayValidateMerchantEvent,
        ) => this.openApplePayMerchantSession(event, applePaySession);

        applePaySession.onshippingcontactselected = (
            event: ApplePayJS.ApplePayShippingContactSelectedEvent,
        ) =>
            firstValueFrom(
                this.applePayService
                    .onShippingAddressChange(event.shippingContact)
                    .pipe(
                        map((updatedApplePayElements) =>
                            applePaySession.completeShippingContactSelection({
                                errors: updatedApplePayElements.applePayError
                                    ? [updatedApplePayElements.applePayError]
                                    : undefined,
                                newTotal: {
                                    ...updatedApplePayElements.total,
                                    type: "final",
                                },
                                newLineItems: updatedApplePayElements.items,
                                newShippingMethods:
                                    updatedApplePayElements.shippingMethods,
                            }),
                        ),
                        catchError(() => {
                            applePaySession.abort();
                            throw new Error("Apple Pay adress failed");
                        }),
                    ),
            );
        applePaySession.onshippingmethodselected = (
            event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
        ) =>
            firstValueFrom(
                this.applePayService
                    .onShippingOptionChange(event.shippingMethod)
                    .pipe(
                        map((updatedApplePayElements) =>
                            applePaySession.completeShippingMethodSelection({
                                newTotal: {
                                    ...updatedApplePayElements.total,
                                    type: "final",
                                },
                                newLineItems: updatedApplePayElements.items,
                            }),
                        ),
                        catchError(() => {
                            applePaySession.abort();
                            throw new Error("Apple Pay shipping method failed");
                        }),
                    ),
            );

        applePaySession.onpaymentauthorized = (
            event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
        ) => {
            this.token$.next(event.payment);
            applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
        };

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        applePaySession.oncancel = (event: ApplePayJS.Event) => {
            event.cancelBubble = true;
            applePaySession.abort();
        };

        return applePaySession;
    }

    private addRequiredFieldsForExpressCheckout(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest,
    ): void {
        applePayPaymentRequest.requiredShippingContactFields = [
            "phone",
            "postalAddress",
        ];
        if (!this.isUserConnected) {
            applePayPaymentRequest.requiredShippingContactFields.push("email");
        }
    }

    private initApplePayPaymentRequest(): Observable<
        ApplePayJS.ApplePayPaymentRequest & { version: number }
    > {
        return combineLatest([
            this.applePayService.getIsLoggedIn(),
            this.applePayService.getApplePayOptions(),
            this.applePayService.updateFromBasket(),
        ]).pipe(
            map(
                ([
                    isLoggedIn,
                    applePayPaymentMethodOptions,
                    { items, shippingMethods, total },
                ]) => {
                    this.isUserConnected = isLoggedIn;
                    return this.createRequest(
                        items,
                        shippingMethods,
                        total,
                        applePayPaymentMethodOptions,
                    );
                },
            ),
        );
    }

    private createRequest(
        items: ApplePayJS.ApplePayLineItem[],
        shippingMethods: ApplePayJS.ApplePayShippingMethod[],
        total: ApplePayJS.ApplePayLineItem,
        applePayPaymentMethodOptions: AdyenApplePayPaymentMethod,
    ): ApplePayJS.ApplePayPaymentRequest & { version: number } {
        const request: ApplePayJS.ApplePayPaymentRequest & { version: number } =
            {
                countryCode: isUnitedKingdom(this.locale)
                    ? "GB"
                    : this.locale.countryCode.toUpperCase(),
                currencyCode: this.country.currency,
                merchantCapabilities:
                    applePayPaymentMethodOptions.merchantCapabilities,
                supportedNetworks: applePayPaymentMethodOptions.brands,
                total,
                shippingMethods,
                lineItems: items,
                shippingType: "shipping",
                version: Number(applePayPaymentMethodOptions.version),
            };
        return request;
    }

    private openApplePayMerchantSession(
        event: ApplePayJS.ApplePayValidateMerchantEvent,
        applePaySession: ApplePaySession,
    ) {
        firstValueFrom(
            this.applePayService.onMerchantValidation(event.validationURL).pipe(
                map((merchantSession) =>
                    applePaySession.completeMerchantValidation(merchantSession),
                ),
                catchError(() => {
                    applePaySession.abort();
                    throw new Error("Apple Pay Merchant validation failed");
                }),
            ),
        );
    }
}
