import { GTMEventPromotionData } from "../types/gtm.type";

import { AnalyticsEvent } from "./analytics.event";

/**
 * Abstract class for promotion click event
 */
export abstract class PromotionClickAnalyticsEvent
    implements AnalyticsEvent<GTMEventPromotionData>
{
    /**
     * Event name expected for every promotion click event
     */
    public name: string = "promotionClick";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: { store: { name: string; position: number } };

    constructor(data: { store: { name: string; position: number } }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventPromotionData {
        return {
            event: this.name,
            ecommerce: {},
        };
    }
}
