<div
    class="dynamic-box-container"
    [ngClass]="{
    checked: checked && !imageSrc,
        'checked-image': imageSrc && checkedImageVariant,
        'hover-image': imageSrc,
    'input-container-fixed-width': hasFixedWidth,
    'unavailable': unavailable,
    }">
    <div class="box-text-content" [class.inactive-cursor-radio]="boxType === 'radio' || boxType === 'checkbox'">
        <ng-container *ngTemplateOutlet="contentToDisplay"></ng-container>
        <ng-container *ngIf="boxType === 'link'; else notLink">
            <ng-container *ngIf="isHref; else useRouterLink">
                <a
                    class="variant-link"
                    [href]="linkUrl"
                    [attr.title]="titleValue"
                    [attr.aria-current]="checkedImageVariant"
                    (click)="handleClick?.emit($event)">
                </a>
            </ng-container>
            <ng-template #useRouterLink>
                <a
                    class="variant-link"
                    [routerLink]="buildRouteArray"
                    [attr.title]="titleValue"
                    [attr.aria-current]="checkedImageVariant"
                    (click)="handleClick?.emit($event)">
                </a>
            </ng-template>
        </ng-container>
    </div>

    <ng-template #contentHidden>
        <ng-content select="[content-hidden]"></ng-content>
    </ng-template>
    <ng-template #notLink>
        <!-- hidden content -->
        <div [formGroup]="formGroup" class="input-content-hidden">
            <ng-container *ngTemplateOutlet="contentHidden"></ng-container>
            <div [title]="titleValue" data-nosnippet>
                <ng-container [ngSwitch]="boxType">
                    <input
                        *ngSwitchCase="'radio'"
                        type="radio"
                        [formControlName]="formName"
                        [value]="value"
                        [attr.name]="formName"
                        [attr.id]="inputId"
                        [checked]="checked"
                        [attr.required]="required"
                        [attr.aria-invalid]="inputAriaInvalid"
                        [attr.aria-describedby]="inputAriaDescribedby"
                        (change)="handleChange?.emit()"
                        (click)="handleClick?.emit($event)"
                        (focus)="handleFocus?.emit()"
                        (blur)="handleBlur?.emit()" />
                    <input
                        *ngSwitchCase="'checkbox'"
                        type="checkbox"
                        [formControlName]="formName"
                        [value]="value"
                        [attr.name]="formName"
                        [attr.id]="inputId"
                        [checked]="checked"
                        [attr.required]="required"
                        [attr.aria-invalid]="inputAriaInvalid"
                        [attr.aria-describedby]="inputAriaDescribedby"
                        (change)="handleChange?.emit()"
                        (click)="handleClick?.emit($event)"
                        (focus)="handleFocus?.emit()"
                        (blur)="handleBlur?.emit()" />
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>
<ng-container *ngIf="displayLabelWithImage">
    <div id="label-with-image" class="visual-text label" [attr.aria-hidden]="true">
        {{ labelValue }}
    </div>
</ng-container>

<ng-template #contentToDisplay>
    <div class="box-display">
        <ng-container *ngIf="!imageSrc; else imageDisplay">
            <div
                class="box-text-display"
                [ngClass]="{ unavailable: unavailable, 'background-color-white': backgroundColorWhite }">
                <span class="visual-text" [attr.aria-hidden]="true">
                    {{ labelValue }}
                </span>
            </div>
        </ng-container>
        <ng-template #imageDisplay>
            <div
                class="box-image-display"
                [class.box-image-display-checkbox]="boxType === 'checkbox' || forceStyleCheckbox"
                [attr.aria-hidden]="'true'"
                [class.black-overlay]="unavailable">
                <!-- In browser mode use lazy loading -->
                <div class="box-image-unavailable-icon svg-icon" [attr.aria-hidden]="'true'"></div>
                <h-svg-icon
                    *ngIf="unavailable"
                    name="diagonal"
                    class="box-image-unavailable-icon svg-icon"></h-svg-icon>
                <picture *ngIf="!isServerMode" class="picture">
                    <source
                        [media]="pageProductImageMediaqueries.tablet"
                        [srcset]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnMobile : true" />
                    <source
                        [media]="pageProductImageMediaqueries.desktop"
                        [srcset]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnDesktop : true" />
                    <img
                        [attr.width]="imageConfig?.width ?? 'auto'"
                        [attr.height]="imageConfig?.height ?? 'auto'"
                        class="box-image-responsive"
                        [src]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnDesktop : true"
                        (load)="handleImageLoaded()"
                        loading="lazy"
                        alt="" />
                </picture>
                <!-- In server mode use default image for SEO -->
                <div *ngIf="isServerMode" class="box-image-responsive">
                    <img [src]="imageSrc! | hImageUrl : imageConfigOnMobile : true" alt="" />
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
