export const EditorialMediaQueryConstants = {
    xsmall: "(min-width: 320px) and (max-width: 414px)",
    small: "(min-width: 415px) and (max-width: 768px)",
    medium: "(min-width: 769px) and (max-width: 1024px)",
    large: "(min-width: 1025px) and (max-width: 1280px)",
    xlarge: "(min-width: 1281px) and (max-width: 1920px)",
    xxlarge: "(min-width: 1921px)",
};

export enum PageProductImageMediaqueries {
    mobile = "(max-width: 414px)",
    tablet = "(max-width: 768px)",
    desktop = "(max-width: 1281px)",
    large = "(max-width: 1921px)",
    isNotMobile = "(min-width: 415px)",
}

export const ContentsSizesConstants = {
    PRODUCT_IN_GRID_MOBILE: 400,
    PRODUCT_IN_GRID_DESKTOP: 700,
    HERO_PRODUCT_IN_GRID_DESKTOP: 800,
    PRODUCT_PAGE: 800,
    GIFTSET_PAGE: 1350,
    SNACKABLE_VIDEO: {
        xsmall: 358,
        small: 768,
        medium: 923,
        large: 923,
        xlarge: 1280,
        xxlarge: 1920,
    },
    PUSH_SNACKABLE_VIDEO_STANDARD: {
        xsmall: 358,
        small: 768,
        medium: 923,
        large: 923,
        xlarge: 1280,
        xxlarge: 1920,
    },
    PRODUCT_LOCATOR: {
        medium: 100,
    },
    ACCOUNT_ERESERVATIONS: {
        medium: 120,
    },
    DEFAULT: {
        xsmall: 414,
        small: 768,
        medium: 1024,
        large: 1280,
        xlarge: 1920,
        xxlarge: 3840,
    },
};

export const DEFAULT_MAX_VISIBLE_IMAGES_ON_PRODUCT_PAGE = 5;
