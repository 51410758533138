export const BACK_IN_STOCK_UNSUBSCRIBE_TECHNICAL_ERROR =
    "back_in_stock_unsubscribe_technical_error";
export const BACK_IN_STOCK_UNSUBSCRIBE_ALREADY_UNSUBSCRIBED =
    "back_in_stock_unsubscribe_already_unsubscribed";
export const BACK_IN_STOCK_UNSUBSCRIBE_OK = "back_in_stock_unsubscribe_ok";

export type BackInStockUnsubscriptionStatus =
    | typeof BACK_IN_STOCK_UNSUBSCRIBE_TECHNICAL_ERROR
    | typeof BACK_IN_STOCK_UNSUBSCRIBE_ALREADY_UNSUBSCRIBED
    | typeof BACK_IN_STOCK_UNSUBSCRIBE_OK;
