import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { Product } from "@hermes/api-model-product";
import { addToCartFailure } from "@hermes/states/basket";

import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import {
    changeButtonEnabledState,
    changeEcomErrorState,
    closeNotificationDisplayed,
    initAddToCartCallToAction,
    setAddToCartCallToActionsToDefault,
} from "../actions/add-to-cart.actions";
import {
    AddToCartState,
    AddToCartStateBasket,
    ButtonEnabled,
    ErrorCodeError,
} from "../add-to-cart.state";
import {
    selectAddToCartBasket,
    selectAddToCartButton,
    selectAddToCartEcomError,
    selectAddToCartLoading,
    selectAddToCartPositionCLicked,
} from "../selectors/add-to-cart.selectors";

@Injectable()
export class AddToCartFacade {
    public addToCartBasket$: Observable<AddToCartStateBasket> =
        this.store.select(selectAddToCartBasket);
    public isAddToCartLoading$ = this.store.select(selectAddToCartLoading);
    public addToCartPositionClicked$ = this.store.select(
        selectAddToCartPositionCLicked,
    );

    constructor(private store: Store<AddToCartState>) {}

    public changeButtonEnabledState(
        itemPosition: number,
        enabled: boolean,
    ): void {
        this.store.dispatch(
            changeButtonEnabledState({ itemPosition, enabled }),
        );
    }
    public changeEcomErrorState(
        itemPosition: number,
        ecomErrorEnabled: boolean,
        ecomErrorCode: string,
    ): void {
        this.store.dispatch(
            changeEcomErrorState({
                itemPosition,
                ecomErrorEnabled,
                ecomErrorCode,
            }),
        );
    }

    public addToCartButton$: (position: number) => Observable<ButtonEnabled> = (
        position: number,
    ) => this.store.select(selectAddToCartButton(position));

    public closeNotification(): void {
        this.store.dispatch(closeNotificationDisplayed());
    }

    public isAddToCartButtonEnabled = (
        positions: number[],
    ): Observable<boolean> =>
        combineLatest(
            positions.map((position) =>
                this.addToCartButton$(position).pipe(
                    map((buttonEnabled) => buttonEnabled?.enabled),
                ),
            ),
        ).pipe(
            map((positionEnabledArray) =>
                positionEnabledArray.reduce(
                    (accumulator, value) => accumulator && value,
                    true,
                ),
            ),
        );

    public selectAddToCartEcomError(
        positions: number[],
    ): Observable<ErrorCodeError> {
        return combineLatest(
            positions.map((position) =>
                this.store.select(selectAddToCartEcomError(position)),
            ),
        ).pipe(
            map((errorCodeErrorArray) =>
                errorCodeErrorArray.reduce(
                    (accumulator, value) =>
                        accumulator?.enabled === true
                            ? accumulator
                            : {
                                  enabled: value?.enabled,
                                  errorCode: value?.errorCode ?? undefined,
                              },
                    {
                        enabled: false,
                    },
                ),
            ),
        );
    }

    public setAddToCartCallToActionsToDefault(position: number) {
        return this.store.dispatch(
            setAddToCartCallToActionsToDefault({ position }),
        );
    }

    public initAddToCartCallToAction(data: Product, isVariant: boolean) {
        return this.store.dispatch(
            initAddToCartCallToAction({ data, isVariant }),
        );
    }

    public addToCartFailure(
        ecomErrorCodes: EcomErrorCodes,
        itemPosition: number,
        context?: string,
    ) {
        this.store.dispatch(
            addToCartFailure({
                error: { internal_code: ecomErrorCodes },
                itemPosition,
                context,
            }),
        );
    }
}
