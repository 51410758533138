export const pspCardType: Record<string, string> = {
    visa: "Visa",
    amex: "American Express",
    mc: "Mastercard",
    jcb: "JCB",
    diners: "Diners Club",
    discover: "Discover",
    maestro: "Maestro",
    cartebancaire: "Carte bancaire",
    dankort: "Dankort",
    eftpos_au: "EFTPOS",
};

export const CARD_NUMBER = "card-number";
