import { AnalyticsEvent, GTMPageViewBase } from "@hermes/utils/analytics";

/**
 * This class is used when the 404 Error Page is displaying
 */
export class NotFoundPageViewEvent implements AnalyticsEvent<GTMPageViewBase> {
    /**
     * Event name expected for every impression event
     */
    public name: string = "pageview";

    /**
     * Event's data from component to format correctly impression event
     */
    public eventData: GTMPageViewBase;

    constructor(data: Record<string, string>) {
        this.eventData = data as unknown as GTMPageViewBase;
    }

    /**
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMPageViewBase {
        const {
            pagename,
            languagecode,
            countrycode,
            buildversion,
            devicetype,
            userId,
            clienttype,
            clientgender,
            pageTranslate,
            previousPage,
        } = this.eventData;
        return {
            event: this.name,
            pagename,
            languagecode,
            countrycode,
            buildversion,
            devicetype,
            userId,
            clienttype,
            clientgender,
            pagetype: "404 error page",
            pageTranslate,
            previousPage,
        };
    }
}
