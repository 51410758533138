<div class="suggestion-container" [@animateContainer]="getSuggestionsState()">
    <div class="search-suggestions" [@animateSuggestions]="getSuggestionsState()">
        <p
            *ngIf="suggestions && suggestions.length > 0"
            id="search-suggestions-label"
            class="label"
            [attr.role]="'status'">
            <!-- Screenreader : Enforce reading this element every time suggestions are refreshed by removing and bringing back the element -->
            <ng-container *hRedraw="suggestions">
                <ng-container i18n="@@hermes-global-translations.search-suggestions"> Suggested searches </ng-container>
                <span hSrOnlyNoSnippet
                    >, {{ suggestions.length }}
                    <ng-container *ngIf="suggestions.length < 2; else pluralSuggestions">
                        <ng-container i18n="@@hermes.accessibility.item">item</ng-container>
                    </ng-container>
                    <ng-template #pluralSuggestions>
                        <ng-container i18n="@@hermes.accessibility.items">items</ng-container>
                    </ng-template>
                </span>
            </ng-container>
        </p>
        <p
            *ngIf="suggestions?.length === 0"
            id="search-suggestions-label"
            class="label"
            [attr.role]="'status'"
            i18n="@@hermes-global-translations.no-suggestions">
            No suggested searches
        </p>
        <ul
            [attr.role]="'listbox'"
            id="header-search-suggestions"
            class="search-suggestion-details"
            [attr.aria-labelledby]="suggestions ? 'search-suggestions-label' : null">
            <ng-container *ngIf="suggestions">
                <li
                    *ngFor="let suggestion of suggestions; let i = index"
                    [id]="i | suggestionItem"
                    class="search-suggestion-item"
                    [class.focused]="i === selected"
                    [attr.role]="'option'"
                    [attr.aria-selected]="i === selected"
                    (mousedown)="redirect(suggestion, i + 1)">
                    <span
                        class="search-suggestion-label"
                        [innerHTML]="suggestion.label | strong : searchTerm : 'presentation'"></span>
                    <ng-container *ngIf="suggestion.quantity && suggestion.quantity >= 0">
                        <span class="search-suggestion-label" [attr.aria-hidden]="'true'">
                            ({{ suggestion.quantity }})</span
                        >
                        <span hSrOnlyNoSnippet
                            >, {{ suggestion.quantity }}
                            <ng-container *ngIf="suggestion.quantity && suggestion.quantity < 2; else pluralItems">
                                <ng-container i18n="@@hermes.accessibility.product">item</ng-container>
                            </ng-container>
                            <ng-template #pluralItems>
                                <ng-container i18n="@@hermes.accessibility.products">items</ng-container>
                            </ng-template>
                        </span>
                    </ng-container>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
