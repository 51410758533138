import { persoAnalyticsConstant } from "./perso-analytics.constants";

export const BELTKIT_ANALYTICS_CATEGORY = "BeltPersoPage";

export const SIZE_GUIDE_ACTION_NAME = "Size_Guide";

export const BELTKITS_EXPANDS_ANALYTICS: {
    [key: string]: { action: string; label?: string };
} = {
    exteriorColor: {
        action: persoAnalyticsConstant.color1Selector,
        label: persoAnalyticsConstant.clickColorMenu,
    },
    interiorColor: {
        action: persoAnalyticsConstant.color2Selector,
        label: persoAnalyticsConstant.clickColorMenu,
    },
    marking: {
        action: persoAnalyticsConstant.markingSelector,
        label: persoAnalyticsConstant.clickMarkingMenu,
    },
    font: {
        action: persoAnalyticsConstant.typographySelector,
        label: persoAnalyticsConstant.clickTypographyMenu,
    },
    fontStyle: { action: persoAnalyticsConstant.typographySelector },
    fontColor: { action: persoAnalyticsConstant.typographySelector },
    beltSize: {
        action: persoAnalyticsConstant.sizeSelector,
        label: persoAnalyticsConstant.clickSizeMenu,
    },
    buckle: {
        action: persoAnalyticsConstant.buckleSelector,
        label: persoAnalyticsConstant.clickBuckleMenu,
    },
};

export const BK_ANALYTICS_PRODUCT_TYPE = "beltkits";
