import { APP_BASE_HREF, PlatformLocation } from "@angular/common";
import { NgModule, TransferState } from "@angular/core";
import { HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import {
    ENV_CONFIG,
    HTTP_REQUEST_TIMEOUT,
    REQUEST,
    RESPONSE,
} from "@hermes/app-core";
import { LogConfig, LogLevel } from "@hermes/logger";
import { BrowserTranslationLoader, browserEnvConfig } from "@hermes/web-custom";
import { LOGGER_CONFIG } from "@hermes/web-logger";
import { WebVitalsModule } from "@hermes/web-vitals";

import { AppComponent } from "app/app.component";
import { AppModule } from "app/app.module";

import { environment } from "environments/environment";

@NgModule({
    imports: [
        AppModule,
        BrowserAnimationsModule,
        HammerModule,
        WebVitalsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: BrowserTranslationLoader,
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: REQUEST,
            useValue: undefined,
        },
        {
            provide: RESPONSE,
            useValue: undefined,
        },
        {
            provide: ENV_CONFIG,
            useFactory: browserEnvConfig,
            deps: [TransferState],
        },
        {
            provide: APP_BASE_HREF,
            useFactory: (platformLocation: PlatformLocation) =>
                platformLocation.getBaseHrefFromDOM().slice(0, -1),
            deps: [PlatformLocation],
        },
        {
            provide: LOGGER_CONFIG,
            useValue: {
                level: environment.production ? LogLevel.Emerg : LogLevel.Trace,
                appenders: {
                    console: { type: "console" },
                },
            } as LogConfig,
        },
        // provide a big request timeout on the client side for slow connections.
        {
            provide: HTTP_REQUEST_TIMEOUT,
            useValue: 180_000,
        },
    ],
})
export class AppBrowserModule {}
