/* eslint-disable no-console */
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppBrowserModule } from "app/app.browser.module";

// import hammer here, @angular/platform-browser provides binding events.
import "hammerjs";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    platformBrowserDynamic()
        .bootstrapModule(AppBrowserModule)
        .catch((error) => console.error(error));
};

if (document.readyState === "complete") {
    bootstrap();
} else {
    document.addEventListener("DOMContentLoaded", bootstrap);
}
