import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

import { CustomerService } from "@hermes/api-model-editorial";

export const fetchCustomerServiceContactsSuccess = createAction(
    "[CustomerServiceContact] Fetch CustomerServiceContacts Success",
    props<{ customerServiceContacts: CustomerService[] }>(),
);

export const fetchCustomerServiceContacts = createAction(
    "[CustomerServiceContact] Fetch CustomerServiceContacts",
);

export const fetchCustomerServiceContactsFailure = createAction(
    "[CustomerServiceContact] Fetch CustomerServiceContacts Failure",
    props<{ error: HttpErrorResponse }>(),
);
