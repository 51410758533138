import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { BasketStateModule } from "@hermes/states/basket";

import { UserEffects } from "./effects/user.effects";
import { UserFacade } from "./facades/user.facade";
import { reducer, userFeatureKey } from "./reducers/user.reducer";
import { UserStateService } from "./services/user-state.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(userFeatureKey, reducer),
        EffectsModule.forFeature([UserEffects, UserStateService]),
        BasketStateModule,
    ],
    providers: [UserFacade],
})
export class UserStateModule {}
