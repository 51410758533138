import { Component, Input, ViewChild, OnInit, forwardRef } from "@angular/core";
import {
    ControlContainer,
    AbstractControl,
    ValidationErrors,
} from "@angular/forms";

import { IConfig } from "ngx-mask";

import { NO_EMOJIS } from "@hermes/utils/helpers";

import { BaseInputComponent } from "../base-input/base-input.component";
import { InputComponent } from "../input/input.component";

/**
 * This component implements a global Password Input Component. It also implements a floating label (like a mat-label - Material Angular).
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * You can desactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-password-input
 *      [controlName]="'password'"
 *      [id]="'password-id'"
 *      [name]="'password'"
 *      [autocomplete]="'new-password'"
 *      label="'My label translated into english'"
 *      i18n-label="@@my.key.translate"
 *      placeholder="'My placeholder translated into english'"
 *      i18n-placeholder="@@my.key.translate"
 *      describe="'My describe'"
 *      i18n-describe="@@my.key.translate"
 *      [errorManagement]="true"
 *      [ariaDescribedby]="id-label">
 *
 *      <h-message-block *ngIf="serviceError" type='error'>
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *
 *
 *  </h-password-input>
 * ```
 *
 */

@Component({
    selector: "h-password-input",
    templateUrl: "./password-input.component.html",
    styleUrls: ["./password-input.component.scss"],
})
export class PasswordInputComponent
    extends BaseInputComponent
    implements OnInit
{
    /**
     * Activate or not the show/hide button feature. Default is true.
     */
    @Input()
    public seeShowLink: boolean = true;

    /**
     * Display or not the required criterias for strong password. Default is false.
     */
    @Input()
    public seeRequiredCriterias: boolean = false;

    /**
     * Direct reference to the "autocomplete" attribut of the input. Default is "new-password".
     */
    @Input()
    public override autocomplete = "new-password";

    @Input()
    public dataTestId?: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    @ViewChild(forwardRef(() => InputComponent))
    public input!: InputComponent;

    public isPasswordVisible = false;
    public mask: string = "";
    public maskPattern?: IConfig["patterns"];

    public isEmpty: boolean = true;
    public hasMinLength: boolean = false;
    public hasUpperCase: boolean = false;
    public hasLowerCase: boolean = false;
    public hasNumeric: boolean = false;
    public hasSpecial: boolean = false;

    constructor(protected override controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        if (this.control.value && this.seeRequiredCriterias) {
            this.updateControls(this.control.value);
        }

        this.maskPattern = {
            P: {
                pattern: new RegExp(NO_EMOJIS),
                optional: true,
            },
        };
        this.mask = "P{128}";
    }

    public handleInput(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.updateControls(value);
    }

    public updateControls(value: string) {
        if (!value) {
            this.isEmpty = true;
            return;
        }

        this.isEmpty = false;
        this.hasMinLength = value.length >= 10;
        this.hasUpperCase = /[A-Z]+/.test(value);
        this.hasLowerCase = /[a-z]+/.test(value);
        this.hasNumeric = /\d+/.test(value);
        this.hasSpecial = /[^\d\sA-Za-z]+/.test(value);
    }

    public override focusInput(): void {
        this.input.focusInput();
    }

    public validate = (control: AbstractControl): ValidationErrors | null => {
        // The lib expects explicitly null
        /* eslint-disable unicorn/no-null */
        if (!this.seeRequiredCriterias || !control.value) {
            return null;
        }

        const passwordValid =
            !this.isEmpty &&
            this.hasMinLength &&
            this.hasUpperCase &&
            this.hasLowerCase &&
            this.hasNumeric &&
            this.hasSpecial;

        return passwordValid ? null : { passwordStrength: true };
        /* eslint-enable unicorn/no-null */
    };
}
