import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { v4 } from "uuid";

import { Context, WINDOW } from "@hermes/app-core";
import { TrayData, TrayFacade, TrayStackService } from "@hermes/states/tray";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { FormService } from "@hermes/utils-generic/services/user-interface";

import {
    CLOSE_TRAY_ANIMATION_TIME,
    TRAY_CLOSE,
    TRAY_CLOSE_ALL,
    TRAY_OPEN,
    TRAY_UPDATE,
    TRAY_UPDATE_TITLE_ON_ERROR,
} from "./drupal-tray.constant";
import { DrupalTraySettings } from "./drupal-tray.settings";

export interface DrupalTrayEvent {
    trayEvent: string;
    trayData: unknown;
}

@Injectable()
export class DrupalTrayService implements OnDestroy {
    private subscription = new Subscription();

    constructor(
        @Inject(WINDOW) window: Window,
        @Inject(DOCUMENT) private document: Document,
        private trayStackService: TrayStackService,
        private drupalTraySettings: DrupalTraySettings,
        private context: Context,
        private formService: FormService,
        private trayFacade: TrayFacade,
    ) {
        if (this.context.isInBrowserMode()) {
            window.addEventListener(
                "drupalTrayEvent",
                (event: CustomEvent<DrupalTrayEvent>) => {
                    this.handleMessageEvent(event);
                },
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Returns scripts that are common to all trays
     */
    public commonDrupalTrayScript(drupalTrayName: string): string {
        return `
            initDrupalTray();
        
            var closeButton = initCloseButtons();
            if (!closeButton) {
                return;
            }
        
            initTrayButtons();
        
            setAccessibilityAttributes(closeButton);
        
            function initDrupalTray() {
                document.querySelector("html").style.overflow = "hidden";
                // Needed to lock focus inside tray
                APP.pubsub.publish("trap-focus", "tray-${drupalTrayName}");
        
                // Needed for drupal analytics to be sent through angular
                window.dataLayer = window.parent.dataLayer;
        
                // Manage focus on tray opening with style
                if (window.parent.document.getElementsByClassName("accessibility-wrapper")[0] && window.parent.document.getElementsByClassName("accessibility-wrapper")[0].classList.contains("keyboard-navigation")) {
                    window.document.body.classList.add("keyboard-nav");
                }

                // Manage tray closure with escape key (keyCode 27)
                window.onkeydown = function(event) {
                    if (event.keyCode === 27) { 
                        eventTray(event, "close");
                    }
                };
            }
        
            function initCloseButtons() {
                var closeButton = document.querySelector(".button-tray.tray-close");
                if (closeButton) {
                    closeButton.focus();
                    bindTrayButton(closeButton, "close");
                }
        
                return closeButton;
            }
        
            function initTrayButtons() {
                var trayButtons = [].slice.call(
                    document.querySelectorAll("[data-tray-trigger]")
                );
                trayButtons.forEach(function(trayButton) {
                    var triggerTray = trayButton.getAttribute("data-tray-trigger");
                    var eventKey = "open:" + triggerTray;
                    bindTrayButton(trayButton, eventKey);
                });
            }
        
            function bindTrayButton(button, eventKey, eventData) {
                button.addEventListener("click", function(event) {
                    eventTray(event, eventKey, eventData);
                });
                button.addEventListener("keydown", function(event) {
                    if (event.key === 'Enter' || event.key === ' ') {
                        eventTray(event, eventKey, eventData);
                    }
                });
            }
        
            function eventTray(e, eventKey, eventData) {
                e.stopPropagation();
                e.preventDefault();

                if (eventKey.indexOf("-angular") < 0) {
                    hideScrollbar(eventKey);
                    
                    const drupalTrayEvent = new CustomEvent('drupalTrayEvent', {
                        detail: {
                            trayEvent: eventKey,
                            trayData: eventData,
                        }
                    });
                    window.parent.dispatchEvent(drupalTrayEvent);
                } else {
                    APP.pubsub.publish(eventKey, eventData);
                }
            }
        
            function hideScrollbar(eventKey) {
                var trayScrollWrapTag = document.getElementById("tray-scroll-wrap");
                var htmlTag = document.querySelector("html");
                if (eventKey !== "close" && trayScrollWrapTag && htmlTag) {
                    trayScrollWrapTag.style.overflow = "hidden";
                    htmlTag.style.overflow = "hidden";
                }
            }
        
            // Sets accessibility attributes including the design pattern ARIA Dialog (Modal)
            function setAccessibilityAttributes(closeButton) {
                var currentTitleTray;
                var currentTray;

                currentTitleTray = closeButton.getAttribute("aria-describedby");
                if (currentTitleTray) {
                    currentTray = document.getElementById(
                        currentTitleTray.split("title-")[1]
                    );
                }
                if (currentTray) {
                    currentTray.setAttribute("aria-labelledBy", currentTitleTray);
                    currentTray.setAttribute("aria-modal", "true");
                    currentTray.setAttribute("aria-hidden", "false");
                    currentTray.setAttribute("role", "dialog");
                }
            }
        `;
    }

    /**
     * Returns styles that are common to all trays
     */
    public commonDrupalTrayStyle(): string {
        return `
            .text-spacing * { 
                line-height: 1.5 !important; 
                letter-spacing: 0.12em !important;
                word-spacing: 0.16em !important;
            } 
            .text-spacing p {
                margin-bottom: 2em !important;
            }
        `;
    }

    /**
     * Listener on Message Event
     *
     * @param event Message Event fired
     */
    private handleMessageEvent(event: CustomEvent<DrupalTrayEvent>) {
        const drupalTrayEventData = event.detail;

        if (!drupalTrayEventData || !drupalTrayEventData.trayEvent) {
            return;
        }

        stopEventPropagation(event);

        const eventData = drupalTrayEventData.trayData as Record<
            string,
            unknown
        >;
        const [action, eventName]: string[] =
            drupalTrayEventData.trayEvent.split(":");
        switch (action) {
            case TRAY_OPEN: {
                this.onOpenTray(eventName, eventData);
                break;
            }
            case TRAY_CLOSE: {
                this.onCloseTray();
                break;
            }
            case TRAY_CLOSE_ALL: {
                this.onCloseAllTrays();
                break;
            }
            case TRAY_UPDATE: {
                this.onUpdateTray(eventName, eventData);
                break;
            }
            case TRAY_UPDATE_TITLE_ON_ERROR: {
                this.onUpdateTrayTitleOnError(eventData.errorOnForm as boolean);
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * Retrieve configuration and open tray
     *
     * @param trayName Tray identifier
     * @param eventData Additional data to forward after opening
     */
    public onOpenTray(
        trayName: string,
        eventData: Record<string, unknown>,
    ): void {
        const trayToLoad: TrayData = {
            uuid: v4(),
            hasOverlay: true,
            hasCategoryInstitutional: false,
            isOpen: true,
            position: "right",
            titleKey: trayName,
            name: trayName,
            isDrupalTray: true,
            ...this.getDrupalTraySettings(trayName, eventData),
        };

        // Avoid duplicate
        if (!this.isTrayLoadedOnTop(trayToLoad)) {
            this.trayFacade.openTray(trayToLoad);
        }
    }

    /**
     * Retrieve DrupalTray specific settings (localstorage usage, customs behaviors, ...)
     *
     * @param trayName Tray identifier
     * @param eventData Additional data to forward after opening
     */
    private getDrupalTraySettings(
        trayName: string,
        eventData: Record<string, unknown>,
    ): Partial<TrayData> {
        const drupalTraySettingsClosure =
            this.drupalTraySettings.SETTINGS[trayName];
        if (drupalTraySettingsClosure) {
            return drupalTraySettingsClosure(eventData);
        }
        return {};
    }

    /**
     * Compare parameter with the current loaded tray (displayed to the user).
     *
     * @param tray Tray to compare with the current loaded tray
     */
    private isTrayLoadedOnTop(tray: TrayData): boolean {
        const loadedTray = this.trayStackService.getTrayOnTop();
        if (loadedTray) {
            return tray.name === loadedTray.name;
        }
        return false;
    }

    /**
     * Close tray and open previous tray if exists (close overlay otherwise).
     * - Set focus on previous tray
     * - Re-enable scrollbar on previous tray
     */
    public onCloseTray(): void {
        this.trayFacade.closeTray();

        const previousTray = this.trayStackService.getTrayOnTop();
        if (previousTray && previousTray.isDrupalTray) {
            const traySelector = previousTray.componentFactory.selector;
            const iframeDocument: Document =
                this.document.querySelector<HTMLIFrameElement>(
                    `${traySelector} .drupal-iframe`,
                ).contentDocument;

            this.focusCloseButton(iframeDocument);
            this.enableScrollBar(iframeDocument);
        }
    }

    /**
     * Close all trays
     */
    private onCloseAllTrays() {
        this.trayStackService.closeAllTrays();
    }

    /**
     * Reset focus on the tray's close button
     */
    private focusCloseButton(drupalTrayIFrameDocument: Document): void {
        const buttonCloseTray: HTMLButtonElement =
            drupalTrayIFrameDocument.querySelector(".button-tray.tray-close");
        if (buttonCloseTray) {
            buttonCloseTray.focus();
        }
    }

    /**
     * Enable scrollbar after closing animation time
     */
    private enableScrollBar(drupalTrayIFrameDocument: Document): void {
        const trayScrollWrap: HTMLElement =
            drupalTrayIFrameDocument.getElementById("tray-scroll-wrap");
        this.subscription.add(
            timer(CLOSE_TRAY_ANIMATION_TIME).subscribe(() => {
                trayScrollWrap.style.overflow = "visible";
                drupalTrayIFrameDocument.documentElement.style.overflow =
                    "visible";
                // Hide last svg
                const svgs = drupalTrayIFrameDocument.querySelectorAll("svg");
                svgs[svgs.length - 1].style.height = "0";
            }),
        );
    }

    /**
     * Handle update tray message
     *
     * @param trayName Tray identifier
     * @param eventData Additional data to forward after opening
     */
    private onUpdateTray(
        eventName: string,
        eventData: Record<string, unknown>,
    ): void {
        // Dispatch message to every iframe (ie. drupal tray)
        this.document
            .querySelectorAll(".drupal-iframe")
            .forEach((iframe: HTMLIFrameElement) => {
                iframe.contentWindow["APP"].pubsub.publish(
                    eventName,
                    eventData,
                );
            });
    }

    /**
     * Handle update page title on form error
     *
     * @param eventData attribut errorOnForm to show or hide error
     */
    public onUpdateTrayTitleOnError(errorOnForm: boolean): void {
        if (errorOnForm) {
            this.formService.prefixTitleOnError();
        } else {
            this.formService.resetTitle();
        }
    }
}
