import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
} from "@angular/core";
import { catchError, from, Subscription } from "rxjs";

import { delay, map, tap } from "rxjs/operators";

import { MessageType, NotificationsService } from "@hermes/aphrodite/layout";
import { INFRA_SETTINGS, LOCALE } from "@hermes/app-core";

import { InfraSettings } from "@hermes/env-infra";
import { CONFIRMATION_PAGE } from "@hermes/fragments/conversion-funnel";
import { Locale } from "@hermes/locale";
import { ModalData, ModalFacade, ModalType } from "@hermes/states/modal";
import { UserStateService } from "@hermes/states/user";
import { ValidationService } from "@hermes/utils/services/payment";
import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

const GLOBAL_OOPS = $localize`:@@hermes-global-translations.oops:Oops!`;
const PAYMENT_DECLINED_ERROR = $localize`:@@hermes-checkout-payment-declined-error:Oops ! Your payment was declined`;

interface AdditionalDetailsState {
    data: { details: Record<string, string> };
}

@Component({
    selector: "h-modal-three-ds2",
    templateUrl: "./modal-three-d-s2.component.html",
    styleUrls: ["./modal-three-d-s2.component.scss"],
})
export class ModalThreeDS2Component implements OnDestroy, AfterViewInit {
    @Input({ required: true })
    public modalData!: Extract<ModalData, { type: ModalType.ThreeDS2 }>;
    public state?: "validation" | "error";
    public errorMessage?: string;
    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(LOCALE) private locale: Locale,
        public urlUtils: UrlUtils,
        private modalFacade: ModalFacade,
        private notificationsService: NotificationsService,
        private userService: UserStateService,
        @Inject(INFRA_SETTINGS) private settings: InfraSettings,
        private validationService: ValidationService,
    ) {}

    public ngAfterViewInit(): void {
        this.initAdyenComponent(this.modalData.clientKey);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private async initAdyenComponent(clientKey: string): Promise<void> {
        const checkout = await import("@adyen/adyen-web");
        const adyenCheckout = await checkout.default({
            environment: this.settings.environment === "prd" ? "live" : "test",
            locale: this.locale.id,
            clientKey,
        });
        adyenCheckout
            .createFromAction(this.modalData.action, this.actionOptions())
            .mount(".adyen-container");
    }

    private actionOptions(): CoreOptions {
        return {
            onAdditionalDetails: (state: AdditionalDetailsState) =>
                this.handleValidation(state.data.details),
        };
    }

    private handleValidation(result: Record<string, string>) {
        this.state = "validation";
        this.subscription.add(
            this.validationService
                .callVerifyWs(result)
                .pipe(
                    map((verifyResult) => {
                        if (verifyResult.validationStatus === "FAILED") {
                            if (verifyResult.message) {
                                this.state = "error";
                                this.errorMessage = verifyResult.message;
                            } else {
                                throw new Error("AUTHORIZATION_REFUSED");
                            }
                        } else {
                            this.urlUtils.redirectToPage(
                                CONFIRMATION_PAGE,
                                verifyResult.orderId,
                            );
                        }
                    }),
                    catchError(() => this.showGlobalError()),
                )
                .subscribe(),
        );
    }

    private showGlobalError() {
        return from(
            this.notificationsService.showNotification({
                message: {
                    title: GLOBAL_OOPS,
                    description: PAYMENT_DECLINED_ERROR,
                },
                type: MessageType.SuperCritical,
                isTemporary: false,
            }),
        ).pipe(
            delay(5000),
            tap(() => {
                this.userService.setSessionNeedsRefresh(true);
                this.urlUtils.redirectToRootPath();
                this.modalFacade.closeModal(false);
            }),
        );
    }
}
