import { Component, OnInit } from "@angular/core";

@Component({
    selector: "h-empty-component",
    templateUrl: "./empty-component.component.html",
})
export class EmptyComponentComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
