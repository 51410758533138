import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AddToCartFacade } from "@hermes/states/add-to-cart";
import { ModalFacade } from "@hermes/states/modal";

import { ProductUtilsService } from "./services/product-utils.service";

@NgModule({
    imports: [CommonModule],
    providers: [ProductUtilsService, AddToCartFacade, ModalFacade],
})
export class ProductUtilsServiceModule {}
