import { Route } from "@angular/router";

/**
 * This types is used in route data to change Templating of Hermes pages
 */
export const LAYOUT_MARGIN_ADAPTATIVE = "adaptative";
export const LAYOUT_MARGIN_FIXE = "fixe";
export const LAYOUT_MARGIN_FULL = "full";
type LayoutMarginAdaptative = typeof LAYOUT_MARGIN_ADAPTATIVE;
type LayoutMarginFix = typeof LAYOUT_MARGIN_FIXE;
type LayoutMarginFull = typeof LAYOUT_MARGIN_FULL;
export type LayoutMarginType =
    | LayoutMarginAdaptative
    | LayoutMarginFix
    | LayoutMarginFull;

export const SHELL_APPEARANCE_FULL = "full";
export const SHELL_APPEARANCE_LIGHT = "light";
export const SHELL_APPEARANCE_NONE = "none";
type ShellAppearanceFull = typeof SHELL_APPEARANCE_FULL;
type ShellAppearanceLight = typeof SHELL_APPEARANCE_LIGHT;
type ShellAppearanceNone = typeof SHELL_APPEARANCE_NONE;
export type ShellAppearanceType =
    | ShellAppearanceFull
    | ShellAppearanceLight
    | ShellAppearanceNone;
// Templating choices (cf. "Global routing module" all the way down to see the default values)
// this interface is placed here to avoid circular dependencies when we import app routing
export interface HermesRoutes extends Route {
    data?: {
        layoutType?: LayoutMarginType;
        shell?: ShellAppearanceType;
        backButton?: any; // to delete soon
    };
}

export const DEVICE_MEDIUM_NAME = "mobile";
export const DEVICE_LARGE_NAME = "desktop";
export type DeviceMobileName = typeof DEVICE_MEDIUM_NAME;
export type DeviceDesktopName = typeof DEVICE_LARGE_NAME;
export type DeviceType = DeviceMobileName | DeviceDesktopName;

export const PRODUCTION_VERSION = "Production version";
export const DEVELOPMENT_VERSION = "Development version";

export type ProductionVersion = typeof PRODUCTION_VERSION;
export type DevelopmentVersion = typeof DEVELOPMENT_VERSION;
export type BuildVersion = ProductionVersion | DevelopmentVersion;

export const BRAND = "hermes";
export const NOTIFICATION_TIME = 4000;
export const COUNTRY_USING_MILES = ["US"];
export const RECAPTCHA_SITE_KEY = "6LefTbwUAAAAAHFiwcgnwmGdn2WJxbD1p-T5d4O8";
export const WECHAT_USER_AGENT = "MicroMessenger";
export const QQBROWER_USER_AGENT = "QQBrowser";
export const CHROME_USER_AGENT = "Chrome";
export const SCENE7_IMAGE_BACKGROUND_COLOR = "$product_item_grid_g$";
// list of countries for which a text is displayed when the image is retouched
export const COUNTRY_LIST_OF_RETOUCHED_IMAGES = ["fr"];

/**
 * This header should be used on post request to inform the content-type of the body.
 * usage: `new HttpHeader({...HTTP_JSON_CONTENT_TYPE_HEADER});`
 */
export const HTTP_JSON_CONTENT_TYPE_HEADER = {
    "Content-Type": "application/json",
};

/**
 * This header should be used to invalidate the api-gateway cache on GET requests.
 * usage: `new HttpHeader({...HTTP_NO_CACHE_CONTROL_HEADER});`
 */
export const HTTP_NO_CACHE_CONTROL_HEADER = {
    "Cache-Control": "no-cache",
};
