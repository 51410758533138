import { persoAnalyticsConstant } from "./perso-analytics.constants";

export const SILK_ANALYTICS_CATEGORY = "SilkPage";

export const SILK_EXPANDS_ANALYTICS: {
    [key: string]: { action: string; label?: string };
} = {
    marking: {
        action: persoAnalyticsConstant.markingSelector,
        label: persoAnalyticsConstant.clickMarkingMenu,
    },
    font: {
        action: persoAnalyticsConstant.typographySelector,
        label: persoAnalyticsConstant.clickTypographyMenu,
    },
    fontStyle: { action: persoAnalyticsConstant.typographySelector },
    fontSize: { action: persoAnalyticsConstant.typographySelector },
    fontColor: { action: persoAnalyticsConstant.typographySelector },
};

export const SILK_PRODUCT_TYPE_ANALYTICS = "silk";
