import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

/**
 * This class is used for click on plus/minus button on giftset list component
 */
export class AddRemoveGiftsetItemEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMEventData {
        return {
            ...super.formatForGTM(),
            eventcategory: "SetsProductPage",
            eventaction: this.eventData.eventaction,
            eventlabel: this.eventData.sku,
        };
    }
}
