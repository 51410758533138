import { Product } from "@hermes/api-model-product";

import { ProductPageState } from "../../reducers/product-page.state";

export const fetchBikiniProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => ({
    ...state,
    productSKU: product.sku,
    currentProduct: product,
    type: product.templateType,
    isLoading: false,
    selectedVariants: product.bikiniAttributes?.items.map(() => ({})) ?? [],
});
