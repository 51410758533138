export const GUEST_BCK_RESPONSE_RETURNING_CUSTOMER =
    "bck_response_returning_customer";
export const GUEST_BCK_RESPONSE_NEW_CUSTOMER = "bck_response_new_customer";
export const GUEST_BCK_RESPONSE_WRONG_VALIDATION_CODE =
    "bck_response_wrong_validation_code";
export const GUEST_BCK_RESPONSE_INVALID_FORMAT = "bck_response_invalid_format";

export type GuestBCKResponseEventsType =
    | typeof GUEST_BCK_RESPONSE_RETURNING_CUSTOMER
    | typeof GUEST_BCK_RESPONSE_WRONG_VALIDATION_CODE
    | typeof GUEST_BCK_RESPONSE_NEW_CUSTOMER
    | typeof GUEST_BCK_RESPONSE_INVALID_FORMAT;

export interface GuestBCKResponse {
    event: GuestBCKResponseEventsType;
    socialSecurityNumber?: string;
}
