<h-geo-filtering-banner
    *ngIf="isGeoFilteringBannerDisplayed$ | async"
    (closeBanner)="closeGeoFilteringBanner()"></h-geo-filtering-banner>
<!-- bottom cookie banner -->
<h-cookie-banner
    *ngIf="cookieBanner$ | async as cookieBanner"
    [cookieBanner]="cookieBanner"
    (closeBanner)="closeCookieBanner()">
</h-cookie-banner>

<h-notification-banner
    *ngIf="notificationsBanners$ | async as notificationsBanners"
    class="header-notifications"
    [notificationsBanners]="notificationsBanners"
    (closeBanner)="closeNotificationsBanner()">
</h-notification-banner>
