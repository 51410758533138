import { Locale } from "@hermes/locale";

import { isQQBrowerAgent } from "../utils/utils.helper";

/**
 * @returns the product link to go to the product page from grid
 */
export const generateLocalizedHref = (url: string, locale: Locale): string => {
    // all product item url must end end with a '/'
    const end = url?.endsWith("/") ? "" : "/";
    // we want the full url with base href (/xx/yy/product/*) all the time.
    return `${locale.urlPrefix}${url}${end}`;
};

export const formatAssetUrl = (url: string, userAgent?: string): string => {
    url = url.replace(/\s/g, "");
    // Some browsers (namely QQBrowser) consider `,` as an srcset
    // separator. Encoding the comma prevents that
    if (isQQBrowerAgent(userAgent)) {
        url = url.replace(/,/g, "%2C");
    }
    return url;
};
