/* eslint-disable rxjs-angular/prefer-composition */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError, TimeoutError } from "rxjs";
import { catchError, timeout } from "rxjs/operators";

import { Context, HTTP_REQUEST_TIMEOUT } from "@hermes/app-core";
import { Logger } from "@hermes/logger";

import { LOGGER } from "@hermes/web-logger";

/**
 * This custom class allows us to log and do stuff when any http request fails.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private context: Context,
        @Inject(LOGGER) private logger: Logger,
        @Inject(HTTP_REQUEST_TIMEOUT) private timeoutValue: number,
    ) {}

    /**
     * Angular HTTP interceptor
     */
    public intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        // Skip http interceptor with 'X-Skip-Http-Interceptor' header
        // This is done so that the feature-flag call during app intialization doesn't fail,
        // and the app always finishes its initialization
        // Do not use this header for any other reason
        if (request.headers && request.headers.has("X-Skip-Http-Interceptor")) {
            const headers = request.headers.delete("X-Skip-Http-Interceptor");
            return next.handle(request.clone({ headers }));
        }

        return next.handle(request).pipe(
            timeout(this.timeoutValue),
            catchError(
                (
                    error: HttpErrorResponse | TimeoutError,
                ): Observable<never> => {
                    if (this.context.isInServerMode()) {
                        // Always explicit the error message. Cast as a writeable to update the message property.
                        (
                            error as {
                                message: string;
                            }
                        ).message = `${error.message} when requesting ${request.urlWithParams}`;
                    }
                    // log in every case
                    this.logger.error(error.message, error);

                    if (error instanceof TimeoutError) {
                        // If TimeoutError, no more processing to be done
                        return throwError(() => error);
                    }

                    // Case of browser request failures:
                    if (
                        this.context.isInBrowserMode() &&
                        error.error &&
                        error.error instanceof ErrorEvent
                    ) {
                        // Client-side error or network error
                        this.logger.error(
                            "Request failed, client-side or Network Error",
                            error.error,
                        );
                    }

                    // default case : technical error, rethrow the error so that it can be catched by the susbscribers or ErrorHandler if there is no error management on call
                    return throwError(() => error);
                },
            ),
        );
    }
}
