import { Product } from "@hermes/api-model-product";
import { PRODUCT_PAGE, QUICK_BUY } from "@hermes/states/add-to-cart";
import {
    SILK_ANALYTICS_CATEGORY,
    SMLG_ANALYTICS_CATEGORY,
    BELTKIT_ANALYTICS_CATEGORY,
} from "@hermes/utils/analytics";
import { ProductTemplateType } from "@hermes/utils/constants";

export const getEventCategory = (
    templateType: string,
    context: string = PRODUCT_PAGE,
): string => {
    if (context === QUICK_BUY) {
        return "Product_Grid";
    }

    switch (templateType) {
        case ProductTemplateType.Beltkit: {
            return "BeltKitPage";
        }
        case ProductTemplateType.AppleWatch: {
            return "AppleWatchPage";
        }
        case ProductTemplateType.Giftset: {
            return "SetsProductPage";
        }
        case ProductTemplateType.DoubleFragrance: {
            return "FragranceProductPage";
        }
        case ProductTemplateType.Look: {
            return "LooksProductPage";
        }
        case ProductTemplateType.Bikini: {
            return "BikiniProductPage";
        }
        case ProductTemplateType.PersoSilk: {
            return SILK_ANALYTICS_CATEGORY;
        }
        case ProductTemplateType.PersoSMLG: {
            return SMLG_ANALYTICS_CATEGORY;
        }
        case ProductTemplateType.PersoBeltkit: {
            return BELTKIT_ANALYTICS_CATEGORY;
        }
        default: {
            return "SingleProductPage";
        }
    }
};

export const getPageType = (templateType: string): string => {
    switch (templateType) {
        case ProductTemplateType.Beltkit: {
            return "double product page belt kits";
        }
        case ProductTemplateType.AppleWatch: {
            return "double product page apple watch";
        }
        case ProductTemplateType.Giftset: {
            return "set product page";
        }
        case ProductTemplateType.PersoSMLG: {
            return "pm product page";
        }
        case ProductTemplateType.DoubleFragrance: {
            return "double product page fragrance";
        }
        case ProductTemplateType.PersoBeltkit: {
            return "belt perso product page";
        }
        case ProductTemplateType.Look: {
            return "look product page";
        }
        case ProductTemplateType.Bikini: {
            return "bikini product page";
        }
        default: {
            return "single product page";
        }
    }
};

export function getFormatedSku(product: Product): string {
    switch (product.templateType) {
        case ProductTemplateType.AppleWatch: {
            return `${product.appleWatchAttributes?.skuGuizmo}|${product.appleWatchAttributes?.skuStrap}`;
        }
        case ProductTemplateType.Beltkit: {
            return `${product.beltkitAttributes?.skuBuckle}|${product.beltkitAttributes?.skuLeather}`;
        }
        case ProductTemplateType.DoubleFragrance: {
            return `${product.doubleFragranceAttributes?.skuPerfume}|${product.doubleFragranceAttributes?.skuAccessory}`;
        }
        default: {
            return product.sku;
        }
    }
}
