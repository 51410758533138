/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { JsonConvert } from "json2typescript";
import { Observable, of } from "rxjs";
import { catchError, filter, first, map } from "rxjs/operators";

import { NotificationsService } from "@hermes/aphrodite/layout";
import { Basket } from "@hermes/api-model-basket";

import { LoginResponse } from "@hermes/api-model-session";
import { Context, LOCALE, StorageService } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { BasketFacade, SerializedBasket } from "@hermes/states/basket";
import { UserFacade, UserStateService } from "@hermes/states/user";

/**
 * Sync customer between rdr / ecom
 * If there is no cookie or no user datas in sessionStorage fetch it from ecom
 * Else return it from sessionStorage
 * It's will resolve
 * - cookie needed for ecom action (as add to cart)
 * - user informations
 * - user basket
 * - shippingAddresses
 * - availableCountries
 */
@Injectable()
export class CustomerSessionResolver {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private basketFacade: BasketFacade,
        private notificationsService: NotificationsService,
        private userFacade: UserFacade,
        private userService: UserStateService,
        private context: Context,
        private storageService: StorageService,
    ) {}

    public resolve(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ): Observable<LoginResponse | undefined> {
        if (this.context.isInBrowserMode()) {
            // There may be user information in session (or not).The NgRx Store is not up to date.
            // We have to launch the synchronization of the NgRx Store with the session storage item.
            this.userFacade.launchUserSynchroWithStorage();

            if (this.hasCustomerSessionStorageOnLocale()) {
                // Set the Guest Account in NgRx Store
                this.userFacade.fetchGuest();

                // (Re)Set the basket in storage & NgRx Store
                const basket = this.userService.getBasket();
                this.basketFacade.setBasket(
                    new JsonConvert().serialize(basket),
                );

                // Create a Login Response and return it.
                const user = this.userService.getUser();
                if (user) {
                    this.userFacade.setUserInStorage(user);
                }
                return of({
                    user: { ...user, basket: undefined },
                    basket,
                } as unknown as LoginResponse);
            }

            // currentStore key is used to check if user session was created on current locale
            this.storageService.setSessionStorageItem(
                "currentStore",
                this.locale.code,
            );

            // Make an HTTP call to /customer-session entrypoint
            return this.userService.fetchCustomerSession().pipe(
                map((response) => {
                    let basket: Basket = undefined as unknown as Basket;
                    if (response.user) {
                        this.userFacade.setUserInStorage(response.user);
                    }

                    if (response.basket) {
                        basket = new JsonConvert().deserializeObject(
                            response.basket,
                            Basket,
                        );
                        this.basketFacade.setBasket(
                            response.basket as unknown as SerializedBasket,
                        );
                    }
                    return {
                        user: this.userService.getUser(),
                        basket,
                    } as unknown as LoginResponse | undefined;
                }),
                filter((response) => response !== undefined),
                catchError(() => {
                    // In case of error, show technical error notification, and dont crash the resolve
                    // (it must go through for the notification component to be loaded)
                    this.notificationsService.technicalError(
                        "Error fetching customer session",
                    );
                    return of(undefined);
                }),
                first(),
            );
        }
        return of(undefined);
    }

    private hasCustomerSessionStorageOnLocale(): boolean {
        const user: Record<string, unknown> =
            this.storageService.getSessionStorageItem("user") || {};
        const basketLocale =
            this.storageService.getSessionStorageItem("currentStore");
        return (
            Object.keys(user).length > 0 && this.locale.code === basketLocale
        );
    }
}
