import { GTMClientType } from "../types/gtm.type";

/**
 * @param user
 * @returns the write wording from User userType
 */
export const getUserType = (userType: string): GTMClientType => {
    switch (userType) {
        case "0": {
            return "newclient";
        }
        case "1": {
            return "buyer";
        }
        default: {
            return "non-connected";
        }
    }
};
