import { CategorySuggestion, ProductSuggestion } from "@hermes/api-model-shell";
import { FlatCategories, getGridPath } from "@hermes/states/menu";

import { Suggestion } from "../model/suggestions";

export const LIMIT_SUGGESTION_CATEGORIES = 3;
export const LIMIT_TOTAL_SUGGESTION = 5;

/**
 * We want to ignore empty search or non printable characters like "%00".
 */
export const isPrintableSearch = (search: string): boolean => {
    let decode = search;
    try {
        decode = decodeURIComponent(search);
    } catch {
        // Not an encoded string.
    }
    return (
        decode.trim().length > 0 &&
        // eslint-disable-next-line no-control-regex
        !/[\u0000-\u0008\u000E-\u001F]/.test(decode)
    );
};

/**
 * Format CategorySuggestions into Suggestion and filter on categories with visible products
 *
 * @param categorySuggestions CategorySuggestions to format and filter
 * @param categories Category list
 */
export const formatAndFilterCategorySuggestions = (
    categorySuggestions: CategorySuggestion[],
    categories: FlatCategories,
): Suggestion[] => {
    const suggestions: Suggestion[] = [];
    for (const suggestion of categorySuggestions) {
        if (
            categories[suggestion.pimCode.toUpperCase()]?.hasVisibleProducts &&
            suggestions.length < LIMIT_SUGGESTION_CATEGORIES
        ) {
            suggestions.push({
                label: suggestion.label,
                quantity: suggestion.results,
                url: getGridPath(categories[suggestion.pimCode.toUpperCase()]),
            });
        }
    }
    return suggestions;
};

export const formatProductSuggestions = (
    urlPrefix: string,
    suggestions: ProductSuggestion[],
    nbSuggestCategories: number,
): Suggestion[] => {
    const suggestedProducts: Suggestion[] = [];
    for (const [index, product] of suggestions.entries()) {
        if (index >= LIMIT_TOTAL_SUGGESTION - nbSuggestCategories) {
            return suggestedProducts;
        }
        if (
            !suggestedProducts.some(
                (suggestProduct) =>
                    suggestProduct.label === product.label &&
                    suggestProduct.sku?.includes(product?.sku.slice(0, 9), 0),
            )
        ) {
            suggestedProducts.push({
                label: product.label,
                url: `${urlPrefix}${product.url}`,
                sku: product.sku,
            });
        }
    }
    return suggestedProducts;
};
