import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

export const CLICK_CGV = "ClickCgvLink";
export const CLICK_CGU = "ClickCguLink";
export const CLICK_LEGAL = "ClickLegalLink";
export const CLICK_PRIVACY = "ClickPrivacyLink";
export const CLICK_TERMS = "ClickTermsLink";
export const CLICK_ACCESSIBILITY = "ClickAccessibilityLink";

export type GTMFooterClickEventData = GTMEventData & { eventType: string };

/**
 * This class is used for click in the Footer component
 */
export class FooterLightClickEvent implements AnalyticsEvent<GTMEventData> {
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: GTMFooterClickEventData;

    constructor(data: Record<string, unknown>) {
        this.eventData = data as unknown as GTMFooterClickEventData;
    }

    /**
     * Return event formatted to send to GTM
     *
     */
    public formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CLICK_CGV: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "General Terms and Conditions of Sale",
                };
            }
            case CLICK_CGU: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "General Terms and Conditions of Use",
                };
            }
            case CLICK_TERMS: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "Terms and conditions",
                };
            }
            case CLICK_PRIVACY: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "Privacy & cookies",
                };
            }
            case CLICK_LEGAL: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "Legal issues",
                };
            }
            case CLICK_ACCESSIBILITY: {
                return {
                    event: this.name,
                    eventcategory: "Cart",
                    eventaction: "Footer",
                    eventlabel: "Accessibility",
                };
            }
            default: {
                return {
                    event: this.name,
                    eventcategory: "SingleProductPage",
                };
            }
        }
    }
}
