import { DOCUMENT } from "@angular/common";
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
} from "@angular/core";

import { Context, WINDOW } from "@hermes/app-core";

import { trayOverlayAnimations } from "./tray-overlay.animations";

/**
 * Component in charge of displaying the gray area when a tray has an overlay.
 */
@Component({
    animations: [trayOverlayAnimations],
    selector: "h-tray-overlay",
    templateUrl: "./tray-overlay.component.html",
    styleUrls: ["./tray-overlay.component.scss"],
})
export class TrayOverlayComponent implements OnChanges {
    /**
     * If the overlay is shown, false by default.
     */
    @Input()
    public shown: boolean = false;
    /**
     * Event sent on overlay click.
     */
    @Output()
    public overlayClick: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private renderer: Renderer2,
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private context: Context,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        // disable page scroll when overlay is opened.
        if (changes.shown && this.context.isInBrowserMode()) {
            if (changes.shown.currentValue) {
                // we calculate the width of the scrollbar
                const scrollBarWidth =
                    this.window.innerWidth -
                    this.document.documentElement.clientWidth;

                this.onShown(scrollBarWidth);
            } else {
                this.onHide();
            }
        }
    }

    /**
     * handle body scroll when tray is open
     *
     * @param scrollBarWidth : scroll width of browser
     */
    public onShown(scrollBarWidth: number): void {
        this.renderer.setStyle(this.document.body, "overflow", "hidden");
        // Avoid width reflow
        this.renderer.setStyle(
            this.document.body,
            "padding-right",
            `${scrollBarWidth}px`,
        );
    }

    /**
     * handle body scroll when tray is close
     *
     */
    public onHide(): void {
        this.renderer.removeStyle(this.document.body, "overflow");
        // Avoid width reflow
        this.renderer.removeStyle(this.document.body, "padding-right");
    }
}
