import { ActionReducerMap, createReducer, on } from "@ngrx/store";

import { BannersData } from "@hermes/api-model-shell";

import * as ShellActions from "../actions/shell.actions";
import { HeaderBasketState, ShellState, State } from "../state/shell.state";

import { footerItemsReducer } from "./footer-items.reducer";
import { SHELL_APPEARANCE_FULL } from "@hermes/utils-generic/constants";

export const initialShellState: ShellState = {
    mode: SHELL_APPEARANCE_FULL,
};

export const initialBasketState: HeaderBasketState = {
    totalItems: 0,
};
export const basketReducer = createReducer(
    initialBasketState,
    on(ShellActions.updateBasket, (state, { amount }) => ({
        ...state,
        totalItems: amount,
    })),
);
// empty initial state for createReducer typing
export const initialBannersState: BannersData =
    undefined as unknown as BannersData;
/**
 * banners
 */
export const bannersReducer = createReducer(
    initialBannersState,
    on(
        ShellActions.fetchBannersSuccess,
        (state, { data }) =>
            ({
                ...state,
                ...data,
            } as BannersData),
    ),
    on(
        ShellActions.fetchBannersFailure,
        (state) =>
            ({
                ...state,
            } as BannersData),
    ),
);

export const shellReducer = createReducer(
    initialShellState,
    on(ShellActions.setShell, (state, { shell }) => ({
        ...state,
        mode: shell,
    })),
);

export const reducers: ActionReducerMap<State> = {
    basket: basketReducer,
    banners: bannersReducer,
    footerItems: footerItemsReducer,
    shell: shellReducer,
};
