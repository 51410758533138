import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map, Observable } from "rxjs";

import { Product } from "@hermes/api-model-product";

import {
    applyStateConfiguration,
    checkBeforeInitializationOfRipeInstance,
    fetchPersonalizeProducts,
    fetchPlatformeConfiguration,
    goBack,
    hideLoader,
    initializationOfRipeInstance,
    initiateBeltkitConfiguratorMapping,
    initiateConfiguratorMapping,
    initiateSilkConfiguratorMapping,
    initiateSmlgConfiguratorMapping,
    resetPersonalizationState,
    updateBlock,
    updateCollapsedToggles,
    updateConfiguration,
    updateConfigurator,
    updateCurrentUrl,
    updateDisplayedUrl,
    updateDku,
    updateEngravingConfiguration,
    updateFirstProduct,
    updateIsCallingPlatforme,
    updateIsDacProductMissing,
    updateIsNoBuckleSelected,
    updateKeyboardTap,
    updateLoadingProgress,
    updateParentProduct,
    updateParsedUrlSkus,
    updatePlateformeExtraInformation,
    updateProductCategory,
    updateSecondProduct,
    updateSeveralConfiguration,
    updateSmallLeatherGoodsLeathers,
    updateUrlParsedParameters,
} from "../actions/product-personalization.actions";
import {
    BELTKIT,
    BlockType,
    SMALL_LEATHER_GOODS,
} from "../constant/product-personalization.constant";
import {
    areSkuEquivalent,
    isEngravingConfiguration,
    isPersoConfigurator,
} from "../helpers/product-personalization.helper";
import { ProductPersonalizationState } from "../reducers/product-personalization.state";
import {
    selectBlock,
    selectCollapsedToggles,
    selectConfiguration,
    selectConfigurator,
    selectEngravingConfiguration,
    selectFirstProduct,
    selectIsCallingPlatforme,
    selectIsDacProductMissing,
    selectIsNoBuckleSelected,
    selectKeyboardTap,
    selectLoadingProgress,
    selectParentProduct,
    selectPlateformeExtraInformation,
    selectPlatformeInitialConfiguration,
    selectProductCategory,
    selectSecondProduct,
    selectSmallLeatherGoodsLeathers,
    selectUrlParsedParameters,
} from "../selectors/product-personalization.selectors";
import {
    PlatformeConfigResponse,
    NO_ENGRAVING,
    PersoConfigurator,
    PersoConfiguratorState,
    ConfigPart,
    EngravingConfiguration,
    KeyboardTap,
    LoadingProgress,
    PersoParsedUrl,
    ProductCategory,
    SmallLeatherGoodsLeather,
} from "../types/product-personalization.type";

@Injectable()
export class ProductPersonalizationFacade {
    constructor(private store: Store<ProductPersonalizationState>) {}

    /**
     * Selectors
     */
    public block$ = this.store.select(selectBlock);
    public collapsedToggles$ = this.store.select(selectCollapsedToggles);
    public configuration$ = this.store.select(selectConfiguration);
    public isNoBuckleSelected$ = this.store.select(selectIsNoBuckleSelected);
    public keyboardTapped$ = this.store.select(selectKeyboardTap);
    public loadingProgress$ = this.store.select(selectLoadingProgress);
    public parentProduct$ = this.store
        .select(selectParentProduct)
        ?.pipe(
            filter(
                (parentProduct): parentProduct is Product => !!parentProduct,
            ),
        );
    public firstProduct$ = this.store.select(selectFirstProduct);
    public secondProduct$ = this.store.select(selectSecondProduct);
    public urlParsedParameters$ = this.store.select(selectUrlParsedParameters);
    public isDacProductMissing$ = this.store.select(selectIsDacProductMissing);
    public productCategory$ = this.store.select(selectProductCategory);
    public smallLeatherGoodsLeathers$ = this.store.select(
        selectSmallLeatherGoodsLeathers,
    );
    public configurator$: Observable<PersoConfigurator> = this.store
        .select(selectConfigurator)
        .pipe(
            filter(
                (
                    configurator: PersoConfiguratorState,
                ): configurator is PersoConfigurator =>
                    isPersoConfigurator(configurator),
            ),
        );
    public engravingConfiguration$: Observable<EngravingConfiguration> =
        this.store
            .select(selectEngravingConfiguration)
            .pipe(
                filter(
                    (
                        engravingConfiguration:
                            | EngravingConfiguration
                            | typeof NO_ENGRAVING,
                    ): engravingConfiguration is EngravingConfiguration =>
                        isEngravingConfiguration(engravingConfiguration),
                ),
            );
    public platformeInitialConfiguration$ = this.store.select(
        selectPlatformeInitialConfiguration,
    );
    public isCallingPlatforme$ = this.store.select(selectIsCallingPlatforme);
    public plateformeExtraInformation$ = this.store
        .select(selectPlateformeExtraInformation)
        .pipe(
            map(({ translations, colorsHexaCodes }) => ({
                translations: translations ?? {},
                colorsHexaCodes: colorsHexaCodes ?? {},
            })),
        );

    /**
     * Actions
     */
    public updateBlock(block: BlockType): void {
        this.store.dispatch(updateBlock({ block }));
    }
    public updateCollapsedToggles(collapsedToggle: string): void {
        this.store.dispatch(updateCollapsedToggles({ collapsedToggle }));
    }
    public updateConfiguration(configuration: ConfigPart): void {
        this.store.dispatch(updateConfiguration({ configuration }));
    }
    public updateSeveralConfiguration(configurations: ConfigPart[]): void {
        this.store.dispatch(updateSeveralConfiguration({ configurations }));
    }
    public updateKeyboardTap(keyboardTap: KeyboardTap | undefined): void {
        this.store.dispatch(updateKeyboardTap({ keyboardTap }));
    }
    public updateLoadingProgress(loadingProgress: LoadingProgress): void {
        this.store.dispatch(updateLoadingProgress({ loadingProgress }));
    }
    public updateParentProduct(parentProduct: Product): void {
        this.store.dispatch(updateParentProduct({ parentProduct }));
        // At each change of parentProduct, we must fetch another Ripe instance to get correct configuration.
        this.store.dispatch(checkBeforeInitializationOfRipeInstance());
    }
    public updateFirstProduct(firstProduct: Product): void {
        this.store.dispatch(updateFirstProduct({ firstProduct }));
    }
    public updateSecondProduct(secondProduct: Product): void {
        this.store.dispatch(updateSecondProduct({ secondProduct }));
    }
    public updateIsNoBuckleSelected(isNoBuckleSelected: boolean): void {
        this.store.dispatch(updateIsNoBuckleSelected({ isNoBuckleSelected }));
    }
    public updateProductCategory(productCategory: ProductCategory): void {
        this.store.dispatch(updateProductCategory({ productCategory }));
    }
    public updateCurrentUrl(currentUrl: string): void {
        this.store.dispatch(updateCurrentUrl({ currentUrl }));
    }
    public updateDku(dku: string): void {
        this.store.dispatch(updateDku({ dku }));
    }
    public updateParsedUrlSkus(skus: {
        firstSku: string;
        secondSku?: string;
    }): void {
        this.store.dispatch(updateParsedUrlSkus(skus));
    }
    public updateUrlParsedParameters(
        urlParsedParameters: PersoParsedUrl,
    ): void {
        this.store.dispatch(updateUrlParsedParameters({ urlParsedParameters }));
    }
    public updateIsDacProductMissing(isDacProductMissing: boolean): void {
        this.store.dispatch(updateIsDacProductMissing({ isDacProductMissing }));
    }
    public updateIsCallingPlatforme(isCallingPlatforme: boolean): void {
        this.store.dispatch(updateIsCallingPlatforme({ isCallingPlatforme }));
    }
    public updatePlateformeExtraInformation(
        colorsHexaCodes: Record<string, string>,
        translations: Record<string, string>,
    ): void {
        this.store.dispatch(
            updatePlateformeExtraInformation({ colorsHexaCodes, translations }),
        );
    }

    public resetPersonalizationState(): void {
        this.store.dispatch(resetPersonalizationState());
    }

    public goBack(): void {
        this.store.dispatch(goBack());
    }
    public updateDisplayedUrl(): void {
        this.store.dispatch(updateDisplayedUrl());
    }
    public hideLoader(): void {
        this.store.dispatch(hideLoader());
    }
    public updateConfigurator(configurator: PersoConfigurator): void {
        this.store.dispatch(updateConfigurator({ configurator }));
    }
    public updateEngravingConfiguration(
        engravingConfiguration: EngravingConfiguration,
    ): void {
        this.store.dispatch(
            updateEngravingConfiguration({ engravingConfiguration }),
        );
    }
    public updateSmallLeatherGoodsLeathers(
        smallLeatherGoodsLeathers: SmallLeatherGoodsLeather[],
    ): void {
        this.store.dispatch(
            updateSmallLeatherGoodsLeathers({ smallLeatherGoodsLeathers }),
        );
    }
    public fetchPlatformeConfiguration(productSku: string): void {
        this.store.dispatch(fetchPlatformeConfiguration({ productSku }));
    }
    public fetchPersonalizeProducts(
        skus: string[],
        productCategory: ProductCategory,
    ): void {
        this.store.dispatch(
            fetchPersonalizeProducts({ skus, productCategory }),
        );
    }
    public applyStateConfiguration(): void {
        this.store.dispatch(applyStateConfiguration());
    }
    public initializationOfRipeInstance(initiateConfigurator: boolean): void {
        this.store.dispatch(
            initializationOfRipeInstance({ initiateConfigurator }),
        );
    }
    public initiateConfiguratorConstruction(): void {
        this.store.dispatch(initiateConfiguratorMapping());
    }
    public initiateConfiguratorMapping(
        platformeConfigResponse: PlatformeConfigResponse,
        productCategory: ProductCategory,
    ): void {
        this.store.dispatch(applyStateConfiguration());

        if (productCategory === BELTKIT) {
            return this.store.dispatch(
                initiateBeltkitConfiguratorMapping({
                    platformeConfigResponse,
                }),
            );
        }
        if (productCategory === SMALL_LEATHER_GOODS) {
            return this.store.dispatch(
                initiateSmlgConfiguratorMapping({
                    platformeConfigResponse,
                }),
            );
        }
        return this.store.dispatch(
            initiateSilkConfiguratorMapping({
                platformeConfigResponse,
            }),
        );
    }

    // Should fetch perso products if SPA is not activated or products not in state.
    public shouldFetchProducts(
        urlParsedParameters: PersoParsedUrl,
    ): Observable<boolean> {
        return this.store
            .select(selectParentProduct)
            .pipe(
                map(
                    (parentProduct: Product | undefined) =>
                        !areSkuEquivalent(
                            urlParsedParameters.defaultSku,
                            parentProduct?.sku,
                        ),
                ),
            );
    }
}
