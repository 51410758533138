/**
 * Replaces "-" by "+" and "_" by "/" to get well formed base64 encoded string
 * and then
 * decodes with base64 algorithm
 * NB: trailing "=" is not needed because it is padding character to "complete"
 * make the last encoded block contain four base64 characters
 * cf. https://en.wikipedia.org/wiki/Base64
 */
export const base64UrlDecode = (str: string): string => {
    const base64 = str.replace(/-/g, "+").replace(/_/g, "/");

    return decodeURIComponent(escape(atob(base64)));
};

/**
 * Encodes string with base64 algorithm
 * and then
 * replaces "+" by "-" and "/" by "_" and remove trailing "=" to avoid
 * special characters in the URL
 */
export const base64UrlEncode = (str: string): string => {
    const base64 = btoa(unescape(encodeURIComponent(str)));

    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};
