<ng-container *ngIf="isNoScriptElement; then noScript; else seo"></ng-container>

<ng-template #seo>
    <div *ngIf="seoLinks?.length" [class.seo-links]="!isNoScriptElement">
        <ng-container *ngFor="let seoLink of seoLinks; trackBy: trackByLink">
            <a *ngIf="seoLink.isDisplayed ?? true" href="{{ seoLink.path }}" aria-disabled="true">{{
                seoLink.label
            }}</a>
        </ng-container>
    </div>
</ng-template>

<ng-template #noScript>
    <noscript>
        <ng-container *ngTemplateOutlet="seo"></ng-container>
    </noscript>
</ng-template>
