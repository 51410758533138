import { Injectable } from "@angular/core";

import { AnalyticsService, EVENT_UAEVENT } from "@hermes/utils/analytics";
const EVENT_CATEGORY_GEO_FILTERING = "GeoBanner";

export const STAY_CLICK_EVENT = "Stay";
export const SWITCH_COUNTRY_CLICK_EVENT = "SwitchCountry";
export const CLOSE_CLICK_EVENT = "Close";

type SendGeoFilteringClickLabel =
    | typeof STAY_CLICK_EVENT
    | typeof SWITCH_COUNTRY_CLICK_EVENT
    | typeof CLOSE_CLICK_EVENT;

@Injectable()
export class BannerAnalyticsService {
    constructor(private analyticsService: AnalyticsService) {}

    public sendGeoFilteringImpressionEvent(
        userCountry: string | null | undefined,
    ): void {
        this.analyticsService.sendRawData({
            event: "nonintuaevent",
            eventcategory: EVENT_CATEGORY_GEO_FILTERING,
            eventaction: "View",
            eventlabel: userCountry || "fetchError",
        });
    }
    public sendGeoFilteringClickEvent(event: SendGeoFilteringClickLabel): void {
        this.analyticsService.sendRawData({
            event: EVENT_UAEVENT,
            eventcategory: EVENT_CATEGORY_GEO_FILTERING,
            eventaction: "Click",
            eventlabel: event,
        });
    }
}
