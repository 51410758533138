import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { PriceComponent } from "./components/price.component";
import { CurrencyModule } from "./currencies/currency.module";

@NgModule({
    imports: [CommonModule, CurrencyModule],
    declarations: [PriceComponent],
    exports: [PriceComponent],
})
/**
 * Module for the Aphrodite Price component
 */
export class PriceModule {}
