import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import {
    OpenIdRequest,
    OpenIdResponse,
    UnionIdRequest,
    UnionIdResponse,
} from "@hermes/api-model-session";
import { Settings } from "@hermes/app-core";
import { HTTP_JSON_CONTENT_TYPE_HEADER } from "@hermes/utils-generic/constants";

@Injectable()
export class WechatService {
    constructor(private http: HttpClient, private settings: Settings) {}

    /**
     * Log in the user ( in magento ) and retrieve his informations from his unionId
     */
    public unionId(request: UnionIdRequest): Observable<UnionIdResponse> {
        const httpOptions = {
            headers: new HttpHeaders({
                ...HTTP_JSON_CONTENT_TYPE_HEADER,
            }),
            withCredentials: true,
        };
        return this.http
            .post<UnionIdResponse>(
                `${this.settings.apiUrl}/unionid`,
                request,
                httpOptions,
            )
            .pipe(
                catchError((e: { error: Error }) => {
                    throw new Error(`unionid response error : ${e.error}`);
                }),
            );
    }

    public openId(request: OpenIdRequest): Observable<OpenIdResponse> {
        const httpOptions = {
            headers: new HttpHeaders({
                ...HTTP_JSON_CONTENT_TYPE_HEADER,
            }),
            withCredentials: true,
        };

        return this.http
            .post<OpenIdResponse>(
                `${this.settings.apiUrl}/wechat/openid`,
                request,
                httpOptions,
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }
}
