import { Product } from "@hermes/api-model-product";

import { ProductPageState } from "../../reducers/product-page.state";

export const fetchLookProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => ({
    ...state,
    productSKU: product.sku,
    currentProduct: product,
    type: product.templateType,
    selectedVariants: product.lookAttributes?.items.map(() => ({})) ?? [],
    isLoading: false,
});
