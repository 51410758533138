import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from "@angular/animations";

/**
 * Slide animation from the left or the right
 */
export const modalAnimations: AnimationTriggerMetadata[] = [
    trigger("easeAnimation", [
        transition("void => active", [
            style({
                opacity: 0,
                transform: "translateY(130%)",
            }),
            animate(
                "350ms",
                style({
                    opacity: 1,
                    transform: "translateY(0%)",
                }),
            ),
        ]),
    ]),
    trigger("fadeAnimation", [
        transition(":enter", [
            style({ opacity: 0 }),
            animate("200ms", style({ opacity: 0.5 })),
        ]),
        transition(":leave", [
            style({ opacity: 0.5 }),
            animate("200ms", style({ opacity: 0 })),
        ]),
    ]),
];
