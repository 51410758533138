import { Component, Input } from "@angular/core";

export const EXTRA_SMALL = "extra-small";
export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";
export const EXTRA_LARGE = "extra-large";
export type PriceSize =
    | typeof EXTRA_SMALL
    | typeof SMALL
    | typeof MEDIUM
    | typeof LARGE
    | typeof EXTRA_LARGE
    | typeof AUTO;

export const SHORT = "short";
export const FULL = "full";
export const AUTO = "auto";
export type PriceFormat = typeof SHORT | typeof FULL;

export const NORMAL = "normal";
export const BOLD = "bold";
export type PriceWeight = typeof NORMAL | typeof BOLD;

/**
 * Price component follow the Design System specifications to render the price on all pages
 *
 * @Input price
 * @Input format
 * @Input size
 * @Input isIndicative
 */
@Component({
    selector: "h-price",
    templateUrl: "price.component.html",
    styleUrls: ["price.component.scss"],
})
export class PriceComponent {
    /**
     * Price value
     */
    @Input()
    public price!: number;
    /**
     * format of currency
     * by default it's undefined to use the default format of the currencyPipe ( Full in all LOCALES except JP)
     *
     * short : ($5)
     */
    @Input()
    public format: PriceFormat | undefined;

    /**
     * small        : 12px
     * medium       : 14px (default)
     * large        : 16px
     * extra-large  : 20px
     */
    @Input()
    public size: PriceSize = MEDIUM;

    /**
     * True when price type is indicative
     */
    @Input()
    public isIndicative? = false;

    @Input()
    public hasPrimaryColor: boolean = true;

    @Input()
    public weight: PriceWeight = NORMAL;
}
