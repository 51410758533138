import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";

import { XSRF_TOKEN_NAME } from "@hermes/utils/constants";

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService) {}

    public intercept<T, U>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<U>> {
        const csrfToken = this.cookieService.get(XSRF_TOKEN_NAME);

        if (csrfToken && !request.headers.has(XSRF_TOKEN_NAME)) {
            return next.handle(
                request.clone({
                    headers: request.headers.set(XSRF_TOKEN_NAME, csrfToken),
                }),
            );
        }

        return next.handle(request);
    }
}
