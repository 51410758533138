import {
    AdditionalNumber,
    City,
    CityBisId,
    Company,
    Country,
    District,
    DistrictBisId,
    DistrictBisName,
    Gender,
    Neighborhood,
    PhoneWithAreaCode,
    Postcode,
    PostcodeBis,
    Prefix,
    Region,
    Street1,
    Street2,
    Street3,
    StreetNumber,
    Telephone,
} from "./address-form.constants";

export const ACCOUNT_PATH = "/account/";
export const LOGIN_PATH = "/login/";
export const PROFIL_PATH = "/profil/";

export const ADDRESS = "address";
export type Address = typeof ADDRESS;
export const CPF_NUMBER = "cpfNumber";
export type CpfNumber = typeof CPF_NUMBER;
export const DATE_OF_BIRTH = "dateOfBirth";
export type DateOfBirth = typeof DATE_OF_BIRTH;
export const EMAIL = "email";
export type Email = typeof EMAIL;
export const FIRSTNAME = "firstname";
export type Firstname = typeof FIRSTNAME;
export const FIRSTNAME2 = "firstname2";
export type Firstname2 = typeof FIRSTNAME2;
export const LASTNAME = "lastname";
export type Lastname = typeof LASTNAME;
export const LASTNAME2 = "lastname2";
export type Lastname2 = typeof LASTNAME2;
export const LOGINPHONE = "loginphone";
export type Loginphone = typeof LOGINPHONE;
export const MIDDLENAME = "middlename";
export type Middlename = typeof MIDDLENAME;
export const NEWSLETTER = "newsletter";
export type Newsletter = typeof NEWSLETTER;
export const PASSWORD = "password";
export type Password = typeof PASSWORD;
export const PHONECODE = "phonecode";
export type Phonecode = typeof PHONECODE;
export const SOCIAL_SECURITY_NUMBER = "socialSecurityNumber";
export type SocialSecurityNumber = typeof SOCIAL_SECURITY_NUMBER;
export const VERIFICATION_CODE = "verificationCode";
export type VerificationCode = typeof VERIFICATION_CODE;
export const PIPL = "pipl";
export type Pipl = typeof PIPL;
export const COMMUNICATION_CONSENT = "communicationConsent";
export type CommunicationConsent = typeof COMMUNICATION_CONSENT;

export type AddressAccountFields =
    | City
    | CityBisId
    | Company
    | District
    | DistrictBisId
    | DistrictBisName
    | Postcode
    | PostcodeBis
    | Region
    | Street1
    | Street2
    | Street3
    | AdditionalNumber
    | Neighborhood
    | StreetNumber;

export type LoginInfoFields =
    | Email
    | Password
    | PhoneWithAreaCode
    | Phonecode
    | Loginphone
    | VerificationCode;

export type PersonalInfoFields =
    | Address
    | CpfNumber
    | Country
    | DateOfBirth
    | Firstname
    | Firstname2
    | Gender
    | Lastname
    | Lastname2
    | Loginphone
    | Middlename
    | Phonecode
    | Prefix
    | SocialSecurityNumber
    | Telephone
    | VerificationCode;

export type LegalInfoFields = Pipl | CommunicationConsent;
export const OLD_PASSWORD_INVALID = "old_password_invalid";
export const UPDATE_PASSWORD_GONE_WRONG = "update_password_gone_wrong";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";

export type UpdatePasswordErrorsResponseType =
    | typeof OLD_PASSWORD_INVALID
    | typeof UPDATE_PASSWORD_GONE_WRONG
    | typeof UPDATE_PASSWORD_SUCCESS;
export interface UpdatePasswordResponse {
    event: UpdatePasswordErrorsResponseType;
}
