import { Action, createReducer, on } from "@ngrx/store";

import {
    addToCart,
    addToCartFailure,
    addToCartSuccess,
} from "@hermes/states/basket";

import {
    changeButtonEnabledState,
    changeEcomErrorState,
    closeNotificationDisplayed,
    initAddToCartCallToAction,
    setAddToCartCallToActionsToDefault,
} from "../actions/add-to-cart.actions";
import * as fromAddToCart from "../add-to-cart.state";

import {
    addToCartProductFailureReducer,
    addToCartProductReducer,
    addToCartProductSuccessReducer,
    changeButtonEnabledStateReducer,
    changeEcomErrorStateReducer,
    closeNotificationDisplayedReducer,
    initCallToActionReducer,
    setAddToCartCallToActionsToDefaultReducer,
} from "./add-to-cart.reducer";

const initialState: fromAddToCart.AddToCartState =
    fromAddToCart.initialAddToCartState;

export const addToCartEditReducer = createReducer(
    initialState,
    on(addToCart, addToCartProductReducer),
    on(addToCartSuccess, addToCartProductSuccessReducer),
    on(addToCartFailure, addToCartProductFailureReducer),
    on(closeNotificationDisplayed, closeNotificationDisplayedReducer),
    on(
        setAddToCartCallToActionsToDefault,
        setAddToCartCallToActionsToDefaultReducer,
    ),
    on(initAddToCartCallToAction, initCallToActionReducer),
    on(changeButtonEnabledState, changeButtonEnabledStateReducer),
    on(changeEcomErrorState, changeEcomErrorStateReducer),
);

/**
 * Reducer for addToCart feature
 *
 * @param state current state of addToCart
 * @param action all addToCart actions
 */
export const addToCartReducer = (
    state: fromAddToCart.AddToCartState = fromAddToCart.initialAddToCartState,
    action: Action,
): fromAddToCart.AddToCartState => addToCartEditReducer(state, action);
