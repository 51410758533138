import {
    createFeatureSelector,
    createSelector,
    DefaultProjectorFn,
    MemoizedSelector,
} from "@ngrx/store";

import { Product, ProductGiftSetItem } from "@hermes/api-model-product";

import { GiftSetBoxSelectedItems } from "../types/giftset-product-page.types";

import {
    giftsetReference,
    seoGiftsetReference,
} from "./../helpers/giftset-product-reference.helper";
import {
    GiftSetBoxState,
    giftsetFeatureKey,
    GiftSetState,
} from "./../reducers/giftset-product-page.state";

/**
 * Product page state slice selector
 */

export const selectGiftSet =
    createFeatureSelector<GiftSetState>(giftsetFeatureKey);

// ---- subs-keys selectors :

export const selectGiftSetBox = createSelector(
    selectGiftSet,
    (giftSet: GiftSetState) => giftSet.box,
);

export const selectGiftSetListItems = createSelector(
    selectGiftSet,
    (giftSet: GiftSetState) => giftSet.listItems,
);

export const selectGiftSetToggleItems = createSelector(
    selectGiftSet,
    (giftSet: GiftSetState) => giftSet.toggleItems,
);

export const selectAvailableGiftSetItems = createSelector(
    selectGiftSetListItems,
    (listItems: ProductGiftSetItem[]) =>
        listItems.filter((item: ProductGiftSetItem) => item.stock?.ecom),
);

export const selectGiftSetBoxItems = createSelector(
    selectGiftSetBox,
    (box: GiftSetBoxState) => box.selectedItems,
);

export const selectGiftSetBoxItemToDelete = createSelector(
    selectGiftSetBox,
    (box: GiftSetBoxState) => box.toDelete,
);

export const selectGiftSetBoxItemsTotalPrice = createSelector(
    selectGiftSetBoxItems,
    (items: GiftSetBoxSelectedItems) =>
        Object.values(items.byId).reduce(
            (accumulator, currentItem) =>
                accumulator + Number.parseInt(currentItem.price, 10),
            0,
        ),
);

export const selectIsHeterogeneousGiftSet = createSelector(
    selectAvailableGiftSetItems,
    (giftsetItems) =>
        !giftsetItems
            .map((giftsetItem) => giftsetItem.price)
            .every((price, _index, array) => price === array[0]),
);

export const selectExpandGiftSetItem = (
    sku: string,
): MemoizedSelector<GiftSetState, boolean> =>
    createSelector(
        selectGiftSetToggleItems,
        (toggleItems: Record<string, boolean>) => toggleItems[sku],
    );

/**
 * GiftSet Product page state slice selector
 */
export const selectGiftSetItemQuantity = (
    sku: string,
): MemoizedSelector<GiftSetState, number> =>
    createSelector(
        selectGiftSetBox,
        (box: GiftSetBoxState) =>
            box.selectedItems.allIds.filter(
                (element) => box.selectedItems.byId[element].sku === sku,
            ).length,
    );

export const selectAddToCartGiftSetParameters = (
    product: Product,
): MemoizedSelector<
    object,
    { sku: string; skuSet: string } | undefined,
    DefaultProjectorFn<{ sku: string; skuSet: string } | undefined>
> =>
    createSelector(selectGiftSetBoxItems, (boxItems) => {
        if (
            boxItems &&
            boxItems.allIds &&
            boxItems.allIds.length === product.giftSetAttributes?.slot
        ) {
            const boxSelectedItemSkus: string[] = boxItems.allIds.map(
                (id: string) => boxItems.byId[id].sku,
            );

            return {
                sku: `${product.sku}-${boxSelectedItemSkus.join("-")}`,
                skuSet: product.sku,
            };
        }
        return undefined;
    });

/**
 * Product reference displayed to user
 */
export const selectCurrentGiftsetProductReference = createSelector(
    selectGiftSetBox,
    (giftSetBox) => giftsetReference(giftSetBox),
);

/**
 * Product reference provided to search engines
 */
export const selectCurrentSeoGiftsetProductReference = createSelector(
    selectGiftSetListItems,
    (giftSetListItems) =>
        seoGiftsetReference(
            giftSetListItems?.filter((item) => item.stock?.ecom),
        ),
);
