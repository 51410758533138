import { Product } from "@hermes/api-model-product";

import { ProductPageState } from "../../reducers/product-page.state";

/**
 * On fetch of double-fragrance product :
 * - there is no variant so no need to calculate wich is selected by default
 * - only calculate if product is OOS disable button and wich warning to display
 */
export const fetchDoubleFragranceProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => ({
    ...state,
    productSKU: product.sku,
    currentProduct: product,
    type: product.templateType,
    isLoading: false,
});
