import {
    CLICK_BACK_PRODUCT_PAGE,
    CLICK_CONTINUE_CHECKOUT,
    CLICK_PHONE_NUMBER,
    CLICK_SHIPPING_METHOD,
    CLICK_SUMMARY_ACCORDION,
} from "../constants/conversion-funnel-common.constants";
import { GTMEventData } from "../types/gtm.type";

import { CallToActionAnalyticsEvent } from "./call-to-action-click.event";

/**
 * This class is used for accordion-click event like open or close a accordion
 */
export class CartPageClickEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CLICK_BACK_PRODUCT_PAGE: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Cart",
                    eventaction: "BackProductPage",
                    eventlabel: this.eventData.label,
                };
            }
            case CLICK_CONTINUE_CHECKOUT: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Cart",
                    eventaction: "ContinueToCheckout",
                };
            }
            case CLICK_PHONE_NUMBER: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Cart",
                    eventaction: "NeedHelp",
                    eventlabel: "CallUs",
                };
            }
            case CLICK_SUMMARY_ACCORDION: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Delivery",
                    eventaction: "Summary",
                    eventlabel: this.eventData.label,
                };
            }
            case CLICK_SHIPPING_METHOD: {
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Delivery",
                    eventaction: "ShippingMethod",
                    eventlabel: this.eventData.label,
                };
            }
            default: {
                return {
                    ...super.formatForGTM(),
                };
            }
        }
    }
}
