import {
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    Renderer2,
    OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { fromEvent, merge, Subscription } from "rxjs";
import { tap, throttleTime } from "rxjs/operators";
import { v4 } from "uuid";

import { openTray } from "../actions/tray.actions";
import { TrayPosition } from "../models/tray-data.model";
import { State } from "../reducers/tray.reducer";

/**
 * Directive in charge of opening a tray dynamically.
 *
 * It sets the aria / accessibility features on the element it is applied to.
 * And it create and sends the tray data to the TrayStackService to be displayed in TrayContainerComponent
 * Usage:
 * ``` html
 * <a [hTrayOpener]="'country-selector-tray'" [trayPosition]="'left'" [trayOverlay]="false"></a>
 * ```
 */
@Directive({
    selector: "[hTrayOpener]",
})
export class TrayOpenerDirective implements OnInit, OnDestroy {
    /**
     * can take module path as argument for lazy-loaded trays
     * should be the same as path declared in `src/app/trays/trays.declaration.ts`
     */
    @Input()
    public hTrayOpener!: string;

    /**
     * true if overlay needs to be shown.
     */
    @Input()
    public trayOverlay!: boolean;

    /**
     * "left" or "right" (default "right")
     */
    @Input()
    public trayPosition: TrayPosition = "right";

    /**
     * translated title
     */
    @Input()
    public trayTitle!: string;

    /**
     * i18n translation key for the title
     */
    @Input()
    public trayTitleKey!: string;

    /**
     * true if tray from Drupal
     */
    @Input()
    public isDrupalTray!: boolean;

    @Input()
    public categoryInstitutional: boolean = false;

    @Input()
    public trayNoPaddingBottom: boolean = false;

    /**
     * Data that will be applied to the inputs of the component.
     */
    @Input()
    public trayMetadata!: Record<string, unknown>;

    /**
     * Background color for title. Default is white.
     */
    @Input()
    public titleBackgroundColor?: string;

    private subscription = new Subscription();

    /**
     *
     * @param elRef reference to the element on which the directive is applied.
     * @param renderer renderer for aot dom access.
     * @param trayStack service
     */
    constructor(
        private elementReference: ElementRef,
        private renderer: Renderer2,
        private store: Store<State>,
    ) {}

    /**
     * Sets accessibility attributes on the element.
     */
    public ngOnInit(): void {
        // sets accessibility attributes on the element.
        this.renderer.setAttribute(
            this.elementReference.nativeElement,
            "aria-haspopup",
            "dialog",
        );

        this.subscription.add(
            merge(
                fromEvent<Event>(this.elementReference.nativeElement, "click"),
                fromEvent<Event>(
                    this.elementReference.nativeElement,
                    "keydown.up",
                ),
            )
                .pipe(
                    tap((event) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }),
                    throttleTime(500), // prevent multiple tray opening
                )
                .subscribe(() => this.openTray()),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openTray(): void {
        this.store.dispatch(
            openTray({
                uuid: v4(),
                name: this.hTrayOpener,
                isDrupalTray: this.isDrupalTray,
                isOpen: true,
                hasOverlay: this.trayOverlay,
                hasCategoryInstitutional: this.categoryInstitutional,
                position: this.trayPosition,
                titleKey: this.trayTitle,
                metadata: this.trayMetadata,
                noPaddingBottom: this.trayNoPaddingBottom,
                titleBackgroundColor: this.titleBackgroundColor,
            }),
        );
    }
}
