import { InjectionToken } from "@angular/core";

import { LogConfig, Logger } from "@hermes/logger";

/**
 * Injection token for the logger name.
 */
export const LOGGER_NAME = "LOGGER_NAME";

/**
 * This injection token is used to inject the Logger implementation and still use the logger interface.
 */
export const LOGGER: InjectionToken<Logger> = new InjectionToken<Logger>(
    "LOGGER",
);

/**
 * This injection token allows the logger configuration to be provided differently in browser and server mode.
 */
export const LOGGER_CONFIG: InjectionToken<LogConfig> =
    new InjectionToken<LogConfig>("LOGGER_CONFIG");

export const LOGGER_CONFIG_FROM_SSR: InjectionToken<LogConfig> =
    new InjectionToken<LogConfig>("LOGGER_CONFIG_FROM_SSR");
