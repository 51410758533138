import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";

import { Subscription } from "rxjs";

import { ModalFacade } from "@hermes/states/modal";

import { MODAL_DECLARATIONS } from "../../model/modal.declaration";

@Component({
    selector: "h-modal-container",
    templateUrl: "./modal-container.component.html",
})
export class ModalContainerComponent implements AfterViewInit, OnDestroy {
    /**
     * Place to inject modal
     */
    @ViewChild("modalHost", { read: ViewContainerRef })
    public modalHost!: ViewContainerRef;

    /**
     * To manage list of subscription
     */
    private subscription: Subscription = new Subscription();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private modalFacade: ModalFacade,
        private cdr: ChangeDetectorRef,
    ) {}

    public ngAfterViewInit(): void {
        this.subscription.add(
            this.modalFacade.modalOpened$.subscribe((modalData) => {
                const modalComponent = MODAL_DECLARATIONS[modalData.type];

                const factory =
                    this.componentFactoryResolver.resolveComponentFactory(
                        modalComponent,
                    );
                const componentReference =
                    this.modalHost.createComponent(factory);
                componentReference.instance.modalData = modalData;
                this.cdr.detectChanges();
            }),
        );
        this.subscription.add(
            this.modalFacade.modalClosed$.subscribe(() => {
                // Add detach to avoid scroll to event
                this.modalHost.detach();
                this.modalHost.clear();
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
