/* eslint-disable rxjs-angular/prefer-composition */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { Context } from "@hermes/app-core";
import { UserStateService } from "@hermes/states/user";

import { InterceptorService } from "./interceptor.service";

/**
 * This interceptor displays a not found page when a backend returns a 404 response
 */
@Injectable()
export class CreateUserSessionOn401UnauthorizedInterceptor
    implements HttpInterceptor
{
    constructor(
        private context: Context,
        private interceptorService: InterceptorService,
        private userService: UserStateService,
    ) {}

    /**
     * Angular HTTP interceptor
     */
    public intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        // If the current interceptor is disabled for current route,
        // we only call next.handle without any custom process
        if (!this.interceptorService.isEnabled(this)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                if (error.status === 401 && this.context.isInBrowserMode()) {
                    return this.userService
                        .fetchCustomerSession()
                        .pipe(switchMap(() => throwError(() => error)));
                }

                // default case : technical error, rethrow the error so that it can be catched by the susbscribers or ErrorHandler if there is no error management on call
                return throwError(() => error);
            }),
        );
    }
}
