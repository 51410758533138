// Tray name
export const TRAY_ORDER = "tray-order";
export const TRAY_REQUEST_EXCHANGE_RETURN = "tray-request-exchange-return";
export const TRAY_EXCHANGE_RETURN_METHOD = "tray-exchange-return-method";
export const TRAY_EXCHANGE_RETURN_INSTRUCTIONS =
    "tray-exchange-return-instructions";
export const TRAY_EXCHANGE_RETURN_DETAILS = "tray-exchange-return-details";
export const TRAY_EDIT_ADDRESS = "tray-edit-address";

// Tray event
export const TRAY_OPEN = "open";
export const TRAY_CLOSE = "close";
export const TRAY_CLOSE_ALL = "closeAll";
export const TRAY_UPDATE = "update";
export const TRAY_UPDATE_TITLE_ON_ERROR = "updateTitleOnError";

// Misc
export const CLOSE_TRAY_ANIMATION_TIME = 350; // ms
