<div class="tray-title" *ngIf="tray" (click)="closeTray.emit($event)">
    <div
        class="accessibility-tray-title"
        cdkFocusInitial
        tabindex="0"
        (keydown.enter)="closeTray.emit($event)"
        (keydown.space)="closeTray.emit($event)">
        <div class="tray-title-i18n">
            <ng-template ngIf="tray.titleKey; else #translatedTitle">
                <span
                    role="heading"
                    [attr.aria-level]="2"
                    [ngSwitch]="tray.titleKey"
                    [id]="'tray-title-' + tray.uuid"
                    ngClass="font-title-large">
                    <!-- Add your tray title here -->
                    <!-- Product Locator tray title -->
                    <ng-container
                        *ngSwitchCase="'hermes-global-translations.filter-by'"
                        i18n="@@hermes-global-translations.filter-by">
                        Filter by
                    </ng-container>
                    <!-- Size guide tray title -->
                    <ng-container *ngSwitchCase="'hermes_commerce.size-guide'" i18n="@@hermes_commerce.size-guide">
                        Size Guide
                    </ng-container>
                    <!-- Menu tray title -->
                    <ng-container *ngSwitchCase="'hermes-categories-tree.shop'" i18n="@@hermes-categories-tree.shop">
                        Menu
                    </ng-container>
                    <!-- Shop locations countries (or cities) tray title -->
                    <ng-container
                        *ngSwitchCase="'hermes-country-selector-tray.country'"
                        i18n="@@hermes-country-selector-tray.country">
                        Location
                    </ng-container>
                    <!-- Product Locator tray title -->
                    <ng-container
                        *ngSwitchCase="'hermes.commerce.product_locator.title'"
                        i18n="@@hermes.commerce.product_locator.title">
                        Find in store
                    </ng-container>
                    <!-- E-Reservation tray title -->
                    <ng-container
                        *ngSwitchCase="'hermes.e-reservation.tray-title'"
                        i18n="@@hermes.e-reservation.tray-title">
                        E-reservation
                    </ng-container>
                    <!-- Back in stock tray title -->
                    <ng-container
                        *ngSwitchCase="'hermes.back-in-stock.tray.subscription.title'"
                        i18n="@@hermes.back-in-stock.tray.subscription.title">
                        Notify me
                    </ng-container>
                    <ng-container
                        *ngSwitchCase="'hermes-global-translations.filter'"
                        i18n="@@hermes-global-translations.filter">
                        Filter
                    </ng-container>
                    <!-- Default case that should not happen -->
                    <ng-container *ngSwitchDefault i18n="@@hermes-global.translations.translation-key-not-found">
                        Translation key not found : {{ tray.titleKey }}
                    </ng-container>
                </span>
            </ng-template>
            <ng-template #translatedTitle>
                {{ tray.translatedTitle }}
            </ng-template>
        </div>
        <button
            class="tray-close-button"
            tabindex="-1"
            i18n-aria-label="@@hermes.accessibility.close_tray"
            aria-label="Close"
            [attr.aria-describedby]="'tray-title-' + tray.uuid">
            <h-svg-icon [name]="'cross-rounded'" class="tray-close-icon mobile-only"> </h-svg-icon>
            <h-svg-icon [name]="'cross-rounded'" class="tray-close-icon desktop-only"> </h-svg-icon>
        </button>
    </div>
</div>
