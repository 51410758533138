import { Action, createReducer, on } from "@ngrx/store";

import { GridMenuEntry } from "@hermes/api-model-shell";

import {
    fetchMenu,
    fetchMenuFailure,
    fetchMenuSuccess,
} from "../actions/menu.action";
import { flattenMenuToCategories } from "../helpers/categories.helper";
import {
    extractLinksFromEntries,
    removeInvisibleEntries,
} from "../helpers/entry.helper";
import { FlatCategories, MenuEntryItem, MenuLink } from "../model/menu.model";

export const menuFeatureKey = "menu";

export interface State {
    entry: MenuEntryItem | undefined;
    categories: FlatCategories | undefined;
    links: MenuLink[] | undefined;
    pending: boolean;
}

export const initialState: State = {
    entry: undefined,
    categories: undefined,
    links: undefined,
    pending: false,
};

const menuReducer = createReducer(
    initialState,
    on(fetchMenu, fetchMenuReducer),
    on(fetchMenuSuccess, fetchMenuSuccessReducer),
    on(fetchMenuSuccess, extractCategoriesReducer),
    on(fetchMenuFailure, fetchMenuFailureReducer),
);

export function reducer(state: State | undefined, action: Action): State {
    return menuReducer(state, action);
}

export function fetchMenuReducer(
    state: State,
    _action: ReturnType<typeof fetchMenu>,
): State {
    return {
        ...state,
        pending: true,
    };
}

export function fetchMenuSuccessReducer(
    state: State,
    action: ReturnType<typeof fetchMenuSuccess>,
): State {
    const rootEntry: MenuEntryItem = removeInvisibleEntries(action.entry);
    return {
        ...state,
        entry: rootEntry,
        links: extractLinksFromEntries(rootEntry.menuEntryList),
        pending: false,
    };
}

export function fetchMenuFailureReducer(
    state: State,
    _action: ReturnType<typeof fetchMenuFailure>,
): State {
    return {
        ...state,
        pending: false,
    };
}

export function extractCategoriesReducer(
    state: State,
    action: ReturnType<typeof fetchMenuSuccess>,
): State {
    return {
        ...state,
        categories: flattenMenuToCategories(
            (action.entry as GridMenuEntry)?.menuEntryList,
        ),
    };
}
