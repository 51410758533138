import { APP_BASE_HREF, CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
} from "@angular/core";

import { Observable, filter, first, map, switchMap, tap } from "rxjs";

import { CustomerService } from "@hermes/api-model-editorial";
import { COUNTRY } from "@hermes/app-core";
import { isChina, Country } from "@hermes/locale";
import {
    CustomerServiceContactFacade,
    CustomerServiceContactStateModule,
} from "@hermes/states/customer-service-contact";
import {
    ENABLE_CONTACT_US_FROM_DISCOVERY,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { ShellFacade, ShellStateModule } from "@hermes/states/shell";
import { PhoneNumberPipeModule } from "@hermes/utils-generic/pipes/phone-number";
import { RouterService } from "@hermes/utils-generic/services/router";

import { footerHereToHelpColumnContactsToCustomerServiceMapper } from "./mapper/customer-service-contact.mapper";
import { OpeningHoursPipeModule } from "./pipe/opening-hours/opening-hours.pipe";
import { CustomerServiceContactAnalyticsService } from "./service/customer-service-contact-analytics.service";

export const FONT_SIZE_MEDIUM = "medium";
export const FONT_SIZE_LARGE = "large";
export type FontSize = typeof FONT_SIZE_MEDIUM | typeof FONT_SIZE_LARGE;

@Component({
    selector: "h-customer-service-contact",
    standalone: true,
    imports: [
        CommonModule,
        OpeningHoursPipeModule,
        PhoneNumberPipeModule,
        CustomerServiceContactStateModule,
        ShellStateModule,
    ],
    providers: [CustomerServiceContactAnalyticsService],
    templateUrl: "./customer-service-contact.component.html",
    styleUrls: ["./customer-service-contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerServiceContactComponent {
    @Input()
    public showEmailUs: boolean = false;

    @Input()
    public fontSize: FontSize = FONT_SIZE_LARGE;

    public isChina = isChina(this.country);

    public customerServiceConctact$: Observable<CustomerService[] | undefined>;

    public customerServiceContactsFromFooterItems$: Observable<
        CustomerService[]
    >;

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(COUNTRY) private country: Country,
        private shellFacade: ShellFacade,
        private customerServiceContactAnalyticsService: CustomerServiceContactAnalyticsService,
        private routerService: RouterService,
        private featureFlagFacade: FeatureFlagFacade,
        private customerServiceContactFacade: CustomerServiceContactFacade,
    ) {
        this.customerServiceContactsFromFooterItems$ =
            this.shellFacade.footerItems$.pipe(
                tap((data) => {
                    if (!data) {
                        this.shellFacade.fetchFooterItems();
                    }
                }),
                filter((footerItems) => footerItems !== undefined),
                map((footerItems) =>
                    footerHereToHelpColumnContactsToCustomerServiceMapper(
                        footerItems.hereToHelp.contacts,
                    ),
                ),
                first(),
            );

        this.customerServiceConctact$ = this.featureFlagFacade
            .isActivated(ENABLE_CONTACT_US_FROM_DISCOVERY)
            .pipe(
                switchMap((isContactUsFromDiscovery: boolean) =>
                    isContactUsFromDiscovery
                        ? this.customerServiceContactFacade.getCustomerServiceContacts()
                        : this.customerServiceContactsFromFooterItems$,
                ),
            );
    }

    public sendEventClickToCall(): void {
        this.customerServiceContactAnalyticsService.sendClickContactUs(
            "call_us",
        );
    }
    public goToContactUsPage(event: MouseEvent): void {
        this.customerServiceContactAnalyticsService.sendClickContactUs(
            "email_us",
        );
        event.preventDefault();
        this.routerService.spaRedirect("/contact-us/");
    }
}
