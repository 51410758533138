import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { Context } from "./services/context";
import { Settings } from "./services/settings";
import { StorageService } from "./services/storage.service";
import { WINDOW_PROVIDERS } from "./services/window.service";

@NgModule({
    imports: [CommonModule],
    providers: [Context, Settings, StorageService, ...WINDOW_PROVIDERS],
})
export class AppCoreModule {}
