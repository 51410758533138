import { Component, Inject, Input } from "@angular/core";

import { FooterLegalLink } from "@hermes/api-model-shell";
import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

@Component({
    selector: "h-footer-legal-links",
    templateUrl: "./footer-legal-links.component.html",
    styleUrls: ["./footer-legal-links.component.scss"],
})
export class FooterLegalLinksComponent {
    /**
     * Legal links are reordered (Display order != Contributed order)
     *
     * @see contributedLegalLinks for more details on reorder.
     */
    public legalLinks: FooterLegalLink[] = [];

    public chinaLicenseLogo = `${this.locale.urlPrefix}/assets/images/china-license.png`;
    public chinaNetworkSecurityLogo = `${this.locale.urlPrefix}/assets/images/china-network-security.png`;

    constructor(@Inject(LOCALE) private locale: Locale) {}

    /**
     * Legal links have a strict behavior :
     * - Third contribution is displayed first with only a image
     * - First contribution is displayed then with only a label
     * - Second contribution is displayed after with an image and a label
     * - Every other contribution is displayed after with only a label
     *
     * If some legal links are not contributed, none will be displayed since we cannot define the right order.
     */
    @Input()
    public set contributedLegalLinks(legalLinks: FooterLegalLink[]) {
        if (legalLinks.length > 2) {
            this.legalLinks = [
                legalLinks[2],
                legalLinks[0],
                legalLinks[1],
                ...legalLinks.slice(3),
            ];
        }
    }
}
