import { Action, createReducer, on } from "@ngrx/store";

import * as ModalActions from "../actions/modal.actions";
import {
    ClosedModalState,
    ModalState,
    OpenedModalState,
} from "../state/modal.state";

export const initialState: ClosedModalState = {
    isOpened: false,
    success: false,
};

const modalReducer = createReducer<ModalState>(
    initialState,
    on(ModalActions.openModal, openModalReducer),
    on(ModalActions.closeModal, closeModalReducer),
);

export function reducer(state: ModalState, action: Action): ModalState {
    return modalReducer(state, action);
}

function openModalReducer(
    state: ModalState,
    action: ReturnType<typeof ModalActions.openModal>,
): OpenedModalState {
    return {
        isOpened: true,
        data: action.data,
    };
}

function closeModalReducer(
    state: ModalState,
    action: ReturnType<typeof ModalActions.closeModal>,
): ClosedModalState {
    return {
        isOpened: false,
        success: action.success ?? false,
    };
}
