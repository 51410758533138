import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { IConfig } from "ngx-mask";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { delay, filter, withLatestFrom } from "rxjs/operators";

import { BaseInputComponent } from "../base-input/base-input.component";
import { InputComponent } from "../input/input.component";

/**
 * This component implements a global Search Input Component.
 *
 * It displays the search input AND the display of a drop-down list but does not manage the business rules between these two visual elements.
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * You can desactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-search-input
 *      [controlName]="'search'"
 *      [id]="'search-id'"
 *      [name]="'search'"
 *      [dataTestId]="'search'"
 *      [autocomplete]="'off'"
 *      [ariaAutoComplete]="'inline'"
 *      [ariaControls]="'aria-controls-id'"
 *      label="My label translated into english"
 *      i18n-label="@@my.key.translate"
 *      placeholder="My placeholder translated into english"
 *      i18n-placeholder="@@my.key.translate"
 *      describe="My describe"
 *      i18n-describe="@@my.key.translate"
 *      [errorManagement]="true"
 *      [showSuggestions]="true"
 *      (handleKeyDown)="handleKeyDown($event)"
 *      (selectNewSuggestion)="selectNewSuggestion($event)"
 *      [ariaDescribedby]="id-label">
 *
 *      <h-message-block errors *ngIf="serviceError" [type]="'error'">
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *
 *       <ng-template #hSearchInputSuggestion *ngFor="let suggestion of suggestions">
 *           {{ suggestion }}
 *       </ng-template>
 *
 *  </h-search-input>
 * ```
 *
 * Note :
 * Exceptionally you must add `errors` attribute to yours errors message elements.
 * @see https://angular.io/guide/content-projection#multi-slot-content-projection
 *
 */

@Component({
    selector: "h-search-input",
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent
    extends BaseInputComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    /**
     * Observe form the content DOM if this component has custom tab table template to display
     */
    @ContentChildren("hSearchInputSuggestion")
    public searchInputSuggestions!: QueryList<TemplateRef<unknown>>;

    @ViewChild(InputComponent)
    public input!: InputComponent;

    @ViewChild("clearButton")
    public clearButton!: ElementRef;

    @Input()
    public dataTestId?: string;

    /**
     * Boolean input to overload the value of hasRightIcon in input field.
     */
    @Input()
    public hasRightIcon: boolean = false;

    /**
     * See how to use IMask package : https://imask.js.org/guide.html or https://github.com/uNmAnNeR/imaskjs/tree/master/packages/angular-imask
     */
    @Input()
    public mask: string = "";
    /**
     * Set to define specific pattern - see how to use ngx-mask package : https://github.com/JsDaddy/ngx-mask
     */
    @Input()
    public maskPattern?: IConfig["patterns"];
    /**
     * Set the special characters you want to allow in input
     */
    @Input()
    public maskSpecialCharacters?: string[];

    @Input()
    public setMinHeight: boolean = false;

    @Input()
    public showClearButton: boolean = true;

    /**
     * Boolean input to easly manage the display of dropdown list.
     */
    @Input()
    public showSuggestions$: BehaviorSubject<boolean> = new BehaviorSubject(
        false,
    );

    @Input()
    public showSearchIcon: boolean = true;

    @Input()
    public maxLength?: string;

    @Input()
    public minLength?: string;

    /**
     * Emit event when one of these keys is pressed : enter / arrowdown / arrowup / escape
     */
    @Output()
    public handleKeyDown = new EventEmitter<KeyboardEvent>();

    @Output()
    public eventOnBlur: EventEmitter<void> = new EventEmitter<void>();
    /**
     * Emit event when new suggestion is selected in the dropdown list. The event is the position of the suggestion in the dropdown list.
     */
    @Output()
    public selectNewSuggestion = new EventEmitter<number>();

    public currentSelectedSuggestion: number = 0;

    public readonly suggestionItemBaseName = "search-suggestion-item-";

    public inputFocusOut$: Subject<void> = new Subject();

    private subscription = new Subscription();

    constructor(protected override controlContainer: ControlContainer) {
        super(controlContainer);
    }

    @HostListener("focusout", ["$event"])
    public focusOut = (event: FocusEvent) => {
        if (event.target instanceof HTMLInputElement) {
            this.inputFocusOut$.next();
        }
    };

    @HostListener("keydown.enter", ["$event"])
    @HostListener("keydown.arrowdown", ["$event"])
    @HostListener("keydown.arrowup", ["$event"])
    @HostListener("keydown.escape", ["$event"])
    public keyDownEvent(event: KeyboardEvent): void {
        /**
         * The Enter key should not submit a parent form and should not emit
         * if the key pressed is on the clear button.
         *
         * In this case, we simply ignore the event.
         */
        if (
            this.showClearButton &&
            this.clearButton.nativeElement === event.target
        ) {
            return;
        }
        this.handleKeyDown.emit(event);
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.inputFocusOut$
                .pipe(
                    withLatestFrom(this.showSuggestions$),
                    filter(([, showSuggestions]) => showSuggestions),
                    delay(100),
                )
                .subscribe(() => {
                    this.showSuggestions$.next(false);
                }),
        );

        this.subscription.add(
            this.handleKeyDown
                .pipe(
                    filter((event) =>
                        ["ArrowDown", "ArrowUp", "Enter"].includes(event.key),
                    ),
                )
                .subscribe((event) => {
                    event.preventDefault();
                    switch (event.key) {
                        case "Enter": {
                            /**
                             * In the case of the enter key, if it has already been pressed, no event is re-emitted.
                             *
                             * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/repeat
                             */
                            if (!event.repeat) {
                                this.selectSuggestion();
                                this.showSuggestions$.next(false);
                            }
                            break;
                        }
                        case "ArrowDown": {
                            this.focusNext();
                            break;
                        }
                        case "ArrowUp": {
                            this.focusPrevious();
                            break;
                        }
                        default:
                        // Do nothing
                    }
                }),
        );
    }

    public ngAfterViewInit(): void {
        this.subscription.add(
            this.searchInputSuggestions.changes.subscribe(() => {
                this.currentSelectedSuggestion = 0;
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public clearButtonClick(): void {
        this.control.setValue("");
        this.input.focusInput();
    }

    public override focusInput(): void {
        this.input.focusInput();
    }

    private selectSuggestion(): void {
        this.selectNewSuggestion.emit(this.currentSelectedSuggestion);
    }

    private focusNext(): void {
        if (
            this.currentSelectedSuggestion + 1 ===
            this.searchInputSuggestions.length
        ) {
            this.currentSelectedSuggestion = 0;
            return;
        }
        this.currentSelectedSuggestion += 1;
    }

    private focusPrevious(): void {
        if (this.currentSelectedSuggestion === 0) {
            this.currentSelectedSuggestion =
                this.searchInputSuggestions.length - 1;
            return;
        }
        this.currentSelectedSuggestion -= 1;
    }
}
