import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

import {
    CHECKOUT_ACCOUNT_BACK_BUTTON,
    CHECKOUT_BACK_BUTTON,
    EVENT_CATEGORY_CHECKOUT_ACCOUNT_BACK_BUTTON,
} from "./back-button-checkout.constants";

export type GTMBackButtonEventData = GTMEventData & {
    eventType:
        | typeof CHECKOUT_BACK_BUTTON
        | typeof CHECKOUT_ACCOUNT_BACK_BUTTON;
};

export class BackButtonCheckoutAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: GTMBackButtonEventData;

    constructor(data: Record<string, unknown>) {
        this.eventData = data as unknown as GTMBackButtonEventData;
    }

    public formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CHECKOUT_BACK_BUTTON: {
                return {
                    event: this.name,
                    eventcategory: "Checkout",
                    eventaction: "BackButton",
                };
            }
            case CHECKOUT_ACCOUNT_BACK_BUTTON: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ACCOUNT_BACK_BUTTON,
                    eventaction: "BackButton",
                };
            }
            default: {
                throw new Error(
                    `Invalid eventType for back-button: '${this.eventData.eventType}'`,
                );
            }
        }
    }
}
