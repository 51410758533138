import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

import { PaymentMethodsResponseDTO } from "../models/payment-methods-response-dto.model";

@Injectable()
export class FetchPaymentMethodsService {
    constructor(
        private settings: Settings,
        private http: HttpClient,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public fetchPaymentMethods(): Observable<PaymentMethodsResponseDTO> {
        const url = `${this.settings.apiUrl}/available-payment-methods`;
        const options = {
            params: {
                locale: this.locale.code,
                legacy: false,
            },
        };

        return this.http.get<PaymentMethodsResponseDTO>(url, options);
    }
}
