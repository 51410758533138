import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { CMSService } from "@hermes/utils/services/api-clients";

import { MenuEffects } from "./effects/menu.effects";
import { MenuFacade } from "./facades/menu.facade";
import * as fromMenu from "./reducers/menu.reducer";
import { MenuService } from "./services/menu.service";

@NgModule({
    imports: [
        StoreModule.forFeature(fromMenu.menuFeatureKey, fromMenu.reducer),
        EffectsModule.forFeature([MenuEffects]),
    ],
    exports: [],
    declarations: [],
    providers: [MenuFacade, MenuService, CMSService],
})
export class MenuStateModule {}
