import {
    Product,
    ProductItemLook,
    ProductSizeVariant,
} from "@hermes/api-model-product";
import {
    CartAnalyticsEvent,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    NODATA,
    NO_SIZE,
    PRODUCT_IN_STOCK,
    getStockTypeAndDisplayOnly,
} from "@hermes/utils/analytics";

/**
 * This class is used for add-to-cart event in cart page for a product set
 */
export class AddToCartLookItem extends CartAnalyticsEvent {
    public override eventData: {
        currencyCode?: string;
        look: Product;
        item: ProductItemLook;
        variant: ProductSizeVariant;
    };

    constructor(data: {
        currencyCode?: string;
        look: Product;
        item: ProductItemLook;
        variant: ProductSizeVariant;
    }) {
        super(data);
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.look,
                            this.eventData.item,
                            this.eventData.variant,
                        ),
                    ],
                },
            },
        };
    }

    /**
     * @param look the look that was displayed when the add to cart happened
     * @param item the item added to cart
     * @param variant the specific variant added to cart, if relevant
     */
    private getProductData(
        look: Product,
        item: ProductItemLook,
        variant?: ProductSizeVariant,
    ): GTMEcommerceItemData {
        return {
            name: item.title,
            id: item.sku ?? variant?.sku ?? NODATA,
            price: item.price?.toFixed(2) ?? NODATA,
            brand: "hermes",
            category: `${item.departmentCode}/${item.familyCode}`,
            quantity: 1,
            variant: look.sku,
            dimension5: variant?.size ?? NO_SIZE,
            dimension6: item.departmentCode,
            dimension7: item.familyCode,
            dimension8: item.productCode,
            dimension9: item.divisionCode,
            dimension10: look.sku,
            dimension37: PRODUCT_IN_STOCK,
            dimension52: "no",
            dimension53: getStockTypeAndDisplayOnly(item.stock),
            dimension54: "no",
        };
    }
}
