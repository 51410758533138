import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ShellEffects } from "./effects/shell.effects";
import { ShellFacade } from "./facade/shell.facade";
import { reducers } from "./reducers/shell.reducer";
import { BannerService } from "./services/banner.service";

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([ShellEffects]),
        StoreModule.forFeature("shellModule", reducers),
    ],
    providers: [ShellFacade, BannerService],
})
export class ShellStateModule {}
