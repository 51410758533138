import { ModelObject } from "@hermes/api-model-core";

import { AvailableStorage } from "../constants/storage.constant";

export class StorageManager {
    private static localStorageInstance: StorageManager;
    private static sessionStorageInstance: StorageManager;

    // Important: do not use a suffix that starts with an underscore
    private static expirationSuffix: string = "EXPIRES_ON";

    private storageType: AvailableStorage = AvailableStorage.LOCAL;
    private webStorage: Storage | undefined;

    private constructor(public currentStorage: AvailableStorage) {
        this.storageType = currentStorage;
        this.init();
    }

    public static getSessionStorageInstance(): StorageManager {
        if (!StorageManager.sessionStorageInstance) {
            StorageManager.sessionStorageInstance = new StorageManager(
                AvailableStorage.SESSION,
            );
        }
        return StorageManager.sessionStorageInstance;
    }

    public static getLocalStorageInstance(): StorageManager {
        if (!StorageManager.localStorageInstance) {
            StorageManager.localStorageInstance = new StorageManager(
                AvailableStorage.LOCAL,
            );
        }
        return StorageManager.localStorageInstance;
    }

    /**
     * Allow to check support
     * Switch from localStorage to sessionStorage if local storage is not available
     */
    private init() {
        if (
            !this.checkSupport() &&
            this.storageType === AvailableStorage.LOCAL
        ) {
            this.storageType = AvailableStorage.SESSION;
            console.warn(
                "The LocalStorage is not Avaialble, the sessionStorage will be used instead.",
            );
            if (!this.checkSupport()) {
                console.warn("The Switch to the sessionStorage has failed.");
            }
            return;
        }
        this.removeExpiredData();
    }

    /**
     * Funtion that allow to get an item with a given key
     *
     * @param key Key of the value to get from storage
     * @param destinationClass Class to parse to (optional)
     * @returns a value parsed in object or in the class passed in params
     */
    public getItem<S>(
        key: string,
        destinationClass?: new () => S,
    ): S | undefined {
        try {
            if (this.checkSupport() && !this.isExpired(key)) {
                const storageString = this.readItemFromStorage(key);
                const storageValue =
                    storageString !== undefined
                        ? JSON.parse(storageString)
                        : undefined;
                return destinationClass
                    ? ModelObject.fromJsonData(storageValue, destinationClass)
                    : (storageValue as S);
            }
            return undefined;
        } catch (error: Error | unknown) {
            if (error instanceof Error) {
                console.warn(error.message);
            }
            return undefined;
        }
    }

    /**
     * Allow to read an item with the key from the defined storage
     */
    private readItemFromStorage(key: string): string | undefined {
        const storageValue = this.webStorage
            ? this.webStorage.getItem(this.normalizeKey(key))
            : undefined;

        if (!storageValue || storageValue === "null") {
            return undefined;
        }
        return storageValue;
    }

    /**
     * To get a storage value who is not a JSON
     *
     * @param key key to get into storage
     */
    public getStringItem(key: string): string | undefined {
        if (!this.checkSupport() || this.isExpired(key)) {
            return undefined;
        }

        try {
            return this.readItemFromStorage(key);
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.warn(e.message);
            }
            return undefined;
        }
    }

    /**
     * Function to set an item in the chosen storage
     *
     * @param key Key of the data to store
     * @param data Data to store
     * @param expireIn optional option to define the expiration delay in minutes
     */
    public setItem<T>(key: string, data: T, expireIn?: number): void {
        if (!this.checkSupport()) {
            return undefined;
        }
        this.webStorage?.setItem(this.normalizeKey(key), JSON.stringify(data));

        if (expireIn) {
            this.setExpirationDate(key, expireIn);
        }
    }

    /**
     * Destroy the data corresponding to the key in param
     *
     * @param key key to destroy
     */
    public deleteItem(key: string): void {
        if (this.checkSupport()) {
            this.webStorage?.removeItem(this.normalizeKey(key));
        }
    }

    /**
     * Allow to check if the chosen storage is reachable
     */
    private checkSupport(): boolean {
        try {
            const supported =
                this.storageType in window &&
                window[this.storageType] !== undefined;

            if (supported) {
                this.webStorage = window[this.storageType];

                // Generate a random number from 0 to 10000000
                const key = `__${Math.round(Math.random() * 1e7)}`;
                this.webStorage.setItem(key, "");
                this.webStorage.removeItem(key);
            }
            return supported;
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.error(e.message);
            }
            return false;
        }
    }

    /**
     * Allow to check if the var related to the given key is expired
     */
    private isExpired(key: string): boolean {
        const expirationValue = this.readItemFromStorage(
            this.addSuffixToKey(key),
        );
        if (
            expirationValue !== undefined &&
            Date.now() > parseInt(JSON.parse(expirationValue), 10)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Allow to set an expiration date to a given Key
     * 1- we add a suffix to the key
     * 2- computes the sum of the current date with the delay converted to millisecond
     * 3- we save the expiration date with the expiration value
     */
    private setExpirationDate(
        key: string,
        expirationDelayInMinutes: number,
    ): void {
        const expireDelayinMs: number = expirationDelayInMinutes * 60 * 1000;
        this.setItem(this.addSuffixToKey(key), Date.now() + expireDelayinMs);
    }

    /**
     * Allow to remove all expired key && expiration key from webstorage
     */
    private removeExpiredData(): void {
        if (!this.checkSupport()) {
            return;
        }

        Object.keys(this.webStorage as Record<string, unknown>)
            .filter(
                (key) =>
                    this.stringEndWith(key, StorageManager.expirationSuffix) &&
                    this.isExpired(this.removeSuffixFromKey(key)),
            )
            .forEach((key) => {
                try {
                    this.deleteItem(key);
                    this.deleteItem(this.removeSuffixFromKey(key));
                } catch (e: unknown) {
                    if (e instanceof Error) {
                        console.error(e.message);
                    }
                }
            });
    }

    /**
     * Endwidth doesn't work with IE,this solution is used instead
     */
    private stringEndWith(key: string, keySuffix: string): boolean {
        return key.indexOf(keySuffix, key.length - keySuffix.length) !== -1;
    }

    /**
     * Add suffix to key
     */
    private addSuffixToKey(key: string): string {
        return `${key}${StorageManager.expirationSuffix}`;
    }

    /**
     * Remove the suffix from the key
     */
    private removeSuffixFromKey(expirationKey: string): string {
        return expirationKey.substring(
            0,
            expirationKey.lastIndexOf(StorageManager.expirationSuffix),
        );
    }

    /**
     * Allow to replace all non-numeric char to '_'
     */
    private normalizeKey(key: string): string {
        return key.replace(/[^\w\d]/g, "_").replace(/_{2,}/g, "_");
    }
}
