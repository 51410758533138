import AJV from "ajv";
import addFormats from "ajv-formats";

import { Logger } from "@hermes/logger";

import {
    PlatformeConfigResponse,
    PlatformeInitialConfiguration,
} from "../types/product-personalization.type";

// We used https://app.quicktype.io/ to generate Shemas
export const validateSchema = (
    jsonSchemaCore: Record<string, unknown>,
    data: PlatformeConfigResponse | PlatformeInitialConfiguration,
    caller: string,
    logger: Logger,
): void => {
    if (!jsonSchemaCore || !data) {
        return;
    }

    const ajv = new AJV({ allErrors: true });

    /**
     * this is mandatory to be able to use "format" keyword in the JSON Schema
     * Check this link for more info https://ajv.js.org/guide/formats.html
     */
    addFormats(ajv);

    const valid = ajv.validate(jsonSchemaCore, data);

    if (!valid) {
        logger.warn(`${caller} data is INVALID`);
        logger.warn(JSON.stringify(ajv.errors));
    }
};
