import { isPlatformBrowser } from "@angular/common";
import {
    FactoryProvider,
    Injectable,
    InjectionToken,
    PLATFORM_ID,
} from "@angular/core";

// Create a new injection token for injecting the window into a component.
export const WINDOW = new InjectionToken<Window | Record<string, unknown>>(
    "WindowToken",
);

// Angular wrapper for obtaining reference to the global window object.
@Injectable()
class WindowRef {
    public get nativeWindow(): Window | Record<string, unknown> {
        return window;
    }
}

// Create an factory function that returns the native window object.
function windowFactory(
    windowRef: WindowRef,
    platformId: Record<string, unknown>,
): Window | Record<string, unknown> {
    if (isPlatformBrowser(platformId)) {
        return windowRef.nativeWindow;
    }
    return {};
}

// Create an injectable provider that uses the windowFactory function for returning the native window object.
const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [WindowRef, PLATFORM_ID],
};

// group providers.
export const WINDOW_PROVIDERS = [
    windowProvider,
    { provide: WindowRef, useClass: WindowRef },
];
