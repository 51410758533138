import { createAction, props } from "@ngrx/store";

import { ModalData } from "../model/modal.model";

export const openModal = createAction(
    "[Modal] Open Modal",
    props<{ data: ModalData }>(),
);

export const closeModal = createAction(
    "[Modal] Close Modal",
    props<{ success?: boolean }>(),
);
