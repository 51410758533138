import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

/**
 * Abstract class for image-gallery event
 */
export abstract class ImageGalleryAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly image-gallery event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: "SingleProductPage",
        };
    }
}
