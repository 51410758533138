<div class="message-wrapper" *ngIf="message">
    <h2 class="title-large" *ngIf="message.title">
        {{ message.title }}
    </h2>

    <p class="title-small">
        {{ message.description }}
    </p>

    <p *ngIf="message.action === 'refresh-page'">
        <a
            href=""
            id="notif-refresh-page-link"
            (click)="reloadCurrentPath()"
            i18n="@@hermes-global-translations.try-again">
            Try again
        </a>
    </p>

    <p *ngIf="message.action === 'back-to-home'">
        <a href="" id="notif-back-to-home-link" (click)="backToHome()" i18n="@@hermes-global-translations.home">
            Go back to home
        </a>
    </p>
</div>
