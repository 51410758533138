/**
 * Cleanup sku url to get the proper sku.
 *
 * @param urlSku the urlSku
 * @return the proper sku
 */
export const urlSkuToSku = (urlSku: string): string =>
    urlSku
        // replace all p by '-'
        .replace(/p/g, "-")
        // replace all 'v' by spaces
        .replace(/v/g, " ");

/**
 * Format sku to get the proper url sku.
 *
 * @param sku the sku
 * @return the proper url sku
 */
export const skuToUrlSku = (sku: string): string =>
    sku
        // replace all '-' by p
        .replace(/-/g, "p")
        // replace all spaces by 'v'
        .replace(/ /g, "v");

/**
 * Format sku to get the proper url sku.
 *
 * @param sku the sku
 * @return the proper url sku
 */
export const skuToId = (sku: string): string =>
    sku
        // replace all spaces by '_'
        .replace(/ /g, "_");

/**
 * formatting product SKU for Product locator services
 */
export const formatSkuForPLServices = (urlSku: string): string =>
    urlSku.replace(/[\s_pv]/g, "-");

/**
 * Format the SKU to the one expected by scene7 :
 * no v but spaces, no first char, no size indication.
 */
export const formatSkuForImage = (fullSKU: string): string => {
    // sku in scene 7 does not have fist char and spaces is valid
    let cleanSKU = fullSKU.replace(/^.(.*)/, "$1").replace("v", " ");
    cleanSKU = cleanSKU.slice(0, 10);
    cleanSKU = cleanSKU.replace("/", ".");
    cleanSKU = cleanSKU.includes("_") ? cleanSKU.split("_")[0] : cleanSKU;

    return cleanSKU;
};

export const isSimpleProduct = (sku: string): boolean =>
    !isBeltkit(sku) &&
    !isAppleWatch(sku) &&
    !isDoubleFragance(sku) &&
    !isGiftSet(sku) &&
    !isLook(sku) &&
    !isBikini(sku);

export const isBeltkit = (sku: string): boolean => {
    const regex = /^U_BELT/;
    return regex.test(sku);
};

export const isAppleWatch = (sku: string): boolean => {
    const regex = /_KIT|\dAWH/;
    return regex.test(sku);
};

export const isDoubleFragance = (sku: string): boolean => {
    const regex = /^V2(?:HERMESSENCE|LOCKSGIFT|LEATHERCASE)/;
    return regex.test(sku);
};

export const isGiftSet = (sku: string): boolean => {
    const regex =
        /^V(3|4|8|12)(?:COLOGNEGIFT|HERMESSENCE|NOMADEGIFT|SOAPGIFTSET|.*BEAUTYSET.*)/;
    return regex.test(sku);
};

export const isLook = (sku: string): boolean => {
    const regex = /H([AP])\d\d(LKDP|LKDC).*HOMME|D([AP])\d\d(LOOK|LKMB).*FEMME/;
    return regex.test(sku);
};

export const isBikini = (sku: string): boolean => {
    const regex = /D(P|A)\d\dSETB\d\dFEMME/;
    return regex.test(sku);
};
