import { Inject, Injectable } from "@angular/core";
import { Observable, of, switchMap, take, throwError } from "rxjs";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { getMapEngine, isBaiduEngine, MapEngines } from "@hermes/utils/helpers";
import { LoadGeoLibrariesService } from "@hermes/utils/services/load-geo-libraries";
// Custom autocomplete prediction format to be used with baidu or gmap
export interface AutocompletePrediction {
    placeId: string;
    mainText: string;
    secondaryText: string;
    description: string;
    matchedSubstringPosition?: number;
    types: string[];
    terms: google.maps.places.PredictionTerm[];
}

export const CHINA_WHITELIST = [
    "Hong Kong",
    "Hongkong",
    "香港",
    "Macau",
    "Macao",
    "澳门",
    "Taiwan",
    "Taïwan",
    "台湾",
];

@Injectable()
export class AutoCompleteProviderService {
    private googleAutoCompleteService?: google.maps.places.AutocompleteService;

    constructor(
        private loadGeoLibrariesService: LoadGeoLibrariesService,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    /**
     * Return the script url depending of the Provider
     *
     * @deprecated Please use from now on the possibilities autoLoadAndGetStatus()
     */
    public loadAutoCompleteLibrary(): Observable<boolean> {
        return this.loadGeoLibrariesService.autoLoadAndGetStatus();
    }

    /**
     * Return the automplete provider depending of the current url
     *
     * @deprecated Please use from now on the possibilities getMapEngine() or isBaiduEngine() or isGoogleEngine()
     */
    public getMapEngine(): MapEngines {
        return getMapEngine(this.locale.countryCode);
    }

    /**
     * get main_text_matched_substrings length
     */
    public getMatchedSubStringPosition(
        predictionFormatting: google.maps.places.StructuredFormatting,
    ): number {
        return predictionFormatting &&
            predictionFormatting.main_text_matched_substrings &&
            predictionFormatting.main_text_matched_substrings.length > 0
            ? predictionFormatting.main_text_matched_substrings[0].length
            : 0;
    }

    public getPlacePrediction(
        search: string,
        componentRestrictions?: google.maps.places.ComponentRestrictions,
        types?: google.maps.places.AutocompletionRequest["types"],
    ): Observable<AutocompletePrediction[]> {
        // Baidu is used by hooking the autocomplete to the input element directly
        if (isBaiduEngine(this.locale.countryCode)) {
            return of([]);
        }
        return this.getGooglePlacePrediction(
            search,
            componentRestrictions,
            types,
        );
    }

    public initBaiduAutocomplete(
        inputElement: HTMLInputElement,
    ): BMap.Autocomplete {
        return new BMap.Autocomplete({ input: inputElement });
    }

    private getGooglePlacePrediction(
        search: string,
        componentRestrictions?: google.maps.places.ComponentRestrictions,
        types?: google.maps.places.AutocompletionRequest["types"],
    ): Observable<AutocompletePrediction[]> {
        return this.loadGeoLibrariesService.isGoogleLoaded$.pipe(
            take(1),
            switchMap((isLoaded) => {
                if (!isLoaded) {
                    return throwError(
                        () => new Error("Google script not yet loaded"),
                    );
                }
                return new Observable<AutocompletePrediction[]>((observer) => {
                    if (!this.googleAutoCompleteService) {
                        this.googleAutoCompleteService =
                            new google.maps.places.AutocompleteService();
                    }

                    this.googleAutoCompleteService.getPlacePredictions(
                        {
                            input: search,
                            componentRestrictions,
                            types,
                        },
                        (predictions, status) => {
                            switch (status) {
                                case google.maps.places.PlacesServiceStatus.OK:
                                    observer.next(
                                        this.formatGooglePrediction(
                                            predictions ?? [],
                                        ),
                                    );
                                    return observer.complete();

                                case google.maps.places.PlacesServiceStatus
                                    .NOT_FOUND:
                                case google.maps.places.PlacesServiceStatus
                                    .ZERO_RESULTS:
                                    observer.next([]);
                                    return observer.complete();
                            }

                            // Fallthrough cases :
                            // google.maps.places.PlacesServiceStatus.INVALID_REQUEST
                            // google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
                            // google.maps.places.PlacesServiceStatus.REQUEST_DENIED
                            // google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR

                            return observer.error(new Error(status));
                        },
                    );
                });
            }),
        );
    }

    /**
     * Formats to common interface, and add China if last term is Honk Kong, Macau or Taiwan
     */
    private formatGooglePrediction(
        predictions: google.maps.places.AutocompletePrediction[],
    ): AutocompletePrediction[] {
        return predictions.map((prediction) => {
            const autocompletePrediction: AutocompletePrediction = {
                placeId: prediction.place_id,
                description: prediction.description,
                mainText: prediction.structured_formatting.main_text,
                secondaryText: prediction.structured_formatting.secondary_text,
                matchedSubstringPosition: this.getMatchedSubStringPosition(
                    prediction.structured_formatting,
                ),
                types: prediction.types,
                terms: prediction.terms,
            };

            const country = prediction.terms.pop()?.value as string;
            if (CHINA_WHITELIST.includes(country)) {
                autocompletePrediction.description = this.concatWithChina(
                    autocompletePrediction.description,
                );
                autocompletePrediction.secondaryText = this.concatWithChina(
                    autocompletePrediction.secondaryText,
                );
            }

            return autocompletePrediction;
        });
    }

    /**
     * Concat description with China
     */
    private concatWithChina(description: string): string {
        return description
            ? `${description}, ${this.getChinaLabel()}`
            : this.getChinaLabel();
    }

    private getChinaLabel(): string {
        switch (this.locale.langCode) {
            case "fr":
                return "Chine";
            case "zh-Hans":
                return "中国";
            default:
                return "China";
        }
    }
}
