import { ProductGiftSetItem } from "@hermes/api-model-product";

import { GiftSetBoxSelectedItems } from "../types/giftset-product-page.types";

export const giftsetFeatureKey = "giftset";
export interface GiftSetState {
    box: GiftSetBoxState;
    listItems: ProductGiftSetItem[];
    toggleItems: { [key: string]: boolean };
}
export interface GiftSetBoxState {
    toDelete?: string;
    selectedItems: GiftSetBoxSelectedItems;
}

/** initial giftset state */
export const initialGiftSetState: GiftSetState = {
    box: {
        selectedItems: { byId: {}, allIds: [] },
    },
    listItems: [],
    toggleItems: {},
};
