// eventAction Checkout
export const EVENT_ACTION_RNVP = "Rnvp";
export const EVENT_ACTION_RNVP_OK = "rnvp_ok";
export const EVENT_ACTION_RNVP_SEARCH = "rnvp_search";

// eventCategory Checkout
export const EVENT_CATEGORY_CHECKOUT_DELIVERY = "Checkout_Delivery";
export const EVENT_CATEGORY_CHECKOUT_ERROR = "Checkout_error";

// eventlabel CONVERSION_FUNNEL common
export const CLICK_PHONE_NUMBER = "clickPhoneNumber";

// pagetypes CONVERSION_FUNNEL common
export const PAGETYPE_CART_PAGE = "Checkout cart";
export const PAGETYPE_CREATE_ACCOUNT = "Checkout_CreateAccount";
export const PAGETYPE_ACCOUNT = "Checkout_account";
export const PAGETYPE_LOGIN = "Checkout_login";
export const PAGETYPE_DELIVERY = "Checkout delivery";
export const PAGETYPE_PAYMENT = "Checkout payment";
export const PAGETYPE_CONFIRMATION = "Checkout confirmation";

// checkoutstep CONVERSION_FUNNEL common
export const CHECKOUTSTEP_LOGIN = "login";
export const CHECKOUTSTEP_NEWACCOUNT = "newaccount";
export const CHECKOUTSTEP_SHIPPING = "shipping";
export const CHECKOUTSTEP_PAYMENT = "payment";
export const CHECKOUTSTEP_ACCOUNT = "account";

// checkoutmainstepname CONVERSION_FUNNEL common
export const CHECKOUTMAINSTEPNAME_CART = "cart";
export const CHECKOUTMAINSTEPNAME_CHECKOUT = "checkout";

// eventlabel CART_PAGE
export const CLICK_BACK_PRODUCT_PAGE = "BackProductPage";
export const CLICK_CONTINUE_CHECKOUT = "clickContinueToCheckout";
export const GIFT_CARD_GIFT_BOX_TICKED = "GiftBoxTicked";
export const GIFT_CARD_MESSAGE_ADDED = "MessageAdded";
export const GIFT_CARD_PRICELESS = "Priceless";

// eventlabel CHECKOUT_PAGE
export const CLICK_SUMMARY_ACCORDION = "clickSummaryAccordion";
export const CLICK_SHIPPING_METHOD = "clickShippingMethod";
export const CLICK_SHIPPING_ADDRESS = "clickShippingAddress";

// eventCategory Checkout
export const CHECKOUT_SUMMARY_OPEN = "checkoutSummaryOpen";
export const CHECKOUT_SUMMARY_CLOSE = "checkoutSummaryClose";
export const CHECKOUT_ORANGE_BOX_EXCEPTIONS = "checkoutOrangeBoxExceptions";

// eventCategory Checkout_error
export const CHECKOUT_STOCK_ISSUE = "Stock_issue";
export const CHECKOUT_INVALID_DOB = "Birthdate";
export const CHECKOUT_INVALID_EMAIL = "Invalid_email";
export const CHECKOUT_INVALID_EMAIL_PASSWORD = "Invalid_email_password";
export const CHECKOUT_INVALID_PHONE_PASSWORD = "Invalid_phone_password";
export const CHECKOUT_BLOCKED_ACCOUNT = "Blocked_account";
export const CHECKOUT_PAYMENT_STOCK_ERROR = "Payment_stock";
export const CHECKOUT_PAYMENT_REJECTED = "Payment_rejected";
export const CHECKOUT_PAYMENT_INFORMATION_ERROR = "Payment_tocheck";
export const CHECKOUT_PAYMENT_AUTHORIZATION_ERROR = "Payment_autho_reject";
export const CHECKOUT_REQUIRED_INFORMATION = "Required_information";
export const CHECKOUT_TERMS_CONDITIONS_REQUIRED = "Terms_conditions";
export const CHECKOUT_RECURRING_CONDITIONS_REQUIRED = "Pre_order";
export const CHECKOUT_SECURITY_CARD_ERROR = "Security_card";
export const CHECKOUT_TRY_LATER = "Generic_message";

// eventCategory Checkout_CreateAccount
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_OPTIN =
    "checkoutCreateAccount_1CreateAccountOptin";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_OPTOUT =
    "checkoutCreateAccount_1CreateAccountOptout";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_MORE_DETAILS_OPEN =
    "checkoutCreateAccount_1CreateAccountMoreDetailsOpen";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_MORE_DETAILS_CLOSE =
    "checkoutCreateAccount_1CreateAccountMoreDetailsClose";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_MORE_DETAILS_PRIVACY_POLICY =
    "checkoutCreateAccount_1CreateAccountMoreDetailsPrivacyPolicy";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_CLICK_LINK_PRIVACY_POLICY =
    "checkoutCreateAccount_1CreateAccountClickLinkPrivacyPolicy";
export const CHECKOUT_CREATE_ACCOUNT_1_CREATE_ACCOUNT_CLICK_BOUTON =
    "checkoutCreateAccount_1CreateAccountClickBouton";
export const CHECKOUT_CREATE_ACCOUNT_2_ACCOUNT_CREATED_KO =
    "checkoutCreateAccount_2AccountCreatedKo";
export const CHECKOUT_CREATE_ACCOUNT_EDIT_ACCOUNT =
    "checkoutCreateAccountEditAccount";
export const CHECKOUT_CREATE_ACCOUNT_PIPL_OPTIN =
    "checkoutCreateAccountPiplOptin";
export const CHECKOUT_CREATE_ACCOUNT_COMMUNICATION_CONSENT_OPTIN =
    "checkoutCreateAccountCommunicationConsentOptin";

// eventCategory Checkout_Login
export const CHECKOUT_LOGIN_OTHER_PHONE = "checkoutLoginOtherPhone";
export const CHECKOUT_LOGIN_PASSWORD_KO_FORGOT_PASSWORD =
    "checkoutLoginPasswordKoForgotPassword";

// eventCategory Checkout_Delivery
export const CHECKOUT_DELIVERY_SHIP_TO_ADDRESS =
    "checkoutDeliveryShipToAddress";
export const CHECKOUT_DELIVERY_EDIT_ADDRESS = "checkoutDeliveryEditAddress";
export const CHECKOUT_DELIVERY_ADD_NEW_ADDRESS =
    "checkoutDeliveryAddNewAddress";
export const CHECKOUT_DELIVERY_COLLECT_IN_STORE =
    "checkoutDeliveryCollectInStore";
export const CHECKOUT_DELIVERY_FIND_A_STORE = "checkoutDeliveryFindAStore";
export const CHECKOUT_DELIVERY_SELECT_A_STORE = "checkoutDeliverySelectAStore";
export const CHECKOUT_DELIVERY_SHOW_MORE_STORES =
    "checkoutDeliveryShowMoreStores";
export const CHECKOUT_DELIVERY_UPDATE_NEW_ADDRESS =
    "checkoutDeliveryUpdateNewAddress";
export const CHECKOUT_DELIVERY_EDIT_ORIGINAL_ADDRESS =
    "checkoutDeliveryEditOriginalAddress";
export const CHECKOUT_DELIVERY_KEEP_ORIGINAL_ADDRESS =
    "checkoutDeliveryKeepOriginalAddress";

// eventCategory Checkout_Payment
export const CHECKOUT_PAYMENT_EDIT_BILLING_ADDRESS =
    "checkoutPaymentEditBillingAddress";
export const CHECKOUT_PAYMENT_3_D_SECURE_SYSTEM =
    "checkoutPayment_3DSecureSystem";
export const CHECKOUT_PAYMENT_GENERAL_TERMS_CONDITIONS =
    "checkoutPaymentGeneralTermsConditions";
export const CHECKOUT_PAYMENT_PRIVACY_POLICY = "checkoutPaymentPrivacyPolicy";
export const CHECKOUT_PAYMENT_CTA_CLICK = "checkoutPaymentPay";
