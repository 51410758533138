import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ProductInViewportService {
    public productInViewport$: Observable<string>;
    // Used to propagate the image id in viewport from detect-viewport.directive to grid-result-item.component
    private fromDirectiveToResultItem = new Subject<string>();

    constructor() {
        this.productInViewport$ = this.fromDirectiveToResultItem.asObservable();
    }

    public productInViewport(id: string): void {
        this.fromDirectiveToResultItem.next(id);
    }
}
