import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

export type HeaderAnalyticsEventData = GTMEventData & {
    virtualpageuri: string;
};

/**
 * Abstract class for variation-click event
 */
export abstract class HeaderAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: HeaderAnalyticsEventData;

    constructor(data?: Record<string, unknown>) {
        this.eventData = data as unknown as HeaderAnalyticsEventData;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: "Header",
        };
    }
}
