import { CommonModule } from "@angular/common";
import {
    Inject,
    InjectionToken,
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from "@angular/core";

import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SvgSymbolsComponent } from "./components/svg-symbols/svg-symbols.component";
import { Svg } from "./icons/svg.model";
import { SvgRegistryService } from "./services/svg-registry.service";

export const SVG_FORROOT_GUARD = new InjectionToken<void>("SVG_FORROOT_GUARD");
export const SVGS = new InjectionToken<Svg[][]>("SVGS");

@NgModule({
    imports: [CommonModule],
    exports: [SvgSymbolsComponent, SvgIconComponent],
    declarations: [SvgSymbolsComponent, SvgIconComponent],
})
export class SvgModule {
    constructor(
        svgRegistry: SvgRegistryService,
        @Inject(SVGS) svgsInjected: Svg[][],
        @Optional()
        @Inject(SVG_FORROOT_GUARD)
        _guard: unknown,
    ) {
        svgRegistry.registerSvgs(
            // Flatten Svg[][] to Svg[]
            svgsInjected.flatMap((currentSvgArray) => currentSvgArray),
        );
    }

    /**
     * Creates and configures a module with a provider registering SVGs.
     *
     * @param svgs An array of `SVG` objects that define the SVGs used in the application.
     */
    public static forRoot(svgs: Svg[]): ModuleWithProviders<SvgModule> {
        return {
            ngModule: SvgModule,
            providers: [
                SvgRegistryService,
                { provide: SVGS, useValue: svgs, multi: true },
                {
                    provide: SVG_FORROOT_GUARD,
                    useFactory: provideForRootGuard,
                    deps: [
                        [SvgRegistryService, new Optional(), new SkipSelf()],
                    ],
                },
            ],
        };
    }

    /**
     * Creates a module with a provider registering SVGs.
     *
     * For standalone components, use {@link registerSvgs} function instead.
     *
     * @param svgs An array of `SVG` objects that define the SVGs used in the module.
     */
    public static forChild(svgs: Svg[]): ModuleWithProviders<SvgModule> {
        return {
            ngModule: SvgModule,
            providers: [{ provide: SVGS, useValue: svgs, multi: true }],
        };
    }
}

/**
 * Prevent duplicate SvgModule.forRoot() call in the app
 */
export const provideForRootGuard = (
    svgRegistryService: SvgRegistryService,
): string => {
    if (svgRegistryService) {
        throw new Error(
            "SvgModule.forRoot() called twice. Feature modules should use SvgModule.forChild() instead.",
        );
    }
    return "guarded";
};
