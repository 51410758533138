import { Product, ProductSizeVariant } from "@hermes/api-model-product";
import {
    PRODUCT_IN_STOCK,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    CartAnalyticsEvent,
    getStockTypeAndDisplayOnly,
} from "@hermes/utils/analytics";

/**
 * This class is used for add-to-cart event in cart page for a bikini
 */
export class AddToCartBikini extends CartAnalyticsEvent {
    public override eventData: {
        currencyCode?: string;
        product: Product;
        variants: ProductSizeVariant[];
    };

    constructor(data: {
        currencyCode?: string;
        product: Product;
        variants: ProductSizeVariant[];
    }) {
        super(data);
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.product,
                            this.eventData.variants,
                        ),
                    ],
                },
            },
        };
    }

    private getProductData(
        product: Product,
        variants?: ProductSizeVariant[],
    ): GTMEcommerceItemData {
        return {
            name: product.title,
            id: product.sku,
            price: product.price ? product.price.toFixed(2) : undefined,
            brand: "hermes",
            category: `${product.bikiniAttributes?.items[0].departmentCode}/${product.bikiniAttributes?.items[0].familyCode}`,
            variant: `${product.bikiniAttributes?.items[0].colorHermes}|${product.bikiniAttributes?.items[1].colorHermes}`,
            quantity: 1,
            dimension5: `${variants?.[0].size}|${variants?.[1].size}`,
            dimension6: product.bikiniAttributes?.items[0].departmentCode,
            dimension7: product.bikiniAttributes?.items[0].familyCode,
            dimension8: product.bikiniAttributes?.items[0].productCode,
            dimension9: product.bikiniAttributes?.items[0].divisionCode,
            dimension37: PRODUCT_IN_STOCK,
            dimension52: "no",
            dimension53: getStockTypeAndDisplayOnly(
                product.stock,
                product.displayMode,
            ),
            dimension54: "no",
        } as GTMEcommerceItemData;
    }
}
