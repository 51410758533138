import { Component, Input } from "@angular/core";

import { SeoLink, SeoLinks } from "../../types/seo-link.types";

@Component({
    selector: "h-seo-links",
    templateUrl: "./seo-links.component.html",
    styleUrls: ["seo-links.component.scss"],
})
export class SeoLinksComponent {
    @Input()
    public seoLinks?: SeoLinks;

    @Input()
    public isNoScriptElement: boolean = false;

    public trackByLink(index: number, item: SeoLink): string {
        return `${index}_${item.label ?? "unknown"}`;
    }
}
