import { createFeatureSelector, createSelector } from "@ngrx/store";

import { Guest, User } from "@hermes/api-model-account";

import { userFeatureKey } from "../reducers/user.reducer";
import { UserState } from "../reducers/user.state";

// Common Selectors
export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

// User Selectors
export const selectUserDetails = createSelector(
    selectUserState,
    (userState: UserState) => userState.details,
);

export const selectUserStatus = createSelector(
    selectUserState,
    (userState: UserState) => userState.userStatus,
);

export const selectCustomerId = createSelector(
    selectUserDetails,
    (userData: User) => userData.customerId,
);

export const selectUserPrefix = createSelector(
    selectUserDetails,
    (userData: User) => userData.prefix,
);

export const selectUserType = createSelector(
    selectUserDetails,
    (userData: User) => userData.userType,
);

export const selectIsLoggedIn = createSelector(
    selectUserDetails,
    (userData: User): boolean => !!userData.customerId,
);

// Guest Selectors
export const selectGuestData = createSelector(
    selectUserState,
    (userState: UserState) => userState.guest,
);

export const selectGuestDob = createSelector(
    selectGuestData,
    (guestData: Guest) => (guestData ? guestData.dob : undefined),
);

export const selectGuestNewsletter = createSelector(
    selectGuestData,
    (guestData: Guest) => (guestData ? guestData.newsletter : undefined),
);

export const selectIsGuestConnected = createSelector(
    selectGuestData,
    (guestData: Guest): boolean =>
        !!guestData &&
        (!!guestData.phoneLogin ||
            (!!guestData.email && guestData.newsletter !== undefined)),
);
