import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Input,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";

import { TrayData } from "../../models/tray-data.model";

/**
 * This component is in charge of displaying the content of a tray from outside.
 * The content can be a lazy loaded component or a TemplateRef.
 */
@Component({
    selector: "h-tray-content",
    templateUrl: "./tray-content.component.html",
})
export class TrayContentComponent implements AfterViewInit {
    /**
     * tray data, contains all we need to display content.
     */
    @Input()
    public data!: TrayData;

    @ViewChild("dynamicContent", { read: ViewContainerRef })
    public dynamicContent!: ViewContainerRef;

    private componentRef!: ComponentRef<unknown>;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        if (this.data && this.data.componentFactory) {
            // create the dynamic component in its view container.
            this.componentRef = this.dynamicContent.createComponent(
                this.data.componentFactory,
            );

            // apply provided data to inputs
            if (this.data.metadata) {
                Object.keys(this.data.metadata).forEach((key) => {
                    (this.componentRef.instance as Record<string, unknown>)[
                        key
                    ] = (this.data.metadata ?? {})[key];
                });
            }

            if (this.data.isDrupalTray) {
                // Provide tray name in DrupalTrayDirective to load iframe
                (
                    this.componentRef.instance as Record<string, unknown>
                ).trayName = this.data.name;
            }

            // we modified the view while it was initialized so relaunch detection:
            this.changeDetectorRef.detectChanges();
        }
    }
}
