import { ChangeDetectionStrategy, Component } from "@angular/core";

import { CookieService } from "ngx-cookie-service";
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    filter,
    map,
    merge,
} from "rxjs";

import { BannerItem, BannersData } from "@hermes/api-model-shell";
import { Context, StorageService, StorageManager } from "@hermes/app-core";
import {
    ANGULAR_DISPLAY_GEO_FILTERING_BANNER,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { COOKIEBANNER, ShellFacade } from "@hermes/states/shell";

import { NOTIFICATIONSHIDE } from "../../constants/cookie.constants";
import { isBannerItemsNotEmpty } from "../../helpers/banner.helper";

@Component({
    selector: "h-banners-top-container",
    templateUrl: "./banners-top-container.component.html",
    styleUrls: ["./banners-top-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannersTopContainerComponent {
    public cookieBanner$: Observable<BannerItem | undefined>;
    public notificationsBanners$: Observable<BannerItem[] | undefined>;
    public isGeoFilteringBannerDisplayed$: Observable<boolean>;

    private banners$: Observable<BannersData>;
    private onNotificationsBannerClosed$: Subject<void> = new Subject<void>();
    private onCookieBannerClosed$: Subject<void> = new Subject<void>();
    private showGeoFilteringBanner$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    private sessionStorage: StorageManager | undefined;

    constructor(
        private context: Context,
        private shellFacade: ShellFacade,
        private cookieService: CookieService,
        private featureFlagFacade: FeatureFlagFacade,
        private storageService: StorageService,
    ) {
        this.sessionStorage = this.storageService.getSessionStorageInstance();
        this.banners$ = this.shellFacade.getBanners();
        this.notificationsBanners$ = this.banners$.pipe(
            map((banners) => banners.notifications),
        );

        this.cookieBanner$ = merge(
            this.banners$.pipe(
                map((banners) => banners.cookie),
                filter((cookieBanner) =>
                    this.shouldCookieBannerBeVisible(cookieBanner),
                ),
            ),
            this.onCookieBannerClosed$.pipe(map(() => undefined)),
        );

        this.notificationsBanners$ = merge(
            this.banners$.pipe(
                map((banners) => banners.notifications),
                filter(isBannerItemsNotEmpty),
            ),
            this.onNotificationsBannerClosed$.pipe(map(() => undefined)),
        );

        this.isGeoFilteringBannerDisplayed$ = combineLatest([
            this.showGeoFilteringBanner$,
            this.featureFlagFacade.isActivated(
                ANGULAR_DISPLAY_GEO_FILTERING_BANNER,
            ),
        ]).pipe(map((predicates) => predicates.every(Boolean)));
    }

    public closeCookieBanner(): void {
        this.onCookieBannerClosed$.next();
    }

    public closeNotificationsBanner(): void {
        this.sessionStorage?.setItem(NOTIFICATIONSHIDE, true);
        this.onNotificationsBannerClosed$.next();
    }

    public closeGeoFilteringBanner(): void {
        this.showGeoFilteringBanner$.next(false);
    }

    private shouldCookieBannerBeVisible(cookieBanner: BannerItem): boolean {
        if (cookieBanner) {
            const isActive = cookieBanner.active;
            const hasCookie = this.cookieService.get(COOKIEBANNER);
            const isNewerBanner =
                Number(hasCookie) < cookieBanner.cookieTimestamp;

            return (
                isActive &&
                (!hasCookie || isNewerBanner) &&
                this.context.isInBrowserMode()
            );
        }
        return false;
    }
}
