import { Inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

/**
 * Principle: angular router is already aware of the locale prefix ("/country/language") with baseHref.
 *
 * If a Navigation starts with the current prefix, remove it from the angular route.
 */
@Injectable()
export class LocalePrefixGuard {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private router: Router,
    ) {}

    public canActivateChild(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree {
        const prefix = `${this.locale.urlPrefix}/`;
        if (state.url.startsWith(prefix)) {
            const routePath: string = state.url.replace(prefix, "");
            return this.router.parseUrl(routePath);
        }
        return true;
    }
}
