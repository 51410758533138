export interface NotificationState {
    displayOverlay: boolean;
    messageOption: MessageOption;
}

export interface MessageOption {
    message?: Message;
    type: MessageType;
    isTemporary: boolean;
    delay?: number;
}

/**
 * Message interface for, only the description is required
 */
export interface Message {
    /** Notification description, should be already translated with $localize */
    description: string;
    /** Notification title, should be already translated with $localize */
    title?: string;
    /** Type of call-to-action displayed in the notification */
    action?: "refresh-page" | "back-to-home";
}

/**
 * Message type enum
 */
export enum MessageType {
    Info = "info",
    Critical = "critical",
    SuperCritical = "superCritical",
    Loader = "loader",
}
