import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import newrelicNamespace from "newrelic";

import { map, Observable, of, switchMap, take, tap } from "rxjs";

import {
    AddAddressResponse,
    CustomerAddressResponse,
    PrivateContact,
} from "@hermes/api-model-account";
import {
    Address,
    ADDRESS_TYPE_ENUM,
    AddressTypeEnum,
} from "@hermes/api-model-address";
import {
    Basket,
    DeliveryAddressRequest,
    DeliveryAddressResponse,
    DeliveryShippingResponse,
} from "@hermes/api-model-basket";

import {
    PaymentRequest as PaymentRequestMercury,
    PaymentResponse as PaymentResponseMercury,
} from "@hermes/api-model-payment";
import {
    Context,
    LOCALE,
    Settings,
    StorageManager,
    StorageService,
    WINDOW,
} from "@hermes/app-core";
import { CustomerService } from "@hermes/fragments/customer";
import { Locale } from "@hermes/locale";
import { BasketFacade } from "@hermes/states/basket";
import {
    AdyenApplePayPaymentMethod,
    PaymentMethodsFacade,
} from "@hermes/states/payment-methods";
import { UserFacade, UserStateService } from "@hermes/states/user";
import {
    GUEST_BCK_RESPONSE_NEW_CUSTOMER,
    GUEST_BCK_RESPONSE_RETURNING_CUSTOMER,
    GuestBCKResponse,
    MERCURY_ADYEN_APPLE_PAY,
} from "@hermes/utils/constants";

import { CartService } from "@hermes/utils/services/api-clients";
import {
    PaymentService,
    ProcessingChannel,
} from "@hermes/utils/services/payment";
import {
    EcomErrorCodes,
    HTTP_JSON_CONTENT_TYPE_HEADER,
} from "@hermes/utils-generic/constants";
import { RecaptchaService } from "@hermes/utils-generic/services/client-api";

@Injectable()
export class ApplePayService {
    private shippingLabel: string = $localize`:@@hermes-global-translations.shipping:Shipping`;
    private taxLabel: string = $localize`:@@hermes-global-translations.taxes:Tax`;
    private totalLabel: string = "Hermès";

    private sessionStorage: StorageManager | undefined;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(WINDOW)
        private window: Window & {
            newrelic: typeof newrelicNamespace;
        },
        private userFacade: UserFacade,
        private paymentFacade: PaymentMethodsFacade,
        private basketFacade: BasketFacade,
        private context: Context,
        private http: HttpClient,
        private settings: Settings,
        private cartService: CartService,
        private recaptchaService: RecaptchaService,
        private storageService: StorageService,
        private customerService: CustomerService,
        private paymentService: PaymentService,
        private userService: UserStateService,
    ) {
        this.sessionStorage = this.storageService.getSessionStorageInstance();
    }

    public getIsLoggedIn(): Observable<boolean> {
        return this.userFacade.isLoggedIn$.pipe(take(1));
    }

    public getApplePayOptions(): Observable<AdyenApplePayPaymentMethod> {
        return this.paymentFacade.getAvailablePaymentMethods().pipe(
            take(1),
            map((paymentMethods) => {
                const adyenApplePayPaymentMethod = paymentMethods.find(
                    (pm): pm is AdyenApplePayPaymentMethod =>
                        pm.code === MERCURY_ADYEN_APPLE_PAY,
                );
                if (!adyenApplePayPaymentMethod) {
                    throw new Error("Apple Pay method not available");
                }

                return adyenApplePayPaymentMethod;
            }),
        );
    }

    public onMerchantValidation(
        validationUrl: string,
    ): Observable<ApplePaySession> {
        const headers = new HttpHeaders({
            ...HTTP_JSON_CONTENT_TYPE_HEADER,
        });

        const url = new URL(this.context.getCurrentOrigin());

        return this.http.post<ApplePaySession>(
            `${this.settings.apiUrl}/create-apple-pay-session`,
            {
                validationUrl,
                locale: this.locale.code,
                domain: url.hostname,
            },
            { headers },
        );
    }

    public onShippingAddressChange(
        shippingApplePayContact: ApplePayJS.ApplePayPaymentContact,
    ): Observable<{
        items: ApplePayJS.ApplePayLineItem[];
        shippingMethods: ApplePayJS.ApplePayShippingMethod[];
        total: ApplePayJS.ApplePayLineItem;
        applePayError?: ApplePayError;
    }> {
        let applePayError: ApplePayError | undefined;

        return this.addCustomerAddress({
            shippingApplePayContact,
            isFirstApplePayAddress: true,
        }).pipe(
            map((addAddressResponse: AddAddressResponse) => {
                // Handle error for being unable to ship by displaying an error to the user
                applePayError = this.handleError(addAddressResponse);

                return addAddressResponse.addressId;
            }),
            switchMap((addressId) => {
                const isKnownButNotConnected = undefined;
                const isKnownButNotConnectedEmail = undefined;
                const isFirstApplePayAddress = true;
                return this.updateCustomerAddress(
                    addressId,
                    ADDRESS_TYPE_ENUM.SHIPPING,
                    isKnownButNotConnected,
                    isKnownButNotConnectedEmail,
                    isFirstApplePayAddress,
                );
            }),
            map((deliveryAddressResponse: DeliveryAddressResponse) => {
                // Handle error for being unable to ship by displaying an error to the user
                applePayError = this.handleError(deliveryAddressResponse);

                return this.basketFacade.setBasket(
                    new JsonConvert().serializeObject(
                        deliveryAddressResponse.basket,
                    ),
                );
            }),
            switchMap(() => this.updateFromBasket(applePayError)),
        );
    }

    public addCustomerAddress({
        shippingApplePayContact,
        isFirstApplePayAddress,
        isKnownButNotConnected,
    }: {
        shippingApplePayContact?: ApplePayJS.ApplePayPaymentContact;
        isFirstApplePayAddress?: boolean;
        isKnownButNotConnected?: boolean;
    }): Observable<AddAddressResponse> {
        if (!shippingApplePayContact) {
            throw new Error("Shipping Address is missing");
        }

        return this.customerService.addAddress(
            this.mapToAddress(shippingApplePayContact, isFirstApplePayAddress),
            "checkout",
            ADDRESS_TYPE_ENUM.SHIPPING,
            isKnownButNotConnected,
        );
    }

    public onShippingOptionChange(
        shippingMethod: ApplePayJS.ApplePayShippingMethod,
    ): Observable<{
        items: ApplePayJS.ApplePayLineItem[];
        shippingMethods: ApplePayJS.ApplePayShippingMethod[];
        total: ApplePayJS.ApplePayLineItem;
        applePayError?: ApplePayError;
    }> {
        return this.updateShippingInMagento(shippingMethod).pipe(
            map((deliveryResponse: DeliveryShippingResponse) =>
                this.basketFacade.setBasket(
                    new JsonConvert().serializeObject(deliveryResponse.basket),
                ),
            ),
            switchMap(() => this.updateFromBasket()),
        );
    }

    public updateCustomerAddress(
        addressId: string,
        addressType: AddressTypeEnum,
        isKnownButNotConnected?: boolean,
        isKnownButNotConnectedEmail?: string,
        isFirstApplePayAddress?: boolean,
    ): Observable<DeliveryAddressResponse> {
        if (!addressId) {
            throw new Error("Address Id is is missing");
        }

        const deliveryRequest: DeliveryAddressRequest = {
            locale: this.locale.code,
            /* eslint-disable @typescript-eslint/naming-convention*/
            customer_address_id: addressId,
            address_type: addressType,
            is_apple_pay: true,
            is_known_but_not_connected: isKnownButNotConnected,
            is_known_but_not_connected_email: isKnownButNotConnected
                ? isKnownButNotConnectedEmail
                : undefined,
            is_first_apple_pay_address: isFirstApplePayAddress,
            /* eslint-enable @typescript-eslint/naming-convention*/
        };

        return this.cartService.updateAddress(deliveryRequest);
    }

    public updateFromBasket(applePayError?: ApplePayError): Observable<{
        items: ApplePayJS.ApplePayLineItem[];
        shippingMethods: ApplePayJS.ApplePayShippingMethod[];
        total: ApplePayJS.ApplePayLineItem;
        applePayError?: ApplePayError;
    }> {
        return this.basketFacade.cart$.pipe(
            map((serializedBasket) =>
                this.basketFacade.deserializeBasket(serializedBasket),
            ),
            map((basket) => {
                const shippingMethods = this.updateShipping(basket);

                const items = this.updateItemsFromBasket(
                    basket,
                    shippingMethods.find(
                        (shipping) =>
                            shipping.identifier === basket.shippingMethod,
                    ) ?? shippingMethods[0],
                );

                const total = this.updateTotalFromBasket(basket);
                return { items, shippingMethods, total, applePayError };
            }),
        );
    }

    public handlePaymentResponse(
        paymentResponse: PaymentResponseMercury,
    ): Observable<Basket | void> {
        if (!paymentResponse || !paymentResponse.orderId) {
            throw new Error("Apple Pay payment failed");
        }

        this.paymentService.handlePaymentResponse(paymentResponse, true);

        return this.userService
            .getCustomerSession()
            .pipe(
                map(() =>
                    this.paymentService.handlePaymentValidation(
                        paymentResponse,
                    ),
                ),
            );
    }

    public startPayment(
        token: ApplePayJS.ApplePayPaymentToken,
        shippingContact: ApplePayJS.ApplePayPaymentContact,
        isUserConnected: boolean,
        processingChannel: ProcessingChannel,
    ): Observable<PaymentResponseMercury> {
        let addressId: string;
        let initialAmounts: {
            total: number;
            tax: number;
        } = { total: 0, tax: 0 };
        let guestResponseResult: GuestBCKResponse;

        return this.basketFacade.cart$.pipe(
            map((serializedBasket) =>
                this.basketFacade.deserializeBasket(serializedBasket),
            ),
            tap(
                (basket) =>
                    (initialAmounts = {
                        total: basket.grandTotal ?? 0,
                        tax: basket.taxAmount ?? 0,
                    }),
            ),
            switchMap(() =>
                this.handleUnconnectedUser(
                    isUserConnected
                        ? this.userService.getUser()?.email
                        : shippingContact?.emailAddress,
                ),
            ),
            switchMap((guestResponse) => {
                guestResponseResult = guestResponse;
                const isFirstApplePayAddress = false;
                const isKnownButNotConnected = Boolean(
                    !isUserConnected &&
                        guestResponseResult.event ===
                            GUEST_BCK_RESPONSE_RETURNING_CUSTOMER,
                );
                return this.addCustomerAddress({
                    shippingApplePayContact: shippingContact,
                    isFirstApplePayAddress,
                    isKnownButNotConnected,
                });
            }),
            switchMap((addAddressResponse) => {
                addressId = addAddressResponse.addressId;
                const isKnowButNotConnected = Boolean(
                    !isUserConnected &&
                        guestResponseResult.event ===
                            GUEST_BCK_RESPONSE_RETURNING_CUSTOMER,
                );
                return this.updateCustomerAddress(
                    addressId,
                    ADDRESS_TYPE_ENUM.BOTH,
                    isKnowButNotConnected,
                    shippingContact?.emailAddress,
                );
            }),
            tap((deliveryResponse) =>
                this.checkApplePayAmountMismatch(
                    deliveryResponse.basket,
                    initialAmounts,
                ),
            ),
            map(() =>
                this.paymentService.createPaymentRequest({
                    paymentMethod: MERCURY_ADYEN_APPLE_PAY,
                    applePayToken: btoa(JSON.stringify(token.paymentData)),
                    processingChannel,
                }),
            ),
            switchMap((request: PaymentRequestMercury) =>
                this.manageUserConnected(
                    request,
                    guestResponseResult,
                    isUserConnected,
                    shippingContact?.emailAddress,
                ),
            ),
            switchMap((request: PaymentRequestMercury) =>
                this.paymentService.placePayment(request),
            ),
        ) as Observable<PaymentResponseMercury>;
    }

    public manageUserConnected(
        request: PaymentRequestMercury,
        guestResponse: GuestBCKResponse,
        isUserConnected: boolean,
        userEmail?: string,
    ): Observable<PaymentRequestMercury> {
        if (isUserConnected) {
            // returning-customer case
            return of(request);
        }

        const responsePayerEmail = userEmail;

        if (guestResponse.event === GUEST_BCK_RESPONSE_RETURNING_CUSTOMER) {
            // returning-but-not-connected case
            request.knownButNotConnectedEmail = responsePayerEmail;

            // Needed by the order confirmation page, as the user will not be connected
            this.sessionStorage?.setItem(
                "knownButNotConnectedEmail",
                responsePayerEmail,
            );
            return of(request);
        }

        if (guestResponse.event === GUEST_BCK_RESPONSE_NEW_CUSTOMER) {
            // Guest case. The mail has been saved on Magento.
            // Error is "MAIL NOT LINKED TO EXISTING ACCOUNT", which is what we want
            return of(request);
        }

        throw new Error("Technical error on the saveGuestEmail request");
    }

    private checkApplePayAmountMismatch(
        basket: Basket,
        initialAmounts: {
            total: number;
            tax: number;
        },
    ): void {
        if (initialAmounts.total !== basket.grandTotal) {
            this.window.newrelic?.noticeError(
                new Error("ApplePay amount mismatch."),
                {
                    initialTotal: initialAmounts.total ?? 0,
                    initialTaxAmount: initialAmounts.tax ?? 0,
                    totalAfterRecalc: basket.grandTotal ?? 0,
                    taxAfterRecalc: basket.taxAmount ?? 0,
                },
            );
        }
    }

    private updateShippingInMagento(
        shippingMethod: ApplePayJS.ApplePayShippingMethod,
    ): Observable<DeliveryShippingResponse> {
        if (!shippingMethod) {
            throw new Error("no shipping option");
        }

        const deliveryShippingRequest = {
            shipping_method: shippingMethod.identifier,
            locale: this.locale.code,
        };
        return this.cartService.updateShippingMethod(deliveryShippingRequest);
    }

    private mapToAddress(
        shippingApplePayContact: ApplePayJS.ApplePayPaymentContact,
        isFirstApplePayAdress?: boolean,
    ): Address {
        const address: Address = new Address();

        address.prefix = "-"; // No prefix provided by Apple Pay
        address.firstName = shippingApplePayContact?.givenName ?? "";
        address.lastName = shippingApplePayContact?.familyName ?? "";

        address.telephone = shippingApplePayContact.phoneNumber ?? "";
        if (shippingApplePayContact?.addressLines) {
            address.street1 = shippingApplePayContact.addressLines[0];
            address.street2 = shippingApplePayContact.addressLines[1];
        }
        address.postcode =
            this.updatePostalCode(
                shippingApplePayContact.postalCode,
                shippingApplePayContact.country,
            ) ?? "";
        address.city = shippingApplePayContact.locality ?? "";
        address.district = shippingApplePayContact.subAdministrativeArea;
        address.region = shippingApplePayContact.administrativeArea ?? "";
        address.countryId = shippingApplePayContact.countryCode ?? "";

        address.isFirstApplePayAdress = Boolean(isFirstApplePayAdress);
        address.isApplePay = true;
        address.applePayEmail = shippingApplePayContact?.emailAddress ?? "";
        return address;
    }

    private updatePostalCode(
        postalCode?: string,
        country?: string,
    ): string | undefined {
        let correctPostalCode = postalCode?.replace(/\s/g, "");
        if (country?.toUpperCase() === "CA") {
            correctPostalCode = correctPostalCode?.padEnd(6, "0");
        }
        return correctPostalCode;
    }

    private updateShipping(
        basket: Basket,
    ): ApplePayJS.ApplePayShippingMethod[] {
        const shippingMethods = basket.availableShippingMethod;

        return Object.keys(shippingMethods).map(
            (key: string): ApplePayJS.ApplePayShippingMethod => {
                const serializedShippingMethod = shippingMethods[key];
                return {
                    identifier: serializedShippingMethod.method,
                    label: serializedShippingMethod.title,
                    amount: serializedShippingMethod.price?.toString(),
                    detail: serializedShippingMethod.duration ?? "",
                };
            },
        );
    }

    private updateItemsFromBasket(
        basket: Basket,
        applePayShippingMethods?: ApplePayJS.ApplePayShippingMethod,
    ): ApplePayJS.ApplePayLineItem[] {
        let displayProductItems: ApplePayJS.ApplePayLineItem[] = [];
        // Prepare the items to display
        basket.items.forEach((item) => {
            const productItem: ApplePayJS.ApplePayLineItem = {
                label: item.name,
                amount: item.unitPrice.toString(),
            };

            displayProductItems = [
                ...displayProductItems,
                ...Array.from<ApplePayJS.ApplePayLineItem>({
                    length: item.qty,
                }).fill(productItem),
            ];
        });

        this.addShippingInItems(displayProductItems, applePayShippingMethods);

        this.addTaxInItems(displayProductItems, basket);

        return displayProductItems;
    }

    private addShippingInItems(
        displayProductItems: ApplePayJS.ApplePayLineItem[],
        applePayShippingMethods?: ApplePayJS.ApplePayShippingMethod,
    ): void {
        displayProductItems.push({
            label: this.shippingLabel,
            amount: applePayShippingMethods?.amount ?? "0",
        });
    }

    private addTaxInItems(
        displayProductItems: ApplePayJS.ApplePayLineItem[],
        basket: Basket,
    ): void {
        // If tax is handeled on this locale, add the tax item
        const localeCode = this.locale.code;
        if (
            basket.showTax &&
            Object.prototype.hasOwnProperty.call(basket.showTax, localeCode) &&
            basket.showTax[localeCode] &&
            displayProductItems
        ) {
            const taxItem: ApplePayJS.ApplePayLineItem = {
                label: this.taxLabel,
                amount: basket.taxAmount?.toString() || "0",
            };
            displayProductItems.push(taxItem);
        }
    }

    private updateTotalFromBasket(basket: Basket): ApplePayJS.ApplePayLineItem {
        return {
            label: this.totalLabel,
            amount: basket.grandTotal ? basket.grandTotal.toString() : "",
            type: "pending",
        };
    }

    private handleUnconnectedUser(
        payerEmail?: string,
    ): Observable<GuestBCKResponse> {
        if (!payerEmail) {
            throw new Error("no payer email");
        }

        return this.customerService.getGuestVerification(
            {
                email: payerEmail,
            } as PrivateContact,
            true,
        );
    }

    private handleError(
        response:
            | CustomerAddressResponse
            | DeliveryAddressResponse
            | DeliveryShippingResponse
            | AddAddressResponse,
    ): ApplePayError | undefined {
        const exclusionCodes: string[] = [
            EcomErrorCodes.BLOCKED_SHIPPING_ADDRESS,
            EcomErrorCodes.DELIVERY_BLOCKER_POSTCODE,
            "4200009",
        ];

        if (!response.error) {
            return undefined;
        }
        if (exclusionCodes.includes(response.error.internalCode)) {
            return new ApplePayError("addressUnserviceable");
        }

        throw new Error("error");
    }
}
