import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { INFRA_SETTINGS } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";

import { PLATFORME_ENDPOINT_CONFIG } from "../constant/product-personalization.constant";
import { PlatformeInitialConfiguration } from "../types/product-personalization.type";

@Injectable()
export class FetchPlatformeConfigurationService {
    constructor(
        private http: HttpClient,
        @Inject(INFRA_SETTINGS) private infraSettings: InfraSettings,
    ) {}

    /**
     * Allow to get platforme config datas
     */
    public fetchPlatformeConfiguration(
        productSku: string,
    ): Observable<PlatformeInitialConfiguration> {
        return this.http
            .get(
                `${this.infraSettings.personalizationPlatformeUrl}/${PLATFORME_ENDPOINT_CONFIG}${productSku}`,
            )
            .pipe(map((response) => response as PlatformeInitialConfiguration));
    }
}
