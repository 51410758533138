import {
    createFeatureSelector,
    createSelector,
    DefaultProjectorFn,
    MemoizedSelector,
} from "@ngrx/store";

import { MERCURY_ADYEN_ONE_CLICK } from "@hermes/utils/constants";

import { isAdyenCreditCardHelper } from "@hermes/utils/helpers";

import { AdyenCreditCardPaymentMethod } from "../models/adyen-credit-card-payment-method.model";
import { paymentFeatureKey, State } from "../reducers/payment-methods.reducer";
import { AdyenOneClickPaymentMethod } from "../models/adyen-one-click-payment-method.model";

const selectPaymentState = createFeatureSelector<State>(paymentFeatureKey);

const selectPaymentMethodsData = createSelector(
    selectPaymentState,
    (paymentState) => paymentState.methods.data,
);

const selectAdyenCreditCardPaymentMethod = createSelector(
    selectPaymentMethodsData,
    (pms) =>
        pms.find((pm): pm is AdyenCreditCardPaymentMethod =>
            isAdyenCreditCardHelper(pm.code),
        ),
);
const selectSavedPaymentCards = createSelector(
    selectPaymentMethodsData,
    (paymentMethods) =>
        paymentMethods.filter(
            (paymentMethod): paymentMethod is AdyenOneClickPaymentMethod =>
                paymentMethod.code === MERCURY_ADYEN_ONE_CLICK,
        ),
);
export const selectSavedPaymentCardsState = createSelector(
    selectPaymentState,
    (state: State) => state.methods.pending,
);

const selectDeleteSavedCardPending = (
    cardId: string,
): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
    createSelector(
        selectPaymentMethodsData,
        (pms) =>
            pms.find(
                (pm): pm is AdyenOneClickPaymentMethod =>
                    pm.code === MERCURY_ADYEN_ONE_CLICK && pm.id === cardId,
            )?.deleteStatus === "pending",
    );

export const paymentQuery = {
    selectPaymentMethodsData,
    selectDeleteSavedCardPending,
    selectAdyenCreditCardPaymentMethod,
    selectSavedPaymentCards,
};

export default paymentQuery;
