import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

import { FooterItemsState, ShellFacade } from "@hermes/states/shell";

@Component({
    selector: "h-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
    @Input()
    public hasLightCopyright!: boolean;

    public footerItems$: Observable<FooterItemsState> =
        this.shellFacade.footerItems$.pipe(
            tap((data) => {
                if (!data) {
                    this.shellFacade.fetchFooterItems();
                }
            }),
            filter((footerItems) => footerItems !== undefined),
            take(1),
        );

    constructor(private shellFacade: ShellFacade) {}
}
