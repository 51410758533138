<div class="carousel-inner" id="carousel-inner">
    <button
        (click)="previousSlide()"
        (keypress.enter)="previousSlide()"
        class="icon-banner arrow-left desktop-only"
        [attr.aria-controls]="'carousel-notification'">
        <span hSrOnlyNoSnippet i18n="@@hermes.header.notification-previously">Previous notification</span>
        <h-svg-icon [name]="'arrow-left'"></h-svg-icon>
    </button>

    <div
        id="carousel-notification"
        [@fade]="launchFade"
        (@fade.done)="launchFade = false"
        class="banner-message-text"
        [attr.role]="'group'"
        [attr.aria-roledescription]="'slide'"
        [attr.aria-live]="'polite'"
        [attr.aria-label]="currentIndex + 1 + ' of ' + notifications.length"
        (swipeleft)="nextSlide()"
        (swiperight)="previousSlide()">
        <h-notification-message [notificationMessage]="notifications[currentIndex]"> </h-notification-message>
    </div>

    <button
        (click)="nextSlide()"
        (keypress.enter)="nextSlide()"
        class="icon-banner arrow-right desktop-only"
        [attr.aria-controls]="'carousel-notification'">
        <span hSrOnlyNoSnippet i18n="@@hermes.header.notification-next">Next notification</span>
        <h-svg-icon [name]="'arrow-right'"></h-svg-icon>
    </button>
</div>

<ul class="bullet-nav mobile-only">
    <li *ngFor="let notification of notifications; index as ind">
        <button
            (click)="setCurrentNotification(ind)"
            (keypress.enter)="setCurrentNotification(ind)"
            [ngClass]="{ 'is-active': currentIndex === ind }"
            [attr.aria-label]="ind + 1 + ' of ' + notifications.length"></button>
    </li>
</ul>
