import {
    Compiler,
    ComponentFactory,
    Injectable,
    Injector,
    NgModuleFactory,
    NgModuleRef,
    Type,
    ɵisPromise as isPromise,
} from "@angular/core";
import { LoadChildrenCallback } from "@angular/router";
import { from, isObservable, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import { TrayData } from "../models/tray-data.model";
import { TRAY_COMPONENT } from "../models/tray.constants";

@Injectable()
export class TrayLoaderService {
    constructor(
        private readonly injector: Injector,
        private readonly compiler: Compiler,
    ) {}

    /**
     * Loads a dynamic component from a module. instanciate its injector and returns a factory.
     *
     * @param tray the data of the tray to load.
     * @returns the ComponentFactory for creating the components or undefined
     */
    public loadDynamicTray(
        tray: TrayData,
    ): Observable<void | ComponentFactory<unknown>> {
        return this.loadModuleFactory(
            tray.lazyModule as LoadChildrenCallback,
        ).pipe(
            map((moduleFactory: NgModuleFactory<unknown>) => {
                // create the module and extract its entryComponent type.
                const moduleRef: NgModuleRef<unknown> = moduleFactory.create(
                    this.injector,
                );
                let componentType;
                try {
                    componentType = moduleRef.injector.get(TRAY_COMPONENT);
                } catch (err) {
                    throw new Error(
                        "Lazy loaded module should provide TRAY_COMPONENT",
                    );
                }
                return moduleRef.componentFactoryResolver.resolveComponentFactory(
                    componentType,
                );
            }),
        );
    }

    /**
     * Code adapted from what the angular router does :
     *
     * @see https://github.com/angular/angular/blob/master/packages/router/src/router_config_loader.ts#L51
     */
    private loadModuleFactory(
        loadChildren: LoadChildrenCallback,
    ): Observable<NgModuleFactory<unknown>> {
        return this.wrapIntoObservable(loadChildren()).pipe(
            mergeMap((t: unknown) => {
                if (t instanceof NgModuleFactory) {
                    return of(t);
                }
                return from(
                    this.compiler.compileModuleAsync(t as Type<unknown>),
                );
            }),
        );
    }

    /**
     * Helper to transform loadChildren callback into an observable.
     *
     * @see https://github.com/angular/angular/blob/master/packages/router/src/utils/collection.ts#L110
     */
    private wrapIntoObservable<T>(
        value: T | Promise<T> | Observable<T>,
    ): Observable<T> {
        if (isObservable(value)) {
            return value;
        }
        if (isPromise(value)) {
            return from(Promise.resolve(value));
        }
        return of(value);
    }
}
