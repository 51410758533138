import { CallingHour, CustomerService } from "@hermes/api-model-editorial";
import {
    FooterHereToHelpColumnContacts,
    FooterHereToHelpItem,
} from "@hermes/api-model-shell";

export const footerHereToHelpColumnContactsToCustomerServiceMapper = (
    footerHereToHelpColumnContactsList: FooterHereToHelpColumnContacts[],
): CustomerService[] =>
    footerHereToHelpColumnContactsList.map((footerHereToHelpColumnContact) => ({
        description: footerHereToHelpColumnContact.description,
        phone: footerHereToHelpColumnContact.phoneNumber,
        callingHours:
            footerHereToHelpColumnContact.openingItems &&
            footerHereToHelpItemsToCallingHoursMapper(
                footerHereToHelpColumnContact.openingItems,
            ),
    }));

const footerHereToHelpItemsToCallingHoursMapper = (
    footerHereToHelpItems: FooterHereToHelpItem[],
): CallingHour[] =>
    footerHereToHelpItems.map((footerHereToHelpItem) => ({
        days: footerHereToHelpItem.days,
        openingHours: footerHereToHelpItem.open,
        closingHours: footerHereToHelpItem.close,
        timezone: footerHereToHelpItem.timezone,
    }));
