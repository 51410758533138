export const BACKSPACE = "Backspace";
export const DELETE = "Delete";
export const LEFT = "ArrowLeft";
export const UP = "ArrowUp";
export const RIGHT = "ArrowRight";
export const DOWN = "ArrowDown";
export const PAGE_UP = "PageUp";
export const PAGE_DOWN = "PageDown";
export const END = "End";
export const HOME = "Home";
export const SPACE = "Space";
export const ENTER = "Enter";
export const TAB = "Tab";
export const ESCAPE = "Escape";
