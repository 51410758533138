import { createFeatureSelector, createSelector } from "@ngrx/store";

import { EXTERNALLIBRARY_FEATURE_KEY } from "../reducer/external-library.reducer";
import {
    ExternalLibraryState,
    INITIALIZED_CSR,
    INITIALIZED_SSR,
    LOADED,
    ERROR,
} from "../reducer/external-library.state";

// Lookup the 'ExternalLibrary' feature state managed by NgRx
export const getExternalLibraryState =
    createFeatureSelector<ExternalLibraryState>(EXTERNALLIBRARY_FEATURE_KEY);

export const selectState = createSelector(
    getExternalLibraryState,
    (state: ExternalLibraryState) => state,
);

export const selectLibrary = (key: string) =>
    createSelector(
        getExternalLibraryState,
        (state: ExternalLibraryState) => state[key],
    );

export const selectIsLoaded = (key: string) =>
    createSelector(selectLibrary(key), (library) => library?.status === LOADED);

export const selectIsLoading = (key: string) =>
    createSelector(selectLibrary(key), (library) =>
        [INITIALIZED_SSR, INITIALIZED_CSR].includes(library?.status),
    );

export const selectIsError = (key: string) =>
    createSelector(selectLibrary(key), (library) => library?.status === ERROR);
