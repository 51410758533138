import { createReducer, on, Action } from "@ngrx/store";

import { CustomerService } from "@hermes/api-model-editorial";

import {
    fetchCustomerServiceContactsFailure,
    fetchCustomerServiceContactsSuccess,
} from "../actions/customer-service-contact.actions";

export const CUSTOMER_SERVICE_CONTACT_FEATURE_KEY = "customerServiceContact";

export interface CustomerServiceContactState {
    customerServiceContacts?: CustomerService[];
    isLoaded: boolean;
}

export const initialCustomerServiceContactState: CustomerServiceContactState = {
    customerServiceContacts: undefined,
    isLoaded: false,
};

export const reducer = (
    state: CustomerServiceContactState,
    action: Action,
): CustomerServiceContactState => customerServiceContactReducer(state, action);

export const fetchCustomerServiceContactsSuccessReducer = (
    state: CustomerServiceContactState,
    action: ReturnType<typeof fetchCustomerServiceContactsSuccess>,
): CustomerServiceContactState => ({
    ...state,
    customerServiceContacts: action.customerServiceContacts,
    isLoaded: true,
});

export const fetchCustomerServiceContactsFailureReducer = (
    state: CustomerServiceContactState,
): CustomerServiceContactState => ({
    ...state,
    customerServiceContacts: undefined,
    isLoaded: false,
});

const customerServiceContactReducer = createReducer(
    initialCustomerServiceContactState,
    on(
        fetchCustomerServiceContactsSuccess,
        fetchCustomerServiceContactsSuccessReducer,
    ),
    on(
        fetchCustomerServiceContactsFailure,
        fetchCustomerServiceContactsFailureReducer,
    ),
);
