import { GTMEventData } from "@hermes/utils/analytics";

import { FooterAnalyticsEvent } from "./footer.event";

/**
 * This class is used for click on category in menu-tray
 */
export class FooterClickEvent extends FooterAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMEventData {
        const label = this.eventData.label;
        const action = this.eventData.action;
        const category = this.eventData.category;
        return {
            ...super.formatForGTM(category),
            eventaction: action,
            eventlabel: label || undefined,
        };
    }
}
