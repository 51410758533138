<div
    #messageText
    role="alert"
    class="banner-message-text"
    [class.expanded]="expanded"
    [innerHTML]="notificationMessage"
    tabindex="0">
</div>

<button
    #buttonShowMore
    class="button-see-more"
    type="button"
    [attr.aria-expanded]="expanded"
    [attr.aria-controls]="'text'"
    (click)="clickSeeMore()">
    <ng-container i18n="@@node--hermes-product-display-default.expand">See more</ng-container>
</button>
