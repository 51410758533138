import { InjectionToken } from "@angular/core";
import { LoadChildrenCallback } from "@angular/router";

/**
 * This is a fake type to trick angular into reading our trays modules as routed
 * Do not rename fields.
 */
export interface RouteLike {
    /**
     * fake path, allows selection.
     * !! should not match a real route.
     */
    path: string;
    /**
     * path to the module relative to the app.module.
     */
    loadChildren: LoadChildrenCallback;
}

/**
 * Returns a tray declaration from the given lazy-loaded tray list
 */
export function getTrayByPath(
    trayList: RouteLike[],
    trayPath: string,
): RouteLike | undefined {
    return trayList.find((trayData) => trayData.path === trayPath);
}

/**
 * Injection token for the list of trays so that angular treats them.
 */
export const TRAY_MODULES: InjectionToken<RouteLike[]> = new InjectionToken<
    RouteLike[]
>("TRAY_MODULES");
