import { CountryCode } from "@hermes/locale";

export const GOOGLE_CALLBACK_PARAMETER = "callback";
export const GOOGLE_MAPS_KEY = "gme-hermesinternational";
export const GOOGLE_MARKER = "google";
export const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?client=${GOOGLE_MAPS_KEY}&libraries=places`;
export const ADD_LANGUAGE_TO_GOOGLE_MAPS_PLACES_API_URL = new Set<CountryCode>([
    "th",
    "kr",
]);
export const ADD_REGION_TO_GOOGLE_MAPS_PLACES_API_URL = new Set<CountryCode>([
    "kr",
]);

export const BAIDU_CALLBACK_PARAMETER = "callback";
export const BAIDU_MAP_KEY = "0NffPGoT9Mk1zGnTukfHZDjwsCy7RunA";
export const BAIDU_MARKER = "TILE_VERSION";
export const BAIDU_MAPS_API_URL = `https://api.map.baidu.com/api?v=3.0&ak=${BAIDU_MAP_KEY}`;
