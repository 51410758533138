import { Action, createReducer, on } from "@ngrx/store";

import {
    fetchFeatureFlagFailure,
    fetchFeatureFlags,
    fetchFeatureFlagSuccess,
} from "../actions/feature-flag-actions";

import {
    FeatureFlagState,
    FeatureFlagStatus,
    initialFeatureFlagState,
    NO_FLAGS,
} from "./feature-flag.state";

const featureFlagReducer = createReducer(
    initialFeatureFlagState,
    on(fetchFeatureFlagSuccess, (_, { flags }) => ({
        status: FeatureFlagStatus.Loaded as FeatureFlagStatus.Loaded,
        flags,
    })),
    on(fetchFeatureFlagFailure, (_, { error }) => ({
        status: FeatureFlagStatus.Error as FeatureFlagStatus.Error,
        error,
        flags: NO_FLAGS,
    })),
    on(fetchFeatureFlags, (_) => ({
        status: FeatureFlagStatus.Loading as FeatureFlagStatus.Loading,
        flags: NO_FLAGS,
    })),
);

export const reducer = (
    state: FeatureFlagState,
    action: Action,
): FeatureFlagState => featureFlagReducer(state, action);
