import { HeaderAnalyticsEvent } from "./header.event";

export interface GTMVirtualPageData {
    event: string;
    eventcategory: string;
    eventaction: string;
    eventlabel: string;
    virtualpageuri: string;
    pagetype: string;
}

/**
 * This class is used for click on account link in header
 */
export class HeaderAccountClickEvent extends HeaderAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMVirtualPageData {
        const virtualpageuri = this.eventData.virtualpageuri;
        return {
            ...super.formatForGTM(),
            eventaction: "TopNavigationHead",
            eventlabel: "Account",
            virtualpageuri: virtualpageuri || "undefined",
            pagetype: "account",
        };
    }
}
