import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, of } from "rxjs";

import { ModelObject } from "@hermes/api-model-core";
import { CountrySelectorData } from "@hermes/api-model-country";
import { Settings } from "@hermes/app-core";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

@Injectable()
export class FetchCountriesService {
    constructor(private http: HttpClient, private settings: Settings) {}

    /**
     * The country-selector will be saved in the localstorage with a defined lifetime
     */
    public fetchCountries(): Observable<CountrySelectorData> {
        return this.http
            .get(`${this.settings.apiUrl}/country-selector`, {
                context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
            })
            .pipe(
                // convert json to CountrySelectorData.
                map((result: unknown) =>
                    ModelObject.fromJsonData(result, CountrySelectorData),
                ),
                catchError(() => {
                    const countries = new CountrySelectorData();
                    countries.areas = [];
                    return of(countries);
                }),
            );
    }
}
