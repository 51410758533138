import {
    animate,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";

import {
    OVERLAY_ANIMATION,
    OVERLAY_OPACITY,
} from "../../models/tray.constants";

/**
 * Slide animation from the left or the right
 */
export const trayOverlayAnimations: AnimationTriggerMetadata = trigger(
    "overlay",
    [
        // state when shown
        state(
            "true",
            style({
                opacity: OVERLAY_OPACITY,
                pointerEvents: "all",
            }),
        ),
        // state when hidden
        state(
            "false",
            style({
                opacity: 0,
                pointerEvents: "none",
            }),
        ),
        // fade in
        transition("false => true", [animate(OVERLAY_ANIMATION)]),
        // fade out
        transition("true => false", [animate(OVERLAY_ANIMATION)]),
    ],
);
