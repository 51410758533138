import { Inject, Injectable, NgZone } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { mergeMap, shareReplay, switchMap, take } from "rxjs/operators";

import { Coordinates } from "@hermes/api-model-common";
import { LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { isBaiduEngine } from "@hermes/utils/helpers";
import { LoadGeoLibrariesService } from "@hermes/utils/services/load-geo-libraries";

const UNKNOW_ERROR_GOOGLE = "Unknown error occured while using Google geoCode";
const GEOLOCATION_TIMEOUT = 5000;

/** Cache expiration time before a new geolocation request is made */
const WINDOW_TIME = 10_000;

type GeoCodeSearchMode = "plain" | "placeId";

export interface MapBounds {
    east: number;
    north: number;
    south: number;
    west: number;
}

export interface ReverseGeoCodeResult {
    coordinates: Coordinates;
    bounds?: MapBounds;
}

@Injectable()
export class GeoService {
    private googleGeocoder?: google.maps.Geocoder;
    private baiduConverter?: BMap.Convertor;
    private baiduGeocoder?: BMap.Geocoder;

    private position$!: Observable<GeolocationPosition>;
    private subscriptionTime: number = 0;

    constructor(
        private ngZone: NgZone,
        private loadGeoLibrariesService: LoadGeoLibrariesService,
        @Inject(LOCALE) private locale: Locale,
        @Inject(WINDOW) private window: Window,
    ) {}

    /**
     * Returns an address string based on navigator.geolocation.getCurrentPosition
     */
    public getCurrentAddress(): Observable<string> {
        return this.getCurrentPosition().pipe(
            mergeMap((position) => this.geoCode(position)),
        );
    }

    /**
     * Observable wrapper for navigator.geolocation.getCurrentPosition
     */
    public getCurrentPosition(): Observable<GeolocationPosition> {
        const currentTime = Date.now();

        if (
            !this.position$ ||
            currentTime - this.subscriptionTime > WINDOW_TIME
        ) {
            this.position$ = new Observable<GeolocationPosition>((observer) => {
                if (!this.window.navigator?.geolocation) {
                    observer.error("Geolocation not available");
                    return;
                }

                this.window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        observer.next(position);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                    },
                    { timeout: GEOLOCATION_TIMEOUT },
                );
            }).pipe(shareReplay(1));

            this.subscriptionTime = currentTime;
        }

        return this.position$;
    }

    /**
     * Returns an address string from WGS84 coordinates
     */
    public geoCode(position: GeolocationPosition): Observable<string> {
        // Ask for loading Baidu or Google Geo library.
        // If it is already loaded, it is not re-downloaded : the State manages it for us.
        return this.loadGeoLibrariesService.isLoaded$.pipe(
            take(1),
            switchMap((isLoaded) => {
                if (!isLoaded) {
                    return throwError(
                        () => new Error("Geo library script not yet loaded"),
                    );
                }

                if (isBaiduEngine(this.locale.countryCode)) {
                    return this.baiduGeocode(position);
                }
                return this.googleGeocode(position);
            }),
        );
    }

    public googleGeocoderResult(
        address: string,
        country: string,
    ): Observable<google.maps.GeocoderResult> {
        // Ask for loading Google Geo library.
        // If it is already loaded, it is not re-downloaded : the State manages it for us.
        return this.loadGeoLibrariesService.isGoogleLoaded$.pipe(
            take(1),
            switchMap((isLoaded) => {
                if (!isLoaded) {
                    return throwError(
                        () => new Error("Google script not yet loaded"),
                    );
                }
                return new Observable<google.maps.GeocoderResult>(
                    (observer) => {
                        if (!this.googleGeocoder) {
                            this.googleGeocoder = new google.maps.Geocoder();
                        }

                        const request: google.maps.GeocoderRequest = {
                            address,
                            componentRestrictions: {
                                country,
                            },
                        };

                        this.googleGeocoder.geocode(
                            request,
                            (results, status) => {
                                this.ngZone.run(() => {
                                    if (
                                        this.isGoogleGeocoderOK(results, status)
                                    ) {
                                        observer.next(results[0]);
                                        observer.complete();
                                        return;
                                    }
                                    if (status) {
                                        observer.error(status);
                                        return;
                                    }
                                    observer.error(UNKNOW_ERROR_GOOGLE);
                                });
                            },
                        );
                    },
                );
            }),
        );
    }

    /**
     * Return coordinates from address - or a placeId (Google notion only)
     *
     * If used when isChina is true, the lat/lng are BD09, so there is a imprecision of ip to a kilometer (by design)
     * If geoCodeSearchMode = "placeId" is only supported by Google services.
     */
    public reverseGeocode(
        search: string,
        geoCodeSearchMode: GeoCodeSearchMode = "plain",
    ): Observable<ReverseGeoCodeResult> {
        // Ask for loading Baidu or Google Geo library.
        // If it is already loaded, it is not re-downloaded : the State manages it for us.
        return this.loadGeoLibrariesService.isLoaded$.pipe(
            take(1),
            switchMap((isLoaded) => {
                if (!isLoaded) {
                    return throwError(
                        () => new Error("Geo library script not yet loaded"),
                    );
                }
                if (isBaiduEngine(this.locale.countryCode)) {
                    return this.baiduReverseGeocode(search);
                }
                return this.googleReverseGeocode(search, geoCodeSearchMode);
            }),
        );
    }

    /**
     * Check status and provide a Type Guard on results since it can't be null anymore
     */
    private isGoogleGeocoderOK(
        results: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus,
    ): results is google.maps.GeocoderResult[] {
        return status === google.maps.GeocoderStatus.OK;
    }

    private googleGeocode(position: GeolocationPosition): Observable<string> {
        return new Observable<string>((observer) => {
            if (!this.googleGeocoder) {
                this.googleGeocoder = new google.maps.Geocoder();
            }

            const request: google.maps.GeocoderRequest = {
                location: new google.maps.LatLng(
                    position.coords.latitude,
                    position.coords.longitude,
                ),
            };

            this.googleGeocoder.geocode(request, (results, status) => {
                if (this.isGoogleGeocoderOK(results, status)) {
                    observer.next(results[0].formatted_address);
                    observer.complete();
                    return;
                }
                if (status) {
                    observer.error(status);
                    return;
                }
                observer.error(UNKNOW_ERROR_GOOGLE);
            });
        });
    }

    private baiduGeocode(position: GeolocationPosition): Observable<string> {
        return new Observable<string>((observer) => {
            const point = new BMap.Point(
                position.coords.longitude,
                position.coords.latitude,
            );

            if (!this.baiduConverter) {
                this.baiduConverter = new BMap.Convertor();
            }

            if (!this.baiduGeocoder) {
                this.baiduGeocoder = new BMap.Geocoder();
            }

            // First, convert the
            // WGS84 coordinates (world standard) to
            // BD09 coordinates (Proprietary coordinate system, not conversible back to the standard)
            this.baiduConverter.translate(
                [point],
                1,
                5,
                (data: { points: unknown[] }) => {
                    if (!data || !data.points || data.points.length !== 1) {
                        observer.error(data);
                        return;
                    }

                    // Then, get the address from the baidu api
                    this.baiduGeocoder?.getLocation(
                        data.points[0] as BMap.Point,
                        (result: { address: string }) => {
                            if (!result || !result.address) {
                                observer.error(result);
                                return;
                            }

                            observer.next(result.address);
                            observer.complete();
                        },
                    );
                },
            );
        });
    }

    private googleReverseGeocode(
        search: string,
        geoCodeSearchMode: GeoCodeSearchMode = "plain",
    ): Observable<ReverseGeoCodeResult> {
        return new Observable<ReverseGeoCodeResult>((observer) => {
            if (!this.googleGeocoder) {
                this.googleGeocoder = new google.maps.Geocoder();
            }
            const request: google.maps.GeocoderRequest = {
                ...(geoCodeSearchMode === "plain" && {
                    address: search,
                }),
                ...(geoCodeSearchMode === "placeId" && {
                    placeId: search,
                }),
            };

            this.googleGeocoder.geocode(request, (results, status) => {
                if (this.isGoogleGeocoderOK(results, status)) {
                    const result: ReverseGeoCodeResult = {
                        coordinates: new Coordinates({
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                        }),
                        bounds: undefined,
                    };

                    // favor viewport if available; otherwise, fall back to bounds
                    const geometryBounds =
                        results[0].geometry.viewport ??
                        results[0].geometry.bounds;

                    if (geometryBounds) {
                        const boundsLiteral = geometryBounds.toJSON();
                        result.bounds = {
                            west: boundsLiteral.west,
                            east: boundsLiteral.east,
                            south: boundsLiteral.south,
                            north: boundsLiteral.north,
                        };
                    }
                    observer.next(result);
                    observer.complete();
                    return;
                }
                if (status) {
                    observer.error(status);
                    return;
                }
                observer.error(UNKNOW_ERROR_GOOGLE);
            });
        });
    }

    private baiduReverseGeocode(
        address: string,
    ): Observable<ReverseGeoCodeResult> {
        return new Observable<ReverseGeoCodeResult>((observer) => {
            // BD09 are, by design, impossible to convert back to WGS84
            // The BD09 lat/lng are returned, so there is an imprecision of up to 1km

            if (!this.baiduGeocoder) {
                this.baiduGeocoder = new BMap.Geocoder();
            }

            this.baiduGeocoder.getPoint(
                address,
                (result: { lat: number; lng: number }) => {
                    if (!result) {
                        observer.error(result);
                        return;
                    }

                    const coordinates: Coordinates = new Coordinates({
                        latitude: result.lat,
                        longitude: result.lng,
                    });

                    observer.next({
                        coordinates,
                        bounds: undefined,
                    });
                    observer.complete();
                },
                undefined as unknown as string,
            );
        });
    }
}
