import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    svgHermesLogo,
    svgHermesLogoSmall,
    SvgModule,
} from "@hermes/aphrodite/svg";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

import { LogoComponent } from "./components/logo/logo.component";

@NgModule({
    imports: [
        CommonModule,
        SrOnlyNoSnippetDirective,
        SvgModule.forChild([svgHermesLogo, svgHermesLogoSmall]),
    ],
    declarations: [LogoComponent],
    exports: [LogoComponent],
})
export class LogoModule {}
