export type GeocodingAdressFieldType =
    | "street_address" // indicates a precise street address.
    | "route" // indicates a named route (such as "US 101").
    | "intersection" // indicates a major intersection, usually of two major roads.
    | "political" // indicates a political entity. Usually, this type"// indicates a polygon of some civil administration.
    | "country" // indicates the national political entity, and is typically the highest order type returned by the Geocoder.
    | "administrative_area_level_1" // indicates a first-order civil entity below the country level. Within the United States, these administrative levels are | " states. Not all nations exhibit these administrative levels. In most cases, administrative_area_level_1 short names will closely match ISO 3166-2 subdivisions | " and other widely circulated lists; however this is not guaranteed as our geocoding results are based on a variety of signals and location data.
    | "administrative_area_level_2" // indicates a second-order civil entity below the country level. Within the United States, these administrative levels are | " counties. Not all nations exhibit these administrative levels.
    | "administrative_area_level_3" // indicates a third-order civil entity below the country level.
    | "administrative_area_level_4" // indicates a fourth-order civil entity below the country level.
    | "administrative_area_level_5" // indicates a fifth-order civil entity below the country level.
    | "administrative_area_level_6" // indicates a sixth-order civil entity below the country level.
    | "administrative_area_level_7" // indicates a seventh-order civil entity below the country level.
    | "colloquial_area" // indicates a commonly-used alternative name for the entity.
    | "locality" // indicates an incorporated city or town political entity.
    | "sublocality" // indicates a first-order civil entity below a locality. For some locations may receive one of the additional types: sublocality_level_1 to | " sublocality_level_5. Each sublocality level is a civil entity. Larger numbers indicate a smaller geographic area.
    | "neighborhood" // indicates a named neighborhood
    | "premise" // indicates a named location, usually a building or collection of buildings with a common name
    | "subpremise" // indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
    | "plus_code" // indicates an encoded location reference, derived from latitude and longitude. Plus codes can be used as a replacement for street addresses in | " places where they do not exist (where buildings are not numbered or streets are not named). See https://plus.codes for details.
    | "postal_code" // indicates a postal code as used to address postal mail within the country.
    | "natural_feature" // indicates a prominent natural feature.
    | "airport" // indicates an airport.
    | "park" // indicates a named park.
    | "point_of_interest" // indicates a named point of interest. Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Eiffel Tower".
    | "floor" // indicates the floor of a building address.
    | "establishment" // typically indicates a place that has not yet been categorized.
    | "landmark" // indicates a nearby place that is used as a reference, to aid navigation.
    | "parking" // indicates a parking lot or parking structure.
    | "post_box" // indicates a specific postal box.
    | "postal_town" // indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
    | "room" // indicates the room of a building address.
    | "street_number" // indicates the precise street number.
    | "bus_station" // indicates the location of a bus.
    | "train_station" // indicates the location of a train.
    | "transit_station"; // indicates the location of a | " transit stop.

type GeocodingAddressTypesModel = Record<GeocodingAdressFieldType, string>;

export const GeocodingAddressTypes: GeocodingAddressTypesModel = {
    street_address: "",
    route: "",
    intersection: "",
    political: "",
    country: "",
    administrative_area_level_1: "",
    administrative_area_level_2: "",
    administrative_area_level_3: "",
    administrative_area_level_4: "",
    administrative_area_level_5: "",
    administrative_area_level_6: "",
    administrative_area_level_7: "",
    colloquial_area: "",
    locality: "",
    sublocality: "",
    neighborhood: "",
    premise: "",
    subpremise: "",
    plus_code: "",
    postal_code: "",
    natural_feature: "",
    airport: "",
    park: "",
    point_of_interest: "",
    floor: "",
    establishment: "",
    landmark: "",
    parking: "",
    post_box: "",
    postal_town: "",
    room: "",
    street_number: "",
    bus_station: "",
    train_station: "",
    transit_station: "",
};
