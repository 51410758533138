import { EcomErrorCodes } from "@hermes/utils-generic/constants";

export interface ConfigMessageError {
    type: "info" | "error";
    translation: string;
    enabled: boolean;
    showBackground: boolean;
}

const unavailableConfigMessageError: ConfigMessageError = {
    type: "info",
    enabled: false,
    showBackground: false,
    translation: $localize`:@@hermes-availability.product-unavailable:Unfortunately this product is no longer available.`,
};

export const ERROR_CART_MESSAGE: Map<string, ConfigMessageError> = new Map([
    [
        EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS,
        {
            type: "info",
            enabled: false,
            showBackground: false,
            translation: $localize`:@@hermes.personalization.notification.quote.mixed.items:Oops,a personalized product can't be associated to another product in cart.'`,
        },
    ],
    [
        EcomErrorCodes.SIZE_OUT_OF_STOCK,
        {
            type: "info",
            enabled: false,
            showBackground: false,
            translation: $localize`:@@hermes-availability.size-unavailable:The desired size is no longer available.`,
        },
    ],
    [
        EcomErrorCodes.ITEM_OUT_OF_STOCK,
        {
            type: "info",
            enabled: false,
            showBackground: false,
            translation: $localize`:@@hermes.catalog.notification.items-out-of-stock:Oops! We're sorry, one or several of your items are no longer available.`,
        },
    ],
    [
        EcomErrorCodes.MAX_CHILD_QUANTITY,
        {
            type: "info",
            enabled: false,
            showBackground: false,
            translation: $localize`:@@hermes.catalog.notification.max-quantity-reached:You have reached the maximum allowed quantity for this item.`,
        },
    ],
    [
        EcomErrorCodes.ERROR_CODE_CART_DEFAULT,
        {
            type: "info",
            enabled: false,
            showBackground: false,
            translation: $localize`:@@hermes.catalog.product_pages.find-in-store-error-service-unavailable:Something went wrong. Fear not, we will be right back.`,
        },
    ],
    [EcomErrorCodes.OUT_OF_STOCK, unavailableConfigMessageError],
    [EcomErrorCodes.INSUFFICIENT_STOCK, unavailableConfigMessageError],
]);
