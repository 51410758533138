import { DOCUMENT, Location } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import type * as express from "express";

import { distinctUntilChanged, filter, Subscription, tap } from "rxjs";

import {
    Context,
    INFRA_SETTINGS,
    LOCALE,
    REQUEST,
    RESPONSE,
    WINDOW,
} from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";
import { Locale } from "@hermes/locale";
import { redirectResponse } from "@hermes/utils/services/response";

import { initPreviousUrlFromReferrer } from "./init-previous-url";

const isPageReload = (): boolean => {
    const [navEntry] = performance.getEntriesByType(
        "navigation",
    ) as PerformanceNavigationTiming[];

    return navEntry && navEntry.type === "reload";
};
@Injectable({
    providedIn: "root",
})
export class UrlUtils implements OnDestroy {
    private previousUrl: string;
    private currentUrl: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private context: Context,
        @Inject(WINDOW) private window: Window,
        @Inject(REQUEST) private request: express.Request,
        @Inject(RESPONSE) private response: express.Response,
        @Inject(LOCALE) private locale: Locale,
        @Inject(INFRA_SETTINGS) private infraSettings: InfraSettings,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private location: Location,
    ) {
        if (this.context.isInServerMode()) {
            this.previousUrl = this.request?.headers?.referer ?? "";
            this.currentUrl = this.request?.url ?? "";
            return;
        }
        this.currentUrl = this.location.path();
        this.previousUrl = isPageReload()
            ? this.location.path()
            : initPreviousUrlFromReferrer({
                  document: this.document,
                  infraSettings: this.infraSettings,
                  locale: this.locale,
              });

        this.subscription.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    distinctUntilChanged(),
                    tap(() => {
                        if (this.currentUrl === this.location.path()) {
                            return;
                        }
                        this.previousUrl = this.currentUrl;
                        this.currentUrl = this.location.path();
                    }),
                )
                .subscribe(),
        );
    }

    /**
     * Retrieves the previous URL based on the application's history or document referrer.
     *
     * @returns The previous URL or empty string if not available.
     */
    public getPreviousUrl(): string {
        // Previous url will be relative if it comes from an internal url
        if (this.previousUrl.length > 0 && !this.previousUrl.includes("http")) {
            return `${this.infraSettings.frontend}${this.locale.urlPrefix}${this.previousUrl}`;
        }
        return this.previousUrl;
    }

    /**
     * Return previous relative url
     */
    public getPreviousRelativeUrl(): string {
        return this.previousUrl.includes("http") ? "/" : this.previousUrl;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /*
     * Allow to redirect to an external url
     */
    public redirectToExternalUrl(url: string): void {
        if (this.context.isInBrowserMode()) {
            this.changeLocation(url);
        }
        if (this.context.isInServerMode()) {
            redirectResponse(this.request, this.response, url);
        }
    }

    /*
     * Allow to redirect to an internal url
     */
    public redirectToInternalUrl(path: string): void {
        if (this.context.isInBrowserMode()) {
            this.changeLocation(`${this.locale.urlPrefix}/${path}`);
        }
    }

    /*
     * Allow to redirect to root path with country && language code
     */
    public redirectToRootPath(): void {
        this.redirectToExternalUrl(`${this.locale.urlPrefix}/`);
    }

    /**
     * Redirects to a specified resource with country and language code.
     *
     * @param resource The name of the resource for redirection
     * @param pathParam Optional. Additional path parameter, used as part of the URL if provided
     */
    public redirectToPage(resource: string, pathParameter?: string): void {
        const path = pathParameter ? `${resource}/${pathParameter}` : resource;
        this.redirectToExternalUrl(`${this.locale.urlPrefix}/${path}/`);
    }

    /**
     * change window.location
     */
    public changeLocation(url: string): void {
        this.window.location.href = url;
    }

    public replaceHistory(url: string): void {
        this.window.history.replaceState({}, "", url);
    }

    public getCurrentUrl(): string {
        const currentURL = `${this.infraSettings.frontend}${
            this.locale.urlPrefix
        }${this.location.path().split("?")[0]}`;
        return decodeURI(currentURL);
    }

    public getCurrentRelativeUrl(): string {
        return this.currentUrl;
    }
}
