/* eslint-disable @typescript-eslint/naming-convention */
import {
    CityBreadcrumbList,
    CountryBreadcrumbList,
    ListItemUrl,
    MicroDataUrl,
    TitleBreadcrumbList,
} from "./structured-data.type";

const TITLE_OUR_STORES = $localize`:@@hermes.store-page.all-our-stores:All our stores`;

export const getBreadcrumbListFromItems = (
    itemListElement: ListItemUrl[],
): MicroDataUrl => ({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
});

export const getCountryBreadcrumbList = (
    baseURL: string,
    countryBreadcrumbList: CountryBreadcrumbList,
): MicroDataUrl =>
    getBreadcrumbListFromItems([
        {
            "@type": "ListItem",
            position: 1,
            name: TITLE_OUR_STORES,
            item: `${baseURL}`,
        },
        {
            "@type": "ListItem",
            position: 2,
            name: countryBreadcrumbList.translatedCountry,
            item: `${baseURL}${countryBreadcrumbList.slugifiedCountry}/`,
        },
    ]);

export const getCityBreadcrumbList = (
    baseURL: string,
    cityBreadcrumbList: CityBreadcrumbList,
): MicroDataUrl =>
    getBreadcrumbListFromItems([
        {
            "@type": "ListItem",
            position: 1,
            name: TITLE_OUR_STORES,
            item: `${baseURL}`,
        },
        {
            "@type": "ListItem",
            position: 2,
            name: cityBreadcrumbList.translatedCountry,
            item: `${baseURL}${cityBreadcrumbList.slugifiedCountry}/`,
        },
        {
            "@type": "ListItem",
            position: 3,
            name: cityBreadcrumbList.translatedCity,
            item: `${baseURL}${cityBreadcrumbList.slugifiedCountry}/${cityBreadcrumbList.slugifiedCity}/`,
        },
    ]);

export const getTitleBreadcrumbList = (
    baseURL: string,
    titleBreadcrumbList: TitleBreadcrumbList,
): MicroDataUrl =>
    getBreadcrumbListFromItems([
        {
            "@type": "ListItem",
            position: 1,
            name: TITLE_OUR_STORES,
            item: `${baseURL}`,
        },
        {
            "@type": "ListItem",
            position: 2,
            name: titleBreadcrumbList.translatedCountry,
            item: `${baseURL}${titleBreadcrumbList.slugifiedCountry}/`,
        },
        {
            "@type": "ListItem",
            position: 3,
            name: titleBreadcrumbList.translatedCity,
            item: `${baseURL}${titleBreadcrumbList.slugifiedCountry}/${titleBreadcrumbList.slugifiedCity}/`,
        },
        {
            "@type": "ListItem",
            position: 4,
            name: titleBreadcrumbList.translatedTitle,
            item: `${baseURL}${titleBreadcrumbList.slugifiedCountry}/${titleBreadcrumbList.slugifiedCity}/${titleBreadcrumbList.slugifiedTitle}/`,
        },
    ]);
