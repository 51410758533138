import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromCustomerServiceContact from "../reducers/customer-service-contact.reducer";

export const selectCustomerServiceContactState =
    createFeatureSelector<fromCustomerServiceContact.CustomerServiceContactState>(
        fromCustomerServiceContact.CUSTOMER_SERVICE_CONTACT_FEATURE_KEY,
    );

export const selectCustomerServiceContacts = createSelector(
    selectCustomerServiceContactState,
    (customerServiceContactState) =>
        customerServiceContactState.customerServiceContacts,
);

export const selectIsLoaded = createSelector(
    selectCustomerServiceContactState,
    (customerServiceContactState) => customerServiceContactState.isLoaded,
);
