import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { JsonConvert } from "json2typescript";
import { of } from "rxjs";
import { catchError, filter, switchMap, withLatestFrom } from "rxjs/operators";

import { User } from "@hermes/api-model-account";
import { StorageManager, StorageService } from "@hermes/app-core";

import { BasketFacade } from "@hermes/states/basket";

import {
    clearData,
    clearDataSuccess,
    fetchGuest,
    fetchGuestSuccess,
    launchUserSynchroWithStorage,
    removeGuest,
    removeGuestSuccess,
    setUserFromStorage,
    setUserInStorage,
    setUserInStorageFailure,
    setUserInStorageSuccess,
    updateGuest,
    userSynchroWithStorageFailure,
} from "../actions/user.actions";
import { UserState } from "../reducers/user.state";
import { selectUserStatus } from "../selectors/user.selectors";
import { UserStateService } from "../services/user-state.service";

@Injectable()
export class UserEffects {
    public fetchGuest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchGuest),
            switchMap(() => [
                fetchGuestSuccess({
                    guest: this.userService.getGuest(),
                }),
            ]),
        ),
    );

    public removeGuest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeGuest),
            switchMap(() => {
                this.sessionStorageInstance?.deleteItem("guest");
                return [removeGuestSuccess()];
            }),
        ),
    );

    public updateGuest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGuest),
            switchMap((payload) => {
                if (payload.guest) {
                    this.sessionStorageInstance?.setItem(
                        "guest",
                        payload.guest,
                    );
                }
                return [fetchGuest()];
            }),
        ),
    );

    public launchUserSynchroWithStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(launchUserSynchroWithStorage),
            withLatestFrom(this.store.select(selectUserStatus)),
            filter(([, userStatus]) => userStatus === "unsynchronized"),
            switchMap(() => this.userService.getCustomerFromSessionStorage()),
            switchMap((user: User) => [
                setUserFromStorage({
                    user,
                }),
            ]),
            catchError((error) =>
                of(userSynchroWithStorageFailure(error.toString())),
            ),
        ),
    );

    public setUserInStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setUserInStorage),
            switchMap(({ user }) => {
                this.sessionStorageInstance?.setItem(
                    "user",
                    new JsonConvert().serializeObject(user),
                );
                return [
                    setUserInStorageSuccess({
                        user,
                    }),
                ];
            }),
            catchError((error) =>
                of(setUserInStorageFailure(error.toString())),
            ),
        ),
    );

    public clearUserData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearData),
            switchMap(() => {
                this.userService.clearUser();
                this.basketFacade.clearData();
                return [clearDataSuccess()];
            }),
        ),
    );

    private sessionStorageInstance?: StorageManager;

    constructor(
        private actions$: Actions,
        private basketFacade: BasketFacade,
        private storageService: StorageService,
        private userService: UserStateService,
        private store: Store<UserState>,
    ) {
        this.sessionStorageInstance =
            this.storageService.getSessionStorageInstance();
    }
}
