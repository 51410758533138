import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import type * as express from "express";

import { REQUEST } from "./tokens";
import { WINDOW } from "./window.service";

/**
 * this class is used to manage context of current application depends of SSR mode
 * -> locale (country/language) depend
 * -> mode (SSR or not)
 * -> Current path
 * -> ...
 */
@Injectable({ providedIn: "root" })
export class Context {
    /**
     * Constructor injection with followed vars
     *
     * @param platformId Angular platform object
     * @param request express Request
     * @param window window WINDOW
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
        @Optional() @Inject(REQUEST) private request: express.Request,
        @Optional() @Inject(WINDOW) private window: Window,
    ) {}

    public isInBrowserMode(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public isInServerMode(): boolean {
        return !isPlatformBrowser(this.platformId);
    }

    /**
     * Returns current path depends of rendering mode (SSR => Use Request, Browser => Use window.location)
     */
    public getCurrentHost(): string {
        if (this.isInBrowserMode()) {
            return this.window.location.host;
        }

        return this.request.hostname;
    }

    /**
     * WARNING : Use ONLY with Angular full-pages
     * Returns current path depends of rendering mode (SSR => Use Request, Browser => Use window.location)
     */
    public getCurrentOrigin(): string {
        if (this.isInBrowserMode()) {
            return this.window.location.origin;
        }

        return this.request.protocol + this.request.hostname;
    }
}
