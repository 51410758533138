import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
    CustomRouterReducerState,
    routerFeatureKey,
} from "../reducers/router.reducers";

export const selectRouterFeatureState =
    createFeatureSelector<CustomRouterReducerState>(routerFeatureKey);

export const selectRouterState = createSelector(
    selectRouterFeatureState,
    (routerState) => routerState.state,
);
export const selectUrl = createSelector(
    selectRouterState,
    (routerState) => routerState.url,
);

export const selectParameters = createSelector(
    selectRouterState,
    (routerState) => routerState.params,
);

export const selectPreviousUrl = createSelector(
    selectRouterState,
    (routerState) => routerState?.previousUrl,
);
