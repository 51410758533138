import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

const NEW_WINDOW = $localize`:@@hermes.footer.social.new-window:(new window)`;

/**
 * Returns the proposition 65 warning with the links (displayed).
 */
export const getProp65warningWithLinks = (
    metals: string,
    plural: boolean,
    domsanitizer?: DomSanitizer,
): SafeHtml | string => {
    const prop65WarningString = getProp65warning(
        metals,
        `<a target="blank" href='https://www.P65Warnings.ca.gov'><U>www.P65Warnings.ca.gov</U><span class="sr-only">${NEW_WINDOW}</span></a>`,
        plural,
    );

    if (domsanitizer) {
        return domsanitizer.bypassSecurityTrustHtml(prop65WarningString);
    }

    return prop65WarningString;
};

/**
 * Returns the proposition 65 warning without the links (hidden).
 * Uses for the screen reader.
 */
export const getProp65warningWithoutLinks = (
    metals: string,
    plural: boolean,
): string =>
    getProp65warning(metals, `www.P65Warnings.ca.gov`, plural)
        .replace("⚠️", "")
        .trim();

export const getProp65warning = (
    metals: string,
    link: string,
    plural: boolean,
): string => {
    const prop65SingleWarning = $localize`:@@hermes_checkout.checkout.cart.prop65:⚠️ WARNING: This product can expose you to chemicals including ${metals}:INTERPOLATION:, which is known to the State of California to cause cancer and/or other health development harm. For more information, visit ${link}:INTERPOLATION_1:`;
    const prop65PluralWarning = $localize`:@@hermes_checkout.checkout.cart.prop65.plural:⚠️ WARNING: One or several product(s) can expose you to chemicals including ${metals}:INTERPOLATION:, which is known to the State of California to cause cancer and/or other health development harm. For more information, visit ${link}:INTERPOLATION_1:`;

    const prop65WarningText = plural
        ? prop65PluralWarning
        : prop65SingleWarning;

    return prop65WarningText.replace("\n", "<br>");
};
