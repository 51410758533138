import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

/**
 * Abstract class for variation-click event
 */
export abstract class FooterAnalyticsEvent
    implements AnalyticsEvent<GTMEventData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: {
        label?: string;
        action?: string;
        category?: string;
    };

    constructor(data: { [key: string]: unknown }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(category = "Footer"): GTMEventData {
        return {
            event: this.name,
            eventcategory: category,
        };
    }
}
