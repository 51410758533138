<fieldset>
    <legend i18n [className]="showTitle ? 'date-selector-title' : 'sr-only'"> {{ title }} </legend>

    <div [formGroup]="dateSelector" [ngClass]="{ 'one-line-min-height': appearance === 'oneLine' }">
        <div [attr.class]="appearance === 'twoLines' ? 'date-container-in-two-lines' : 'date-container'">
            <ng-container *ngIf="dateFormat === dayMonthYear">
                <ng-content *ngTemplateOutlet="dayInput"></ng-content>
                <ng-content *ngTemplateOutlet="monthSelect"></ng-content>
                <ng-content *ngTemplateOutlet="yearInput"></ng-content>
            </ng-container>
            <ng-container *ngIf="dateFormat === monthDayYear">
                <ng-content *ngTemplateOutlet="monthSelect"></ng-content>
                <ng-content *ngTemplateOutlet="dayInput"></ng-content>
                <ng-content *ngTemplateOutlet="yearInput"></ng-content>
            </ng-container>
            <ng-container *ngIf="dateFormat === yearMonthDay">
                <ng-content *ngTemplateOutlet="yearInput"></ng-content>
                <ng-content *ngTemplateOutlet="monthSelect"></ng-content>
                <ng-content *ngTemplateOutlet="dayInput"></ng-content>
            </ng-container>

            <ng-template #monthSelect>
                <!-- month select -->
                <h-select-input
                    [ngClass]="'order' + monthOrder.toString()"
                    [id]="'month-select'"
                    [autocomplete]="'bday-month'"
                    [controlName]="'month'"
                    [options]="monthOptions"
                    [dataTestId]="dataTestId ? dataTestId + 'Month' : ''"
                    [setMinHeight]="setMinHeightMonth"
                    [selectedOptionValue]="selectedOptionMonthValue || ''"
                    class="input month-input"
                    label="Month"
                    i18n-label="@@hermes_global.month"
                    (focusout)="focusOut$.next()">
                </h-select-input>
            </ng-template>
            <ng-template #dayInput>
                <!-- day select -->
                <h-input
                    [ngClass]="'order' + dayOrder.toString()"
                    [id]="'day-input'"
                    [controlName]="'day'"
                    [mask]="dayMask"
                    [dataTestId]="dataTestId ? dataTestId + 'Day' : ''"
                    [setMinHeight]="setMinHeightDay"
                    [autocomplete]="'bday-day'"
                    [maxLength]="'2'"
                    class="input day-input"
                    i18n-label="@@hermes-global.day-capitalized"
                    label="Day"
                    i18n-placeholder="@@hermes_global.dd"
                    placeholder="DD"
                    (focusout)="focusOut$.next()">
                </h-input>
            </ng-template>
            <ng-template #yearInput>
                <!-- year select -->
                <h-input
                    [ngClass]="'order' + yearOrder.toString()"
                    [id]="'year-input'"
                    [controlName]="'year'"
                    [mask]="yearMask"
                    [dataTestId]="dataTestId ? dataTestId + 'Year' : ''"
                    [setMinHeight]="setMinHeightYear"
                    [autocomplete]="'bday-year'"
                    [maxLength]="'4'"
                    class="input year-input"
                    i18n-label="@@hermes-global.year-capitalized"
                    label="Year"
                    i18n-placeholder="@@hermes_global.yyyy"
                    placeholder="YYYY"
                    (focusout)="focusOut$.next()">
                </h-input>
            </ng-template>
        </div>

        <!-- generic error messages -->
        <h-message-block
            *ngIf="dateSelector.errors?.invalid || dateSelector.errors?.ageLimitInvalid"
            [showBackground]="false"
            [autoAddPadding]="false"
            [ariaRole]="undefined"
            type="error">
            <ng-container *ngIf="dateSelector?.errors?.invalid" i18n="@@hermes-global-translations.oops-dob-format">
                Oops, this doesn't seem quite right! This date does not exist.
            </ng-container>
            <ng-container
                *ngIf="dateSelector.errors?.ageLimitInvalid"
                i18n="@@hermes-global-translations-error-majority">
                You cannot sign up as a member because the date of birth you entered has been confirmed to be under the
                age of {{ ageLimit }}.
            </ng-container>
        </h-message-block>

        <!-- age of majority message -->
        <p *ngIf="ageLimit !== 0" class="font-information-text" i18n="@@hermes-global-translations-info-majority">
            Youth under the age of {{ ageLimit }} cannot join as a member.
        </p>
    </div>
</fieldset>
