import { GTMEventData } from "@hermes/utils/analytics";
import { ProductTemplateType } from "@hermes/utils/constants";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

import { ImageGalleryAnalyticsEvent } from "./image-gallery.event";

/**
 * This class is used for open-zoom event in product-content
 */
export class ProductContentOpenZoomEvent extends ImageGalleryAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(this.eventData.templateType);

        return {
            ...super.formatForGTM(),
            eventcategory,
            eventaction: "Zoom",
            eventlabel: this.getZoomLabel(),
        };
    }

    /**
     * Get zoom label for look and bikini products
     */
    public getZoomLabel(): string | undefined {
        // When zooming on set
        if (
            (this.eventData.templateType === ProductTemplateType.Look &&
                this.eventData.isTopLevelProduct) ||
            (this.eventData.templateType === ProductTemplateType.Bikini &&
                this.eventData.isTopLevelProduct)
        ) {
            return (
                this.eventData.templateType.charAt(0).toUpperCase() +
                this.eventData.templateType.slice(1)
            );
        }
        // When zooming on item
        if (
            (this.eventData.templateType === ProductTemplateType.Look &&
                !this.eventData.isTopLevelProduct) ||
            (this.eventData.templateType === ProductTemplateType.Bikini &&
                !this.eventData.isTopLevelProduct)
        ) {
            return "Product";
        }
        return undefined;
    }
}
