import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromBasket from "../reducers/basket.reducer";

export const selectBasketState = createFeatureSelector<fromBasket.State>(
    fromBasket.basketFeatureKey,
);

export const selectCart = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => basketState.cart,
);

export const selectTotalItems = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => basketState.cart?.total_items,
);

export const selectError = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => basketState.error,
);

export const selectHasErrors = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => {
        if (basketState.error.hasError) {
            return true;
        }
        const itemInError = basketState.cart?.items?.find(
            (item) => item["has_error"],
        );
        return !!itemInError;
    },
);

export const selectPending = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => basketState.pending,
);

export const selectBillingAddress = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) => basketState.cart.billing_address,
);

export const selectSagawaTimeslots = createSelector(
    selectBasketState,
    (basketState: fromBasket.State) =>
        basketState.cart.shipping_appointment_timeslots,
);

export const basketQuery = {
    selectCart,
    selectTotalItems,
    selectError,
    selectHasErrors,
    selectPending,
    selectBillingAddress,
    selectSagawaTimeslots,
};
