import { CountryCode } from "@hermes/locale";

export const DAY_MONTH_YEAR = ["day", "month", "year"];
export const MONTH_DAY_YEAR = ["month", "day", "year"];
export const YEAR_MONTH_DAY = ["year", "month", "day"];

export type DateFormat =
    | typeof DAY_MONTH_YEAR
    | typeof MONTH_DAY_YEAR
    | typeof YEAR_MONTH_DAY;

export const dateSelectorFormat: {
    [key in CountryCode]: DateFormat;
} = {
    at: MONTH_DAY_YEAR,
    au: MONTH_DAY_YEAR,
    be: MONTH_DAY_YEAR,
    br: MONTH_DAY_YEAR,
    ca: MONTH_DAY_YEAR,
    ch: MONTH_DAY_YEAR,
    cn: YEAR_MONTH_DAY,
    cz: MONTH_DAY_YEAR,
    de: DAY_MONTH_YEAR,
    dh: MONTH_DAY_YEAR,
    dk: MONTH_DAY_YEAR,
    es: DAY_MONTH_YEAR,
    fi: MONTH_DAY_YEAR,
    fr: DAY_MONTH_YEAR,
    hk: MONTH_DAY_YEAR,
    ie: MONTH_DAY_YEAR,
    it: DAY_MONTH_YEAR,
    jp: MONTH_DAY_YEAR,
    kr: MONTH_DAY_YEAR,
    lu: MONTH_DAY_YEAR,
    mo: MONTH_DAY_YEAR,
    mx: DAY_MONTH_YEAR,
    my: MONTH_DAY_YEAR,
    nl: MONTH_DAY_YEAR,
    no: MONTH_DAY_YEAR,
    pl: MONTH_DAY_YEAR,
    pt: DAY_MONTH_YEAR,
    ri: MONTH_DAY_YEAR,
    se: MONTH_DAY_YEAR,
    sg: MONTH_DAY_YEAR,
    th: MONTH_DAY_YEAR,
    tw: DAY_MONTH_YEAR,
    uk: MONTH_DAY_YEAR,
    us: MONTH_DAY_YEAR,
};
