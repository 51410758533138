import {
    BELTKIT,
    NOT_SUPPORTED,
    SILK,
    SMALL_LEATHER_GOODS,
} from "../constant/product-personalization.constant";

/**
 * Information of key pressed on lucky signs keyboard
 */
export interface KeyboardTap {
    key: string;
    translationKey: string;
    type: string;
}

export type ConfigParts = ConfigPartsSmlg | ConfigPartsBk | ConfigPartsCommon;

/**
 * When we are in small leather goods customization page, we use liningColor but not interiorColor, buckle and beltSize
 * When we are in belt kits customization page, we use interiorColor, buckle and beltSize but not liningColor
 *
 * Other members are used in all cases
 */
export interface ConfigPartsSmlg extends ConfigPartsCommon {
    leather?: ConfigPart;
    liningColor?: ConfigPart;
}

/**
 * When we are in small leather goods customization page, we use liningColor but not interiorColor, buckle and beltSize
 * When we are in belt kits customization page, we use interiorColor, buckle and beltSize but not liningColor
 *
 * Other members are used in all cases
 */
export interface ConfigPartsBk extends ConfigPartsCommon {
    interiorColor?: ConfigPart;
    buckle?: ConfigPart;
    beltSize?: ConfigPart;
}

/**
 * NgRx stored selected items of radio buttons
 */
export interface ConfigPart {
    id: string;
    name: string;
    value: string;
    color?: string;
    sku?: string;
    exteriorLeather?: string;
    liningLeather?: string;
    unicolorAllowed?: boolean;
    fontFamily?: string;
}
/**
 * When we are in small leather goods customization page, we use liningColor but not interiorColor, buckle and beltSize
 * When we are in belt kits customization page, we use interiorColor, buckle and beltSize but not liningColor
 *
 * Other members are used in all cases
 */
export interface ConfigPartsCommon {
    exteriorColor?: ConfigPart;
    fontMarkingZone?: ConfigPart;
    fontStyle?: ConfigPart;
    fontSize?: ConfigPart;
    fontColor?: ConfigPart;
    updatedMessage?: ConfigPart;
    buckle?: ConfigPart;
    beltSize?: ConfigPart;
}

export interface PersoParsedUrl {
    defaultSku?: string;
    slugWithSku?: string;
    firstSku?: string;
    secondSku?: string;
    productDku?: string;
    requireConfCall: boolean;
    currentUrl?: string;
    originalUrl: string;
}

export type ProductCategory =
    | typeof SMALL_LEATHER_GOODS
    | typeof SILK
    | typeof BELTKIT
    | typeof NOT_SUPPORTED;

export interface ParsedRoute {
    slug: string;
    productCategory: ProductCategory;
    originalUrl: string;
    fragment?: string;
}

export type LoadingProgress = "loading" | "loaded";

/**
 * list of type of personalization in capa
 */
export type CapaPersoNames = "SOIE" | "SMLG" | "BK";

/**
 * Engraving config
 */
export interface EngravingConfiguration {
    font: string;
    style: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    font_size: string;
    position: string;
    message: string;
}

export enum PartMaterial {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    exterior = "exterior",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    lining = "lining",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interior = "interior",
    Buckle = "buckle",
}

export interface Buckles {
    id?: string;
    sku?: string;
    color?: string;
    name?: string;
    url?: string;
    default?: boolean;
}

export interface BeltSizes {
    size?: string;
    default?: boolean;
}
export interface DkuConfiguration {
    message?: string;
    exterior?: RipeFetchedPart;
    lining?: RipeFetchedPart;
    font?: string;
    fontSize?: string;
    style?: string;
    position?: string;
}

export interface DkuConfigurationBeltkit extends DkuConfiguration {
    buckle?: { material: string; color: string };
    beltSize?: string;
}

export interface RipeFetchedPart {
    color?: string;
    material?: string;
}

export interface MappedBeltkitConfigurator extends BaseConfigurator {
    leatherSku: string;
    exteriorBeltColor: Color[];
    interiorBeltColor: Color[];
    buckles?: Buckles[];
    sizes?: BeltSizes[];
}
export interface MappedSmlgConfigurator extends BaseConfigurator {
    leather?: SmallLeatherGoodsLeather[];
}
export type ConfiguratorType =
    | MappedSmlgConfigurator
    | MappedBeltkitConfigurator
    | BaseConfigurator;

export interface BaseConfigurator {
    markingConfiguration: MarkingConfiguration;
    engravingConfigurator?: EngravingConfigurator;
    luckySigns?: LuckySignsDetails;
}
export interface Color {
    label: string;
    default?: boolean;
    unicolorAllowed?: boolean;
}

export interface SmallLeatherGoodsLeather {
    sku?: string;
    default?: boolean;
    interiorColors?: Color[];
    exteriorColors?: Color[];
    exteriorLeather?: string;
    interiorLeather?: string;
    name?: string;
    translation?: string;
}

export interface EngravingConfigurator {
    fontConf: RadioButtonItemModel[];
    fontSizeConf: RadioButtonItemModel[];
    styleConf: RadioButtonItemModel[];
    positionConf: RadioButtonItemModel[];
}

/**
 * Max authorised character on input text
 */
export enum RulesDescription {
    GlobalPattern = "GlobalPattern",
    Letter = "Letter",
    SpeCharAmpersand = "SpeCharAmpersand",
    SpeCharDot = "SpeCharDot",
    SpeCharHyphen = "SpeCharHyphen",
    LuckySigns = "LuckySigns",
}

export interface PlateformeExtraInformationsMap {
    key: string;
    value: string;
}

export enum InputType {
    Style = "style",
    Font = "font",
    Position = "position",
    FontSize = "font_size",
}

export enum PartType {
    Exterior = "exterior",
    Interior = "interior",
}

/**
 * Small leather goods products can have the faces : backclose, backopen, frontopen, frontclose
 * Beltkits products can have the faces : front, back, zoom_back
 */
export enum MarkingPosition {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    backclose = "backclose",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    backopen = "backopen",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    frontopen = "frontopen",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    frontclose = "frontclose",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    front = "front",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    back = "back",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    zoom_back = "zoom_back",

    // eslint-disable-next-line @typescript-eslint/naming-convention
    frontSilk = "front",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    zoomSilk = "zoom",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wornSilk = "worn",
}

export interface FacesMapping {
    exteriorFaces: string[];
    interiorFaces: string[];
}

/**
 * This interface is used to call Ripe's setParts function
 */
export interface RipePart {
    name: string;
    value: string;
    color: string;
}

/**
 * API parameters to get values for each FontType
 */
export enum PropertyEngravingType {
    FONT = "font",
    FONTSIZE = "font_size",
    STYLE = "style",
    POSITION = "position",
}

/* eslint-disable @typescript-eslint/naming-convention */
// PlatformeInitialConfig
export interface PlatformeInitialConfiguration {
    brand: string;
    model: string;
    version?: string;
    product_id?: any;
    variant?: any;
    parts: Parts;
    hash: string;
    dku: string;
    query: string;
}

export interface Parts {
    exterior: Exterior;
    lining?: Lining;
    shadow?: Shadow;
    interior?: Lining;
}

export interface Exterior {
    material?: string;
    color?: string;
    face?: string;
}

export interface Lining {
    material?: string;
    color?: string;
    face?: string;
}

export interface Shadow {
    material?: string;
    color?: string;
    face?: string;
    hidden?: boolean;
}

export interface Part {
    name: string;
    materials: Material[];
}

export interface Material {
    name: string;
    colors: string[];
}

export interface FaceM {
    frames: number;
    thumbnail: boolean;
}

export interface FacesM {
    backclose?: FaceM;
    frontclose: FaceM;
    frontopen: FaceM;
}

export interface Thumbnail {
    name: string;
    face: string;
    frame: number;
}
export interface Scale {
    male: string;
    female: string;
    other: string;
    all: string;
}

export interface ZIndex {
    shadow: number;
    lining: number;
    exterior: number;
    overlay: number;
}

export interface Property {
    name: string;
    type: string;
}

export interface Initials {
    z_index: number;
    properties: Property[];
    $profiles: any;
    profiles: string[];
}

export interface LuckySign {
    id: string;
    translation_key: string;
    url: string;
    value: string;
}

export interface SpecialCharacters {
    id: string;
    value: string;
    translation_key: string;
    url: string;
}

export interface LeatherCombination {
    id: string;
    exterior_leather: string;
    interior_leather: string;
    combination_key_trad: string;
}
export interface Meta {
    sku_generic: any;
    lucky_signs: LuckySign[];
    special_characters?: SpecialCharacters[];
    leather_combinations?: LeatherCombination[];
    buckles?: any;
    force_bicolor?: string[];
}

export interface PlatformeConfigResponse {
    name: string;
    path: string;
    parts: Part[];
    brand: string;
    description: string;
    title: string;
    gender: string;
    scale: Scale;
    toe?: string;
    type?: string;
    tags?: string[];
    sizes?: any;
    frames: number;
    faces: string[];
    faces_m: FacesM;
    hidden: string[];
    defaults: Parts;
    initials: Initials;
    z_index: ZIndex;
    meta: Meta;
    version: number;
    thumbnails?: Thumbnail[];
    order: any;
    blacklist: any;
    initials_patch: any;
    initials_patch_materials: any[];
    initials_dot_materials: any[];
    initials_pivot_part: string;
    always_patch: boolean;
    alias: any;
    restrictions: any[];
    size?: number[];
}
/**
 * Data model for radio button item
 * - src is only for images (the image file must exists)
 * - color is only for colors (can be a web color name, an hexadecimal code, rgb(a) or hsl(a) format)
 * - fontFamily is only for text (the font file must exists)
 */
export interface RadioButtonItemModel {
    id: string; // id input
    value: string; // value radio
    name: string; // showed value
    default: boolean;
    disabled: boolean;
    sku?: string;
    src?: string;
    color?: string;
    fontFamily?: string;
    exteriorLeather?: string;
    liningLeather?: string;
    unicolorAllowed?: boolean;
    displayShowMore?: boolean;
}

/**
 * Types of radio button items
 */
export enum RadioButtonType {
    Color = "color",
    Text = "text",
    Image = "image",
}

/**
 * Data model for radio button group
 */
export interface RadioButtonModel {
    type: RadioButtonType;
    expandable: boolean;
    name: string;
    label?: boolean;
    items: RadioButtonItemModel[];
    hasDefault: boolean;
}

export interface MarkingRule {
    ruleDescription: string;
    maxOccurrence: number;
    regExp: RegExp;
    partielPattern: boolean;
}

export interface MarkingConfiguration {
    message?: string;
    markingRules: MarkingRule[];
}

export interface LuckySigns {
    id: string;
    value: string;
    url: string;
    translation?: string;
    translationKey?: string;
}

export interface LuckySignsDetails {
    details: LuckySigns[];
}

export interface BeltkitsConfigurator {
    leatherSku?: string;
    markingConfiguration?: MarkingConfiguration;
    exteriorColor?: RadioButtonModel;
    interiorColor?: RadioButtonModel;
    buckle?: RadioButtonModel;
    beltSize?: RadioButtonModel;
    fontStyle?: RadioButtonModel;
    fontSize?: RadioButtonModel;
    fontColor?: RadioButtonModel;
    luckySigns?: LuckySignsDetails;
}

export interface SmallLeatherGoodsConfigurator {
    markingConfiguration?: MarkingConfiguration;
    leather?: RadioButtonModel;
    exteriorColor?: RadioButtonModel;
    interiorColor?: RadioButtonModel;
    fontMarkingZone?: RadioButtonModel;
    fontStyle?: RadioButtonModel;
    fontSize?: RadioButtonModel;
    fontColor?: RadioButtonModel;
    luckySigns?: LuckySignsDetails;
}
export interface SilkConfigurator {
    markingConfiguration?: MarkingConfiguration;
    fontStyle?: RadioButtonModel;
    fontSize?: RadioButtonModel;
    fontColor?: RadioButtonModel;
}
export const NOT_LOADED = "not loaded";
export const NO_ENGRAVING = "no engraving";
export const NO_CONFIGURATION = "no configuration";

export type PersoConfiguratorState =
    | SmallLeatherGoodsConfigurator
    | BeltkitsConfigurator
    | SilkConfigurator
    | typeof NOT_LOADED;

export type PersoConfigurator =
    | SmallLeatherGoodsConfigurator
    | BeltkitsConfigurator
    | SilkConfigurator;

export interface Expend {
    leather?: string;
    liningColor?: string;
    fontColor?: string;
    fontMarkingZone?: string;
    fontStyle?: string;
    fontSize?: string;
    exteriorColor?: string;
    updatedMessage?: string;
    buckle?: string;
    beltSize?: string;
    marking?: string;
    interiorColor?: string;
}
