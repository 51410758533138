import { createFeatureSelector, createSelector } from "@ngrx/store";

import { ModalState } from "../state/modal.state";

export const modalFeatureKey = "modal";

export const selectModalState =
    createFeatureSelector<ModalState>(modalFeatureKey);

export const selectIsModalOpened = createSelector(
    selectModalState,
    (modalState: ModalState) => modalState.isOpened,
);

export const selectModalSuccess = createSelector(
    selectModalState,
    (modalState: ModalState) => "success" in modalState && modalState.success,
);
