import { Injectable } from "@angular/core";

import { Context } from "@hermes/app-core";

@Injectable()
export class NavigationService {
    constructor(private readonly context: Context) {}

    /**
     * Disable the execution of default actions if the event is not properly treated
     */
    public preventDefault(e: Event): void {
        e.preventDefault();
    }

    /**
     * Disable the scroll and keys navigation
     */
    public disableScrollandKeys(): void {
        if (this.context.isInBrowserMode()) {
            window.addEventListener("keydown", this.preventDefault, {
                passive: false,
            }); // keys navigation event
            window.addEventListener("scroll", this.preventDefault, {
                passive: false,
            }); // scroll event
            if (document && document["onwheel"]) {
                document.onwheel = this.preventDefault; // older browsers, IE
            }

            if (document && document["ontouchmove"]) {
                document.ontouchmove = this.preventDefault; // mobile
            }
        }
    }

    /**
     * Enable the scroll and keys navigation
     */
    public enableScrollandKeys(): void {
        if (this.context.isInBrowserMode()) {
            window.removeEventListener("keydown", this.preventDefault); // keys navigation event
            window.removeEventListener("scroll", this.preventDefault); // scroll event

            document.onwheel = undefined as unknown as typeof document.onwheel; // older browsers, IE
            document.ontouchmove = undefined; // mobile
        }
    }
}
