<svg
    [attr.aria-hidden]="ariaHidden"
    [attr.aria-label]="ariaLabel"
    [attr.role]="role"
    [attr.focusable]="false"
    [attr.nopin]="'nopin'"
    width="100%"
    height="100%"
    [attr.viewBox]="viewBox"
    [attr.preserveAspectRatio]="'none'"
    [attr.data-test-id]="testId">
    <use attr.href="#ng__{{ name }}"></use>
</svg>
