export const INITIALIZED_SSR = "server-side-initialized";
export const INITIALIZED_CSR = "client-side-initialized";
export const LOADED = "loaded";
export const ERROR = "error";

export const isLibraryError = (
    library: Library | LibraryError,
): library is LibraryError => (library as LibraryError).message !== undefined;

export interface Library {
    url: string;
    windowAttribute: string;
    status: typeof INITIALIZED_SSR | typeof INITIALIZED_CSR | typeof LOADED;
    timeout?: number;
    callbackParameterName?: string;
}
export interface LibraryError {
    message: string;
    status: typeof ERROR;
}

/**
 * Interface for the 'ExternalLibrary' state
 */
export type ExternalLibraryState = Record<string, Library | LibraryError>;
