<div [formGroup]="phoneWithAreaCodeForm" [class.set-min-height]="setMinHeight">
    <div class="generic-input-item">
        <fieldset>
            <legend class="sr-only">
                <ng-container>{{ phoneLabel }}</ng-container>
            </legend>

            <div class="container-area-code">
                <input
                    class="generic-input"
                    formControlName="areaCode"
                    [id]="getAreaCodeId()"
                    [type]="'tel'"
                    [name]="'area-code'"
                    [autocomplete]="'tel-country-code'"
                    [class.generic-input-error]="controlHaveErrors(areaCodeControl)"
                    [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
                    [attr.aria-invalid]="controlHaveErrors(areaCodeControl)"
                    [attr.aria-describedby]="'area-code-input-error'"
                    [attr.data-testid]="dataTestId ? dataTestId + '-area-code' : ''"
                    [mask]="areaCodeMask"
                    [dropSpecialCharacters]="false"
                    [prefix]="'+'"
                    [validation]="false"
                    data-cs-mask
                    required
                    (focusout)="focusOut$.next()" />

                <label [for]="getAreaCodeId()">
                    {{ areaCodeLabel }}
                    <!-- '*' is an universal identifier for required input fields, no translation is used then -->
                    <span [attr.aria-hidden]="'true'">{{ " *" }}</span>
                </label>
            </div>

            <div class="container-phone">
                <input
                    #phoneInput
                    class="generic-input phone-number"
                    formControlName="phone"
                    [id]="getPhoneId()"
                    [name]="'phone'"
                    [type]="'tel'"
                    [placeholder]="'&nbsp;'"
                    [autocomplete]="autocomplete"
                    [class.generic-input-error]="controlHaveErrors(phoneControl)"
                    [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async"
                    [attr.aria-invalid]="controlHaveErrors(phoneControl)"
                    [attr.aria-describedby]="'phone-input-error'"
                    [attr.data-testid]="dataTestId ? dataTestId + '-number' : ''"
                    [mask]="phoneWithAreaCodeMask"
                    [patterns]="phoneWithAreaCodeMaskPattern"
                    [validation]="false"
                    (keydown.enter)="forceBlur($event)"
                    data-cs-mask
                    required
                    (focusout)="focusOut$.next()" />
                <label class="generic-label" [for]="getPhoneId()"
                    >{{ phoneLabel }}
                    <!-- '*' is an universal identifier for required input fields, no translation is used then -->
                    <span [attr.aria-hidden]="'true'">{{ "*" }}</span>
                </label>
            </div>
        </fieldset>
    </div>

    <div class="login-errors-container">
        <h-message-block
            id="area-code-input-error"
            *ngIf="
                controlHaveErrors(areaCodeControl) &&
                (areaCodeControl.errors?.required || areaCodeControl.errors?.pattern)
            "
            [showBackground]="false"
            [autoAddPadding]="false"
            [ariaRole]="undefined"
            type="error">
            <ng-container i18n="@@hermes.common.required_information_input"> Required information </ng-container>
            <span hSrOnlyNoSnippet>{{ areaCodeLabel }}</span>
        </h-message-block>
        <h-message-block
            id="phone-input-error"
            *ngIf="controlHaveErrors(phoneControl) && phoneControl.errors?.required"
            [showBackground]="false"
            [autoAddPadding]="false"
            [ariaRole]="undefined"
            type="error">
            <ng-container i18n="@@hermes.common.required_information_input"> Required information </ng-container>
            <span hSrOnlyNoSnippet>{{ phoneLabel }}</span>
        </h-message-block>
        <h-message-block
            id="phone-input-error"
            *ngIf="controlHaveErrors(phoneControl) && phoneControl.errors?.minlength"
            [showBackground]="false"
            [autoAddPadding]="false"
            [ariaRole]="undefined"
            type="error">
            <ng-container i18n="@@hermes_global.minimum-8-characters"> Minimum 8 characters </ng-container>
            <span hSrOnlyNoSnippet>{{ phoneLabel }}</span>
        </h-message-block>
        <ng-content></ng-content>
    </div>

    <div *ngIf="showJapanLabel" class="japanese-phone-text">
        <p i18n="@@hermes-global-translations.japan-phone-number-part1" class="font-information-text">
            ※+81の国番号に続けて、携帯電話番号／市外局番の最初の0を除いて入力してください。
        </p>
        <p i18n="@@hermes-global-translations.japan-phone-number-part2" class="font-information-text">
            （例：「090～」→「90～」、「045～」→「45～」）
        </p>
    </div>
</div>
