import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "h-notification-slider",
    templateUrl: "./notification-slider.component.html",
    styleUrls: ["./notification-slider.component.scss"],
    animations: [
        trigger("fade", [
            state("true", style({ opacity: 0 })),
            state("false", style({ opacity: 1 })),
            transition("true => false", [animate("1s ease")]),
        ]),
    ],
})
export class NotificationSliderComponent implements OnInit {
    @Input()
    public notifications!: string[];

    public currentIndex: number = 0;

    public lastIndex!: number;

    public launchFade: boolean = false;

    public ngOnInit(): void {
        this.lastIndex = this.notifications.length - 1;
    }

    public previousSlide(): void {
        this.launchFade = true;

        this.currentIndex =
            this.currentIndex === 0 ? this.lastIndex : this.currentIndex - 1;
    }

    public nextSlide(): void {
        this.launchFade = true;

        this.currentIndex =
            this.currentIndex === this.lastIndex ? 0 : this.currentIndex + 1;
    }

    public setCurrentNotification(index: number): void {
        this.launchFade = true;
        this.currentIndex = index;
    }
}
