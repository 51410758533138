import { ApplicationRef, Inject, Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { first, tap } from "rxjs/operators";

import { Context, WINDOW } from "@hermes/app-core";

@Injectable()
export class FocusService implements OnDestroy {
    public elementToFocus: string | undefined;
    private subscription = new Subscription();

    constructor(
        protected context: Context,
        private appRef: ApplicationRef,
        @Inject(WINDOW) private window: Window,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public focusElementOnLoaderClose(
        mustScrollTop = true,
        isGridContext: boolean = false,
    ): void {
        const focusElementId = this.elementToFocus;
        this.elementToFocus = undefined;

        if (focusElementId && this.context.isInBrowserMode()) {
            // to fix delayed scrollToTop on the grid
            if (mustScrollTop && isGridContext) {
                this.window.scrollTo(0, 0);
            }

            this.subscription.add(
                this.appRef.isStable
                    .pipe(
                        first((stable) => stable),
                        tap(() => {
                            const focusElement =
                                document.getElementById(focusElementId);

                            if (focusElement) {
                                focusElement.focus();
                            }
                            if (
                                focusElement &&
                                mustScrollTop &&
                                !isGridContext
                            ) {
                                this.window.scrollTo(0, 0);
                            }
                        }),
                    )
                    .subscribe(),
            );
        }
    }

    /**
     * Function to focus a link in a list
     *
     * @param  listElement ul element
     * @param index of the element to focus (default 0)
     */
    public focusElementInList(
        listElement: HTMLUListElement,
        index: number = 0,
    ): void {
        // get the li element
        const li = listElement.children[index];

        // get the link tag
        const links: HTMLCollectionOf<HTMLAnchorElement> =
            li.getElementsByTagName("a");

        // focus the link product
        links[0].focus();
    }
}
