import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { AbstractProductVariant, BoxType } from "@hermes/api-model-product";
import { Context, LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { PageProductImageMediaqueries } from "@hermes/utils/constants";
import { skuToUrlSku } from "@hermes/utils-generic/helpers";
import { ImageConfig } from "@hermes/utils-generic/pipes/image-url";

import { generateUrl } from "../helpers/dynamic-box.helper";

export const MOBILE_SIZE = 64;
export const DESKTOP_SIZE = 56;
export const RADIO = "radio";
export const LINK = "link";
export const CHECKBOX = "checkbox";
export const NONE = "none";

export interface ImageAspectRatio {
    width: number;
    height?: number;
}

/**
 * This component implements a box radio button Component.
 *
 *
 */
@Component({
    selector: "h-dynamic-box",
    templateUrl: "./dynamic-box.component.html",
    styleUrls: ["./dynamic-box.component.scss"],
})
export class DynamicBoxComponent implements OnInit {
    /** Input value */
    @Input()
    public value!: string;

    /** Unique id to link label to input */
    @Input()
    public inputId!: string;

    /** Has fixed width */
    @Input()
    public hasFixedWidth: boolean = true;

    /** FormName to link all inputs together */
    @Input()
    public formName!: string;

    /** FormGroup to link input value to form  */
    @Input()
    public formGroup!: FormGroup;

    /** Set radio input to disabled */
    @Input()
    public disabled: boolean = false;

    /** Set radio input to required */
    @Input()
    public required: boolean = false;

    /** Set radio input to checked */
    @Input()
    public checked: boolean = false;

    /** apply background color white */
    @Input()
    public backgroundColorWhite: boolean = false;

    /** Set input aria-invalid */
    @Input()
    public inputAriaInvalid!: boolean;

    /** Set input aria-describedby */
    @Input()
    public inputAriaDescribedby: string | undefined;

    @Input()
    public unavailable: boolean = false;

    @Input()
    public boxType: BoxType = RADIO;

    @Input()
    public forceStyleCheckbox: boolean = false;

    @Input()
    public labelValue!: string;

    @Input()
    public imageSrc?: string;

    @Input()
    public checkedImageVariant!: boolean;

    @Input()
    public titleValue: string = "";

    @Input()
    public displayLabelWithImage: boolean = false;

    @Input()
    public linkUrl?: string;

    @Input()
    public isHref: boolean = false;

    /** Handle change event*/
    @Output()
    public handleChange: EventEmitter<void> = new EventEmitter<void>();

    /** Handle click event*/
    @Output()
    public handleClick: EventEmitter<Event> = new EventEmitter<Event>();

    /** Handle focus event*/
    @Output()
    public handleFocus: EventEmitter<void> = new EventEmitter<void>();

    /** Handle blur event*/
    @Output()
    public handleBlur: EventEmitter<void> = new EventEmitter<void>();

    // If image is loaded
    public loaded: boolean = false;

    public imageConfigOnMobile: ImageConfig = {
        width: MOBILE_SIZE,
        height: MOBILE_SIZE,
        sourceSet: false,
    };

    public imageConfigOnDesktop: ImageConfig = {
        width: DESKTOP_SIZE,
        height: DESKTOP_SIZE,
        sourceSet: false,
    };

    /** Enum of media queries for availability in the template. */
    public pageProductImageMediaqueries = PageProductImageMediaqueries;

    public isServerMode: boolean = false;

    public buildRouteArray: string[] = [];

    private inputVariant!: AbstractProductVariant;

    private inputImageConfig?: ImageConfig;

    constructor(
        private context: Context,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public get imageConfig(): ImageConfig | undefined {
        return this.inputImageConfig;
    }
    @Input()
    public set imageConfig(value: ImageAspectRatio | undefined) {
        if (value) {
            if (!value.height) {
                value.height = value.width;
            }
            this.inputImageConfig = {
                ...value,
                sourceSet: false,
            };
        }
    }

    public get variant(): AbstractProductVariant {
        return this.inputVariant;
    }

    @Input()
    public set variant(variant: AbstractProductVariant) {
        this.inputVariant = variant;
        const skuParts = skuToUrlSku(variant.sku).split("/");

        if (this.isHref) {
            this.linkUrl = generateUrl(
                this.locale.urlPrefix,
                variant.slug,
                skuParts,
            );
        } else {
            const routes: string[] = [
                "product",
                `${variant.slug}-${skuParts.shift()}`,
            ];
            if (skuParts.length > 0) {
                routes.push(...skuParts);
            }
            // slash at the end with empty string
            this.buildRouteArray = ["/", ...routes, ""];
        }
    }

    public ngOnInit(): void {
        this.isServerMode = this.context.isInServerMode();
    }

    public handleImageLoaded(): void {
        this.loaded = true;
    }
}
