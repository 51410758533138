import { Location } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Context, LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { AnalyticsService } from "@hermes/utils/analytics";

import { isWeChatAgent } from "@hermes/utils/helpers";
import { RouterService } from "@hermes/utils-generic/services/router";

import {
    CHECKOUT_ACCOUNT_BACK_BUTTON,
    CHECKOUT_BACK_BUTTON,
} from "./back-button-checkout.constants";
import { BackButtonCheckoutAnalyticsEvent } from "./back-button-checkout.event";

@Component({
    selector: "h-back-button-checkout",
    templateUrl: "./back-button-checkout.component.html",
    styleUrls: ["./back-button-checkout.component.scss"],
})
export class BackButtonCheckoutComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    private step!: string;
    constructor(
        private location: Location,
        private analyticsService: AnalyticsService,
        private router: Router,
        private routerService: RouterService,
        private context: Context,
        private activatedRoute: ActivatedRoute,
        @Inject(WINDOW) private window: Window,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.activatedRoute.queryParams.subscribe((data) => {
                this.step = data["step"];
            }),
        );
    }
    public goBackLink(): void {
        const eventType = this.window.location.href.includes("login")
            ? CHECKOUT_ACCOUNT_BACK_BUTTON
            : CHECKOUT_BACK_BUTTON;
        this.analyticsService.sendData(
            new BackButtonCheckoutAnalyticsEvent({
                eventType,
            }),
        );
        // weChat app sometimes as realy quirky navigation, so we have to explictly tell it where to navigate on back to prevent getting stuck in navigation loops
        if (
            this.context.isInBrowserMode() &&
            isWeChatAgent(this.window.navigator.userAgent)
        ) {
            if (this.location.path().includes("/checkout/")) {
                this.router.navigate(["cart", ""]);
            }
            if (this.location.path().includes("/cart")) {
                this.window.location.href = `${this.locale.urlPrefix}/`;
            }
        }
        if (this.location.path().includes("/login") && !this.step) {
            this.routerService.spaRedirect("/cart");
        }
        this.location.back();
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
