import { ActionReducer, MetaReducer } from "@ngrx/store";

export const debugReducer =
    (reducer: ActionReducer<unknown>): ActionReducer<unknown> =>
    (state, action) => {
        const result = reducer(state, action);
        /* eslint-disable no-console*/
        console.groupCollapsed(action.type);
        console.log("prev state", state);
        console.log("action", action);
        console.log("next state", result);
        console.groupEnd();
        /* eslint-enable no-console*/
        return result;
    };

export const metaReducersFactory = (params: {
    debug: boolean;
}): MetaReducer[] => (params.debug ? [debugReducer] : []);
