import { DOCUMENT, Location } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationSkipped,
    Router,
    RouterEvent,
} from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { Subscription, firstValueFrom } from "rxjs";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";

import { LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { FeatureFlagFacade, FeatureFlagName } from "@hermes/states/flipper";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

type EndOfNavigation =
    | NavigationEnd
    | NavigationError
    | NavigationCancel
    | NavigationSkipped;
export const isEndOfNavigation = (
    routerEvent: Event,
): routerEvent is EndOfNavigation =>
    routerEvent instanceof NavigationEnd ||
    routerEvent instanceof NavigationError ||
    routerEvent instanceof NavigationSkipped ||
    routerEvent instanceof NavigationCancel;
export const urlWithoutFragment = (routerEvent: RouterEvent): string =>
    routerEvent.url.split("#")[0];

@Injectable()
export class RouterService implements OnDestroy {
    public pageChanged$ = this.router.events.pipe(
        map((event) => event as RouterEvent),
        filter((event) => event instanceof NavigationEnd),
        map(urlWithoutFragment),
        distinctUntilChanged(),
    );
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE) private locale: Locale,
        private router: Router,
        private featureFlagFacade: FeatureFlagFacade,
        private loadingBarService: LoadingBarService,
        private breakpointService: BreakpointService,
        private location: Location,
    ) {}

    /**
     * Navigate to path via Angular's router for full pages when the feature exist and is activated
     * Else, do a native redirect
     *
     * @param pathWithLocale path to redirect to
     * @param featureFlag SPA routing flag
     */
    public async navigate(
        pathWithLocale: string,
        featureFlag?: FeatureFlagName,
    ): Promise<void> {
        if (featureFlag) {
            const flag = await firstValueFrom(
                this.featureFlagFacade.isActivated(featureFlag),
            );

            if (flag) {
                return this.spaRedirect(pathWithLocale);
            }
            return this.nativeRedirect(pathWithLocale);
        }

        return this.nativeRedirect(pathWithLocale);
    }

    public resetFocus(): void {
        const beforeFirstA11yLink: HTMLElement = this.document.getElementById(
            "skip-links-accessibility",
        ) as HTMLElement;

        const beforeLogo: HTMLElement = this.document.getElementById(
            "logo-accessibility",
        ) as HTMLElement;

        const viewportLarge = this.breakpointService.mediumBreakpointMatcher();

        const focusTarget = viewportLarge ? beforeFirstA11yLink : beforeLogo;

        focusTarget?.setAttribute("tabindex", "-1");
        focusTarget?.focus();
        focusTarget?.removeAttribute("tabindex");
        if (!viewportLarge) {
            this.window.scrollTo(0, 0); // Force scroll since focus don't trigger an implicit scroll on mobile
        }
    }

    /**
     * Redirect user via Angular's Router.
     *
     * @param pathWithLocale path to redirect to
     */
    public async spaRedirect(pathWithLocale: string): Promise<void> {
        // Trigger the loading bar
        this.loadingBarService.useRef().start();

        this.subscriptions.add(
            this.pageChanged$.pipe(take(1)).subscribe(() => {
                this.resetFocus();
            }),
        );

        // Remove urlPrefix as we are already in the localized application (avoid navigating to "/us/en/us/en/...")
        await this.router.navigateByUrl(
            pathWithLocale.replace(this.locale.urlPrefix, ""),
            { state: { previousUrl: this.location.path() } },
        );

        this.loadingBarService.useRef().complete();
    }

    /**
     * Redirect user via browser.
     *
     * @param pathWithLocale path to redirect to
     * @param isNewTab true to open in new tab
     */
    public nativeRedirect(pathWithLocale: string, isNewTab = false): void {
        if (isNewTab) {
            this.window
                .open(pathWithLocale, "_blank", "noopener,noreferrer")
                ?.focus();
            return;
        }

        const historyState = this.window.history.state;
        if (historyState) {
            historyState.previousUrl = this.location.path();
        }

        this.window.location.href = pathWithLocale;
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
