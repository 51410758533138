import type { PaymentAction } from "@adyen/adyen-web/dist/types/types";
import { TemplateRef } from "@angular/core";

import { PaymentRequest } from "@hermes/api-model-payment";

type ProcessingChannel = typeof PaymentRequest.prototype.processingChannel;

export enum ModalType {
    AddToCart = "add-to-cart",
    TermsAndConditions = "terms-and-conditions",
    ThreeDS2 = "threeDS2",
    ApplePay = "applePay",
}

// Inspired by app/common/interfaces/product-template.types
type ProductTemplateType =
    | "simple"
    | "beltkit"
    | "doublefragrance"
    | "apple-watch"
    | "giftset"
    | "perso-smlg"
    | "perso-beltkit"
    | "perso-silk"
    | "look"
    | "bikini";

export interface ModalDataAddToCart {
    type: ModalType.AddToCart;
    standardDelivery: boolean;
    // Uaevent boolean for analytics or not
    isUAEvent: boolean;
    isFailedAddToCart: boolean;
    disableScrollOnClosingModal: boolean;
    templateType?: ProductTemplateType;
    errorMessage?: string;
    context?: string;
}

export interface ModalDataApplePay {
    type: ModalType.TermsAndConditions;
    templateRef?: TemplateRef<unknown>;
}

export interface ModalDataThreeDS {
    type: ModalType.ThreeDS2;
    clientKey: string;
    action: PaymentAction;
}

export interface ModalDataRedirectApplePay {
    type: ModalType.ApplePay;
    token: ApplePayJS.ApplePayPaymentToken;
    shippingContact: ApplePayJS.ApplePayPaymentContact;
    processingChannel: ProcessingChannel;
}

export type ModalData =
    | ModalDataAddToCart
    | ModalDataApplePay
    | ModalDataThreeDS
    | ModalDataRedirectApplePay;
