import { COUNTRIES, CountryCode, countryFromCode } from "@hermes/locale";
import {
    CountryCode as CountryCodeForPhone,
    getCountries,
    getCountryCallingCode,
} from "libphonenumber-js";

export function getAreaCode(
    selectedCountryCode: string,
    localeCode: CountryCode,
): string {
    let countryExists = false;
    COUNTRIES.forEach((country) => {
        if (country.countryCode === selectedCountryCode) {
            countryExists = true;
        }
    });
    const countryCode = countryExists
        ? countryFromCode(selectedCountryCode as CountryCode).cegidCountryCode
        : selectedCountryCode;
    const formattedCountry = countryCode?.toUpperCase() as CountryCodeForPhone;

    const localeCountryCode = countryFromCode(localeCode).cegidCountryCode;
    const formattedLocaleCode =
        localeCountryCode.toUpperCase() as CountryCodeForPhone;

    if (getCountries().includes(formattedCountry)) {
        return getCountryCallingCode(formattedCountry);
    }
    return getCountryCallingCode(formattedLocaleCode);
}
