import { Action, createReducer, on } from "@ngrx/store";

import {
    cleanProductState,
    defaultImageGalleryPosition,
    fetchCrossSellProduct,
    fetchCrossSellProductFailure,
    fetchCrossSellProductSuccess,
    fetchProduct,
    fetchProductFailure,
    fetchProductSuccess,
    openSelectorToggle,
    resetSizeSelectedVariants,
    resetVariant,
    resetVariantSelectedBoxType,
    selectVariant,
    showMoreVariant,
    toggleZoom,
} from "../actions/product-page.actions";

import {
    cleanProductStateReducer,
    defaultImageGalleryPositionReducer,
    fetchCrossSellProductFailureReducer,
    fetchCrossSellProductReducer,
    fetchCrossSellProductSuccessReducer,
    fetchProductFailureReducer,
    fetchProductSuccessReducer,
    openSelectorToggleReducer,
    resetSizeSelectedVariantsReducer,
    resetVariantReducer,
    resetVariantSelectedBoxTypeReducer,
    selectVariantReducer,
    setIsLoadingReducer,
    showMoreVariantReducer,
    toggleZoomReducer,
} from "./product-page.reducer";
import * as fromProduct from "./product-page.state";

const initialState: fromProduct.ProductPageState =
    fromProduct.initialProductState;

export const productPageEditReducer = createReducer(
    initialState,
    on(fetchProduct, setIsLoadingReducer),
    on(fetchProductSuccess, fetchProductSuccessReducer),
    on(fetchProductFailure, fetchProductFailureReducer),
    on(selectVariant, selectVariantReducer),
    on(resetSizeSelectedVariants, resetSizeSelectedVariantsReducer),
    on(showMoreVariant, showMoreVariantReducer),
    on(openSelectorToggle, openSelectorToggleReducer),
    on(toggleZoom, toggleZoomReducer),
    on(fetchCrossSellProduct, fetchCrossSellProductReducer),
    on(fetchCrossSellProductSuccess, fetchCrossSellProductSuccessReducer),
    on(fetchCrossSellProductFailure, fetchCrossSellProductFailureReducer),
    on(cleanProductState, cleanProductStateReducer),
    on(resetVariant, resetVariantReducer),
    on(resetVariantSelectedBoxType, resetVariantSelectedBoxTypeReducer),
    on(defaultImageGalleryPosition, defaultImageGalleryPositionReducer),
);

/**
 * Reducer for product page wich will edit state
 *
 * @param state current state of product page
 * @param action all product page actions
 */
export const productPageReducer = (
    state: fromProduct.ProductPageState = fromProduct.initialProductState,
    action: Action,
): fromProduct.ProductPageState => productPageEditReducer(state, action);
