import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { CustomerServiceContactEffects } from "./effects/customer-service-contact.effects";
import { CustomerServiceContactFacade } from "./facades/customer-service-contact.facade";
import * as fromCustomerServiceContact from "./reducers/customer-service-contact.reducer";
import { CustomerServiceContactService } from "./services/customer-service-contact.service";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromCustomerServiceContact.CUSTOMER_SERVICE_CONTACT_FEATURE_KEY,
            fromCustomerServiceContact.reducer,
        ),
        EffectsModule.forFeature([CustomerServiceContactEffects]),
    ],
    providers: [CustomerServiceContactFacade, CustomerServiceContactService],
})
export class CustomerServiceContactStateModule {}
