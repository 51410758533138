import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import {
    svgArrowLeft,
    svgArrowRight,
    svgCross,
    SvgModule,
} from "@hermes/aphrodite/svg";
import { TrayStateModule } from "@hermes/states/tray";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";
import { IsoCountryCodePipeModule } from "@hermes/utils-generic/pipes/iso-country-code";

import { AccountCreationBannerComponent } from "./components/account-creation-banner/account-creation-banner.component";
import { BannersTopContainerComponent } from "./components/banners-top-container/banners-top-container.component";
import { CookieBannerComponent } from "./components/cookie-banner/cookie-banner.component";
import { CookieLinkComponent } from "./components/cookie-link/cookie-link.component";
import { GeoFilteringBannerComponent } from "./components/geo-filtering-banner/geo-filtering-banner.component";
import { NotificationBannerComponent } from "./components/notification-banner/notification-banner.component";
import { NotificationMessageComponent } from "./components/notification-message/notification-message.component";
import { NotificationSliderComponent } from "./components/notification-slider/notification-slider.component";
import { BannerAnalyticsService } from "./services/banner-analytics.service";

@NgModule({
    imports: [
        CommonModule,
        SvgModule.forChild([svgCross, svgArrowLeft, svgArrowRight]),
        TranslateModule,
        IsoCountryCodePipeModule,
        TrayStateModule,
        SrOnlyNoSnippetDirective,
    ],
    declarations: [
        CookieLinkComponent,
        NotificationSliderComponent,
        NotificationMessageComponent,
        NotificationBannerComponent,
        AccountCreationBannerComponent,
        CookieBannerComponent,
        GeoFilteringBannerComponent,
        BannersTopContainerComponent,
    ],
    exports: [
        AccountCreationBannerComponent,
        BannersTopContainerComponent,
        NotificationBannerComponent,
    ],
    providers: [BannerAnalyticsService],
})
export class BannersModule {}
