import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { Guest, User } from "@hermes/api-model-account";

import {
    clearData,
    fetchGuest,
    launchUserSynchroWithStorage,
    removeGuest,
    setUserInStorage,
    updateGuest,
} from "../actions/user.actions";
import { UserState } from "../reducers/user.state";
import * as userQuery from "../selectors/user.selectors";

@Injectable()
export class UserFacade {
    // User Selectors
    public userDetails$ = this.store.select(userQuery.selectUserDetails);

    public customerId$ = this.store.select(userQuery.selectCustomerId);

    public isLoggedIn$ = this.store.select(userQuery.selectIsLoggedIn);

    public userPrefix$ = this.store.select(userQuery.selectUserPrefix);

    public userType$ = this.store.select(userQuery.selectUserType);

    public userStatus$ = this.store.select(userQuery.selectUserStatus);

    public userIsSynchronized$ = this.userStatus$.pipe(
        map((status) => status === "synchronized"),
    );

    // Guest Selectors
    public guestData$ = this.store.select(userQuery.selectGuestData);

    public guestDob$ = this.store.select(userQuery.selectGuestDob);

    public guestNewsletter$ = this.store.select(
        userQuery.selectGuestNewsletter,
    );

    public isGuestConnected$ = this.store.select(
        userQuery.selectIsGuestConnected,
    );

    constructor(private store: Store<UserState>) {}

    // Actions
    public fetchGuest(): void {
        this.store.dispatch(fetchGuest());
    }

    public launchUserSynchroWithStorage(): void {
        this.store.dispatch(launchUserSynchroWithStorage());
    }

    public updateGuest(guest: Guest): void {
        this.store.dispatch(updateGuest({ guest }));
    }

    public setUserInStorage(user: User): void {
        this.store.dispatch(setUserInStorage({ user }));
    }

    public removeGuest(): void {
        this.store.dispatch(removeGuest());
    }

    public fetchUserInfos(): void {
        this.fetchGuest();
        this.launchUserSynchroWithStorage();
    }

    public isConnected(): Observable<{
        userConnected: boolean;
        guestConnected: boolean;
    }> {
        return combineLatest([this.isLoggedIn$, this.isGuestConnected$]).pipe(
            filter(
                ([user, guest]) => user !== undefined && guest !== undefined,
            ),
            take(1),
            map(([user, guest]) => ({
                userConnected: user,
                guestConnected: guest,
            })),
        );
    }

    public clearData(): void {
        this.store.dispatch(clearData());
    }
}
