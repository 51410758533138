import { Injectable, OnDestroy } from "@angular/core";
import { Subscription, timer } from "rxjs";

import { TrayData, TrayStackService } from "@hermes/states/tray";

import {
    TRAY_EDIT_ADDRESS,
    TRAY_EXCHANGE_RETURN_DETAILS,
    TRAY_EXCHANGE_RETURN_INSTRUCTIONS,
    TRAY_EXCHANGE_RETURN_METHOD,
    TRAY_ORDER,
    TRAY_REQUEST_EXCHANGE_RETURN,
} from "./drupal-tray.constant";

export type DrupalTrayClosure = (
    data?: Record<string, unknown>,
) => Pick<TrayData, "openCallback" | "closeCallback">;

export interface DrupalTraysSettings {
    [trayName: string]: DrupalTrayClosure;
}

@Injectable()
export class DrupalTraySettings implements OnDestroy {
    public subscription = new Subscription();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly SETTINGS: DrupalTraysSettings = {
        [TRAY_ORDER]: this.useLocalStorage("tray-order-data"),

        [TRAY_REQUEST_EXCHANGE_RETURN]: this.useLocalStorage(
            "tray-request-exchange-return-data",
        ),

        [TRAY_EXCHANGE_RETURN_METHOD]: this.useLocalStorage(
            "tray-exchange-return-method-data",
        ),

        [TRAY_EXCHANGE_RETURN_INSTRUCTIONS]: DrupalTraySettings.combineSettings(
            this.useLocalStorage("tray-exchange-return-instructions-data"),
            this.closePreviousTrays(TRAY_EXCHANGE_RETURN_INSTRUCTIONS),
        ),

        [TRAY_EXCHANGE_RETURN_DETAILS]: this.useLocalStorage(
            "tray-exchange-return-details-data",
        ),

        [TRAY_EDIT_ADDRESS]: this.useLocalStorage("tray-edit-address-data"),
    };

    constructor(private trayStackService: TrayStackService) {}

    /**
     * Build settings by combining different behaviors in args
     *
     * @param args behaviors to combine
     */
    private static combineSettings(
        ...args: DrupalTrayClosure[]
    ): DrupalTrayClosure {
        return (data?: Record<string, unknown>) => ({
            openCallback: () => {
                args.forEach(
                    (arg) => arg(data).openCallback && arg(data).openCallback(),
                );
            },
            closeCallback: () => {
                args.forEach(
                    (arg) =>
                        arg(data).closeCallback && arg(data).closeCallback(),
                );
            },
        });
    }

    /**
     * Link a DrupalTray to a localStorage item
     * - Save data to localStorage before opening
     * - Remove data from localStorage after closing
     */
    private useLocalStorage(itemKey: string): DrupalTrayClosure {
        return (data?: Record<string, unknown>) => ({
            openCallback: () =>
                localStorage.setItem(itemKey, JSON.stringify(data)),
            closeCallback: () => localStorage.removeItem(itemKey),
        });
    }

    /**
     * Close previous trays when opening a new one. Closing process is hidden to users.
     */
    private closePreviousTrays(openedTray: string): DrupalTrayClosure {
        return () => ({
            openCallback: () => {
                // Close trays in background (avoid closing all trays before opening a new tray by delaying closing)
                const closingDelay = 100; // ms

                this.subscription.add(
                    timer(closingDelay).subscribe(() =>
                        this.trayStackService.trays
                            .filter((openTray) => openTray.name !== openedTray)
                            .forEach((openTray) => {
                                this.trayStackService.closeTray(openTray.uuid);
                            }),
                    ),
                );
            },
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
