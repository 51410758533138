import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import {
    hideLoader,
    hideNotification,
    keyboardNavigation,
    mouseNavigation,
    showLoader,
    showNotification,
    updateHeaderHeight,
} from "../actions/layout.actions";
import { MessageOption } from "../model/notification.model";
import {
    IHeaderMainContainerProperties,
    State,
} from "../reducers/layout.reducer";
import {
    selectDisplayOverlay,
    selectHeaderHeight,
    selectHeaderInnerHeight,
    selectHeaderOffsetHeight,
    selectKeyboardNavigation,
    selectNotificationMessage,
} from "../selectors/layout.selectors";

@Injectable()
export class LayoutFacade implements OnDestroy {
    public headerOffsetHeight$ = this.store.select(selectHeaderOffsetHeight);
    public headerInnerHeight$ = this.store.select(selectHeaderInnerHeight);
    public headerHeight$ = this.store.select(selectHeaderHeight);
    public notificationMessage$ = this.store.select(selectNotificationMessage);
    public displayOverlay$ = this.store.select(selectDisplayOverlay);
    public keyboardNavigation$ = this.store.select(selectKeyboardNavigation);

    private subscription = new Subscription();

    constructor(private store: Store<State>) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateHeaderHeight(header: IHeaderMainContainerProperties): void {
        this.store.dispatch(updateHeaderHeight({ header }));
    }

    public showLoader(): void {
        this.store.dispatch(showLoader());
    }

    public hideLoader(): void {
        this.store.dispatch(hideLoader());
    }

    public showNotification(
        displayOverlay: boolean,
        messageOption: MessageOption,
    ): void {
        this.store.dispatch(
            showNotification({
                displayOverlay,
                messageOption,
            }),
        );
    }

    public hideNotification(): void {
        this.store.dispatch(hideNotification());
    }

    public enableKeyboardNavigation(): void {
        this.store.dispatch(keyboardNavigation());
    }

    public enableMouseNavigation(): void {
        this.store.dispatch(mouseNavigation());
    }
}
