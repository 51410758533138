type FormTranslations = (params: {
    min?: number;
    max?: number;
    alteredMax?: number;
}) => Record<string, string>;
export const formTranslations: FormTranslations = ({
    min,
    max,
    alteredMax,
}) => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    "hermes_account.your-first-name": $localize`:@@hermes_account.your-first-name:First name`,
    "hermes_account.your-last-name": $localize`:@@hermes_account.your-last-name:Last name`,
    "hermes_account.province": $localize`:@@hermes_account.province:Province`,
    "hermes.common.only_latin_characters_input": $localize`:@@hermes.common.only_latin_characters_input:Please use latin characters only`,
    "hermes.forms.dropdown.value.gender.female": $localize`:@@hermes.forms.dropdown.value.gender.female:Female`,
    "hermes.forms.dropdown.value.gender.male": $localize`:@@hermes.forms.dropdown.value.gender.male:Male`,
    "hermes_global.address-line-1": $localize`:@@hermes_global.address-line-1:Address`,
    "hermes_global.address-line-1-mx": $localize`:@@hermes_global.address-line-1-mx:Address`,
    "hermes_global.address-line-1.error.length": $localize`:@@hermes_global.address-line-1.error.length:Your address is too long. Please continue in the following field.`,
    "hermes_global.address-line-1-flat": $localize`:@@hermes_global.address-line-1-flat:Address (Flat, Floor, Block/Tower)`,
    "hermes_global.address-line-2": $localize`:@@hermes_global.address-line-2:Address continued`,
    "hermes_global.address-line-2-mx": $localize`:@@hermes_global.address-line-2-mx:Address continued`,
    "hermes_global.address-line-2-estate": $localize`:@@hermes_global.address-line-2-estate:Address continued (Estate/building, Street)`,
    "hermes_global.city": $localize`:@@hermes_global.city:City`,
    "hermes_global.company": $localize`:@@hermes_global.company:Company`,
    "hermes_global.district": $localize`:@@hermes_global.district:District`,
    "hermes_global.first-name-2": $localize`:@@hermes_global.first-name-2:First name 2`,
    "hermes_global.gender": $localize`:@@hermes_global.gender:Gender`,
    "hermes_global.last-name-2": $localize`:@@hermes_global.last-name-2:Last name 2`,
    "hermes_global.postcode": $localize`:@@hermes_global.postcode:Post code`,
    "hermes_global.region": $localize`:@@hermes_global.region:Region`,
    "hermes_global.telephone": $localize`:@@hermes_global.telephone:Telephone number`,
    "hermes_global.title": $localize`:@@hermes_global.title:Title`,
    "hermes-global-translations.validation.telephone": $localize`:@@hermes-global-translations.validation.telephone:Oops, this doesn't seem quite right! Your phone number can only include digits.`,
    "hermes_global.select": $localize`:@@hermes_global.select:- Select -`,
    "hermes_global.state": $localize`:@@hermes_global.state:State`,
    "hermes_global.street-number": $localize`:@@hermes_global.street-number:Street number`,
    "hermes_global.street-number-mx": $localize`:@@hermes_global.street-number-mx:Street number`,
    "hermes_global.zipcode": $localize`:@@hermes_global.zipcode:Zip code`,
    "hermes_global.additional-number": $localize`:@@hermes_global.additional-number:Additional number`,
    "hermes_global.neighborhood": $localize`:@@hermes_global.neighborhood:Neighborhood`,
    "hermes_global.district-mx": $localize`:@@hermes_global.district-mx:District`,
    "hermes-global-translations.state": $localize`:@@hermes-global-translations.state:State`,
    "hermes_global.zip-+": $localize`:@@hermes_global.zip-+:Zip +`,
    "hermes-global-translations.japan-lastname-kanji-letters": $localize`:@@hermes-global-translations.japan-lastname-kanji-letters":正確な氏名を入力してください`,
    "hermes-global-translations.japan-lastname-katakana-letters": $localize`:@@hermes-global-translations.japan-lastname-katakana-letters:全角カタカナで入力してください。`,
    "hermes-global-translations.japan-number": $localize`:@@hermes-global-translations.japan-number:数字を入力しないでください`,
    "hermes-global-translations.postcode_between": $localize`:@@hermes-global-translations.postcode_between:Please enter between ${min}:INTERPOLATION: and ${max}:INTERPOLATION_1: characters.`,
    "hermes-global-translations.postcode_between_with_space": $localize`:@@hermes-global-translations.postcode_between_with_space:Please enter between ${min}:INTERPOLATION: and ${alteredMax}:INTERPOLATION_1: characters.`,
    "hermes-global-translations.postcode_digitsonly_between": $localize`:@@hermes-global-translations.postcode_digitsonly_between:Please enter between ${min}:INTERPOLATION: and ${max}:INTERPOLATION_1: digits.`,
    "hermes-global-translations.postcode_digitsonly_between_space": $localize`:@@hermes-global-translations.postcode_digitsonly_between_space:Please enter between ${min}:INTERPOLATION: and ${alteredMax}:INTERPOLATION_1: digits.`,
    "hermes-global-translations.postcode_digitsonly_max": $localize`:@@hermes-global-translations.postcode_digitsonly_max:Please enter ${max}:INTERPOLATION: digits.`,
    "hermes-global-translations.postcode_digitsonly_max_with_space": $localize`:@@hermes-global-translations.postcode_digitsonly_max_with_space:Please enter ${alteredMax}:INTERPOLATION: digits.`,
    "hermes-global-translations.postcode_digitsonly_or": $localize`:@@hermes-global-translations.postcode_digitsonly_or:Please enter ${min}:INTERPOLATION: or ${max}:INTERPOLATION_1: digits.`,
    "hermes-global-translations.postcode_digitsonly_or_with_space": $localize`:@@hermes-global-translations.postcode_digitsonly_or_with_space:Please enter ${min}:INTERPOLATION: or ${alteredMax}:INTERPOLATION_1: digits.`,
    "hermes-global-translations.postcode_or": $localize`:@@hermes-global-translations.postcode_or:Please enter ${min}:INTERPOLATION: or ${alteredMax}:INTERPOLATION_1: characters.`,
    "hermes-global-translations.postcode_max": $localize`:@@hermes-global-translations.postcode_max:Please enter ${max}:INTERPOLATION: characters.`,
    "hermes-global-translations.postcode_max_with_space": $localize`:@@hermes-global-translations.postcode_max_with_space:Please enter ${alteredMax}:INTERPOLATION: characters.`,
    "hermes_global.address-line-1-long-flat-room-unit-floor": $localize`:@@hermes_global.address-line-1-long-flat-room-unit-floor:Address (Flat / Room / Unit / Floor No.)`,
    "hermes_global.address-line-2-long-block-phase-building-complex": $localize`:@@hermes_global.address-line-2-long-block-phase-building-complex:Address (Block / Phase No. / Building / Complex)`,
    "hermes_global.address-line-3-long-street-details": $localize`:@@hermes_global.address-line-3-long-street-details:Address (Street No. / Street / Complex)`,
    /* eslint-enable @typescript-eslint/naming-convention */
});
