import {
    createFeatureSelector,
    createSelector,
    DefaultProjectorFn,
    MemoizedSelector,
    Selector,
} from "@ngrx/store";

import { AddToCartState, AddToCartStateBasket } from "../add-to-cart.state";

export const addToCartFeatureKey = "addToCart";

export const selectAddToCart =
    createFeatureSelector<AddToCartState>(addToCartFeatureKey);

// ---- subs-keys selectors :
export const selectAddToCartBasket: Selector<
    AddToCartState,
    AddToCartStateBasket
> = createSelector(
    selectAddToCart,
    (addToCart: AddToCartState) => addToCart.basket,
);

export const selectAddToCartLoading = createSelector(
    selectAddToCart,
    (addToCart: AddToCartState) => addToCart.loading,
);

export const selectAddToCartPositionCLicked = createSelector(
    selectAddToCart,
    (addToCart: AddToCartState) => addToCart.addToCartPositionClicked,
);

export const selectAddToCartEcomError = (
    position: number = 0,
): MemoizedSelector<
    object,
    {
        errorCode?: string;
        enabled: boolean;
    },
    DefaultProjectorFn<{
        errorCode?: string;
        enabled: boolean;
    }>
> =>
    createSelector(
        selectAddToCart,
        (addToCart: AddToCartState) =>
            addToCart.addToCartCallToActions[position]?.ecomError,
    );

export const selectAddToCartButton = (
    position: number = 0,
): MemoizedSelector<
    object,
    {
        enabled: boolean;
    },
    DefaultProjectorFn<{
        enabled: boolean;
    }>
> =>
    createSelector(
        selectAddToCart,
        (addToCart: AddToCartState) =>
            addToCart.addToCartCallToActions[position]?.button,
    );
