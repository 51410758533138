import { Action, createReducer, on } from "@ngrx/store";
import { v4 } from "uuid";

import { ProductGiftSet, ProductGiftSetItem } from "@hermes/api-model-product";
import { addToCartFailure } from "@hermes/states/basket";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import {
    addGiftSetItem,
    askForItemRemoval,
    emptySelectedItems,
    expandGiftSetItem,
    fetchGiftsetProductSuccess,
    removeGiftSetItem,
} from "./../actions/giftset-product-page.actions";
import {
    BoxItemStatus,
    BoxSelectedItem,
} from "./../types/giftset-product-page.types";
import {
    GiftSetState,
    initialGiftSetState,
} from "./giftset-product-page.state";

/**
 * On fetch of giftset product :
 * - initialize product box
 * - populate listItems
 * - calculate if all items are OOS disable button and wich warning to display
 */
export const fetchGiftSetProductSuccessReducer = (
    state: GiftSetState,
    action: ReturnType<typeof fetchGiftsetProductSuccess>,
): GiftSetState => {
    // cast to keep type safe
    const giftsetProduct = action.product as ProductGiftSet;
    // initialize toggle
    const toggleItems: { [key: string]: boolean } = {};
    giftsetProduct?.items?.forEach((item: ProductGiftSetItem) => {
        toggleItems[item.sku] = true;
    });

    return {
        ...state,
        box: {
            ...state.box,
            selectedItems: { byId: {}, allIds: [] },
        },
        listItems: giftsetProduct?.items,
        toggleItems,
    };
};

export const emptySelectedItemsReducer = (
    state: GiftSetState,
    _action: ReturnType<typeof emptySelectedItems>,
): GiftSetState =>
    // If AddToCart on giftSet page, empty selected items.
    state.box?.selectedItems?.allIds?.length === 0
        ? { ...state }
        : {
              ...state,
              box: {
                  ...state.box,
                  selectedItems: {
                      allIds: [],
                      byId: {},
                  },
              },
          };

export const addGiftSetItemReducer = (
    state: GiftSetState,
    action: ReturnType<typeof addGiftSetItem>,
): GiftSetState => {
    const { byId, allIds } = state.box.selectedItems;
    const { sku } = action;

    const productItem: ProductGiftSetItem | undefined = state.listItems.find(
        (item) => item.sku === sku,
    );
    let neoBoxItem: BoxSelectedItem;

    if (productItem) {
        neoBoxItem = {
            sku: productItem.sku,
            image: productItem.image,
            title: productItem.title,
            hasError: false,
            status: BoxItemStatus.VOID,
            price: productItem.price,
        };
        const id = v4();
        const neoById = byId
            ? {
                  ...byId,
                  [id]: neoBoxItem,
              }
            : { [id]: neoBoxItem };

        const neoAllIds = allIds ? [...allIds, id] : [id];

        return {
            ...state,
            box: {
                ...state.box,
                selectedItems: {
                    allIds: neoAllIds,
                    byId: neoById,
                },
            },
        };
    }

    return {
        ...state,
        box: {
            ...state.box,
        },
    };
};

export const removeGiftSetItemReducer = (
    state: GiftSetState,
    action: ReturnType<typeof removeGiftSetItem>,
): GiftSetState => {
    const { byId, allIds } = state.box.selectedItems;
    let neoAllIds: string[] = [];

    // id specify in action (delete by id)
    if (action.id) {
        neoAllIds = allIds.filter((id) => id !== action.id);
    }
    // sku specify in action (delete by sku)
    else if (action.sku) {
        let idToDelete: string | undefined;
        // start from the end for delete the last corresponding item in the list
        for (let index = allIds.length - 1; index >= 0; index--) {
            if (!idToDelete && byId[allIds[index]].sku === action.sku) {
                idToDelete = allIds[index];
            }
        }
        neoAllIds = allIds.filter((id) => id !== idToDelete);
    }
    // if nothing is specify just protect the state and delete nothing
    else {
        neoAllIds = allIds;
    }

    const neoById: {
        [id: string]: BoxSelectedItem;
    } = {};
    neoAllIds.forEach((id) => {
        neoById[id] = byId[id];
    });

    return {
        ...state,
        box: {
            ...state.box,
            selectedItems: {
                allIds: neoAllIds,
                byId: neoById,
            },
        },
    };
};

/**
 * When user click on  giftset item toggle
 * - switch this toggle status
 * - close all other
 */
export const expandGiftSetItemReducer = (
    state: GiftSetState,
    action: ReturnType<typeof expandGiftSetItem>,
): GiftSetState => {
    const oldToggleState = state.toggleItems;
    const neoToggleState: { [key: string]: boolean } = {};
    const toggleKey = action.sku;
    Object.keys(oldToggleState)
        .filter((key) => key !== toggleKey)
        .forEach((key) => {
            neoToggleState[key] = true;
        });
    neoToggleState[toggleKey] = !oldToggleState[toggleKey];
    return {
        ...state,
        toggleItems: {
            ...neoToggleState,
        },
    };
};

export const askForItemRemovalReducer = (
    state: GiftSetState,
    action: ReturnType<typeof askForItemRemoval>,
): GiftSetState => {
    const reversedAllIds = [...state.box.selectedItems.allIds].reverse();
    const byId = state.box.selectedItems.byId;
    let lastItemId: string | undefined;
    reversedAllIds.forEach((id: string) => {
        if (byId[id].sku === action.sku && !lastItemId) {
            lastItemId = id;
        }
    });
    return {
        ...state,
        box: {
            ...state.box,
            toDelete: lastItemId,
        },
    };
};

export const productSetItemEcomError = (
    state: GiftSetState,
    action: ReturnType<typeof addToCartFailure>,
): GiftSetState => {
    let listItems = state.listItems;
    // remove item from list only when is a stock error
    if (
        action.error.internal_code === EcomErrorCodes.OUT_OF_STOCK ||
        action.error.internal_code === EcomErrorCodes.INSUFFICIENT_STOCK
    ) {
        listItems = state.listItems.filter(
            (item) => item.sku !== action.error.item,
        );
    }

    const neoById = state.box.selectedItems.byId;
    state.box.selectedItems.allIds.forEach((id) => {
        if (neoById[id].sku === action.error.item) {
            neoById[id].hasError = true;
        }
    });

    return {
        ...state,
        listItems,
        box: {
            ...state.box,
            selectedItems: {
                ...state.box.selectedItems,
                byId: neoById,
            },
        },
    };
};

export const giftsetProductPageEditReducer = createReducer(
    initialGiftSetState,
    on(addGiftSetItem, addGiftSetItemReducer),
    on(removeGiftSetItem, removeGiftSetItemReducer),
    on(expandGiftSetItem, expandGiftSetItemReducer),
    on(addToCartFailure, productSetItemEcomError),
    on(askForItemRemoval, askForItemRemovalReducer),
    on(fetchGiftsetProductSuccess, fetchGiftSetProductSuccessReducer),
    on(emptySelectedItems, emptySelectedItemsReducer),
);

/**
 * Reducer for giftset product page wich will edit state
 *
 * @param state current state of giftset product page
 * @param action all giftset product page actions
 */
export const giftsetProductPageReducer = (
    state: GiftSetState = initialGiftSetState,
    action: Action,
): GiftSetState => giftsetProductPageEditReducer(state, action);
