import { Component, EventEmitter, Input, Output } from "@angular/core";

import { TrayData } from "../../models/tray-data.model";

@Component({
    selector: "h-tray-title",
    styleUrls: ["./tray-title.component.scss"],
    templateUrl: "./tray-title.component.html",
})
export class TrayTitleComponent {
    /**
     * Tray displayed title.
     */
    @Input()
    public tray!: TrayData;

    @Output()
    public closeTray: EventEmitter<Event> = new EventEmitter<Event>();
}
