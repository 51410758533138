export const persoAnalyticsConstant = {
    // eventlabel
    clickLeatherMenu: "Click_LeatherMenu",
    clickMarkingMenu: "Click_MarkingMenu",
    clickTypographyMenu: "Click_TypographyMenu",
    clickColorMenu: "Click_ColourMenu",
    clickSizeMenu: "Click_SizeMenu",
    clickBuckleMenu: "Click_BuckleMenu",
    clickInitialsTab: "Initials",

    // eventAction
    leatherSelector: "Leather_Selector",
    exteriorSelector: "exterior_Selector",
    liningSelector: "Lining_Selector",
    markingSelector: "Marking_Selector",
    typographySelector: "Typography_Selector",
    sizeSelector: "Size_Selector",
    buckleSelector: "Buckle_Selector",
    color1Selector: "Colour1_Selector",
    color2Selector: "Colour2_Selector",
};

// Actions
export const errorMagentoAction = "Error_message";
export const saveAction = "Save";

// Labels
export const checkFeasibilityKOLabel = "Large_number";
export const errorMagentoAlreadyInCartLabel = "Already_in_cart";
