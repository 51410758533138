import { MetaDefinition } from "@angular/platform-browser";

/**
 * Provide a way to build metatags array with a generic attribute.
 *
 * @usageNotes
 *
 * ```
 * new MetatagBuilder()
 *   .addTag("itemprop", "name", name)
 *   .addTag("itemprop", "image", image)
 *   .addTag("itemprop", "description", description)
 *   .build();
 * ```
 *
 * The preceding code will produce a MetaDefinition array
 * leading to HTML as below when given to Angular's Meta service.
 *
 * ```
 * <meta itemprop="name" content="{name}">
 * <meta itemprop="image" content="{image}">
 * <meta itemprop="description" content="{description}">
 * ```
 */
export class MetatagBuilder {
    private metatags: MetaDefinition[] = [];

    /**
     * Add a new tag if content is truthy. This will produce a MetaDefinition to create a tag as follow :
     * `<meta {attribute}="{attributeValue}" content="{content}">`
     *
     * @param attribute Meta attribute ("name", "itemprop", "property")
     * @param attributValue Value linked to previous parameter
     * @param content Content value
     */
    public addTag(
        attribute: keyof MetaDefinition,
        attributValue: string,
        content?: string,
    ): MetatagBuilder {
        if (content) {
            this.metatags.push({
                [attribute]: attributValue,
                content,
            });
        }
        return this;
    }

    /**
     * Generate metatags
     */
    public build(): MetaDefinition[] {
        return this.metatags;
    }
}
