import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ModalEffects } from "./effects/modal.effects";
import { ModalFacade } from "./facades/modal.facade";
import { reducer } from "./reducers/modal.reducer";
import { modalFeatureKey } from "./selectors/modal.selectors";

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([ModalEffects]),
        StoreModule.forFeature(modalFeatureKey, reducer),
    ],
    providers: [ModalFacade],
})
export class ModalStateModule {}
