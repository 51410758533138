import {
    CHECKOUT_PAYMENT_3_D_SECURE_SYSTEM,
    CHECKOUT_PAYMENT_CTA_CLICK,
    CHECKOUT_PAYMENT_EDIT_BILLING_ADDRESS,
    CHECKOUT_PAYMENT_GENERAL_TERMS_CONDITIONS,
    CHECKOUT_PAYMENT_PRIVACY_POLICY,
} from "../constants/conversion-funnel-common.constants";
import { GTMEventData } from "../types/gtm.type";

import { CheckoutAnalyticsEvent } from "./checkout.event";

export class CheckoutPaymentAnalyticsEvent extends CheckoutAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CHECKOUT_PAYMENT_EDIT_BILLING_ADDRESS:
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Payment",
                    eventaction: "EditBillingAddress",
                };
            case CHECKOUT_PAYMENT_3_D_SECURE_SYSTEM:
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Payment",
                    eventaction: "3DSecureSystem",
                };
            case CHECKOUT_PAYMENT_GENERAL_TERMS_CONDITIONS:
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Payment",
                    eventaction: "GeneralTermsConditions",
                };
            case CHECKOUT_PAYMENT_PRIVACY_POLICY:
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Payment",
                    eventaction: "PrivacyPolicy",
                };
            case CHECKOUT_PAYMENT_CTA_CLICK:
                return {
                    ...super.formatForGTM(),
                    eventcategory: "Checkout_Payment",
                    eventaction: "Pay",
                    eventlabel: this.eventData.returnStatus,
                };
            default:
                return {
                    ...super.formatForGTM(),
                };
        }
    }
}
