import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { map, pairwise } from "rxjs/operators";

import { setPreviousUrl } from "../actions/router.action";

/**
 * Router effects should contain only side effects caused by router actions (@see https://ngrx.io/guide/router-store/actions)
 * or during custom router actions (see router.action file)
 *
 * Those actions are performed before or during angular routing phase.
 */
@Injectable()
export class RouterEffects {
    public previousUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigatedAction),
            // Thanks to `pairwise` operator, we can obtain the previous and current values in a single array.
            // Note : `pairwise` emits on the second and subsequent emissions but not on the first emission (there is no previous value).
            pairwise(),
            map(([previous]) => {
                const previousUrl = previous.payload.event.url;
                return setPreviousUrl({ previousUrl });
            }),
        ),
    );

    constructor(private actions$: Actions) {}
}
