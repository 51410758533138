import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromLayout from "../reducers/layout.reducer";

export const selectLayoutState = createFeatureSelector<fromLayout.State>(
    fromLayout.layoutFeatureKey,
);

export const selectHeader = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.header,
);

export const selectHeaderOffsetHeight = createSelector(
    selectHeader,
    (header: { offsetHeight: number; innerHeight: number }) =>
        header.offsetHeight,
);
export const selectHeaderInnerHeight = createSelector(
    selectHeader,
    (header: { offsetHeight: number; innerHeight: number }) =>
        header.innerHeight,
);
export const selectHeaderHeight = createSelector(
    selectHeader,
    (header: { offsetHeight: number; offsetTop: number }) =>
        header.offsetHeight + header.offsetTop,
);

export const selectLoaderState = createSelector(
    selectLayoutState,
    (loaderState: fromLayout.State) => loaderState.loading,
);

export const selectNotificationMessage = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.notification?.messageOption,
);

export const selectDisplayOverlay = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) =>
        !!layoutState.notification?.displayOverlay,
);

export const selectKeyboardNavigation = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.keyboardNavigation,
);
