import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { TrayData } from "../models/tray-data.model";

import { TrayLoaderService } from "./tray-loader.service";

/**
 * The TrayStackService is responsible for holding a stack of TrayData
 * and dispatching changes trough an Observable.
 */
@Injectable()
export class TrayStackService {
    public trays$: Observable<TrayData[]>;
    public get trays(): TrayData[] {
        return this.traysSubject.getValue();
    }

    private traysSubject: BehaviorSubject<TrayData[]>;

    constructor(private readonly trayLoader: TrayLoaderService) {
        this.traysSubject = new BehaviorSubject([] as TrayData[]);
        this.trays$ = this.traysSubject.asObservable();
    }

    /**
     * Return the tray on top of TrayStack (ie. tray displayed to user)
     */
    public getTrayOnTop(): TrayData | void {
        if (this.trays.length) {
            return this.trays[this.trays.length - 1];
        }
    }

    /**
     * Adds a tray to the stack
     */
    public pushTray(tray: TrayData): Subscription | undefined {
        if (tray.openCallback) {
            tray.openCallback();
        }

        // direct access we already have the template, just create the tray.
        if (tray.templateRef) {
            this.traysSubject.next([...this.traysSubject.getValue(), tray]);
            return undefined;
        }

        // case of lazy loaded tray.
        return this.trayLoader
            .loadDynamicTray(tray)
            .pipe(first())
            .subscribe((factory) => {
                if (factory) {
                    tray.componentFactory = factory;
                    this.traysSubject.next([
                        ...this.traysSubject.getValue(),
                        tray,
                    ]);
                }
            });
    }

    /**
     * Removes last tray.
     */
    public popTray(): TrayData | undefined {
        const trays = this.traysSubject.getValue();
        const poppedTray = trays.pop();
        if (poppedTray) {
            this.callbackOnClose(poppedTray);
            this.traysSubject.next(trays);
            return poppedTray;
        }
        return undefined;
    }

    /**
     * Close tray in stack based on uuid
     */
    public closeTray(uuid: string): TrayData | void {
        const trays = this.traysSubject.getValue();
        const trayToRemoveIndex: number = trays.findIndex(
            (tray) => tray.uuid === uuid,
        );
        if (trayToRemoveIndex > -1) {
            const [poppedTray] = trays.splice(trayToRemoveIndex, 1);
            this.callbackOnClose(poppedTray);
            this.traysSubject.next(trays);
            return poppedTray;
        }
    }

    /**
     * Close all the trays!
     */
    public closeAllTrays(): void {
        this.traysSubject.getValue().forEach(this.callbackOnClose);
        this.traysSubject.next([]);
    }

    private callbackOnClose(tray: TrayData): void {
        if (tray && tray.closeCallback) {
            tray.closeCallback();
        }
    }
}
