import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { JsonConvert } from "json2typescript";
import { of } from "rxjs";
import {
    catchError,
    filter,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from "rxjs/operators";

import {
    AddToCartResponse,
    CustomOptions,
    ErrorItem,
    PersistResponse,
    RemoveFromCartResponse,
    SetCartItemResponse,
} from "@hermes/api-model-basket";
import { StorageService } from "@hermes/app-core";
import { CartService } from "@hermes/utils/services/api-clients";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import * as BasketActions from "../actions/basket.actions";
import { clearSessionDataSuccess } from "../actions/basket.actions";
import { EcomError, SerializedBasket } from "../models/basket-feature.model";
import { State } from "../reducers/basket.reducer";
import { basketQuery } from "../selectors/basket.selectors";
import { BasketService } from "../services/basket.service";

@Injectable()
export class BasketEffects {
    public addToCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.addToCart),
            switchMap(({ addToCartPayload }) =>
                this.cartService
                    .addProductToCart(
                        addToCartPayload.sku,
                        addToCartPayload.skuSet,
                        addToCartPayload.dku,
                        addToCartPayload.urlImage,
                        addToCartPayload.customOptions,
                    )
                    .pipe(
                        map((response: AddToCartResponse) => {
                            // if ecp respond with basket object : add to cart is OK
                            if (response.basket && !response.error) {
                                this.setBasketInStorage(
                                    response.basket as unknown as SerializedBasket,
                                );
                                return BasketActions.addToCartSuccess({
                                    basket: response.basket as unknown as SerializedBasket,
                                    displayNotifOnSuccess:
                                        addToCartPayload.displayNotifOnSuccess,
                                    itemPosition: addToCartPayload.itemPosition,
                                    sku: addToCartPayload.sku,
                                    templateType: addToCartPayload.templateType,
                                    sendAnalyticsEvent:
                                        addToCartPayload.sendAnalyticsEvent,
                                    disableScrollOnClosingModal: Boolean(
                                        addToCartPayload.isQuickBuy,
                                    ),
                                    isProductEngraving: Boolean(
                                        addToCartPayload.customOptions
                                            ?.engraving?.length,
                                    ),
                                    context: addToCartPayload.context,
                                    product: addToCartPayload.product,
                                    currencyCode: addToCartPayload.currencyCode,
                                });
                            }

                            // In some case, ecp can respond OK 200 with a technical error stack, so create an unknown error
                            return BasketActions.addToCartFailure({
                                error: response.error || {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    internal_code: EcomErrorCodes.UNKNOWN_ERROR,
                                },
                                itemPosition: addToCartPayload.itemPosition,
                                shouldOpenModal: Boolean(
                                    addToCartPayload.displayNotifOnFail,
                                ),
                                context: addToCartPayload.context,
                            });
                        }),
                        catchError((error) => {
                            // when response is not OK 200, create error
                            let internalCode: EcomErrorCodes;
                            switch (error?.error?.error) {
                                case "AvailabilitiesError": {
                                    internalCode =
                                        EcomErrorCodes.INSUFFICIENT_STOCK;
                                    break;
                                }
                                case "MaxChildQuantityError": {
                                    internalCode =
                                        EcomErrorCodes.MAX_CHILD_QUANTITY;
                                    break;
                                }
                                case "ProductNotFeasibleError": {
                                    internalCode =
                                        EcomErrorCodes.ERROR_CODE_PERSONALIZATION_CAPACITY;
                                    break;
                                }
                                case "PersonalizeItemError": {
                                    internalCode =
                                        EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS;
                                    break;
                                }
                                case "OutOfStockError": {
                                    internalCode = EcomErrorCodes.OUT_OF_STOCK;
                                    break;
                                }
                                default: {
                                    internalCode =
                                        EcomErrorCodes.TECHNICAL_ERROR;
                                }
                            }
                            return of(
                                BasketActions.addToCartFailure({
                                    error: {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        internal_code: internalCode,
                                    },
                                    itemPosition: addToCartPayload.itemPosition,
                                    shouldOpenModal: Boolean(
                                        addToCartPayload.displayNotifOnFail,
                                    ),
                                    context: addToCartPayload.context,
                                }),
                            );
                        }),
                    ),
            ),
        ),
    );

    public removeFromCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.removeFromCart),
            switchMap((payload) =>
                this.cartService.removeProductsFromCart([payload]).pipe(
                    map((response: RemoveFromCartResponse) => {
                        // if ecp respond with basket object : remove from cart is OK
                        if (
                            response.basket &&
                            (!response.error ||
                                response.error.internal_code ===
                                    EcomErrorCodes.DELETED_ITEM)
                        ) {
                            // save basket in storage
                            this.setBasketInStorage(
                                response.basket as unknown as SerializedBasket,
                            );

                            return BasketActions.removeFromCartSuccess({
                                basket: response.basket as unknown as SerializedBasket,
                            });
                        }

                        // In some case, ecp can respond OK 200 with a technical error stack, so create an unknown error
                        return BasketActions.removeFromCartFailure({
                            error: response.error || {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                internal_code: EcomErrorCodes.UNKNOWN_ERROR,
                            },
                        });
                    }),
                    catchError(() =>
                        // when response is not OK 200, create error with ecom technical error code
                        of(
                            BasketActions.removeFromCartFailure({
                                error: {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    internal_code:
                                        EcomErrorCodes.TECHNICAL_ERROR,
                                },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    public resetCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.resetCart),
            withLatestFrom(this.store.select(basketQuery.selectCart)),
            switchMap(([_, basket]) =>
                this.cartService
                    .removeProductsFromCart(
                        basket.items.map((item) => {
                            const typedItem = item as {
                                sku: string;
                                qty: number;
                                customOptions?: CustomOptions;
                            };
                            return {
                                sku: typedItem.sku,
                                qty: typedItem.qty,
                                customOptions: typedItem.customOptions,
                            };
                        }),
                    )
                    .pipe(
                        map((response: RemoveFromCartResponse) => {
                            // If ecp respond with basket object : remove from cart is OK
                            if (response.basket && !response.error) {
                                return BasketActions.resetCartSuccess({
                                    basket: response.basket as unknown as SerializedBasket,
                                });
                            }

                            // In some case, ecp can respond OK 200 with a technical error stack, so create an unknown error
                            return BasketActions.resetCartFailure({
                                error: response.error || {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    internal_code: EcomErrorCodes.UNKNOWN_ERROR,
                                },
                            });
                        }),
                        catchError(() =>
                            // when response is not OK 200, create error with ecom technical error code
                            of(
                                BasketActions.resetCartFailure({
                                    error: {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        internal_code:
                                            EcomErrorCodes.TECHNICAL_ERROR,
                                    },
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    public setBasket$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BasketActions.setBasket),
                tap((payload) => {
                    this.setBasketInStorage(payload.basket);
                }),
            ),
        { dispatch: false },
    );

    public updateBasket$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BasketActions.updateBasket),
                tap((payload) => {
                    this.basketService.updateSessionBasket(payload.basket);
                }),
            ),
        { dispatch: false },
    );

    public persistBasket$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.persistBasket),
            switchMap((payload) =>
                this.basketService.persist(payload.persistRequest).pipe(
                    tap((response: PersistResponse) => {
                        if (response.error) {
                            throw new Error(response.error);
                        }
                    }),
                    filter(
                        (response: PersistResponse) =>
                            response.basket && !response.error,
                    ),
                    map((response: PersistResponse) => {
                        // save basket in storage
                        this.setBasketInStorage(
                            response.basket as unknown as SerializedBasket,
                        );
                        return BasketActions.persistBasketSuccess({
                            basket: response.basket as unknown as SerializedBasket,
                        });
                    }),
                    // when response is not OK 200, create error with ecom technical error code
                    catchError(() =>
                        of(
                            BasketActions.persistBasketFailure({
                                error: {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    internal_code:
                                        EcomErrorCodes.TECHNICAL_ERROR,
                                },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    public setCartItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.setCartItem),
            switchMap((payload) =>
                this.cartService
                    .setCartItemProduct(
                        payload.sku,
                        payload.qty,
                        payload.customOptions,
                    )
                    .pipe(
                        map((response: SetCartItemResponse) => {
                            // If ecp respond with basket object : remove from cart is OK
                            if (response.basket && !response.error) {
                                // save basket in storage
                                this.setBasketInStorage(
                                    response.basket as unknown as SerializedBasket,
                                );
                                return BasketActions.setCartItemSuccess({
                                    basket: response.basket as unknown as SerializedBasket,
                                });
                            }

                            // In some case, ecp can respond OK 200 with a technical error stack, so create an unknown error
                            const error: EcomError = {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                internal_code: EcomErrorCodes.UNKNOWN_ERROR,
                            };

                            if (response.error) {
                                const errorItem =
                                    new JsonConvert().deserializeObject(
                                        response.error,
                                        ErrorItem,
                                    );
                                error.internal_code =
                                    errorItem.internalCode as EcomErrorCodes;
                                error["sku"] = errorItem.item;
                                if (
                                    error.internal_code ===
                                    EcomErrorCodes.DELETED_ITEM
                                ) {
                                    return BasketActions.removeFromCartSuccess({
                                        basket: response.basket as unknown as SerializedBasket,
                                    });
                                }
                            }

                            return BasketActions.setCartItemFailure({
                                error,
                            });
                        }),
                        // when response is not OK 200, create error with ecom technical error code
                        catchError(() =>
                            of(
                                BasketActions.setCartItemFailure({
                                    error: {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        internal_code:
                                            EcomErrorCodes.TECHNICAL_ERROR,
                                    },
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    public clearSessionData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BasketActions.clearData, BasketActions.clearSessionData),
            switchMap(() => {
                this.basketService.clearSessionBasket();
                return [clearSessionDataSuccess()];
            }),
        ),
    );

    constructor(
        private actions$: Actions,
        private cartService: CartService,
        private storageService: StorageService,
        private store: Store<State>,
        private basketService: BasketService,
    ) {}

    /**
     * Save user.basket in session storage
     *
     * @param : the serialized basket to save
     */
    private setBasketInStorage(basket: SerializedBasket): void {
        const instance = this.storageService.getSessionStorageInstance();
        const user = instance?.getItem<{ basket: SerializedBasket }>(
            "user",
        ) || {
            basket: undefined,
        };
        user.basket = basket;
        instance?.setItem("user", user);
    }
}
