import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

import { LOCALE, StorageService } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

export const SESSION_STORAGE_GRID_URL_KEY = "gridUrl";

/**
 * Manages a grid URL for back-button.
 */
@Injectable()
export class BackToGridService {
    constructor(
        private location: Location,
        private storageService: StorageService,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    /**
     * Store URL of current grid in SessionStorage
     *
     * @param url string URL of current grid
     */
    public storeGridUrl(url: string): void {
        this.storageService.setSessionStorageItem(
            SESSION_STORAGE_GRID_URL_KEY,
            // Might not be internal redirect if SPA routing is disable on back to grid button
            this.location.prepareExternalUrl(url),
        );
    }

    /**
     * Get URL of previous grid from sessionStorage
     * If cache have been emptied before clicking on the button we reload the page
     */
    public getUrlPreviousGrid(): string {
        const gridUrl: string | undefined =
            this.storageService.getSessionStorageItem(
                SESSION_STORAGE_GRID_URL_KEY,
            );
        return (
            gridUrl ??
            this.location.prepareExternalUrl(this.location.path(true))
        );
    }

    public hasGridUrlKey(): boolean {
        let isValid: boolean = false;
        const gridUrl: string | undefined =
            this.storageService.getSessionStorageItem(
                SESSION_STORAGE_GRID_URL_KEY,
            );
        if (gridUrl) {
            const localeRegExp = new RegExp(`^${this.locale.urlPrefix}`);
            const isSameLocale = localeRegExp.test(gridUrl);
            if (!isSameLocale) {
                this.storageService.removeSessionStorageItem(
                    SESSION_STORAGE_GRID_URL_KEY,
                );
            }
            isValid = isSameLocale;
        }
        return isValid;
    }
}
