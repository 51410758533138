<div [@logoAnimation]="showLogo">
    <h-logo (click)="onLogoClick()"></h-logo>
</div>
<div class="header-bar-icons">
    <div class="menu">
        <nav
            [attr.role]="'navigation'"
            [attr.data-testid]="'header-menu-navigation'"
            i18n-aria-label="@@hermes.accessibility.navigation_menu"
            aria-label="Navigation menu">
            <button
                [attr.aria-expanded]="'false'"
                [attr.aria-haspopup]="'dialog'"
                [attr.aria-controls]="'tray-nav-shop'"
                id="collection-button"
                class="button button-icon button-not-black"
                (click)="sendTopNavigationEvent('MenuBurger')"
                [hTrayOpener]="'menu-tray'"
                [trayPosition]="'left'"
                [categoryInstitutional]="true"
                [trayOverlay]="true"
                aria-label="Menu"
                i18n-aria-label="@@block--hermes-commerce--nav.shop"
                [trayTitle]="'hermes-categories-tree.shop'">
                <h-svg-icon [name]="'bars'" class="icon"></h-svg-icon>
                <span class="icon-label" aria-hidden="true" i18n="@@block--hermes-commerce--nav.shop">Menu</span>
            </button>
        </nav>
    </div>

    <div class="search-bar" [attr.data-testid]="'header-search-bar'">
        <h-header-search-bar (searchExpanded)="toggleLogo($event)"></h-header-search-bar>
    </div>

    <nav
        aria-label="account and cart"
        i18n-aria-label="@@hermes.accessibility.navigation_menu_account_and_cart"
        role="navigation">
        <ul class="header-bar-icons">
            <li class="account">
                <button
                    *ngIf="(isLoggedIn$ | async) === true && (isAccountPageAngularEnabled$ | async) === false"
                    [hTrayOpener]="'tray-account'"
                    [trayOverlay]="true"
                    [isDrupalTray]="true"
                    class="button button-icon button-not-black"
                    id="account-button"
                    [attr.aria-expanded]="'false'"
                    [attr.aria-haspopup]="'dialog'"
                    [attr.aria-controls]="'tray-account'"
                    [attr.data-testid]="'header-account-button'"
                    (click)="sendAccountEvent()">
                    <h-svg-icon class="icon-logged" [name]="'person-with-dot'"></h-svg-icon>
                    <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.user-account">Account</span>
                    <span hSrOnlyNoSnippet>, </span>
                    <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.connected">Connected</span>
                    <span class="icon-label" aria-hidden="true" i18n="@@block--hermes-commerce--nav.user-account"
                        >Account</span
                    >
                </button>
                <a
                    *ngIf="(isAccountPageAngularEnabled$ | async) === true && (isLoggedIn$ | async) === true"
                    href="{{ baseHref + accountUrl }}"
                    class="button button-icon button-not-black"
                    id="account-link"
                    (click)="goToPage($event, accountUrl)">
                    <h-svg-icon [name]="'person-with-dot'" class="icon-logged"></h-svg-icon>
                    <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.user-account">Account</span>
                    <span hSrOnlyNoSnippet>, </span>
                    <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.connected">Connected</span>
                    <span class="icon-label" aria-hidden="true" i18n="@@block--hermes-commerce--nav.user-account"
                        >Account</span
                    >
                </a>
                <a
                    *ngIf="(isLoggedIn$ | async) === false"
                    href="{{ baseHref + loginUrl }}"
                    class="button button-icon button-not-black"
                    id="account-link"
                    (click)="goToPage($event, loginUrl)">
                    <h-svg-icon [name]="'person'" class="icon-logged"></h-svg-icon>
                    <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.user-account">Account</span>
                    <span hSrOnlyNoSnippet>, </span>
                    <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.disconnected">Offline</span>
                    <span class="icon-label" aria-hidden="true" i18n="@@block--hermes-commerce--nav.user-account"
                        >Account</span
                    >
                </a>
            </li>
            <li class="cart">
                <button
                    [attr.data-testid]="'header-cart-button'"
                    (click)="goToCartAndSendEvent('ShoppingCart')"
                    (keypress.enter)="goToCart()"
                    class="button button-icon button-not-black"
                    id="open-cart">
                    <div class="cart-icon">
                        <ng-container *ngIf="totalItems$ | async; then filledCart; else cart"></ng-container>
                        <div *ngIf="totalItems$ | async" class="items-counter" aria-hidden="true"
                            ><span>{{ totalItems$ | async }}</span></div
                        >
                    </div>
                    <span class="icon-label" aria-hidden="true" i18n="@@block--hermes-commerce--nav.cart">Cart</span>
                    <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.cart">Cart</span>
                    <span hSrOnlyNoSnippet>, </span>
                    <span *ngIf="totalItems$ | async" hSrOnlyNoSnippet>{{ totalItems$ | async }}</span>
                    <span hSrOnlyNoSnippet id="accessibility-header-cart-count">{{
                        basketFacade.cart$ | async | cartLabel
                    }}</span>
                    <ng-template #cart><h-svg-icon [name]="'cart'" class="icon-cart"></h-svg-icon></ng-template>
                    <ng-template #filledCart
                        ><h-svg-icon [name]="'filled-cart'" class="icon-cart"></h-svg-icon
                    ></ng-template>
                </button>
            </li>
        </ul>
    </nav>
</div>
