import { BreakpointState } from "@angular/cdk/layout";
import {
    Component,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";

import { Context, WINDOW } from "@hermes/app-core";
import { FooterColumnState } from "@hermes/states/shell";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

@Component({
    selector: "h-footer-columns",
    templateUrl: "./footer-columns.component.html",
    styleUrls: ["./footer-columns.component.scss"],
})
export class FooterColumnsComponent implements OnInit, OnDestroy {
    @Input()
    public columns!: FooterColumnState[];

    private viewportWidth!: number;
    private isMobile: boolean = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private context: Context,
        private breakpointService: BreakpointService,
        @Inject(WINDOW) private window: Window,
    ) {}

    /**
     * Close all columns on resize to avoid edge cases :
     * - all columns opened when shrinking to mobile resolution
     * - only one column opened when expanding to tablet/desktop resolution
     *
     * Some devices (mainly Iphone) could trigger the event without having an effective change of the viewport width.
     * When the viewport width remains the same, we do not close columns.
     */
    @HostListener("window:resize")
    public onResize(): void {
        if (this.window.innerWidth !== this.viewportWidth) {
            this.viewportWidth = this.window.innerWidth;

            this.closeAllColumns();
        }
    }

    public ngOnInit(): void {
        if (this.context.isInBrowserMode()) {
            this.subscription.add(
                this.breakpointService
                    .mediumBreakpointObserver()
                    .subscribe((value: BreakpointState) => {
                        this.isMobile = !value.matches;
                    }),
            );
        }

        this.viewportWidth = this.window.innerWidth;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Toggle footer columns accordions.
     * Due to interaction between columns, this component manage open/close state of columns.
     *
     * Mobile behavior (maximum one column opened) :
     * - Opening a column will close the others
     * - Closing a column has no side effects
     *
     * Tablet/Deskop behavior (toggle all) :
     * - Opening a column will open all columns at once
     * - Clossing a column will close all columns at once
     *
     * @param column column to toggle
     */
    public handleToggleColumn(column: FooterColumnState): void {
        if (this.isMobile) {
            const shouldOpenColumn = !column.opened;

            if (shouldOpenColumn) {
                this.closeAllColumns();
            }
            this.toggleColumn(column);
        } else {
            this.toggleAllColumns();
        }
    }

    private toggleAllColumns(): void {
        this.columns.forEach((column) => this.toggleColumn(column));
    }

    private toggleColumn(column: FooterColumnState) {
        column.opened = !column.opened;
    }

    private closeAllColumns(): void {
        this.columns.forEach((column) => (column.opened = false));
    }
}
