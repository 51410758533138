const TYPE = "@type";
const CONTEXT = "@context";

interface Structure {
    [TYPE]: string;
    name: string;
}

interface Offer {
    [TYPE]: string;
    priceCurrency?: string;
    price: string;
    availability: string;
    seller: Structure;
}

export interface SimilarProduct {
    [TYPE]: string;
    name: string;
    url: string;
}

export class ProductMicroDatas {
    public [CONTEXT]: string = "http://schema.org/";
    public [TYPE]: string = "Product";
    public name?: string;
    public image: string[] = [];
    public description?: string;
    public mpn?: string;
    public sku?: string;
    public url?: string;
    public brand: Structure = {
        [TYPE]: "Brand",
        name: "Hermès",
    };
    public offers: Offer = {
        [TYPE]: "Offer",
        availability: "",
        price: "",
        priceCurrency: "",
        seller: {
            [TYPE]: "Organization",
            name: "Hermès",
        },
    };
    public isSimilarTo: SimilarProduct[] = [];
}
