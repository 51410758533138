import { NONE } from "@hermes/aphrodite/dynamic-box";
import {
    BoxType,
    Product,
    ProductColorVariant,
    ProductVolumeVariant,
} from "@hermes/api-model-product";

import { hasColorVariant, hasVolumeVariant } from "@hermes/utils/helpers";

import { ProductPageState } from "../../reducers/product-page.state";

/**
 * On fetch of simple product :
 * - calculate wich variant is selected by default for color and volume
 * - calculate if product is OOS disable button and wich warning to display
 */
export const fetchSimpleProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => {
    // calculate selected variants
    const selectedColor: ProductColorVariant | undefined = hasColorVariant(
        product,
    )
        ? product.variants?.colors.find(
              (variant) =>
                  variant.refColoris === product.simpleAttributes?.refColoris,
          )
        : undefined;

    const selectedVolume: ProductVolumeVariant | undefined = hasVolumeVariant(
        product,
    )
        ? product.variants?.volumes.find(
              (variant) => variant.volume === product.massVolume,
          )
        : undefined;

    const variantSelectedBoxType: BoxType =
        (state.selectedVariants?.[0]?.color?.boxType ||
            state.selectedVariants?.[0]?.size?.boxType ||
            state.selectedVariants?.[0]?.strap?.boxType ||
            state.selectedVariants?.[0]?.volume?.boxType) ??
        NONE;

    return {
        ...state,
        productSKU: product.sku,
        currentProduct: product,
        type: product.templateType,
        selectedVariants: [
            {
                color: selectedColor,
                volume: selectedVolume,
                // no size selected by default
                size: undefined,
            },
        ],
        isLoading: false,
        variantSelectedBoxType,
    };
};
