import { MenuEntry, MenuEntryType } from "@hermes/api-model-shell";

import { CategoryEntry } from "../model/menu.model";

/**
 * Flatten data from menu entries (in tree) into a key-value object
 * - key : PIM Code
 * - value : CategoryEntry
 *
 * TRIVIA :
 * Since menu entries are in a tree structure, this function goes
 * through entries recursively (using depth first search)
 *
 * @param menuEntries menu entries in a tree structure
 * @param categories Already flatten categories, starts with empty object
 * @returns Flattened categories
 */
export const flattenMenuToCategories = (
    menuEntries: MenuEntry[],
    categories: Record<string, CategoryEntry> = {},
): Record<string, CategoryEntry> => {
    menuEntries.forEach((menuEntry) => {
        if (menuEntry.type !== MenuEntryType.separatorMenuEntry) {
            // Depth first
            flattenMenuToCategories(menuEntry.menuEntryList, categories);

            if (isCategory(menuEntry) && menuEntry.pimCode) {
                categories[menuEntry.pimCode] = {
                    ...menuEntry,
                    menuEntryList: [], // Flat data, cleanup existing children
                } as CategoryEntry;
            }
        }
    });
    return categories;
};

/**
 * Determine if a menu entry is category entry (having a path to a product grid)
 *
 * @param menuEntry menu entry to check
 * @returns Typecheck : parameter is a category entry
 */
export const isCategory = (menuEntry: MenuEntry): menuEntry is CategoryEntry =>
    menuEntry.type === MenuEntryType.gridMenuEntry ||
    menuEntry.type === MenuEntryType.landitoMenuEntry;

/**
 * Get path to product grid from a category entry
 */
export const getGridPath = (category: CategoryEntry): string => {
    if (category.type === MenuEntryType.landitoMenuEntry) {
        return category.gridPath;
    }
    return category.path;
};
