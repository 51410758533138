import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { LoaderSpinnerModule } from "@hermes/aphrodite/loader-spinner";

import { CallToActionComponent } from "./components/call-to-action.component";

@NgModule({
    imports: [CommonModule, LoaderSpinnerModule],
    declarations: [CallToActionComponent],
    exports: [CallToActionComponent],
})
export class ButtonModule {}
