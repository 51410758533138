import { createAction, props } from "@ngrx/store";

import { Library } from "../reducer/external-library.state";

export type LoadingExternalScriptAction = Omit<Library, "status"> & {
    key: string;
};

export const load = createAction(
    "[ExternalLibrary] Loading an external script",
    props<LoadingExternalScriptAction>(),
);

export const addScriptOnServerSide = createAction(
    "[ExternalLibrary] Prepare server-side scripts",
    props<LoadingExternalScriptAction>(),
);

export const addScriptOnServerSideSuccess = createAction(
    "[ExternalLibrary] Successful preparation of server-side scripts",
    props<{ key: string }>(),
);

export const addScriptOnClientSide = createAction(
    "[ExternalLibrary] Loading client-side scripts",
    props<LoadingExternalScriptAction>(),
);

export const addScriptOnClientSideSuccess = createAction(
    "[ExternalLibrary] Successful loading of client-side scripts",
    props<{ key: string }>(),
);

export const addScriptOnClientSideError = createAction(
    "[ExternalLibrary] Error when loading client-side scripts",
    props<{ key: string; message: string }>(),
);
