import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormGroup,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { scrollIntoView } from "seamless-scroll-polyfill";

const PREFIXERROR = $localize`:@@hermes-global-translations.error-on-form:Error on form -`;

@Injectable()
export class FormService {
    private registeredControls = new Map<unknown, Set<AbstractControl>>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
    ) {}

    public handleTitleOnError(form: UntypedFormGroup): void {
        if (form && form.touched && !form.valid) {
            this.prefixTitleOnError();
        } else {
            this.resetTitle();
        }
    }

    public prefixTitleOnError(): void {
        const currentTitle = this.titleService.getTitle();
        if (!currentTitle.includes(PREFIXERROR)) {
            this.titleService.setTitle(`${PREFIXERROR} ${currentTitle}`);
        }
    }

    public resetTitle(): void {
        const currentTitle = this.titleService.getTitle();
        this.titleService.setTitle(currentTitle.replace(`${PREFIXERROR} `, ""));
    }

    public focusOnError(
        parentSelector?: string,
        scrollParameters?: ScrollIntoViewOptions,
    ): void {
        const invalid = this.document.querySelectorAll(
            [
                `${parentSelector} textarea.ng-invalid`,
                `${parentSelector} input.ng-invalid`,
                `${parentSelector} input.adyen-checkout__input--invalid`,
                `${parentSelector} span.adyen-checkout__input--error iframe`,
                `${parentSelector} input.name-card-holder-invalid`,
                `${parentSelector} div.frame--invalid iframe`,
                `${parentSelector} h-select-input select.ng-invalid`,
                `${parentSelector} h-select-input [aria-invalid="true"]`,
                `${parentSelector} .radio-checked h-checkbox.ng-invalid`,
            ].join(","),
        );

        // eslint-disable-next-line unicorn/prefer-spread
        this.scrollInViewAndFocus(Array.from(invalid), scrollParameters);
    }

    public scrollInViewAndFocus(
        invalidElements: Element[],
        scrollParameters?: ScrollIntoViewOptions,
    ): void {
        if (invalidElements && invalidElements.length > 0) {
            const firstNotDisabledElement =
                this.getFirstNotDisabledElement(invalidElements);
            scrollIntoView(
                invalidElements[0],
                scrollParameters ?? { block: "center", behavior: "smooth" },
            );
            if (firstNotDisabledElement) {
                (firstNotDisabledElement as HTMLElement).focus();
            }
        }
    }

    /**
     * Return first element not having disabled
     */
    public getFirstNotDisabledElement(
        elements: Element[],
    ): Element | undefined {
        return elements.find((element) => !element.hasAttribute("disabled"));
    }
    // Mark everything as Touched, necessary to display errors messages
    public markAllAsTouched(input: AbstractControl): void {
        input.markAsTouched({ onlySelf: true });

        if (
            input instanceof UntypedFormArray ||
            input instanceof UntypedFormGroup
        ) {
            for (const control of Object.values(input.controls)) {
                this.markAllAsTouched(control);
            }
        }
    }

    /**
     * Stores a reference to an AbstractControl for it to be marked as touched when markRegisteredControlsAsTouched is called
     * When deleting the form (OnDestroy), do not forget to call unregisterLinkedControls
     *
     * @param component the containing component reference
     * @param control the control
     */
    public registerControl(component: unknown, control: AbstractControl): void {
        if (!this.registeredControls.has(component)) {
            this.registeredControls.set(component, new Set());
        }
        this.registeredControls.get(component)?.add(control);
    }

    /**
     * Removes all the registered AbstractControl for the passed form
     * This needs to be called in OnDestroy
     */
    public unregisterLinkedControls(component: unknown): void {
        this.registeredControls.delete(component);
    }

    /**
     * Marks all the registered components as touched
     */
    public markRegisteredControlsAsTouched(): void {
        for (const controlSet of this.registeredControls.values()) {
            for (const control of controlSet) {
                this.markAllAsTouched(control);
            }
        }
    }
}
