import {
    AfterViewChecked,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";

@Component({
    selector: "h-notification-message",
    templateUrl: "./notification-message.component.html",
    styleUrls: ["./notification-message.component.scss"],
})
export class NotificationMessageComponent implements AfterViewChecked {
    @Input()
    public notificationMessage!: string;
    @ViewChild("messageText")
    public contentElement!: ElementRef<HTMLDivElement>;

    @ViewChild("buttonShowMore")
    public buttonElement!: ElementRef;

    public expanded: boolean = false;
    public arrayLinksVisibility: boolean[] = [];

    public clickSeeMore(): void {
        this.expanded = true;
        this.buttonElement.nativeElement.style.display = "none";
        this.contentElement.nativeElement.style.paddingBottom = "10px";
        this.contentElement.nativeElement.focus();
        this.makeAllLinksAccessible();
    }

    public showSeeMoreButton(): void {
        const hasOverflow =
            this.contentElement.nativeElement.scrollHeight >
            this.contentElement.nativeElement.offsetHeight;

        if (hasOverflow) {
            this.buttonElement.nativeElement.style.visibility = "inherit";
            this.buttonElement.nativeElement.style.display = "inherit";
            this.createArrayOfVisibleLinks();
            this.makeInvisibleLinksInaccessible();
        } else {
            this.buttonElement.nativeElement.style.display = "none";
            this.makeAllLinksAccessible();
        }
    }

    public ngAfterViewChecked(): void {
        this.showSeeMoreButton();
    }

    /**
     * Create array of boolean that show if link is visible or not.
     */
    public createArrayOfVisibleLinks(): void {
        this.arrayLinksVisibility = Array.from(
            this.contentElement.nativeElement.querySelectorAll<HTMLAnchorElement>(
                "a",
            ),
        ).map((link) =>
            this.isVisible(link, this.contentElement.nativeElement),
        );
    }

    /**
     * Set tabindex -1 to links to be not focusable by tabulation when the text is not expanded.
     */
    public makeInvisibleLinksInaccessible(): void {
        Array.from(
            this.contentElement.nativeElement.querySelectorAll<HTMLAnchorElement>(
                "a",
            ),
        ).forEach((link, index) => {
            link.setAttribute(
                "tabindex",
                this.arrayLinksVisibility[index] ? "0" : "-1",
            );
        });
    }

    /**
     * Set tabindex 0 to all links when text is expanded.
     */
    public makeAllLinksAccessible(): void {
        Array.from(
            this.contentElement.nativeElement.querySelectorAll<HTMLAnchorElement>(
                "a",
            ),
        ).forEach((link) => link.setAttribute("tabindex", "0"));
    }

    /**
     * Return a boolean, this function check if link is visible or not.
     */
    public isVisible(element: HTMLElement, container: HTMLElement): boolean {
        const linkBottomPosition = element.offsetTop + element.offsetHeight;
        const containerBottomPosition =
            container.offsetTop + container.offsetHeight;

        // The link is fully visible in the container
        return linkBottomPosition < containerBottomPosition;
    }
}
