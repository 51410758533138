import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";

import { RouterEffects } from "./effects/router.effects";
import { routerReducer, routerFeatureKey } from "./reducers/router.reducers";
import { ApolloRouterStateSerializer } from "./serializer/router-state-serializer.class";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(routerFeatureKey, routerReducer),
        EffectsModule.forFeature([RouterEffects]),
        StoreRouterConnectingModule.forRoot({
            serializer: ApolloRouterStateSerializer,
        }),
    ],
})
export class RouterStateModule {}
