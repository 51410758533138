import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { FeatureFlagResponse } from "@hermes/api-model-feature-flag";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

import {
    FeatureFlagDefinition,
    FeatureFlagName,
} from "../constants/feature-flags.constant";
import { FeatureFlagFacade } from "../facades/feature-flag.facade";
import { FeatureFlags } from "../reducers/feature-flag.state";

@Injectable()
export class FeatureFlagService {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private http: HttpClient,
        private settings: Settings,
        private featureFlagFacade: FeatureFlagFacade,
    ) {}

    /**
     * retrieve a list of values for given feature flags.
     */
    public featureFlagCall(
        flags: FeatureFlagDefinition[],
    ): Observable<FeatureFlags> {
        // construct final request
        let params: HttpParams = new HttpParams().set(
            "locale",
            this.locale.code,
        );
        flags.forEach((flag) => {
            params = params.append("features", `${flag.flagName}`);
            params = params.append("values", `${flag.defaultValue}`);
        });
        return this.http
            .get(`${this.settings.apiUrl}/feature-flag`, {
                params,
            })
            .pipe(
                map((response: unknown) =>
                    new JsonConvert().deserializeObject(
                        response,
                        FeatureFlagResponse,
                    ),
                ),
                map((featureFlagResponse) => {
                    const featureFlagValues: Partial<FeatureFlags> = {};
                    featureFlagResponse.featureFlags.forEach(
                        ({ id, value }) => {
                            featureFlagValues[id as FeatureFlagName] =
                                value as boolean;
                        },
                    );
                    return featureFlagValues as FeatureFlags;
                }),

                catchError((error) => {
                    throw new Error(
                        `Feature flag resolver error: ${error.message.toString()}`,
                    );
                }),
            );
    }

    /**
     * @deprecated The service will no longer be shared with features.
     * It should only be used to retrieve the list of flags for the application.
     *
     * Please use the dedicated NgRx facade: FeatureFlagFacade.
     *
     * Non-neglectable note: the Observable can no longer be `undefined`. It is always a boolean (true or false)
     */
    public getFeatureFlagValue(flagName: FeatureFlagName): Observable<boolean> {
        return this.featureFlagFacade.isActivated(flagName);
    }
}
