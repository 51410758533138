import { Action, createReducer, on } from "@ngrx/store";

import {
    updateBlock,
    updateCollapsedToggles,
    updateConfiguration,
    resetPersonalizationState,
    updateKeyboardTap,
    updateLoadingProgress,
    updateParentProduct,
    updateUrlParsedParameters,
    updateFirstProduct,
    updateSecondProduct,
    updateIsDacProductMissing,
    updateProductCategory,
    updateConfigurator,
    updateSeveralConfiguration,
    updateEngravingConfiguration,
    fetchPlatformeConfigurationSuccess,
    updateIsCallingPlatforme,
    updateSmallLeatherGoodsLeathers,
    updatePlateformeExtraInformation,
    updateCurrentUrl,
    updateDku,
    updateParsedUrlSkus,
    updateIsNoBuckleSelected,
} from "../actions/product-personalization.actions";
import { LOADED } from "../constant/product-personalization.constant";
import { ConfigPart } from "../types/product-personalization.type";

import {
    initialState,
    ProductPersonalizationState,
} from "./product-personalization.state";

export const personalizationFeatureKey = "personalization";

export const personalizationPageReducer = (
    state: ProductPersonalizationState = initialState,
    action: Action,
): ProductPersonalizationState => productPageEditReducer(state, action);

export const updateConfigurationReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateConfiguration>,
): ProductPersonalizationState => ({
    ...state,
    configuration: {
        ...state.configuration,
        [action.configuration.id]: action.configuration,
    },
});

export const updateSeveralConfigurationReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateSeveralConfiguration>,
): ProductPersonalizationState => {
    let configuration = { ...state.configuration };

    action.configurations.forEach(
        (configPart: ConfigPart) =>
            (configuration = {
                ...configuration,
                [configPart.id]: configPart,
            }),
    );

    return {
        ...state,
        configuration,
    };
};

export const updateProductCategoryReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateProductCategory>,
): ProductPersonalizationState => ({
    ...state,
    productCategory: action.productCategory,
});

export const updateCollapsedTogglesReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateCollapsedToggles>,
): ProductPersonalizationState => ({
    ...state,
    collapsedToggles: {
        ...initialState.collapsedToggles,
        [action.collapsedToggle]: false,
    },
});

export const updateBlockReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateBlock>,
): ProductPersonalizationState => ({
    ...state,
    block: action.block,
});

export const updateKeyboardTapReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateKeyboardTap>,
): ProductPersonalizationState => ({
    ...state,
    keyboardTap: action.keyboardTap,
});

export const resetPersonalizationStateReducer = (
    _state: ProductPersonalizationState,
    _action: ReturnType<typeof resetPersonalizationState>,
): ProductPersonalizationState => initialState;

export const updateConfiguratorLoadingStateReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateLoadingProgress>,
): ProductPersonalizationState => ({
    ...state,
    loadingProgress: action.loadingProgress,
});

export const updateParentProductReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateParentProduct>,
): ProductPersonalizationState => ({
    ...state,
    parentProduct: action.parentProduct,
});

export const updateFirstProductReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateFirstProduct>,
): ProductPersonalizationState => ({
    ...state,
    firstProduct: action.firstProduct,
});

export const updateSecondProductReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateSecondProduct>,
): ProductPersonalizationState => ({
    ...state,
    secondProduct: action.secondProduct,
});

export const updateCurrentUrlReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateCurrentUrl>,
): ProductPersonalizationState => ({
    ...state,
    urlParsedParameters: {
        ...state.urlParsedParameters,
        currentUrl: action.currentUrl,
    },
});

export const updateDkuReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateDku>,
): ProductPersonalizationState => ({
    ...state,
    urlParsedParameters: {
        ...state.urlParsedParameters,
        productDku: action.dku,
    },
});

export const updateParsedUrlSkusReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateParsedUrlSkus>,
): ProductPersonalizationState => ({
    ...state,
    urlParsedParameters: {
        ...state.urlParsedParameters,
        firstSku: action.firstSku,
        secondSku: action.secondSku,
    },
});

export const updateUrlParsedParametersReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateUrlParsedParameters>,
): ProductPersonalizationState => ({
    ...state,
    urlParsedParameters: action.urlParsedParameters,
});

export const updateIsDacProductMissingReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateIsDacProductMissing>,
): ProductPersonalizationState => ({
    ...state,
    isDacProductMissing: action.isDacProductMissing,
});

export const updateIsCallingPlatformeReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateIsCallingPlatforme>,
): ProductPersonalizationState => ({
    ...state,
    isCallingPlatforme: action.isCallingPlatforme,
});

export const updateConfiguratorReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateConfigurator>,
): ProductPersonalizationState => ({
    ...state,
    configurator: action.configurator,
    loadingProgress: LOADED,
});

export const updateEngravingConfigurationReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateEngravingConfiguration>,
): ProductPersonalizationState => ({
    ...state,
    engravingConfiguration: action.engravingConfiguration,
});

export const updateSmallLeatherGoodsLeathersReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updateSmallLeatherGoodsLeathers>,
): ProductPersonalizationState => ({
    ...state,
    smallLeatherGoodsLeathers: action.smallLeatherGoodsLeathers,
});

export const fetchPlatformeConfigurationSuccessReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof fetchPlatformeConfigurationSuccess>,
): ProductPersonalizationState => ({
    ...state,
    platformeInitialConfiguration: action.platformeConfiguration,
});

export const updatePlateformeExtraInformationReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updatePlateformeExtraInformation>,
): ProductPersonalizationState => ({
    ...state,
    translations: action.translations,
    colorsHexaCodes: action.colorsHexaCodes,
});

export const updatIsNoBuckleSelectedReducer = (
    state: ProductPersonalizationState,
    action: ReturnType<typeof updatIsNoBuckleSelectedReducer>,
): ProductPersonalizationState => ({
    ...state,
    isNoBuckleSelected: action.isNoBuckleSelected,
});

export const productPageEditReducer = createReducer(
    initialState,

    on(updateBlock, updateBlockReducer),
    on(updateCollapsedToggles, updateCollapsedTogglesReducer),
    on(updateConfiguration, updateConfigurationReducer),
    on(updateSeveralConfiguration, updateSeveralConfigurationReducer),
    on(updateFirstProduct, updateFirstProductReducer),
    on(updateIsDacProductMissing, updateIsDacProductMissingReducer),
    on(updateIsCallingPlatforme, updateIsCallingPlatformeReducer),
    on(updateKeyboardTap, updateKeyboardTapReducer),
    on(updateLoadingProgress, updateConfiguratorLoadingStateReducer),
    on(updateParentProduct, updateParentProductReducer),
    on(updateSecondProduct, updateSecondProductReducer),
    on(updateCurrentUrl, updateCurrentUrlReducer),
    on(updateDku, updateDkuReducer),
    on(updateParsedUrlSkus, updateParsedUrlSkusReducer),
    on(updateUrlParsedParameters, updateUrlParsedParametersReducer),
    on(updateProductCategory, updateProductCategoryReducer),
    on(updateConfigurator, updateConfiguratorReducer),
    on(updateEngravingConfiguration, updateEngravingConfigurationReducer),
    on(updateSmallLeatherGoodsLeathers, updateSmallLeatherGoodsLeathersReducer),
    on(updateIsNoBuckleSelected, updatIsNoBuckleSelectedReducer),
    on(
        updatePlateformeExtraInformation,
        updatePlateformeExtraInformationReducer,
    ),
    on(
        fetchPlatformeConfigurationSuccess,
        fetchPlatformeConfigurationSuccessReducer,
    ),

    on(resetPersonalizationState, resetPersonalizationStateReducer),
);
