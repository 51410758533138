import { Component, ElementRef, ViewChild } from "@angular/core";

import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

@Component({
    selector: "h-back-to-home-button",
    templateUrl: "./back-to-home-button.component.html",
    styleUrls: ["./back-to-home-button.component.scss"],
})
export class BackToHomeButtonComponent {
    @ViewChild("backButton")
    public backButton: ElementRef | undefined;

    constructor(private urlUtils: UrlUtils) {}

    public goBackToHome(): void {
        this.urlUtils.redirectToRootPath();
    }

    public focus(): void {
        if (this.backButton && this.backButton.nativeElement) {
            this.backButton.nativeElement.focus();
        }
    }
}
