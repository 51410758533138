import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";

import { CurrencyPipe as HermesCurrencyPipe } from "./currency.pipe";
import { CurrencyService } from "./currency.service";

@NgModule({
    imports: [CommonModule],
    declarations: [HermesCurrencyPipe],
    exports: [HermesCurrencyPipe],
    providers: [CurrencyService, CurrencyPipe, HermesCurrencyPipe],
})
export class CurrencyModule {}
