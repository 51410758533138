import { A11yModule } from "@angular/cdk/a11y";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { TranslateModule } from "@ngx-translate/core";

import { svgCrossRounded, SvgModule } from "@hermes/aphrodite/svg";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

import { CategoryInstitutionalComponent } from "./components/category-institutional/category-institutional.component";
import { TrayContainerComponent } from "./components/tray-container/tray-container.component";
import { TrayContentComponent } from "./components/tray-content/tray-content.component";
import { TrayOverlayComponent } from "./components/tray-overlay/tray-overlay.component";
import { TrayOverlayMapComponent } from "./components/tray-overlay-map/tray-overlay-map.component";
import { TrayTitleComponent } from "./components/tray-title/tray-title.component";
import { TrayOpenerDirective } from "./directives/tray-opener.directive";
import { TrayZoneDirective } from "./directives/tray-zone.directive";
import { TrayEffects } from "./effects/tray.effects";
import { TrayFacade } from "./facades/tray.facade";
import { reducer, trayFeatureKey } from "./reducers/tray.reducer";
import { MapPortalService } from "./services/map-portal.service";
import { TrayLoaderService } from "./services/tray-loader.service";
import { TrayStackService } from "./services/tray-stack.service";

@NgModule({
    imports: [
        A11yModule,
        CommonModule,
        EffectsModule.forFeature([TrayEffects]),
        PortalModule,
        StoreModule.forFeature(trayFeatureKey, reducer),
        SvgModule.forChild([svgCrossRounded]),
        ReactiveFormsModule,
        TranslateModule,
        SrOnlyNoSnippetDirective,
    ],
    declarations: [
        CategoryInstitutionalComponent,
        TrayContainerComponent,
        TrayContentComponent,
        TrayTitleComponent,
        TrayOverlayComponent,
        TrayOpenerDirective,
        TrayZoneDirective,
        TrayOverlayMapComponent,
    ],
    exports: [
        TrayOpenerDirective,
        TrayZoneDirective,
        TrayContainerComponent,
        TrayOverlayMapComponent,
    ],
    providers: [TrayFacade, TrayLoaderService, TrayStackService],
})
export class TrayStateModule {
    public static forRoot(): ModuleWithProviders<TrayStateModule> {
        return {
            ngModule: TrayStateModule,
            providers: [MapPortalService],
        };
    }
}
