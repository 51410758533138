import { Component, Inject, OnInit } from "@angular/core";

import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

export const HERMES_LOGO_PATH = "hermes-logo";
@Component({
    selector: "h-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit {
    public homeUrl: string | undefined;

    public hermesLogoPath = `${this.settings.assetsUrlEditoImage}${HERMES_LOGO_PATH}`;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private settings: Settings,
    ) {}

    public ngOnInit(): void {
        this.homeUrl = `${this.locale.urlPrefix}/`;
    }
}
