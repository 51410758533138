import { CommonModule } from "@angular/common";
import { Inject, NgModule, Optional, SkipSelf } from "@angular/core";

import {
    WebVitalsEnvironment,
    WEB_VITALS_ENVIRONMENT,
} from "./web-vitals.environment";
import { WebVitalsService } from "./web-vitals.service";

@NgModule({
    imports: [CommonModule],
    providers: [WebVitalsService],
})
export class WebVitalsModule {
    constructor(
        webVitalsService: WebVitalsService,
        @Inject(WEB_VITALS_ENVIRONMENT)
        webVitalsEnvironment: WebVitalsEnvironment,
        @Optional()
        @SkipSelf()
        parentModule?: WebVitalsModule,
    ) {
        if (parentModule) {
            throw new Error(
                "WebVitalsModule is already loaded. Import it in the AppModule only",
            );
        }
        webVitalsService.init(webVitalsEnvironment);
    }
}
