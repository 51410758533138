import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

/**
 * This class is used for click on category in menu-tray
 */
export class MenuFooterClickEvent implements AnalyticsEvent<GTMEventData> {
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly variation-click event
     */
    public eventData: {
        menuLvl?: string[];
        pimCode?: string;
        label?: string;
        action?: string;
    };

    constructor(data: { [key: string]: string | string[] | undefined }) {
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public formatForGTM(): GTMEventData {
        const label: string | undefined = this.eventData.label;
        const action: string | undefined = this.eventData.action;
        return {
            event: this.name,
            eventcategory: "ShopNavTray",
            eventaction: action,
            eventlabel: label || undefined,
        };
    }
}
