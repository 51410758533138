import { FeatureFlippingEntry } from "@hermes/feature-flipping";

export enum PersoFeatureFlippingKey {
    /* eslint-disable @typescript-eslint/naming-convention */
    pm_key = "smallleathergoods_personalization",
    silk_key = "silk_personalization",
    beltkit_key = "beltkits_personalization",
    /* eslint-enable @typescript-eslint/naming-convention */
}

// Catalog feature flag constants
export const PERFUME_ENGRAVING = "perfume_engraving";
export const MANAGE_HP_FROM_FH = "manage_HP_from_FH";
export const BEAUTY_VIRTUAL_TRY_ON = "beauty_virtual_try_on";
export const GRID_FILTER_REVAMP = "grid_filter_revamp";
export const VIRTUAL_TRY_ON = "virtual_try_on";

// Checkout feature flag constants
export const ENABLE_LIVECHAT = "enable_livechat";
export const ENABLE_SAMPLE_BLOCK_INTO_CART = "enable_sample_block_into_cart";

export const DAC_CHECKOUT_NEW_FLAT_KEY = "dac_checkout_new_flat";

export const ACCOUNT_PAGE_ANGULAR = "account_page_angular";
export const COMMUNICATION_CONSENT_ACTIVATION = "communication_consent";
export const PIPL_CHECKBOX_ACTIVATION = "pipl_checkbox_angular";

export const ENABLE_NOVA_CART = "enable_nova_cart";

// Payment feature flag constants
export const RISKIFIED_BEACON = "riskified_beacon";

export const ANGULAR_SPA_ACTIVATION_ON_PERSO_GENERIC_CONFIGURATOR =
    "angular_spa_activation_on_perso_generic_configurator";

export const ADYEN_PAYPAL_CHECKOUT_IN_CONTEXT =
    "adyen_paypal_checkout_in_context";

// PL/E-resa feature flag constants
export const PRODUCT_LOCATOR_ACTIVATION = "product_locator";
export const ERESERVATION_ACTIVATION = "ereservation";
export const ERESERVATION_LIGHT_ACCOUNT_ACTIVATION =
    "ereservation_light_account_captcha";
export const PRODUCT_LOCATOR_DISPLAY_MAP_ACTIVATION =
    "product_locator_display_map";

// Display Only
export const DISPLAY_ONLY = "product_display_only";
export const BACK_IN_STOCK = "product_back_in_stock";

// Aftersales feature flag constants
export const AFTERSALES_FIND_A_SHOP_ACTIVATION = "aftersales_find_a_store";
export const AFTERSALES_V2 = "aftersales_endpoint_v2";

// Appointment in store flag constants
export const APPOINTMENT_IN_STORE = "appointment_in_store";

export const REMOVE_PRICELESS_INVOICE_CHECKBOX =
    "remove_priceless_invoice_checkbox";

// Content feature flag constants
export const ANGULAR_SPA_ACTIVATION_ON_MENU_STORY_LINK =
    "angular_spa_activation_on_menu_story_link";
export const ANGULAR_DISPLAY_GEO_FILTERING_BANNER =
    "angular_display_geo_filtering_banner";

export const ADDITIONAL_STREET_ADDRESS = "additional_street_address";

export const SEO_COMBINED_FACETS_ACTIVATION_ON_GRID =
    "seo_combined_facets_activation_on_grid";
export const ANGULAR_DISPLAY_LINK_AGEC = "angular_display_link_agec";

export const AGEC_DANGEROUS_SUBSTANCE_DESCRIPTION =
    "agec_dangerous_substance_description";

export const ANGULAR_AGEC_NEW_FORMAT_PACKAGING = "agec_new_format_packaging";

export const FOOTER_NEWSLETTER_REMOVAL = "remove_footer_newsletter_block";

export const ENABLE_MORE_SHIPPING_INFO = "enable_more_shipping_info";
export const LEAD_TIME_FROM_INVENTORY = "lead_time_from_inventory";
export const ENABLE_CFDI_BLOCK_IN_CHECKOUT = "enable_cfdi_block_in_checkout";

export const ENABLE_FRAGRANCE_FINDER_PAGE = "fragrance_finder_page";
export const ENABLE_AGEC_LINK_ARRAY = "enable_agec_link_array";
export const ENABLE_JS_CRAMBLER = "js_crambler";
export const ENABLE_DISCOVERY_SIZE_GUIDE = "size_guide";
export const ENABLE_DISCOVERY_FAQ = "get_faq_from_discovery";

export const GET_LEGAL_FROM_DISCOVERY = "get_legal_pages_from_discovery";

export const ENABLE_TARGET_SIZE_GUIDE_API = "size_guide_api";

export const ERESERVATION_ACCOUNT_ACTIVATION = "ereservation_on_account_page";

export const ENABLE_HUMAN_SECURITY_CODE_DEFENDER =
    "enable_human_security_code_defender";

export const ENABLE_CONTACT_US_FROM_DISCOVERY = "get_contact_us_discovery";

/**
 * add your feature flag "flagName" and "defaultValue" value
 *
 * /!\ this list is used only by when the APP_INIT dispatch FetchFeatureFlags action /!\
 */
export const FEATURE_FLAGS = [
    {
        flagName: PersoFeatureFlippingKey.beltkit_key,
        defaultValue: true,
    },
    {
        flagName: PersoFeatureFlippingKey.pm_key,
        defaultValue: true,
    },
    {
        flagName: PersoFeatureFlippingKey.silk_key,
        defaultValue: true,
    },
    {
        flagName: ANGULAR_SPA_ACTIVATION_ON_PERSO_GENERIC_CONFIGURATOR,
        defaultValue: false,
    },
    {
        flagName: DAC_CHECKOUT_NEW_FLAT_KEY,
        defaultValue: false,
    },
    {
        flagName: PRODUCT_LOCATOR_DISPLAY_MAP_ACTIVATION,
        defaultValue: true,
    },
    {
        flagName: PRODUCT_LOCATOR_ACTIVATION,
        defaultValue: true,
    },
    {
        flagName: ERESERVATION_LIGHT_ACCOUNT_ACTIVATION,
        defaultValue: true,
    },
    {
        flagName: ERESERVATION_ACTIVATION,
        defaultValue: true,
    },
    {
        flagName: ERESERVATION_ACCOUNT_ACTIVATION,
        defaultValue: false,
    },
    {
        flagName: ENABLE_LIVECHAT,
        defaultValue: true,
    },
    {
        flagName: AFTERSALES_FIND_A_SHOP_ACTIVATION,
        defaultValue: false,
    },
    {
        flagName: PIPL_CHECKBOX_ACTIVATION,
        defaultValue: false,
    },
    {
        flagName: APPOINTMENT_IN_STORE,
        defaultValue: false,
    },
    {
        flagName: RISKIFIED_BEACON,
        defaultValue: false,
    },
    {
        flagName: ENABLE_SAMPLE_BLOCK_INTO_CART,
        defaultValue: true,
    },
    {
        flagName: AFTERSALES_V2,
        defaultValue: false,
    },
    {
        flagName: COMMUNICATION_CONSENT_ACTIVATION,
        defaultValue: false,
    },
    {
        flagName: DISPLAY_ONLY,
        defaultValue: false,
    },
    {
        flagName: BACK_IN_STOCK,
        defaultValue: false,
    },
    {
        flagName: REMOVE_PRICELESS_INVOICE_CHECKBOX,
        defaultValue: false,
    },
    {
        flagName: ANGULAR_SPA_ACTIVATION_ON_MENU_STORY_LINK,
        defaultValue: false,
    },
    {
        flagName: ADDITIONAL_STREET_ADDRESS,
        defaultValue: false,
    },
    {
        flagName: ANGULAR_DISPLAY_GEO_FILTERING_BANNER,
        defaultValue: false,
    },
    {
        flagName: SEO_COMBINED_FACETS_ACTIVATION_ON_GRID,
        defaultValue: false,
    },
    {
        flagName: ADYEN_PAYPAL_CHECKOUT_IN_CONTEXT,
        defaultValue: false,
    },
    {
        flagName: ACCOUNT_PAGE_ANGULAR,
        defaultValue: true,
    },
    {
        flagName: PERFUME_ENGRAVING,
        defaultValue: false,
    },
    {
        flagName: ANGULAR_DISPLAY_LINK_AGEC,
        defaultValue: false,
    },
    {
        flagName: AGEC_DANGEROUS_SUBSTANCE_DESCRIPTION,
        defaultValue: false,
    },
    {
        flagName: ANGULAR_AGEC_NEW_FORMAT_PACKAGING,
        defaultValue: false,
    },
    {
        flagName: BEAUTY_VIRTUAL_TRY_ON,
        defaultValue: false,
    },
    {
        flagName: VIRTUAL_TRY_ON,
        defaultValue: false,
    },
    {
        flagName: FOOTER_NEWSLETTER_REMOVAL,
        defaultValue: false,
    },
    {
        flagName: ENABLE_MORE_SHIPPING_INFO,
        defaultValue: false,
    },
    {
        flagName: LEAD_TIME_FROM_INVENTORY,
        defaultValue: false,
    },

    {
        flagName: ENABLE_CFDI_BLOCK_IN_CHECKOUT,
        defaultValue: false,
    },
    {
        flagName: ENABLE_NOVA_CART,
        defaultValue: false,
    },
    {
        flagName: ENABLE_FRAGRANCE_FINDER_PAGE,
        defaultValue: false,
    },
    {
        flagName: ENABLE_AGEC_LINK_ARRAY,
        defaultValue: false,
    },
    {
        flagName: ENABLE_JS_CRAMBLER,
        defaultValue: false,
    },
    {
        flagName: ENABLE_DISCOVERY_SIZE_GUIDE,
        defaultValue: false,
    },
    {
        flagName: GRID_FILTER_REVAMP,
        defaultValue: false,
    },
    {
        flagName: ENABLE_TARGET_SIZE_GUIDE_API,
        defaultValue: false,
    },
    {
        flagName: ENABLE_HUMAN_SECURITY_CODE_DEFENDER,
        defaultValue: false,
    },
    {
        flagName: ENABLE_DISCOVERY_FAQ,
        defaultValue: false,
    },
    {
        flagName: GET_LEGAL_FROM_DISCOVERY,
        defaultValue: false,
    },
    {
        flagName: ENABLE_CONTACT_US_FROM_DISCOVERY,
        defaultValue: false,
    },
] as const;

export type FeatureFlagName = (typeof FEATURE_FLAGS)[number]["flagName"];

export type FeatureFlagDefinition = FeatureFlippingEntry & {
    flagName: FeatureFlagName;
};
