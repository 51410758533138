import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { Observable } from "rxjs";

import {
    Basket,
    PersistRequest,
    PersistResponse,
} from "@hermes/api-model-basket";
import {
    StorageManager,
    LOCALE,
    Settings,
    StorageService,
    Context,
} from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { HTTP_JSON_CONTENT_TYPE_HEADER } from "@hermes/utils-generic/constants";

import { SerializedBasket } from "../models/basket-feature.model";

@Injectable()
export class BasketService {
    private sessionStorage: StorageManager | undefined;

    constructor(
        private context: Context,
        private storageService: StorageService,
        private http: HttpClient,
        private settings: Settings,
        @Inject(LOCALE) private locale: Locale,
    ) {
        this.sessionStorage = this.storageService.getSessionStorageInstance();
    }

    public persist(
        persistRequest: PersistRequest,
    ): Observable<PersistResponse> {
        persistRequest.locale = this.locale.code;
        return this.http.post<PersistResponse>(
            `${this.settings.apiUrl}/basket/persist`,
            new JsonConvert().serializeObject(persistRequest),
            {
                headers: new HttpHeaders({
                    ...HTTP_JSON_CONTENT_TYPE_HEADER,
                }),
            },
        );
    }

    /**
     * Clear Basket in Session Storage
     */
    public clearSessionBasket(): void {
        const userStorage: { basket?: Basket } =
            this.getSessionStorageData("user") || {};
        userStorage.basket = new Basket();
        this.setSessionStorageData("user", userStorage);
        this.setSessionStorageData("total_items", 0);
    }

    /**
     * Update Basket in Session Storage
     */
    public updateSessionBasket(basket: SerializedBasket): void {
        const userStorage: { basket: SerializedBasket } = {
            ...this.getSessionStorageData("user"),
            basket,
        };
        this.setSessionStorageData("user", userStorage);
        this.setSessionStorageData("total_items", basket.total_items);
        this.setSessionStorageData(
            "has_product_threshold",
            basket.has_product_threshold,
        );
    }

    public setSessionStorageData<S>(key: string, data: S): void {
        if (this.context.isInBrowserMode() && this.sessionStorage) {
            this.sessionStorage.setItem<S>(key, data);
        }
    }

    public getSessionStorageData<S>(key: string): S | undefined {
        return this.context.isInBrowserMode() && this.sessionStorage
            ? this.sessionStorage.getItem<S>(key)
            : undefined;
    }
}
