import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";

import { Observable, combineLatest, filter, map, switchMap } from "rxjs";

import { CustomerService } from "@hermes/api-model-editorial";

import { fetchCustomerServiceContacts } from "../actions/customer-service-contact.actions";

import { CustomerServiceContactState } from "../reducers/customer-service-contact.reducer";
import {
    selectIsLoaded,
    selectCustomerServiceContacts,
} from "../selectors/customer-service-contact.selectors";

@Injectable()
export class CustomerServiceContactFacade {
    private readonly customerServiceContactStore = inject(
        Store<CustomerServiceContactState>,
    );

    private customerServiceContacts$ = this.customerServiceContactStore.select(
        selectCustomerServiceContacts,
    );

    private isLoaded$ = this.customerServiceContactStore.select(selectIsLoaded);

    public getCustomerServiceContacts(): Observable<
        CustomerService[] | undefined
    > {
        return this.isLoaded$.pipe(
            switchMap((isCustomerServiceContactsLoaded) => {
                if (isCustomerServiceContactsLoaded) {
                    return this.customerServiceContacts$;
                }
                this.customerServiceContactStore.dispatch(
                    fetchCustomerServiceContacts(),
                );
                return combineLatest([
                    this.customerServiceContacts$,
                    this.isLoaded$,
                ]).pipe(
                    filter(([_, loaded]) => loaded),
                    map(
                        ([customerServiceContacts, _]) =>
                            customerServiceContacts,
                    ),
                );
            }),
        );
    }
}
