import { Component, Inject, Input, OnInit } from "@angular/core";

import { FooterSocialNetwork } from "@hermes/api-model-shell";
import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { FooterSocialNetworkWithSvg } from "@hermes/states/shell";
import { AnalyticsService } from "@hermes/utils/analytics";

import { FooterClickEvent } from "../../events/click.event";

@Component({
    selector: "h-footer-social-networks",
    templateUrl: "./footer-social-networks.component.html",
    styleUrls: ["./footer-social-networks.component.scss"],
})
export class FooterSocialNetworksComponent implements OnInit {
    @Input()
    public socialNetworks!: FooterSocialNetworkWithSvg[];

    public weChatImageUrl!: string;
    public showWeChatTootip: boolean = false;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private analyticsService: AnalyticsService,
    ) {}

    public ngOnInit(): void {
        this.weChatImageUrl = `${this.locale.urlPrefix}/assets/images/qr-code-wechat.png`;
    }

    public handleClickOnSocialNetwork(
        $event: Event,
        socialNetworkLabel: string,
    ): void {
        $event.stopPropagation();

        this.analyticsService.sendData(
            new FooterClickEvent({
                action: "SocialNetworksIcons",
                label: this.formatLabelForAnalytics(socialNetworkLabel),
            }),
        );
    }

    public handleClickOnWechat(
        $event: Event,
        socialNetworkLabel: string,
    ): void {
        this.showWeChatTootip = true;

        this.handleClickOnSocialNetwork($event, socialNetworkLabel);
    }

    public hideWeChatTooltip(): void {
        this.showWeChatTootip = false;
    }

    public trackBySocialNetwork(
        index: number,
        socialNetwork: FooterSocialNetwork,
    ): string {
        return `${index}_${socialNetwork?.machineName}`;
    }

    /**
     * Format a social network label by removing everything starting from the first dash
     *
     * Trivia : Some social networks are contributed with an overly precise label (for instance "wechat-grey").
     * We remove parts of the label before sending to analytics.
     *
     * @param label Label to format for analytics
     */
    private formatLabelForAnalytics(label: string) {
        return label.split("-")[0];
    }
}
