import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { INFRA_SETTINGS } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";

/**
 * This interceptor always set withCredentials: true header on each request.
 *
 * Reason: we have datadome bot protection and it requires that each request must be authenticated.
 * interceptor is skipped for plateforme api calls.
 */
@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
    constructor(@Inject(INFRA_SETTINGS) private infraSettings: InfraSettings) {}

    /**
     * Angular HTTP interceptor
     */
    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        // for requests to plateforme api
        if (
            request.url.startsWith(
                this.infraSettings.personalizationPlatformeUrl,
            )
        ) {
            // pass request without modifying it.
            return next.handle(request);
        }
        // Otherwise, all requests must be sent with datadome authentication cookie
        return next.handle(
            request.clone({
                withCredentials: true,
            }),
        );
    }
}
