import { BreakpointState } from "@angular/cdk/layout";
import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    signal,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { filter, first, Subject, Subscription, tap } from "rxjs";

import { Context, LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    createDate,
    isValidDate,
    isDatePassed,
    isDateOverAgeLimit,
} from "@hermes/utils-generic/helpers";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";
// eslint-disable-next-line @nx/enforce-module-boundaries

import { OptionItem } from "../select-input/select-input.component";

import {
    DateFormat,
    dateSelectorFormat,
    DAY_MONTH_YEAR,
    MONTH_DAY_YEAR,
    YEAR_MONTH_DAY,
} from "./date-selector.constants";

/**
 * This component implements a global date selector Component with somes not mandatory date limit rules
 * (date limit can for example be used as the date of majority, there is also a pre-written error message for this case).
 *
 * "Age" term is logically a pre-calculated indicator of how much time there is between the indicated date and the current date
 * (no business rule here, a simple help for calculations)
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this input with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 *
 * Usage:
 * ```
 *  <h-date-selector
 *      [appearance]="'oneLine'"
 *      [ageLimit]=19
 *      [formControlName]="'dateSelector'"
 *      [setMinHeight]="false"
 *      [showTitle]="true"
 *      title="'My title translated into english'"
 *      i18n-title="@@my.key.translate">
 *  </h-date-selector>
 * ```
 *
 */

// One line display on desktop and three lines on mobile
export const ONE_LINE = "oneLine";
// Always displayed on two lines
export const TWO_LINES = "twoLines";
export type NumberOfRowAppearance = typeof ONE_LINE | typeof TWO_LINES;

@Component({
    selector: "h-date-selector",
    templateUrl: "./date-selector.component.html",
    styleUrls: ["./date-selector.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateSelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateSelectorComponent),
            multi: true,
        },
    ],
})
export class DateSelectorComponent
    implements
        ControlValueAccessor,
        OnInit,
        OnDestroy,
        Validator,
        AfterViewInit
{
    @Input()
    public appearance: NumberOfRowAppearance = ONE_LINE;

    @Input()
    public ageLimit: number = 0;

    @Input()
    public formControlName!: string;

    @Input()
    public dataTestId?: string;

    @Input()
    public title!: string;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    @Input()
    public showTitle: boolean = true;

    @Input()
    public selectedOptionMonthValue?: string;

    public dayMonthYear: string[] = DAY_MONTH_YEAR;
    public monthDayYear: string[] = MONTH_DAY_YEAR;
    public yearMonthDay: string[] = YEAR_MONTH_DAY;

    public dayControl: UntypedFormControl = new UntypedFormControl("");
    public monthControl: UntypedFormControl = new UntypedFormControl("");
    public yearControl: UntypedFormControl = new UntypedFormControl("");
    public dateSelector: UntypedFormGroup = new UntypedFormGroup(
        {
            day: this.dayControl,
            month: this.monthControl,
            year: this.yearControl,
        },
        { updateOn: "blur" },
    );

    public dayMask: string = "99";
    public yearMask: string = "9999";

    public monthOptions: OptionItem[] = [
        {
            value: "0",
            text: $localize`:@@hermes-global-translations.january:January`,
        },
        {
            value: "1",
            text: $localize`:@@hermes-global-translations.february:February`,
        },
        {
            value: "2",
            text: $localize`:@@hermes-global-translations.march:March`,
        },
        {
            value: "3",
            text: $localize`:@@hermes-global-translations.april:April`,
        },
        { value: "4", text: $localize`:@@hermes-global-translations.may:May` },
        {
            value: "5",
            text: $localize`:@@hermes-global-translations.june:June`,
        },
        {
            value: "6",
            text: $localize`:@@hermes-global-translations.july:July`,
        },
        {
            value: "7",
            text: $localize`:@@hermes-global-translations.august:August`,
        },
        {
            value: "8",
            text: $localize`:@@hermes-global-translations.september:September`,
        },
        {
            value: "9",
            text: $localize`:@@hermes-global-translations.october:October`,
        },
        {
            value: "10",
            text: $localize`:@@hermes-global-translations.november:November`,
        },
        {
            value: "11",
            text: $localize`:@@hermes-global-translations.december:December`,
        },
    ];

    public focusOut$: Subject<void> = new Subject();
    public isMobileSignal = signal(true);

    public subscription: Subscription = new Subscription();

    public touched!: () => void;

    public dateFormat: DateFormat = dateSelectorFormat[this.locale.countryCode];
    public dayOrder: number = 2;
    public monthOrder: number = 1;
    public yearOrder: number = 3;

    public setMinHeightDay!: boolean;
    public setMinHeightMonth!: boolean;
    public setMinHeightYear!: boolean;

    constructor(
        private appReference: ApplicationRef,
        private cdr: ChangeDetectorRef,
        private controlContainer: ControlContainer,
        private breakpointService: BreakpointService,
        private context: Context,
        @Inject(LOCALE) public locale: Locale,
    ) {}

    public ngOnInit(): void {
        this.dayOrder = this.dateFormat.indexOf("day") + 1;
        this.monthOrder = this.dateFormat.indexOf("month") + 1;
        this.yearOrder = this.dateFormat.indexOf("year") + 1;

        this.subscription.add(
            this.focusOut$
                .pipe(
                    filter(
                        () =>
                            this.dayControl.touched &&
                            this.monthControl.touched &&
                            this.yearControl.touched,
                    ),
                )
                .subscribe(() => {
                    this.touched();
                }),
        );

        /**
         * Because of `touched` event doesnt realy exists when we implements ControlValueAccessor
         * we have to implements manually the reaction to the `markAsTouched` function from parent Control.
         *
         * @see https://github.com/angular/angular/issues/10887 for tracking the issue.
         */
        const control = this.controlContainer.control;
        if (!control) {
            // Should never be the case with proper inputs, it is to satisfy Typescript's typestrict-ness
            return;
        }

        const formControl = control.get(this.formControlName);

        if (!formControl) {
            // Should never be the case with proper inputs, it is to satisfy Typescript's typestrict-ness
            return;
        }

        formControl.markAsTouched = () => {
            this.dateSelector.markAllAsTouched();
            this.cdr.detectChanges();
        };

        if (this.isRequired(formControl)) {
            this.dayControl.addValidators(Validators.required);
            this.monthControl.addValidators(Validators.required);
            this.yearControl.addValidators(Validators.required);
        }

        this.subscription.add(
            this.dateSelector.valueChanges
                .pipe(
                    // Filter if all fields are touched
                    filter(
                        ({ year, day, month }) =>
                            this.dayControl.touched ||
                            this.yearControl.touched ||
                            this.monthControl.touched ||
                            day ||
                            month ||
                            year,
                    ),
                    // Remove invalid errors
                    tap(() => this.removeInvalidErrors()),
                    // Filter if not required and one field is set
                    // Or filter if required and all fields are set
                    filter(
                        ({ year, day, month }) =>
                            (!this.isRequired(formControl) &&
                                (year || day || month)) ||
                            (this.isRequired(formControl) &&
                                !!year &&
                                !!day &&
                                !!month),
                    ),
                )
                .subscribe(() => {
                    this.verifyDateAgainstLimit();
                }),
        );
    }

    public ngAfterViewInit(): void {
        if (this.context.isInBrowserMode()) {
            this.subscription.add(
                this.breakpointService
                    .mediumBreakpointObserver()
                    .subscribe((value: BreakpointState) => {
                        this.isMobileSignal.set(!value.matches);
                        this.setInputsMinHeight();
                        this.cdr.detectChanges();
                    }),
            );

            // To be sure to have the correct value when the application is fully loaded, ngAfterViewInit is not enough
            this.subscription.add(
                this.appReference.isStable
                    .pipe(first((stable) => stable))
                    .subscribe(() => {
                        this.isMobileSignal.set(
                            !this.breakpointService.mediumBreakpointMatcher(),
                        );
                        this.setInputsMinHeight();
                        this.cdr.detectChanges();
                    }),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public registerOnTouched(onTouchedFunction: () => void): void {
        this.touched = onTouchedFunction;
    }

    public writeValue(value: {
        day: string;
        month: string;
        year: string;
    }): void {
        if (value) {
            this.dateSelector.patchValue(value, { emitEvent: false });
        }
    }

    public registerOnChange(
        onChangeFunction: (value: {
            day: string;
            month: string;
            year: string;
        }) => void,
    ): void {
        this.subscription.add(
            this.dateSelector.valueChanges.subscribe(onChangeFunction),
        );
    }

    public registerOnValidatorChange(onValidatorFunction: () => void): void {
        this.subscription.add(
            this.dateSelector.statusChanges.subscribe(onValidatorFunction),
        );
    }

    public validate(): ValidationErrors | null {
        return this.isTouched() && this.dateSelector.valid
            ? Validators.nullValidator
            : {
                  ...this.dateSelector.errors,
                  ...this.yearControl.errors,
                  ...this.monthControl.errors,
                  ...this.dayControl.errors,
              };
    }

    /**
     * @description Check if the date limit is valid
     */
    public verifyDateAgainstLimit(): void {
        const day = this.dayControl.value;
        const month = this.monthControl.value;
        const year = this.yearControl.value;

        if (!isValidDate(year, month, day)) {
            this.setInvalidErrors();
            return;
        }

        const date = createDate(year, month, day);

        if (!isDatePassed(date)) {
            this.setInvalidErrors();
            return;
        }

        // Check if the customer is above the age limit
        if (this.ageLimit !== 0 && !isDateOverAgeLimit(date, this.ageLimit)) {
            this.setInvalidErrors();
            this.dateSelector.setErrors({ ageLimitInvalid: true });
        }
    }

    public setInvalidErrors(): void {
        this.dateSelector.setErrors({ invalid: true });
        this.yearControl.setErrors({ invalid: true });
        this.monthControl.setErrors({ invalid: true });
        this.dayControl.setErrors({ invalid: true });
    }

    public removeInvalidErrors(): void {
        // The lib expects explicitly null
        /* eslint-disable unicorn/no-null */
        this.dateSelector.setErrors(null);

        this.yearControl.setErrors(
            this.yearControl.errors?.["required"]
                ? { ...this.yearControl.errors }
                : null,
        );

        this.monthControl.setErrors(
            this.monthControl.errors?.["required"]
                ? { ...this.monthControl.errors }
                : null,
        );

        this.dayControl.setErrors(
            this.dayControl.errors?.["required"]
                ? { ...this.dayControl.errors }
                : null,
        );
        /* eslint-enable unicorn/no-null */
    }

    /**
     * Determine if for this input, there is a Validator of the type "required".
     */
    private isRequired(control: AbstractControl | undefined | null): boolean {
        if (control?.validator) {
            const validator = control.validator({} as AbstractControl);
            return validator && validator["required"];
        }
        return false;
    }

    /**
     * @private Verify if one of the date's input is touched
     */
    private isTouched(): boolean {
        return (
            this.dayControl.touched ||
            this.yearControl.touched ||
            this.monthControl.touched
        );
    }

    private setInputsMinHeight(): void {
        this.setMinHeightDay = this.setInputMinHeight(this.dayOrder.toString());
        this.setMinHeightMonth = this.setInputMinHeight(
            this.monthOrder.toString(),
        );
        this.setMinHeightYear = this.setInputMinHeight(
            this.yearOrder.toString(),
        );
    }

    private setInputMinHeight(inputOrder: string): boolean {
        if (!this.setMinHeight) {
            return false;
        }

        const isLastInput = inputOrder === "3";
        const isOneLineApperance = this.appearance === "oneLine";

        if (this.isMobileSignal()) {
            return !isLastInput;
        }

        if (isOneLineApperance) {
            return false;
        }

        return !isLastInput;
    }
}
