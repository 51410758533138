import { Action, ActionReducer } from "@ngrx/store";

/**
 * Helper function which allows us for the same State, to extend third party reducers
 * and therefore, to concatenate it with other reducers
 *
 * Usage exemple :
 * ```
 * const myConcatedReducers = concatReducers(
 *   thirdPartyReducer,
 *   otherCustomReducer,
 * );
 * ```
 */
export const concatReducers =
    <T>(...reducers: Array<ActionReducer<T>>) =>
    (state: T | undefined, action: Action): T | undefined =>
        // eslint-disable-next-line unicorn/no-array-reduce
        reducers.reduce(
            (accumulatedState, reducer) => reducer(accumulatedState, action),
            state,
        );
