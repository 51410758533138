import { Action, createReducer, on } from "@ngrx/store";

import * as layoutActions from "../actions/layout.actions";
import { NotificationState } from "../model/notification.model";

export const layoutFeatureKey = "layout";

export interface IHeaderMainContainerProperties {
    offsetHeight: number;
    innerHeight: number;
    offsetTop: number;
}

export interface State {
    header: IHeaderMainContainerProperties;
    keyboardNavigation: boolean;
    loading: boolean;
    notification: NotificationState;
}

export const initialState: State = {
    header: {
        offsetHeight: undefined as unknown as number,
        innerHeight: undefined as unknown as number,
        offsetTop: 0,
    },
    keyboardNavigation: false,
    loading: false,
    notification: undefined as unknown as NotificationState,
};

const layoutReducer = createReducer(
    initialState,
    on(layoutActions.updateHeaderHeight, (state, { header }) => ({
        ...state,
        header,
    })),
    on(layoutActions.showLoader, showLoaderReducer),
    on(layoutActions.hideLoader, hideLoaderReducer),
    on(layoutActions.showNotification, showNotificationReducer),
    on(layoutActions.hideNotification, hideNotificationReducer),
    on(layoutActions.keyboardNavigation, keyboardNavigationReducer),
    on(layoutActions.mouseNavigation, mouseNavigationReducer),
);

export function reducer(state: State | undefined, action: Action): State {
    return layoutReducer(state, action);
}

function showLoaderReducer(state: State): State {
    return { ...state, loading: true };
}

function hideLoaderReducer(state: State): State {
    return { ...state, loading: false };
}

function showNotificationReducer(
    state: State,
    action: ReturnType<typeof layoutActions.showNotification>,
) {
    return {
        ...state,
        notification: {
            displayOverlay: action.displayOverlay,
            messageOption: action.messageOption,
        },
    };
}

function hideNotificationReducer(state: State) {
    return {
        ...state,
        notification: undefined as unknown as NotificationState,
    };
}

function keyboardNavigationReducer(state: State): State {
    return { ...state, keyboardNavigation: true };
}

function mouseNavigationReducer(state: State): State {
    return { ...state, keyboardNavigation: false };
}
