import {
    animate,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";

import {
    CONTENT_LEFT_TRANSLATION,
    CONTENT_RIGHT_TRANSLATION,
    HIDDEN_LEFT_TRANSLATION,
    HIDDEN_RIGHT_TRANSLATION,
    HIDE_TRAY_ANIMATION,
    NO_TRANSLATION,
    SHOW_TRAY_ANIMATION,
    SHOWN_TRANSLATION,
} from "../../models/tray.constants";

/**
 * Slide animation from the left or the right
 */
export const trayContainerAnimations: AnimationTriggerMetadata[] = [
    trigger("tray", [
        // Open animation from left
        transition("void => left", [
            style({
                opacity: 0,
                transform: HIDDEN_LEFT_TRANSLATION,
            }),
            animate(
                SHOW_TRAY_ANIMATION,
                style({
                    opacity: 1,
                    transform: SHOWN_TRANSLATION,
                }),
            ),
        ]),

        // Open animation from right
        transition("void => right", [
            style({
                opacity: 0,
                transform: HIDDEN_RIGHT_TRANSLATION,
            }),
            animate(
                SHOW_TRAY_ANIMATION,
                style({
                    opacity: 1,
                    transform: SHOWN_TRANSLATION,
                }),
            ),
        ]),

        // Close animation from left
        transition("left => void", [
            animate(
                HIDE_TRAY_ANIMATION,
                style({
                    opacity: 0,
                    transform: HIDDEN_LEFT_TRANSLATION,
                }),
            ),
        ]),

        // Close animation from right
        transition("right => void", [
            animate(
                HIDE_TRAY_ANIMATION,
                style({
                    opacity: 0,
                    transform: HIDDEN_RIGHT_TRANSLATION,
                }),
            ),
        ]),
    ]),
    // animations when the tray enter or leaves the stack.
    trigger("trayContent", [
        transition("void => false", [
            // Initial Styles
            style({
                opacity: 0,
                transform: CONTENT_RIGHT_TRANSLATION,
            }),
            animate(
                SHOW_TRAY_ANIMATION,
                style({
                    opacity: 1,
                    transform: NO_TRANSLATION,
                }),
            ),
        ]),
        transition("false => void", [
            style({
                opacity: 1,
                transform: NO_TRANSLATION,
            }),
            animate(
                HIDE_TRAY_ANIMATION,
                style({
                    opacity: 0,
                    transform: CONTENT_RIGHT_TRANSLATION,
                }),
            ),
        ]),
        // when tray is here and shown
        state(
            "false",
            style({
                opacity: 1,
                transform: NO_TRANSLATION,
            }),
        ),
        // when tray is here but hidden
        state(
            "true",
            style({
                opacity: 0,
                transform: CONTENT_LEFT_TRANSLATION,
            }),
        ),
        transition("false => true", [animate(HIDE_TRAY_ANIMATION)]),
        transition("true => false", [animate(SHOW_TRAY_ANIMATION)]),
    ]),
];
