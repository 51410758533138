import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable()
export class MapPortalService {
    public attach$: ReplaySubject<ComponentPortal<unknown>>;

    constructor() {
        this.attach$ = new ReplaySubject<ComponentPortal<unknown>>();
    }

    public attachMapPortal(portal: ComponentPortal<unknown>): void {
        this.attach$.next(portal);
        this.attach$.complete();
    }
}
