export const preconnectLinks = [
    "js-agent.newrelic.com",
    "assets.hermes.com",
    "adservice.google.com",
    "www.google-analytics.com",
    "www.googletagmanager.com",
    "dd.hermes.com",
    "stream.hermes.cn",
    "cdn.cookielaw.org",
    "www.google.com",
    "bam.nr-data.net",
    "beacon.riskified.com",
    "c.riskified.com",
    "geolocation.onetrust.com",
    "img.riskified.com",
    "td.doubleclick.net",
    "region1.google-analytics.com",
];

export const chinaPreconnectLinks = [
    "js-agent.newrelic.com",
    "assets.hermes.cn",
    "www.googletagmanager.com",
    "hm.baidu.com",
    "dd.hermes.com",
    "stream.hermes.cn",
    "bam.nr-data.net",
];
