import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { GiftsetProductStateModule } from "@hermes/states/giftset-product";
import { UserStateModule } from "@hermes/states/user";
import { RecaptchaService } from "@hermes/utils-generic/services/client-api";

import { PaymentMethodsEffects } from "./effects/payment-methods.effects";
import { PaymentMethodsFacade } from "./facades/payment-methods.facade";
import * as fromPayment from "./reducers/payment-methods.reducer";
import { AvailablePaymentMethodsService } from "./services/available-payment-methods.service";
import { FetchPaymentMethodsService } from "./services/fetch-payment-methods.service";
import { SavedCardService } from "./services/saved-card.service";

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([PaymentMethodsEffects]),
        StoreModule.forFeature(
            fromPayment.paymentFeatureKey,
            fromPayment.reducer,
        ),
        UserStateModule,
        GiftsetProductStateModule,
    ],
    providers: [
        RecaptchaService,
        PaymentMethodsFacade,
        SavedCardService,
        FetchPaymentMethodsService,
        AvailablePaymentMethodsService,
    ],
})
export class PaymentMethodsStateModule {}
