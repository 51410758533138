import "@angular/localize/init";

// All ECOM error codes
export enum EcomErrorCodes {
    LOGIN_IS_INVALID = "2300001",
    EMAIL_INVALID_FORMAT = "2200001",
    EMAIL_ALREADY_EXISTS = "2400001",
    LOGIN_SOCIAL_SECURITY_NUMBER_EXISTS = "2400015",
    OUT_OF_STOCK = "3300007",
    SIZE_OUT_OF_STOCK = "3300007b",
    ITEM_OUT_OF_STOCK = "3300007c",
    OUT_OF_STOCK_WHEN_PAYMENT = "3400005",
    INSUFFICIENT_STOCK = "3400007",
    MAX_CHILD_QUANTITY = "3000007",
    NO_ACCOUNT = "2300001",
    LOGIN_ERROR = "2310001",
    LOGIN_TOO_MANY_ATTEMPTS = "3100001",
    SMS_ERROR_BANNED = "3100002",
    LOGIN_EXPIRED_SMS_CODE = "3100001",
    LOGIN_SMS_WRONG_CODE = "3100003",
    SMS_CODE_TO_EARLY = "3100004",
    SMS_ERROR_TOO_MANY_ATTEMPTS = "3100005",
    LOGIN_SAME_EMAIL_TOO_MANY_TIMES = "2330001",
    ERROR_CODE_PERSONALIZATION_QUOTE = "8000047",
    ERROR_CODE_PERSONALIZATION_PLATFORME = "8001047",
    ERROR_CODE_PERSONALIZATION_CAPACITY = "9000046",
    ERROR_CODE_PERSONALIZATION_MIXEDITEMS = "8000048",
    ERR_PAYMENT_PROCESSING = "5500000",
    ERR_PAYMENT_KO = "5500001",
    BLOCKED_SHIPPING_ADDRESS = "4210002",
    DELIVERY_BLOCKER_POSTCODE = "4210501",
    ERR_INPUT_IS_MISSING = "2000",
    ERR_INPUT_IS_EMPTY = "2100",
    ERR_INPUT_IS_INVALID = "2200",
    INTERNAL_ERROR_CODE_INPUT_IS_MISSING = "2000000",
    // unknown error : when magento respond with an unknown error
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    // technical error : when magento respond with a technical error
    TECHNICAL_ERROR = "TECHNICAL_ERROR",
    ERROR_CODE_CART_DEFAULT = "ERROR_DEFAULT",
    ERR_ECPAY = "9001009",
    DELETED_ITEM = "2300013",
}

export enum EcomCustomerAccountErrorCode {
    ERROR_OLD_PASSWORD_INVALID = "2310001",
    ERROR_OLD_SAME_AS_NEW_PASSWORD = "2320001",
}

export enum EcomSmsErrorCode {
    LOGIN_TOO_MANY_ATTEMPTS = EcomErrorCodes.LOGIN_TOO_MANY_ATTEMPTS,
    SMS_ERROR_BANNED = EcomErrorCodes.SMS_ERROR_BANNED,
    LOGIN_SMS_WRONG_CODE = EcomErrorCodes.LOGIN_SMS_WRONG_CODE,
    LOGIN_EXPIRED_SMS_CODE = EcomErrorCodes.LOGIN_EXPIRED_SMS_CODE,
    SMS_CODE_TO_EARLY = EcomErrorCodes.SMS_CODE_TO_EARLY,
    SMS_ERROR_TOO_MANY_ATTEMPTS = EcomErrorCodes.SMS_ERROR_TOO_MANY_ATTEMPTS,
}
