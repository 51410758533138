import { BasketItem } from "@hermes/api-model-basket";

import {
    HERMES_BRAND,
    NODATA,
    NO_SIZE,
    PRODUCT_AVAILABILITY_STATUS_ECOM,
} from "../constants/analytics.constant";
import { BK_ANALYTICS_PRODUCT_TYPE } from "../product-analytics/constants/beltkit-analytics.constants";
import { SMLG_PRODUCT_TYPE_ANALYTICS } from "../product-analytics/constants/smlg-analytics.constants";
import { GTMEcommerceItemData } from "../types/gtm.type";

export const PRODUCT_TYPE_BELTKIT = "BELTKIT";
export const PRODUCT_TYPE_FRAGRANCE_GIFT = "FRAGRANCE_GIFT";

export const isPersoProductByBasketItem = (product: BasketItem): boolean =>
    [SMLG_PRODUCT_TYPE_ANALYTICS, BK_ANALYTICS_PRODUCT_TYPE].includes(
        product.options?.perso_info?.perso_product_type,
    );

export const getFamilyAttributes = (
    product: BasketItem,
): GTMEcommerceItemData => {
    // The order of ifs is important here because a personalized beltkit can also be seen as a complex product
    if (isPersoProductByBasketItem(product)) {
        return getPersoProductAttributes(product);
    }
    if (product.setItems && product.setItems.length > 1) {
        return getComplexProductAttributes(product);
    }
    return getSimpleProductAttributes(product);
};

export const getPersoProductAttributes = (
    product: BasketItem,
): GTMEcommerceItemData => {
    // Generic properties
    const analyticsInfos: GTMEcommerceItemData = {
        name: product.name,
        id: product?.options?.container_sku,
        brand: HERMES_BRAND,
        price:
            product.setItems && product.finalPrice
                ? product.finalPrice.toFixed(2)
                : product.unitPrice.toFixed(2),
        quantity: product.qty,
        category: `${product.departmentCode}/${product.familyCode}`,
        variant: product.sku || NODATA,
        // eslint-disable-next-line unicorn/explicit-length-check
        dimension5: product.size || NO_SIZE,
        dimension6: product.departmentCode,
        dimension7: product.familyCode,
        dimension8: product.productCode,
        dimension9: product.divisionCode,
        dimension52: "yes",
        dimension53: PRODUCT_AVAILABILITY_STATUS_ECOM,
        dimension54: "yes",
    } as GTMEcommerceItemData;

    // Beltkit products
    if (
        product.options?.perso_info?.perso_product_type ===
        BK_ANALYTICS_PRODUCT_TYPE
    ) {
        const leatherProduct: BasketItem = product.setItems[
            product.setItems.length - 1
        ] as BasketItem;
        const buckleProduct: BasketItem | undefined =
            product.setItems.length > 1
                ? (product.setItems[0] as BasketItem)
                : undefined;

        analyticsInfos.category = `${leatherProduct.departmentCode}/${leatherProduct.familyCode}`;
        analyticsInfos.dimension5 =
            leatherProduct.size === "" ? NO_SIZE : leatherProduct.size;

        // Beltkit with buckle
        if (buckleProduct) {
            analyticsInfos.variant = `${leatherProduct.sku}|${buckleProduct.sku}`;
            analyticsInfos.dimension6 = `${leatherProduct.departmentCode}|${buckleProduct.departmentCode}`;
            analyticsInfos.dimension7 = `${leatherProduct.familyCode}|${buckleProduct.familyCode}`;
            analyticsInfos.dimension8 = `${leatherProduct.productCode}|${buckleProduct.productCode}`;
            analyticsInfos.dimension9 = `${leatherProduct.divisionCode}|${buckleProduct.divisionCode}`;
        } else {
            // Beltkit without buckle
            analyticsInfos.variant = `${leatherProduct.sku}`;
            analyticsInfos.dimension6 = `${leatherProduct.departmentCode}`;
            analyticsInfos.dimension7 = `${leatherProduct.familyCode}`;
            analyticsInfos.dimension8 = `${leatherProduct.productCode}`;
            analyticsInfos.dimension9 = `${leatherProduct.divisionCode}`;
        }
    }

    // Small leather good products
    if (
        product.options?.perso_info?.perso_product_type ===
        SMLG_PRODUCT_TYPE_ANALYTICS
    ) {
        analyticsInfos.variant = product?.displaySingleLineSku;
    }

    return analyticsInfos;
};

export const getComplexProductAttributes = (
    product: BasketItem,
): GTMEcommerceItemData => {
    // Init default value
    const analyticsInfos: GTMEcommerceItemData = {
        id: product.sku.split("-").slice(1).join("|"),
        category: `${product.setItems[0].departmentCode}/${product.setItems[0].familyCode}`,
        variant: product.setItems.map((item) => item.sku).join("|"),
        dimension5: product.setItems.length.toString(),
        dimension6: product.setItems[0].departmentCode,
        dimension7: product.setItems[0].familyCode,
        dimension8: product.setItems.map((item) => item.productCode).join("|"),
        dimension9: product.setItems.map((item) => item.divisionCode).join("|"),
        dimension10: product.setItems.map((item) => item.sku).join("|"),
    } as GTMEcommerceItemData;

    if (product.displaySingleLine) {
        analyticsInfos.category = `${product.setItems[0].departmentCode}|${product.setItems[1].departmentCode}/${product.setItems[0].familyCode}|${product.setItems[1].familyCode}`;
        analyticsInfos.variant =
            product.setItems[0].color || product.setItems[1].color
                ? `${product.setItems[0].color}|${product.setItems[1].color}`
                : NODATA;
        analyticsInfos.dimension5 =
            product.size === "" ? NO_SIZE : product.size;
        analyticsInfos.dimension6 = `${product.setItems[0].departmentCode}|${product.setItems[1].departmentCode}`;
        analyticsInfos.dimension7 = `${product.setItems[0].familyCode}|${product.setItems[1].familyCode}`;
        analyticsInfos.dimension8 = `${product.setItems[0].productCode}|${product.setItems[1].productCode}`;
        analyticsInfos.dimension9 = `${product.setItems[0].divisionCode}|${product.setItems[1].divisionCode}`;
        analyticsInfos.dimension10 = undefined;
        return analyticsInfos;
    }

    // Product type Beltkit
    if (product.options?.container_type?.includes(PRODUCT_TYPE_BELTKIT)) {
        analyticsInfos.category = `${product.setItems[0].departmentCode}|${product.setItems[1].departmentCode}/${product.setItems[0].familyCode}|${product.setItems[1].familyCode}`;
        analyticsInfos.variant =
            product.setItems[0].color || product.setItems[1].color
                ? `${product.setItems[1].color}|${product.setItems[0].color}`
                : NODATA;
        analyticsInfos.dimension5 = `${product.setItems[1].size}`;
        analyticsInfos.dimension6 = `${product.setItems[0].departmentCode}|${product.setItems[1].departmentCode}`;
        analyticsInfos.dimension7 = `${product.setItems[0].familyCode}|${product.setItems[1].familyCode}`;
        analyticsInfos.dimension8 = `${product.setItems[1].productCode}|${product.setItems[0].productCode}`;
        analyticsInfos.dimension9 = `${product.setItems[0].divisionCode}|${product.setItems[1].divisionCode}`;
        analyticsInfos.dimension10 = undefined;
        return analyticsInfos;
    }

    // Product type SET
    if (
        product.options?.container_type?.includes(PRODUCT_TYPE_FRAGRANCE_GIFT)
    ) {
        analyticsInfos.id = product.options.container_sku;
        analyticsInfos.category = "V/V";
    }

    return analyticsInfos;
};

export const getSimpleProductAttributes = (
    product: BasketItem,
): GTMEcommerceItemData =>
    ({
        id: product.sku,
        // eslint-disable-next-line unicorn/explicit-length-check
        dimension5: product.size || NO_SIZE,
        category: `${product.departmentCode}/${product.familyCode}`,
        variant: product.color || NODATA,
        dimension6: product.departmentCode,
        dimension7: product.familyCode,
        dimension8: product.productCode,
        dimension9: product.divisionCode,
        dimension10: NODATA,
    } as GTMEcommerceItemData);
