import { Injectable } from "@angular/core";

import { Context } from "./context";
import { StorageManager } from "./storage-manager.service";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    private localStorageInst: StorageManager | undefined;
    private sessionStorageInst: StorageManager | undefined;

    constructor(private context: Context) {
        if (this.context.isInBrowserMode()) {
            this.sessionStorageInst =
                StorageManager.getSessionStorageInstance();
            this.localStorageInst = StorageManager.getLocalStorageInstance();
        }
    }

    public getSessionStorageInstance(): StorageManager | undefined {
        if (this.context.isInBrowserMode()) {
            return this.sessionStorageInst;
        }
        return undefined;
    }

    public getLocalStorageInstance(): StorageManager | undefined {
        if (this.context.isInBrowserMode()) {
            return this.localStorageInst;
        }
        return undefined;
    }

    public removeSessionStorageItem(key: string): void {
        const sessionStorage = this.getSessionStorageInstance();
        if (sessionStorage) {
            sessionStorage.deleteItem(key);
        }
    }

    public setSessionStorageItem<S>(
        key: string,
        data: S,
        expireIn?: number,
    ): void {
        const sessionStorage = this.getSessionStorageInstance();
        if (sessionStorage) {
            sessionStorage.setItem(key, data, expireIn);
        }
    }

    public getSessionStorageItem<S, T extends new () => S>(
        key: string,
        destinationClass?: T,
    ): S | undefined {
        const sessionStorage = this.getSessionStorageInstance();
        if (!sessionStorage) {
            return undefined;
        }
        return sessionStorage.getItem(key, destinationClass);
    }
}
