<div class="block-wrapper">
    <nav aria-label="quick access" i18n-aria-label="@@hermes.accessibility.quick-access" role="navigation">
        <ul *ngIf="skipLinks$ | async as skipLinks" class="skip-links-header">
            <li id="skip-links-accessibility">
                <a [href]="skipLinks.content" class="skip-links" i18n="@@hermes.accessibility.skip-to-content"
                    >Go to main content</a
                >
            </li>
            <li>
                <a
                    [href]="skipLinks.product"
                    class="skip-links"
                    [hFocus]="'#collection-button'"
                    i18n="@@hermes.accessibility.skip-to-product-navigation"
                    >Go to product browsing</a
                >
            </li>
            <li *ngIf="isCanada || isUsa">
                <a [href]="skipLinks.accessibility" class="skip-links" i18n="@@hermes-footer-accessibility-label"
                    >Accessibility</a
                >
            </li>
        </ul>
    </nav>

    <nav
        class="useful-shortcuts"
        aria-label="useful shortcuts"
        i18n-aria-label="@@hermes.accessibility.useful-shortcuts"
        role="navigation">
        <ul class="header-links">
            <li>
                <button
                    *ngIf="isCanada"
                    class="capitalize-first-letter locale-change js-locale-tray link-to-button-country-language"
                    [hTrayOpener]="'country-selector-tray'"
                    [trayPosition]="'right'"
                    [trayOverlay]="true"
                    [trayTitle]="'hermes-country-selector-tray.country'">
                    {{ "hermes-global-translations.header-country-label" | translate }}
                </button>

                <button
                    *ngIf="!isCanada"
                    class="locale-change js-locale-tray link-to-button-country-language"
                    [hTrayOpener]="'country-selector-tray'"
                    [trayPosition]="'right'"
                    [trayOverlay]="true"
                    [trayTitle]="'hermes-country-selector-tray.country'">
                    {{ "hermes_country_selector" | translate }}
                </button>
            </li>
            <li>
                <a
                    [href]="findAStoreUrl"
                    class="find-a-store"
                    i18n="@@page--hermes-global--find-store.find-a-store"
                    (click)="navigateOnShopPage($event, 'FindAStore')">
                    Find a store
                </a>
            </li>
            <li>
                <a class="email-us" href="{{ baseHref }}/contact-us/" (click)="goToContactUsPage($event)">
                    {{ "hermes.page-header.link.title.help" | translate }}
                </a>
            </li>
        </ul>
    </nav>
</div>
