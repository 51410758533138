import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import {
    fetchMenu,
    fetchMenuFailure,
    fetchMenuSuccess,
} from "../actions/menu.action";
import { MenuService } from "../services/menu.service";

@Injectable()
export class MenuEffects {
    public fetchMenu$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchMenu),
            switchMap(() =>
                this.menuService.fetchMenu().pipe(
                    map((response) => fetchMenuSuccess({ entry: response })),
                    catchError((error) => of(fetchMenuFailure({ error }))),
                ),
            ),
        ),
    );
    public fetchMenuFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchMenuFailure),
                tap(({ error }) =>
                    this.logger.error(
                        "Error when retrieving menu data",
                        error.message,
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        @Inject(LOGGER) private logger: Logger,
        private actions$: Actions,
        private menuService: MenuService,
    ) {}
}
