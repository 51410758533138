import { Injectable, TransferState } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { from, map, Observable, of } from "rxjs";

import { LocaleId } from "@hermes/locale";

import { TRANSLATIONS_CACHE_KEY } from "./translations.constants";

@Injectable()
export class BrowserTranslationLoader implements TranslateLoader {
    constructor(private transferState: TransferState) {}

    public getTranslation(
        localeId: LocaleId,
    ): Observable<Record<string, string>> {
        const translationsFromServer = this.transferState.get(
            TRANSLATIONS_CACHE_KEY,
            undefined,
        );
        if (translationsFromServer) {
            return of(translationsFromServer);
        }

        // Fallback for browser only
        return from(
            import(
                /* webpackChunkName: "i18n-translations" */
                /* webpackMode: "lazy-once" */
                `./i18n/${localeId}`
            ),
        ).pipe(map((locale) => locale.translations));
    }
}
