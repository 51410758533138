import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControlDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Context } from "@hermes/app-core";

import { BaseInputComponent } from "../base-input/base-input.component";

/**
 * This component implements a global Input Component. It also implements a floating label (like a mat-label - Material Angular).
 *
 * It uses the Reactive Forms to manage controls and data and implements the ControlValueAccessor interface.
 *
 * @see https://angular.io/guide/reactive-forms
 * @see https://angular.io/guide/forms-overview#common-form-foundation-classes
 *
 * You must use this textarea with a FormGroup : `controlName` must have the name of the AbstractControl.
 *
 * It manages basic forms errors for you :
 * * Required error
 * You can desactivate these automatic errors if you wish.
 *
 * You can also add errors message yourself (see example).
 *
 * Usage:
 * ```
 *  <h-textarea-input
 *      [id]="'field-id'"
 *      [name]="'field-name'"
 *      [rows]="4"
 *      [maxLength]="4"
 *      [readOnly]="readOnly"
 *      [controlName]="(field)"
 *      [errorManagement]="true"
 *      [dataTestId]="data-test-id"
 *      [setMinHeight]="false"
 *      [label]="'My label translated into english'"
 *      [placeholder]="'My placeholder translated into english'"
 *      [describe]="'My describe translated into english'"
 *      [ariaDescribedby]="id-label"
 *      i18n-label="@@my.key.translate"
 *      i18n-placeholder="@@my.key.translate"
 *      i18n-describe="@@my.key.translate">
 *
 *      <h-message-block *ngIf="serviceError" type='error'>
 *             <ng-container i18n="@@my.key.translate">
 *                    New service error
 *             </ng-container>
 *      </h-message-block>
 *  </h-textarea-input>
 * ```
 *
 */
@Component({
    selector: "h-textarea-input",
    templateUrl: "./textarea-input.component.html",
    styleUrls: ["./textarea-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TextareaInputComponent),
            multi: true,
        },
    ],
})
export class TextareaInputComponent
    extends BaseInputComponent
    implements ControlValueAccessor, Validator, OnInit
{
    /**
     * How many rows to display
     * Default : 4
     */
    @Input()
    public rows: number = 4;

    /**
     * How many characters max
     * Default : undefined
     */
    @Input()
    public maxLength: number | undefined = undefined;

    /**
     * Whether the input is readonly
     * Default: false;
     */
    @Input()
    public readOnly: boolean = false;

    /**
     * Whether the input is required
     * Default: false;
     */
    @Input()
    public required: boolean = false;

    /**
     * DataTestId for automated e2e tests
     */
    @Input()
    public dataTestId?: string;

    /**
     * Country code to handle specific CSS properties for non-latin chars
     * Default: "en"
     */
    @Input()
    public countryCode: string = "en";

    @Input()
    public customFontSize!: string;

    /**
     * Permits to enable aria invalid even when errorManagement is disabled
     */
    @Input()
    public enableAriaInvalid: boolean = this.errorManagement || true;

    /**
     * Centers text within textarea
     */
    @Input()
    public centerText: boolean = false;

    /**
     * Whether the textarea is resizable
     */
    @Input()
    public disableResize: boolean = false;

    @ViewChild("textArea")
    public textarea!: ElementRef<HTMLTextAreaElement>;

    /**
     * Add a custom validation function (using the Validator interface).
     */
    @Input()
    public customValidatorFunction: (
        control: AbstractControl,
    ) => ValidationErrors | null = Validators.nullValidator;

    // When true, sets a min height to the component in order for the error messages to display without moving other elements
    @Input()
    public setMinHeight: boolean = false;

    /**
     * Observe FormControl usage
     */
    @ViewChild(FormControlDirective, { static: true })
    public formControlDirective!: FormControlDirective;

    public textInputAreaStyle: string = "min-height: 78px";

    constructor(
        protected override controlContainer: ControlContainer,
        public layoutFacade: LayoutFacade,
        private context: Context,
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        this.textInputAreaStyle = this.setMinHeight
            ? `min-height: ${this.rows * 20 + 58}px`
            : "";
    }

    public registerOnTouched(callback: unknown): void {
        this.formControlDirective.valueAccessor?.registerOnTouched(callback);
    }

    public registerOnChange(callback: unknown): void {
        this.formControlDirective.valueAccessor?.registerOnChange(callback);
    }

    public writeValue(value: string | number): void {
        this.formControlDirective.valueAccessor?.writeValue(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.formControlDirective.valueAccessor?.setDisabledState?.(isDisabled);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.customValidatorFunction(control);
    }

    public override focusInput(): void {
        this.textarea.nativeElement.focus();
        if (this.context.isInBrowserMode()) {
            scrollIntoView(this.textarea.nativeElement, { block: "center" });
        }
    }

    /**
     * Determine if the formControl status is set to "disabled".
     */
    public isDisabled(): boolean {
        return this.control && this.control.disabled;
    }

    /**
     * In case the Form is configured to update on blur events,
     * force the blur event to update value and validity of the AbstractControl
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event
     */
    public forceBlur(event: Event): void {
        const eventKeyboard = event as KeyboardEvent;
        // In case the user keeps pressing the key, the event is cancelled.
        if (eventKeyboard.repeat) {
            event.preventDefault();
            return;
        }

        if (this.control.updateOn === "blur") {
            const focusEvent = new FocusEvent("blur");
            this.textarea.nativeElement.dispatchEvent(focusEvent);
        }
    }

    public setStyle(): string {
        return this.setMinHeight ? `min-height: ${this.rows * 20 + 58}px` : "";
    }
}
