import { Portal } from "@angular/cdk/portal";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { MapPortalService } from "../../services/map-portal.service";

@Component({
    selector: "h-tray-overlay-map",
    templateUrl: "./tray-overlay-map.component.html",
    styleUrls: ["./tray-overlay-map.component.scss"],
})
export class TrayOverlayMapComponent implements OnInit, OnDestroy {
    /**
     * Map Portal Component used to display Product locator Map
     */
    public mapPortal!: Portal<unknown>;

    private subscription: Subscription = new Subscription();

    constructor(private mapPortalService: MapPortalService) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.mapPortalService.attach$.subscribe((portal) => {
                this.mapPortal = portal;
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
