import { Component, Input } from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    UntypedFormControl,
} from "@angular/forms";

/**
 * BaseInputComponent help you to create single input component using the ControlValueAccessor interface
 *
 * @see https://angular.io/api/forms/ControlValueAccessor for more details
 *
 * It provides a set of Angular Input that you must use, when it's required.
 *
 */
@Component({
    template: "",
})
export class BaseInputComponent {
    /**
     * Similar to formControlName, controlName tracks the name of the FormControl through generic inputs.
     * Note : we could have kept `formControlName` but its use across multiple levels generates errors.
     *
     * It's a required input
     *
     */
    @Input()
    public controlName!: string;

    /**
     * Add `data-cs-mask` attribut on the input to disable the tracking of ContentSquare.
     *
     * It's a optional input
     *
     */
    @Input()
    public contentSquareMaskEnabled: boolean = true;

    /**
     * Direct reference to the "id" attribut of the input.
     *
     * It's a required input
     */
    @Input()
    public id!: string;

    /**
     * Direct reference to the "name" attribut of the input. If not provied, "name" equals "id".
     *
     * It's an required input
     */
    @Input()
    public name!: string;

    /**
     * Label used as a "floating" label
     * To use the translate system with this label, you can used "i18n-label="@@my.trad"
     *
     */
    @Input()
    public label?: string;

    /**
     * Additional field description.
     * To use the translate system with this label, you can used "i18n-describe="@@my.trad"
     *
     * The field is automatically inject with label of the input with a <span class="sr-only"> tag.
     *
     * It's an optional input
     */
    @Input()
    public describe?: string;

    /**
     * Label used as a placeholder
     * To use the translate system with this label, you can used "i18n-placeholder="@@my.trad"
     *
     * It's an optional input
     */
    @Input()
    public placeholder?: string;

    /**
     * Direct reference to the "autocomplete" attribut of the input. Default is "off".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete.
     *
     * It's an optional input
     */
    @Input()
    public autocomplete: string = "off";

    /**
     * Direct reference to the "spellcheck" attribut of the input. Default is "false".
     *
     * @see https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/spellcheck
     *
     * It's an optional input
     */
    @Input()
    public spellcheck: boolean = false;

    /**
     * Direct reference to the "aria-autoComplete" attribut of the input. Default is "undefined".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/ariaAutoComplete
     *
     * It's an optional input
     */
    @Input()
    public ariaAutoComplete: "inline" | "list" | "both" | "none" | undefined =
        undefined;

    /**
     * Direct reference to the "aria-controls" attribut of the input. Default is "undefined".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
     * @see https://www.w3.org/TR/wai-aria-1.1/#aria-controls
     *
     * It's an optional input
     */
    @Input()
    public ariaControls: string | undefined = undefined;

    /**
     * Direct reference to the "aria-activedescendant" attribut of the input. Default is undefined.
     *
     * @see https://www.w3.org/WAI/GL/wiki/Using_aria-activedescendant_to_allow_changes_in_focus_within_widgets_to_be_communicated_to_Assistive_Technology
     */
    @Input()
    public ariaActiveDescendant: string | undefined = undefined;
    /**
     * If you do not want errors to be displayed automatically you can set this parameter to "false".
     */
    @Input()
    public errorManagement = true;

    /**
     * Direct reference to the "aria-discribedby" attribut of the input. Default is "undefined".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
     * @see https://www.w3.org/TR/wai-aria-1.1/#aria-describedby
     *
     * It's an optional input
     */
    @Input()
    public ariaDescribedby?: string = undefined;

    constructor(protected controlContainer: ControlContainer) {}

    public get control(): UntypedFormControl {
        return this.controlContainer.control?.get(
            this.controlName,
        ) as UntypedFormControl;
    }

    /**
     * Determine if for this input, there is a Validator of the type "required".
     */
    public isRequired(): boolean {
        if (this.control.validator) {
            const validator = this.control.validator({} as AbstractControl);
            if (validator && validator["required"]) {
                return true;
            }
        }
        return false;
    }

    public controlHaveErrors(): boolean {
        return (
            this.control.invalid && (this.control.dirty || this.control.touched)
        );
    }

    public focusInput(): void {
        throw new Error("Not implemented");
    }
}
