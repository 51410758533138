import { makeStateKey, StateKey, TransferState } from "@angular/core";

import { envConfigFactory, EnvironmentConfiguration } from "@hermes/env-infra";

export const envConfigStateKey: StateKey<EnvironmentConfiguration> =
    makeStateKey("envConfig");

export const browserEnvConfig = (
    transferState: TransferState,
): EnvironmentConfiguration =>
    transferState.get<EnvironmentConfiguration>(
        envConfigStateKey,
        envConfigFactory({}),
    );

export const serverEnvConfig = (
    transferCache: TransferState,
    environmentConfigFromSSR?: EnvironmentConfiguration,
): EnvironmentConfiguration => {
    if (!environmentConfigFromSSR) {
        throw new Error("environment config not provided");
    }
    transferCache.set<EnvironmentConfiguration>(
        envConfigStateKey,
        environmentConfigFromSSR,
    );
    return environmentConfigFromSSR;
};
