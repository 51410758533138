import { Injectable, OnDestroy } from "@angular/core";
import {
    FormlyConfig,
    FormlyFieldConfig,
    FormlyTemplateOptions,
} from "@ngx-formly/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { formTranslations } from "./form-translations";

export interface FieldGroup {
    fieldGroup: FieldGroup[];
    validation: { messages: { pattern: string; [index: string]: string } };
    props: {
        label: string;
        description: string;
        options: Array<{ value: string; text: string }>;
        minLength: number;
        maxLength: number;
    };
}

const REQUIRED = $localize`:@@hermes.common.required_information_input:Required information`;

/**
 * abstract class for form service
 */
@Injectable()
export abstract class AbstractFormService implements OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(
        private translate: TranslateService,
        private formlyConfig: FormlyConfig,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Init translation for required message
     */
    public initFormlyRequiredMessageTranslation(): void {
        this.formlyConfig.addValidatorMessage("required", () => REQUIRED);
    }

    /**
     * Transform field and add good translation for labels and error messages
     *
     * @param field: any (field)
     *
     */
    protected transformFormlyField(
        field: FormlyFieldConfig,
    ): FormlyFieldConfig {
        // Nominal case
        if (field.props) {
            return this.translationNominalCase(field);
        }
        // Complex fields (like firstname/lastname)
        return this.translationComplexFields(field);
    }

    private translationNominalCase(
        field: FormlyFieldConfig,
    ): FormlyFieldConfig {
        // For label
        if (field?.props?.label) {
            field.props.label = formTranslations({})[field.props.label]
                ? formTranslations({})[field.props.label]
                : field.props.label;
        }

        // For select options
        if (field?.props?.options) {
            field.props.options.forEach((option: FormlyTemplateOptions) => {
                if (option["text"]) {
                    option["text"] =
                        formTranslations({})[option["text"]] ?? option["text"];
                }
            });
        }

        // For description
        if (field?.props?.description) {
            this.subscription.add(
                this.translate
                    .get(field.props.description)
                    .subscribe((description: string) => {
                        if (field.props?.description) {
                            field.props.description = description;
                        }
                    }),
            );
        }

        // For validation
        if (
            field.validation?.messages &&
            Object.keys(field.validation.messages).length > 0
        ) {
            const min = field?.props?.minLength;
            const max = field?.props?.maxLength;
            const alteredMax = field?.props?.maxLength
                ? field.props.maxLength - 1
                : undefined;
            const patternMessage =
                field.validation.messages["pattern"].toString();

            field.validation.messages["pattern"] = formTranslations({
                min,
                max,
                alteredMax,
            })[patternMessage]
                ? formTranslations({
                      min,
                      max,
                      alteredMax,
                  })[patternMessage]
                : patternMessage;
        }

        return field;
    }

    private translationComplexFields(
        field: FormlyFieldConfig,
    ): FormlyFieldConfig {
        // For label
        const fieldGroupList = field.fieldGroup as unknown as FieldGroup[];
        fieldGroupList.forEach((fieldGroup) => {
            fieldGroup.props.label = formTranslations({})[
                fieldGroup.props.label
            ]
                ? formTranslations({})[fieldGroup.props.label]
                : fieldGroup.props.label;

            // Checks if there is some custom validation en the field
            if (fieldGroup.validation) {
                // error message for the field if they exists
                for (const [property, tradKey] of Object.entries(
                    fieldGroup.validation.messages,
                )) {
                    fieldGroup.validation.messages[property] = formTranslations(
                        {},
                    )[tradKey];
                }
            }
        });
        return field;
    }
}
