import { Component, Inject, Input } from "@angular/core";

import { COUNTRY } from "@hermes/app-core";
import { Country, isKorea } from "@hermes/locale";

@Component({
    selector: "h-footer-copyright",
    templateUrl: "./footer-copyright.component.html",
    styleUrls: ["./footer-copyright.component.scss"],
})
export class FooterCopyrightComponent {
    @Input()
    public hasLightCopyright!: boolean;
    /**
     * True if locale is Korea
     */
    public isKorea = isKorea(this.country);

    /**
     * Get current Year for copyright
     */
    public year = new Date().getFullYear();

    constructor(@Inject(COUNTRY) private country: Country) {}
}
