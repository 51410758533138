import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import {
    closeAllOtherTrays,
    closeTray,
    closeTrayByName,
    openTray,
} from "../actions/tray.actions";
import { TrayData } from "../models/tray-data.model";
import { State } from "../reducers/tray.reducer";

@Injectable()
export class TrayFacade implements OnDestroy {
    private subscription = new Subscription();

    constructor(private store: Store<State>) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openTray(data: TrayData): void {
        this.store.dispatch(openTray(data));
    }

    public closeTray(): void {
        this.store.dispatch(closeTray());
    }

    public closeTrayByName(name: string): void {
        this.store.dispatch(closeTrayByName({ name }));
    }

    public closeAllOtherTrays(name: string): void {
        this.store.dispatch(closeAllOtherTrays({ name }));
    }
}
