import { createAction, props } from "@ngrx/store";

import { Product } from "@hermes/api-model-product";

export const addGiftSetItem = createAction(
    "[Giftset Product Page] Add giftset item",
    props<{ sku: string }>(),
);

export const removeGiftSetItem = createAction(
    "[Giftset Product Page] Remove giftset item",
    props<{ sku?: string; id?: string }>(),
);

export const expandGiftSetItem = createAction(
    "[Giftset Product Page] Expand giftset item",
    props<{ sku: string }>(),
);

export const emptySelectedItems = createAction(
    "[Giftset Product Page] Empty giftset selected items",
);

export const fetchGiftsetProductSuccess = createAction(
    "[Giftset Product Page] Fetch Giftset Product Success",
    props<{ product: Product }>(),
);

export const askForItemRemoval = createAction(
    "[Giftset Product Page] Ask for removal of an item sku",
    props<{ sku: string }>(),
);
