import {
    LEGACY_ALIPAY,
    LEGACY_CUP,
    LEGACY_WECHAT,
    MERCURY_ADYEN_ALIPAY_HK,
    MERCURY_ADYEN_ALIPAY_WAP,
    MERCURY_ADYEN_ALIPAY,
    MERCURY_ADYEN_APPLE_PAY,
    MERCURY_ADYEN_CREDITCARD,
    MERCURY_ADYEN_KCP_CC,
    MERCURY_ADYEN_KCP_NAVERPAY,
    MERCURY_ADYEN_KCP_PAYCO,
    MERCURY_ADYEN_PAYPAL,
    MERCURY_ADYEN_PIX,
    MERCURY_ADYEN_SAVEDCARD,
    MERCURY_ALIPAY,
    MERCURY_CHECKOUTCOM_CC,
    MERCURY_KAKAOPAY,
    MERCURY_PAYPAL,
    MERCURY_WECHAT,
    MERCURY_ADYEN_ONE_CLICK,
    STRIPE_CC,
} from "@hermes/utils/constants";

const KR_KAKAOPAY_MAX_AMOUNT: number = 2_000_000;

export type MethodToDetect =
    | "hsbc"
    | "wechat"
    | "alipay"
    | "pix"
    | "paypal"
    | "creditcard"
    | "adyenCreditCards"
    | "applePay"
    | "kcpCreditCard"
    | "kcpPayco"
    | "kcpNaverpay"
    | "kakaopay"
    | "hppRedirect";

export const isCreditCardPaymentHelper = (paymentMethod: string): boolean =>
    [
        MERCURY_ADYEN_CREDITCARD,
        MERCURY_ADYEN_SAVEDCARD,
        MERCURY_CHECKOUTCOM_CC,
        STRIPE_CC,
    ].includes(paymentMethod);

export const isAdyenCreditCardHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_CREDITCARD === paymentMethod;

export const isCheckoutCreditCardHelper = (paymentMethod: string): boolean =>
    MERCURY_CHECKOUTCOM_CC === paymentMethod;

export const isPaypalHelper = (paymentMethod: string): boolean =>
    [MERCURY_ADYEN_PAYPAL, MERCURY_PAYPAL].includes(paymentMethod);

export const isPixHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_PIX === paymentMethod;

export const isAlipayHelper = (paymentMethod: string): boolean =>
    [
        "alipay",
        "alipay_wap",
        LEGACY_ALIPAY,
        MERCURY_ADYEN_ALIPAY,
        MERCURY_ADYEN_ALIPAY_WAP,
        MERCURY_ADYEN_ALIPAY_HK,
        MERCURY_ALIPAY,
    ].includes(paymentMethod);

export const isWechatHelper = (paymentMethod: string): boolean =>
    [MERCURY_WECHAT, LEGACY_WECHAT].includes(paymentMethod);

export const isHsbcHelper = (paymentMethod: string): boolean =>
    LEGACY_CUP === paymentMethod;

export const isKakaopayMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_KAKAOPAY === paymentMethod;

export const isApplePayMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_APPLE_PAY === paymentMethod;

export const isKcpCreditCardMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_CC === paymentMethod;

export const isKcpPaycoMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_PAYCO === paymentMethod;

export const isKcpNaverpayMethodHelper = (paymentMethod: string): boolean =>
    MERCURY_ADYEN_KCP_NAVERPAY === paymentMethod;

export const isAdyenOneClickPaymentMethodHelper = (
    paymentMethod: string,
): boolean => MERCURY_ADYEN_ONE_CLICK === paymentMethod;

export const isHPPRedirectPaymentMethodHelper = (
    paymentMethod: string,
): boolean =>
    [
        "alipay",
        "alipay_wap",
        LEGACY_ALIPAY,
        LEGACY_CUP,
        LEGACY_WECHAT,
        MERCURY_ADYEN_ALIPAY,
        MERCURY_ADYEN_ALIPAY_HK,
        MERCURY_ADYEN_ALIPAY_WAP,
        MERCURY_ADYEN_KCP_CC,
        MERCURY_ADYEN_KCP_NAVERPAY,
        MERCURY_ADYEN_KCP_PAYCO,
        MERCURY_ADYEN_PIX,
        MERCURY_ALIPAY,
        MERCURY_KAKAOPAY,
        MERCURY_WECHAT,
    ].includes(paymentMethod);

const helpersMapper: Record<string, (paymentMethod: string) => boolean> = {
    hsbc: isHsbcHelper,
    wechat: isWechatHelper,
    alipay: isAlipayHelper,
    paypal: isPaypalHelper,
    pix: isPixHelper,
    creditcard: isCreditCardPaymentHelper,
    adyenCreditCards: isAdyenCreditCardHelper,
    applePay: isApplePayMethodHelper,
    kcpCreditCard: isKcpCreditCardMethodHelper,
    kcpPayco: isKcpPaycoMethodHelper,
    kcpNaverpay: isKcpNaverpayMethodHelper,
    kakaopay: isKakaopayMethodHelper,
    hppRedirect: isHPPRedirectPaymentMethodHelper,
};

export const isPaymentMethodHelper = (
    paymentCode: string,
    paymentMethod: string,
): boolean => {
    if (paymentMethod in helpersMapper) {
        return helpersMapper[paymentMethod](paymentCode);
    }
    return false;
};

export const getFormatedPaymentMethodHelper = (
    paymentCode: string,
): string | undefined =>
    [
        {
            method: isApplePayMethodHelper,
            formatedName: "applepay-with-border",
        },
        { method: isAlipayHelper, formatedName: "alipay" },
        { method: isPaypalHelper, formatedName: "paypal" },
        { method: isWechatHelper, formatedName: "wechatpay" },
        { method: isPixHelper, formatedName: "pix" },
        { method: isHsbcHelper, formatedName: "cup" },
        {
            method: isKcpCreditCardMethodHelper,
            formatedName: "kcp-credit-card",
        },
        { method: isKcpPaycoMethodHelper, formatedName: "kcp-payco" },
        { method: isKcpNaverpayMethodHelper, formatedName: "kcp-naverpay" },
        { method: isKakaopayMethodHelper, formatedName: "kakaopay" },
        {
            method: isHPPRedirectPaymentMethodHelper,
            formatedName: "hppRedirect",
        },
    ].find((paymentHelper) => paymentHelper.method(paymentCode))?.formatedName;

export const isAmountOkForKakaopayHelper = (amount: number): boolean =>
    amount < KR_KAKAOPAY_MAX_AMOUNT;
