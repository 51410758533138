import { NgModule, Pipe, PipeTransform } from "@angular/core";

import { ImageConfig, Scene7ImageUrlBuilder } from "./scene7-image-url.helper";

@Pipe({
    name: "hImageUrl",
    pure: false,
})
export class ImageUrlPipe implements PipeTransform {
    public transform(
        url: string,
        value: ImageConfig,
        applyBackground = false,
    ): string {
        return new Scene7ImageUrlBuilder(url, value).build(applyBackground);
    }
}

@NgModule({
    declarations: [ImageUrlPipe],
    exports: [ImageUrlPipe],
    providers: [ImageUrlPipe],
})
export class ImageUrlPipeModule {}
