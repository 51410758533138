import { User, Guest } from "@hermes/api-model-account";

export type UserState = (DetailsState | DetailsStateError) & GuestState;

interface BaseDetailsState {
    details: User;
}

export interface DetailsState extends BaseDetailsState {
    userStatus: "unsynchronized" | "synchronized";
}

export interface DetailsStateError extends BaseDetailsState {
    userStatus: "error";
    userError: string;
}

export interface GuestState {
    guest: Guest;
}

export const initialState: UserState = {
    details: {} as User,
    guest: {} as Guest,
    userStatus: "unsynchronized",
};
