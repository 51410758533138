import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { UserStateModule } from "@hermes/states/user";

import { EmptyComponentComponent } from "./components/empty-component/empty-component.component";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { MainContentComponent } from "./components/main-content/main-content.component";
import { LocalePrefixGuard } from "./guards/locale-prefix.guard";
import { CustomerSessionResolver } from "./resolvers/customer-session.resolver";
import { XsrfTokenResolver } from "./resolvers/xsrf-token.resolver";

@NgModule({
    imports: [CommonModule, RouterModule, UserStateModule],
    declarations: [
        EmptyComponentComponent,
        ErrorPageComponent,
        MainContentComponent,
    ],
    exports: [
        EmptyComponentComponent,
        ErrorPageComponent,
        MainContentComponent,
    ],
    providers: [CustomerSessionResolver, LocalePrefixGuard, XsrfTokenResolver],
})
export class RouterComponentsModule {}
