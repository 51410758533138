import { CountryCode } from "@hermes/locale";

/**
 * Usage Note: use helpers (see ./map-engine.helper) instead of these constants for your work.
 */
export const GOOGLE_ENGINE_KEY = "GOOGLE";
export const BAIDU_ENGINE_KEY = "BAIDU";
export type MapEngines = typeof BAIDU_ENGINE_KEY | typeof GOOGLE_ENGINE_KEY;

/**
 * The one and only constant in the definition of Geo engines to be used depending on the country !
 */
export const MAP_ENGINE_BY_COUNTRIES: Partial<Record<CountryCode, MapEngines>> &
    Record<"default", MapEngines> = {
    default: GOOGLE_ENGINE_KEY,
    cn: BAIDU_ENGINE_KEY,
};
