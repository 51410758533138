import { createReducer, Action, on } from "@ngrx/store";

import {
    addScriptOnServerSide,
    addScriptOnClientSide,
    addScriptOnClientSideSuccess,
    addScriptOnClientSideError,
} from "../actions/external-library.actions";

import {
    ERROR,
    ExternalLibraryState,
    INITIALIZED_CSR,
    INITIALIZED_SSR,
    isLibraryError,
    LOADED,
} from "./external-library.state";

export const EXTERNALLIBRARY_FEATURE_KEY = "externalLibrary";

export const initialState: ExternalLibraryState = {};

const onAddScriptOnServerSide = (
    state: ExternalLibraryState,
    action: ReturnType<typeof addScriptOnServerSide>,
): ExternalLibraryState => ({
    ...state,
    [action.key]: {
        status: INITIALIZED_SSR,
        url: action.url,
        windowAttribute: action.windowAttribute,
        timeout: action.timeout,
        callbackParameterName: action.callbackParameterName,
    },
});

const onAddScriptOnClientSide = (
    state: ExternalLibraryState,
    action: ReturnType<typeof addScriptOnClientSide>,
): ExternalLibraryState => ({
    ...state,
    [action.key]: {
        status: INITIALIZED_CSR,
        url: action.url,
        windowAttribute: action.windowAttribute,
        timeout: action.timeout,
        callbackParameterName: action.callbackParameterName,
    },
});

const onAddScriptOnClientSideSuccess = (
    state: ExternalLibraryState,
    action: ReturnType<typeof addScriptOnClientSideSuccess>,
): ExternalLibraryState => {
    const library = state[action.key];
    /**
     * If the lib does not already exist at this stage, it is a usage problem. The message is ignored.
     *
     * Use `isLibraryError` only for type guard.
     *
     */
    if (!library || isLibraryError(library)) {
        return state;
    }
    return {
        ...state,
        [action.key]: {
            ...library,
            status: LOADED,
        },
    };
};

const onAddScriptOnClientSideError = (
    state: ExternalLibraryState,
    action: ReturnType<typeof addScriptOnClientSideError>,
): ExternalLibraryState => ({
    ...state,
    [action.key]: {
        message: action.message,
        status: ERROR,
    },
});

const externalLibraryReducer = createReducer(
    initialState,
    on(addScriptOnServerSide, onAddScriptOnServerSide),
    on(addScriptOnClientSide, onAddScriptOnClientSide),
    on(addScriptOnClientSideSuccess, onAddScriptOnClientSideSuccess),
    on(addScriptOnClientSideError, onAddScriptOnClientSideError),
);

export const reducer = (
    state: ExternalLibraryState | undefined,
    action: Action,
) => externalLibraryReducer(state, action);
