import { Coordinates } from "@hermes/api-model-common";
import { Locale } from "@hermes/locale";

import { isBaiduEngine } from "../map-engine";

/**
 * Helper that allows us to generate, depending on the language, the direction URL between an origin and a destination
 */
export const getDirectionUrl =
    (locale: Locale) =>
    (destination: Coordinates, origin?: Coordinates): string => {
        if (isBaiduEngine(locale.countryCode)) {
            return getBaiduDirectionUrl(destination, origin);
        }
        return getGoogleDirectionUrl(destination, origin);
    };

export const getGoogleDirectionUrl = (
    destination: Coordinates,
    origin: Coordinates | undefined,
): string => {
    const originUrl = origin
        ? `&origin=${origin.latitude},${origin.longitude}`
        : "";

    return `https://www.google.com/maps/dir/?api=1${originUrl}&destination=${destination.latitude},${destination.longitude}`;
};

export const getBaiduDirectionUrl = (
    destination: Coordinates,
    origin: Coordinates | undefined,
): string => {
    if (!origin) {
        origin = destination;
    }
    return `https://api.map.baidu.com/direction?origin=latlng:${origin.latitude},${origin.longitude}|name:起点&destination=latlng:${destination.latitude},${destination.longitude}|name:目的地&mode=driving&region=地区&output=html`;
};
