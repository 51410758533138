<section @slideDown class="banner account-creation-banner">
    <div class="banner-message">
        <ng-container *ngIf="isAccountCreatedByWeChat; then accountCreatedByWeChatMessage; else accountCreatedMessage">
        </ng-container>

        <ng-template #accountCreatedMessage>
            <p data-cs-mask class="banner-message-text">
                {{
                    "hermes-global-translations.your-account-has-been-created-info-banner-angular"
                        | translate : { prefix: this.prefix, firstname: this.firstname, lastname: this.lastname }
                }}</p
            >
        </ng-template>
        <ng-template #accountCreatedByWeChatMessage>
            <p
                data-cs-mask
                class="banner-message-text"
                i18n="@@hermes-global-translations.your-account-has-been-created-info-banner-wechat">
                {{ prefix }} {{ firstname }} {{ lastname }} welcome to Hermes.com! Your account has been successfully
                created and linked to your WeChat account.
            </p>
        </ng-template>
    </div>

    <button (click)="close()" (keypress.enter)="close()" class="close-icon">
        <span hSrOnlyNoSnippet i18n="@@hermes_global.close-info-banner-accessibility">Close the notification</span>
        <h-svg-icon [name]="'cross'"></h-svg-icon>
    </button>
</section>
