import { createAction, props } from "@ngrx/store";

import { BannersData, FooterData } from "@hermes/api-model-shell";
import { ShellAppearanceType } from "@hermes/utils-generic/constants";

export const updateBasket = createAction(
    "[Basket] Update Basket ",
    props<{ amount: number }>(),
);
export const fetchBanners = createAction("[Shell] Fetch Banners");
export const fetchBannersSuccess = createAction(
    "[Shell] Fetch Banners Success",
    props<{ data: BannersData }>(),
);
export const fetchBannersFailure = createAction(
    "[Shell] Fetch Banners Failure",
    props<{ error: Error }>(),
);

export const fetchFooterItems = createAction("[Shell] Fetch FooterItems");
export const fetchFooterItemsSuccess = createAction(
    "[Shell] Fetch FooterItems Success",
    props<{ data: FooterData }>(),
);
export const fetchFooterItemsFailure = createAction(
    "[Shell] Fetch FooterItems Failure",
    props<{ error: Error }>(),
);

export const setShell = createAction(
    "[Shell] Set current shell mode",
    props<{
        shell: ShellAppearanceType;
    }>(),
);
