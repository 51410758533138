import {
    CHECKOUT_BLOCKED_ACCOUNT,
    CHECKOUT_INVALID_DOB,
    CHECKOUT_INVALID_EMAIL,
    CHECKOUT_INVALID_EMAIL_PASSWORD,
    CHECKOUT_INVALID_PHONE_PASSWORD,
    CHECKOUT_PAYMENT_AUTHORIZATION_ERROR,
    CHECKOUT_PAYMENT_INFORMATION_ERROR,
    CHECKOUT_PAYMENT_REJECTED,
    CHECKOUT_PAYMENT_STOCK_ERROR,
    CHECKOUT_RECURRING_CONDITIONS_REQUIRED,
    CHECKOUT_REQUIRED_INFORMATION,
    CHECKOUT_SECURITY_CARD_ERROR,
    CHECKOUT_STOCK_ISSUE,
    CHECKOUT_TERMS_CONDITIONS_REQUIRED,
    CHECKOUT_TRY_LATER,
    EVENT_CATEGORY_CHECKOUT_ERROR,
} from "../constants/conversion-funnel-common.constants";
import { GTMEventData } from "../types/gtm.type";

import { CheckoutAnalyticsEvent } from "./checkout.event";

export class CheckoutErrorAnalyticsEvent extends CheckoutAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        switch (this.eventData.eventType) {
            case CHECKOUT_STOCK_ISSUE: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Stock_issue",
                    eventlabel: this.eventData.sku,
                };
            }
            case CHECKOUT_INVALID_DOB: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Birthdate",
                };
            }
            case CHECKOUT_INVALID_EMAIL: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Invalid_email",
                };
            }
            case CHECKOUT_INVALID_EMAIL_PASSWORD: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Invalid_email_password",
                };
            }
            case CHECKOUT_INVALID_PHONE_PASSWORD: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Invalid_phone_password",
                };
            }
            case CHECKOUT_BLOCKED_ACCOUNT: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Blocked_account",
                };
            }
            case CHECKOUT_PAYMENT_STOCK_ERROR: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Payment_stock",
                    eventlabel: this.eventData.sku,
                };
            }
            case CHECKOUT_PAYMENT_REJECTED: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Payment_rejected",
                };
            }
            case CHECKOUT_PAYMENT_INFORMATION_ERROR: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Payment_tocheck",
                };
            }
            case CHECKOUT_PAYMENT_AUTHORIZATION_ERROR: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Payment_autho_reject",
                };
            }
            case CHECKOUT_REQUIRED_INFORMATION: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Required_information",
                };
            }
            case CHECKOUT_TERMS_CONDITIONS_REQUIRED: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Terms_conditions",
                };
            }
            case CHECKOUT_RECURRING_CONDITIONS_REQUIRED: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Pre_order",
                };
            }
            case CHECKOUT_SECURITY_CARD_ERROR: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Security_card",
                };
            }
            case CHECKOUT_TRY_LATER: {
                return {
                    event: this.name,
                    eventcategory: EVENT_CATEGORY_CHECKOUT_ERROR,
                    eventaction: "Generic_message",
                };
            }
            default: {
                return {
                    ...super.formatForGTM(),
                };
            }
        }
    }
}
