import { Inject, Injectable } from "@angular/core";

import { Context, LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

import {
    NOT_LOADED,
    NO_CONFIGURATION,
    NO_ENGRAVING,
    PersoConfigurator,
    PersoConfiguratorState,
    PlatformeInitialConfiguration,
    EngravingConfiguration,
    PersoParsedUrl,
    ProductCategory,
} from "../types/product-personalization.type";

import { buildProductPersoUrl } from "./../mappers/product-personalization.mapper";

@Injectable()
export class UrlProductPersoHelper {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private context: Context,
    ) {}

    /**
     * return the current full url
     */
    public getCurrentUrl = (
        productCategory: ProductCategory,
        urlParsedParameters: PersoParsedUrl,
    ): string =>
        `${this.context.getCurrentOrigin()}${
            this.locale.urlPrefix
        }/${buildProductPersoUrl(productCategory, urlParsedParameters)}`;
}

/*
 * check if configurator is loaded
 */
export const isPersoConfigurator = (
    configurator: PersoConfiguratorState,
): configurator is PersoConfigurator => configurator !== NOT_LOADED;

/*
 * check if engravingConfiguration is loaded
 */
export const isEngravingConfiguration = (
    engravingConfiguration: EngravingConfiguration | typeof NO_ENGRAVING,
): engravingConfiguration is EngravingConfiguration =>
    engravingConfiguration !== NO_ENGRAVING;

/*
 * check if platformeInitialConfiguration is loaded
 */
export const isPlatformeInitialConfiguration = (
    platformeInitialConfiguration:
        | PlatformeInitialConfiguration
        | typeof NO_CONFIGURATION,
): platformeInitialConfiguration is PlatformeInitialConfiguration =>
    platformeInitialConfiguration !== NO_CONFIGURATION;

export const areSkuEquivalent = (
    sku1: string | undefined,
    sku2: string | undefined,
): boolean => {
    if (!sku1 || !sku2) {
        return false;
    }
    return sku1.replace(" ", "v") === sku2.replace(" ", "v");
};
