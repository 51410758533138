import {
    animate,
    animateChild,
    query,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
} from "@angular/core";

import { WINDOW } from "@hermes/app-core";
import { AnalyticsService } from "@hermes/utils/analytics";

import { SearchEvent } from "../../events/search.event";
import { Suggestion } from "../../model/suggestions";

@Component({
    selector: "h-header-search-suggestions",
    templateUrl: "./header-search-suggestions.component.html",
    styleUrls: ["./header-search-suggestions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("animateContainer", [
            state("open", style({ opacity: 1, height: "*" })),
            state("closed", style({ opacity: 0, height: 0 })),
            transition("closed => open", [
                animate("100ms ease-out"),
                query("@animateSuggestions", animateChild()),
            ]),
            transition("open => closed", [animate("100ms ease-out")]),
        ]),
        trigger("animateSuggestions", [
            state("open", style({ opacity: 1, transform: "none" })),
            state("closed", style({ opacity: 0, transform: "translateX(5%)" })),
            transition("closed => open", [animate("300ms ease-out")]),
        ]),
    ],
})
export class HeaderSearchSuggestionsComponent {
    @Input()
    public suggestions?: Suggestion[];

    @Input()
    public searchTerm!: string;

    @Input()
    public selected: number | undefined;

    constructor(
        @Inject(WINDOW) private window: Window,
        private analyticsService: AnalyticsService,
    ) {}

    public getSuggestionsState(): "open" | "closed" {
        return this.suggestions ? "open" : "closed";
    }

    public sendSearchEvent(suggestionLabel: string, position: number): void {
        this.analyticsService.sendData(
            new SearchEvent({
                eventaction: "Search suggest list",
                eventlabel: `${this.searchTerm} : ${suggestionLabel}`,
                eventvalue: position,
            }),
        );
    }

    public redirect(suggestion: Suggestion, position: number): void {
        this.sendSearchEvent(suggestion.label, position);
        this.window.location.href = suggestion.url;
    }
}
