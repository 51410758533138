import { DOCUMENT } from "@angular/common";
import { Directive, HostListener, Inject, Input } from "@angular/core";

import { Logger } from "@hermes/logger";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { LOGGER } from "@hermes/web-logger";

/**
 * @description
 *
 * An attribute directive that set focus on another element. When triggering keyboard.enter event for the containing HTML element, the focus is set to element matching selector.
 * HTML element to focus is found with a querySelector on document. Prevents further propagation and default action on keyboard.enter event.
 *
 * The `hFocus` directive is most commonly used to ensure that keyboard navigation is working as intended. Skip links, used to bypass content, can be seen as an example.
 *
 * @usageNotes
 *
 * Set focus to an HTML element with a specific id.
 *
 * ```
 * <some-element [hFocus]="'#idValue'">...</some-element>
 * ```
 *
 * Set focus to an HTML element with a specific class.
 *
 * ```
 * <some-element [hFocus]="'.clazz'">...</some-element>
 * ```
 *
 * When no HTML element matches the selector, focus doesn't change but event's propagation and default action are still prevented.
 * When no selector is provided to `hFocus` directive, nothing happens and warning logs are printed.
 */
@Directive({ selector: "[hFocus]" })
export class FocusDirective {
    /**
     * Set focus to element on keyboard.enter event.
     * Define element to focus via selector : `[hFocus]="'#id'"` or `[hFocus]="'.clazz'"`
     */
    @Input()
    public hFocus: string | undefined;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOGGER) private logger: Logger,
    ) {}

    @HostListener("keydown.enter", ["$event"])
    public setFocus(event: KeyboardEvent): void {
        if (this.hFocus) {
            stopEventPropagation(event);
            const elementToFocus = this.document.querySelector<HTMLElement>(
                this.hFocus,
            );

            if (elementToFocus) {
                elementToFocus.focus();
            } else {
                this.logger.debug(
                    `No element to focus with selector : ${this.hFocus}`,
                );
            }
        } else {
            this.logger.warn("No selector for hFocus directive");
        }
    }
}
