import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
    catchError,
    filter,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from "rxjs/operators";

import { ProductUtilsService } from "@hermes/fragments/product-utils";
import { Logger } from "@hermes/logger";
import { addToCartFailure, addToCartSuccess } from "@hermes/states/basket";
import {
    ConfigMessageError,
    ERROR_CART_MESSAGE,
    ProductTemplateType,
} from "@hermes/utils/constants";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";
import { LOGGER } from "@hermes/web-logger";

import {
    fetchCrossSellProduct,
    fetchCrossSellProductFailure,
    fetchCrossSellProductSuccess,
    fetchProduct,
    fetchProductFailure,
    fetchProductSuccess,
    selectVariant,
    updateProductHeadTag,
} from "../actions/product-page.actions";
import {
    selectCurrentProduct,
    selectProduct,
} from "../selectors/product.selectors";
import { CrossSellingService } from "../services/cross-selling.service";
import { FetchProductService } from "../services/fetch-product.service";
import { ProductPageHeadService } from "../services/head/product-page-head.service";

import * as fromProduct from "./../reducers/product-page.state";

/**
 * Effects on product page to call services or external API
 */
@Injectable()
export class ProductPageEffects {
    public fetchProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchProduct),
            switchMap(({ sku, isVariant }) =>
                this.fetchProductService.fetchProductWithSku(sku).pipe(
                    map((response) =>
                        fetchProductSuccess({
                            data: response,
                            isVariant,
                        }),
                    ),
                    catchError((error) =>
                        of(fetchProductFailure({ error, sku })),
                    ),
                ),
            ),
        ),
    );

    public updateProductHeadTag$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateProductHeadTag),
                withLatestFrom(this.store.select(selectProduct)),
                tap(([action, state]) => {
                    this.productPageHeadService.initHeadTag(
                        action.product,
                        action.productReference,
                        state.crossSell,
                    );
                }),
            ),
        { dispatch: false },
    );

    public fetchCrossSellProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchCrossSellProduct),
            withLatestFrom(this.store.select(selectCurrentProduct)),
            switchMap(([_action, product]) =>
                this.crossSellingService
                    .fetchCrossSellingProducts(product)
                    .pipe(
                        map((response) =>
                            fetchCrossSellProductSuccess({ data: response }),
                        ),
                        catchError((error) =>
                            of(fetchCrossSellProductFailure({ error })),
                        ),
                    ),
            ),
        ),
    );

    public fetchProductFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchProductFailure),
                map(({ error, sku }) => {
                    this.logger.error(
                        `An '${error.name}' error occured when fetching product data with sku '${sku}': ${error.message}`,
                    );
                    throw error;
                }),
            ),
        { dispatch: false },
    );

    public fetchCrossSellProductFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchCrossSellProductFailure),
                tap(({ error }) => {
                    this.logger.warn(
                        `An '${error.name}' error occured when fetching cros-sell data: ${error.message}`,
                    );
                }),
            ),
        { dispatch: false },
    );

    public initAddToCartCallToAction$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fetchProductSuccess),
                map((action) =>
                    this.productUtilsService.initAddToCartCallToAction(
                        action.data,
                        action.isVariant,
                    ),
                ),
            ),
        { dispatch: false },
    );

    public selectVariant$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectVariant),
                withLatestFrom(this.store.select(selectCurrentProduct)),
                map(([action, product]) => {
                    if (
                        action.variantItem.sku !== product.sku &&
                        // Variant changes for Look & Bikini are managed into ProductListItemService.
                        // It is not necessary to pass in condition
                        product.templateType !== ProductTemplateType.Bikini &&
                        product.templateType !== ProductTemplateType.Look
                    ) {
                        return this.productUtilsService.setAddToCartToDefault(
                            action.position ?? 0,
                        );
                    }
                    return of();
                }),
            ),
        { dispatch: false },
    );

    public addToCartSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartSuccess),
                filter((payload) => payload.displayNotifOnSuccess),
                tap((payload) => {
                    this.productUtilsService.openSuccessAddToCartModal(payload);
                }),
            ),
        { dispatch: false },
    );

    public addToCartFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartFailure),
                filter((payload) => Boolean(payload.shouldOpenModal)),
                tap((payload) => {
                    if (payload.error.internal_code) {
                        const configMessageError:
                            | ConfigMessageError
                            | undefined =
                            ERROR_CART_MESSAGE.get(
                                payload.error.internal_code.toString(),
                            ) ??
                            ERROR_CART_MESSAGE.get(
                                EcomErrorCodes.ERROR_CODE_CART_DEFAULT,
                            );

                        this.productUtilsService.openFailedAddToCartModal(
                            configMessageError?.translation ?? "",
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        @Inject(LOGGER) private logger: Logger,
        private fetchProductService: FetchProductService,
        private store: Store<fromProduct.ProductPageState>,
        private productPageHeadService: ProductPageHeadService,
        private crossSellingService: CrossSellingService,
        private productUtilsService: ProductUtilsService,
    ) {}
}
