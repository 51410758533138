import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
} from "@angular/core";

@Directive({
    selector: "[hModalPositioning]",
})
export class ModalPositioningDirective implements OnChanges {
    @Input()
    public topOffset?: number;

    @Input()
    public leftOffset?: number;

    constructor(private element: ElementRef) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["topOffset"] && this.topOffset) {
            this.element.nativeElement.style.top = `${this.topOffset}px`;
        }
        if (changes["leftOffset"] && this.leftOffset) {
            this.element.nativeElement.style.left = `${this.leftOffset}px`;
        }
    }
}
