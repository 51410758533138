import { DOCUMENT } from "@angular/common";
import {
    makeStateKey,
    ApplicationRef,
    Inject,
    Injectable,
    NgZone,
    Renderer2,
    RendererFactory2,
    TransferState,
} from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
    catchError,
    filter,
    interval,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
    withLatestFrom,
    timeout as rxjsTimeout,
} from "rxjs";

import { Context, WINDOW } from "@hermes/app-core";

import {
    load,
    addScriptOnServerSide,
    addScriptOnClientSide,
    addScriptOnClientSideSuccess,
    addScriptOnClientSideError,
    addScriptOnServerSideSuccess,
    LoadingExternalScriptAction,
} from "../actions/external-library.actions";
import { ExternalLibraryState } from "../reducer/external-library.state";
import { selectState } from "../selectors/external-library.selectors";

export const EXTERNAL_LIBRARY_KEY =
    makeStateKey<string[]>("external-libraries");

/**
 * The amount of time you have to wait before checking that the script is actually loaded.
 */
export const DEFAULT_TIMEFRAME = 100;
/**
 * Default value of the timout from which the script is considered not loaded.
 */
export const DEFAULT_TIMEOUT = 10_000;

type LoadScriptElementInHeadSuccess = Pick<
    LoadingExternalScriptAction,
    "key" | "windowAttribute" | "timeout"
>;
type LoadScriptElementInHeadError = LoadingExternalScriptAction & {
    error: Error;
};

const isLoadScriptElementInHeadError = (
    result: LoadScriptElementInHeadSuccess | LoadScriptElementInHeadError,
): result is LoadScriptElementInHeadError =>
    (result as LoadScriptElementInHeadError).error !== undefined;

@Injectable()
export class ExternalLibraryEffects {
    public readonly renderer: Renderer2;

    /**
     * Depending on the execution context, we set the rel value of the <link> element with our script to load (server side)
     *
     * Otherwise (browser side), we add the script tag and listen to its loading
     */
    public load$: Observable<
        ReturnType<typeof addScriptOnClientSide | typeof addScriptOnServerSide>
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(load),
            withLatestFrom(this.store.select(selectState)),
            // If the key is not already present in the State (= the tags are not already added), then we go to the next step
            filter(([{ key }, state]) => !state[key]),
            map(([loadParameters]) => {
                if (this.context.isInServerMode()) {
                    return addScriptOnServerSide(loadParameters);
                }
                return addScriptOnClientSide(loadParameters);
            }),
        ),
    );

    /**
     * Effect that prepares the client to download the script.
     *
     * by adding different element in the <head> of the page, on server side.
     */
    public addScriptOnServerSide$: Observable<
        ReturnType<typeof addScriptOnServerSideSuccess>
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(addScriptOnServerSide),
            filter(() => this.context.isInServerMode()),
            map(({ url, key, callbackParameterName }) => {
                let constructedUrl = url;
                /**
                 * If a callback name to put in parameter is provided, then a particular treatment must be done
                 *
                 * The main idea is not to have this callback declared too late, in case the library loads before Angular has completely built its page.
                 *
                 * We choose here to write JS directly in the Window object.
                 *
                 * We will declare the callback function and a flag (boolean) that will pass from false to true in case of loading.
                 */
                if (callbackParameterName) {
                    /**
                     * The marker is what will identify the loading flag in the Window object.
                     */
                    const marker = this.getMarkerName(key);

                    /**
                     * The name of the function is the function that we will give as a script to call
                     * to the external library to signify that the loading is finished.
                     */
                    const functionName = this.getFunctionName(key);

                    /**
                     * The script that we will inject in the head of the page (and minify)
                     */
                    const script = `
                        window['${marker}'] = false;
                        window['${functionName}'] = () => {
                            window['${marker}'] = true;
                        }
                    `;
                    this.addScriptFromJavascript(script.replace(/\s+/g, ""));

                    /**
                     * In the script to be called, we add a parameter (often named `callback`)
                     * with the name of the function (that we created before) to be called when it has finished loading.
                     */
                    const urlConstructor = new URL(url);
                    urlConstructor.searchParams.append(
                        callbackParameterName,
                        functionName,
                    );
                    constructedUrl = urlConstructor.toString();
                }

                /**
                 * With or without callback, we add the <link> element and the <script> element with the final constructed url
                 */
                this.addLinkElementInHead(constructedUrl);
                this.addScriptFromExternalSource(constructedUrl);
                this.addScriptInTransferState(key);
                return addScriptOnServerSideSuccess({ key });
            }),
        ),
    );

    /**
     * Effect that prepares the client to download the script.
     *
     * by adding different element in the <head> of the page, on server side.
     */
    public addScriptOnClientSide$: Observable<
        ReturnType<
            | typeof addScriptOnClientSideError
            | typeof addScriptOnClientSideSuccess
        >
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(addScriptOnClientSide),
            filter(() => this.context.isInBrowserMode()),
            switchMap(
                ({
                    key,
                    url,
                    windowAttribute,
                    timeout,
                    callbackParameterName,
                }) => {
                    const isScriptInTransferState = this.isInTransferState(key);
                    /**
                     * If the script is in the Transfer State then it is considered with confidence that
                     * it has been added in the <head> of the page and therefore, it is already loaded or loading.
                     */
                    if (isScriptInTransferState) {
                        const marker = this.getMarkerName(key);
                        /**
                         * If there is a callback parameter AND it has not already been called,
                         * then we replace it with an "observed" callback (we rewrite the callback function)
                         */
                        if (
                            callbackParameterName &&
                            this.window[marker] === false
                        ) {
                            return this.listenForCallback(
                                key,
                                windowAttribute,
                                timeout,
                            );
                        }
                        /**
                         * Otherwise we consider that the lib is either already loaded or being loaded BUT without callback
                         */
                        return of({
                            key,
                            url,
                            windowAttribute,
                            timeout,
                        });
                    }

                    /**
                     * If the script is NOT in the Transfer, we have to add the `<script>` element in the `<head>` of the page.
                     *
                     * If the callbackParameterName is provided, then we set up an Observable to listen to the loading of the lib
                     */
                    if (callbackParameterName) {
                        const urlConstructor = new URL(url);
                        urlConstructor.searchParams.append(
                            callbackParameterName,
                            this.getFunctionName(key),
                        );
                        /**
                         * Adding the script in the Head ...
                         */
                        this.addScriptFromExternalSource(
                            urlConstructor.toString(),
                        );

                        /**
                         * And we wait directly for the callback to be called
                         */
                        return this.listenForCallback(
                            key,
                            windowAttribute,
                            timeout,
                        );
                    }

                    /**
                     * Finally if there is not callback, we simply add the script in the head of the page
                     * and wait for it to load using the `load` event of the Renderer.
                     */
                    const script = this.addScriptFromExternalSource(url);
                    return this.loadScriptElementInHead(
                        script,
                        key,
                        windowAttribute,
                    );
                },
            ),
            switchMap((result) => {
                const { key, windowAttribute, timeout } = result;
                if (isLoadScriptElementInHeadError(result)) {
                    return of(
                        addScriptOnClientSideError({
                            key,
                            // eslint-disable-next-line unicorn/consistent-destructuring
                            message: result.error.toString(),
                        }),
                    );
                }
                /**
                 * At this step, whatever the loading mode, the script is declared in the head of the page.
                 *
                 * We now check that it is correctly loaded AND available for use.
                 */
                if (this.markerIsInWindow(windowAttribute)) {
                    return of(addScriptOnClientSideSuccess({ key }));
                }

                /**
                 * If not we check every 100 ms that the windowAttribute is available in the window object.
                 *
                 * An error is raised after 5 seconds.
                 */
                return this.waitingForLoading(
                    key,
                    windowAttribute,
                    timeout,
                ).pipe(
                    map(() => addScriptOnClientSideSuccess({ key })),
                    catchError((error) =>
                        of(
                            addScriptOnClientSideError({
                                key,
                                message: error.toString(),
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    /**
     * In case of successful loading, we always delete the attributes added in the window (callback management)
     */
    public cleanWindow$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    addScriptOnClientSideSuccess,
                    addScriptOnClientSideError,
                ),
                tap(({ key }) => {
                    delete this.window[this.getMarkerName(key)];
                    delete this.window[this.getFunctionName(key)];
                }),
            ),
        { dispatch: false },
    );

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(WINDOW)
        private readonly window: Window & Record<string, unknown>,
        protected readonly rendererFactory: RendererFactory2,
        private readonly actions$: Actions,
        private context: Context,
        private store: Store<ExternalLibraryState>,
        private applicationReference: ApplicationRef,
        private transferState: TransferState,
        private ngZone: NgZone,
    ) {
        // eslint-disable-next-line unicorn/no-null
        this.renderer = rendererFactory.createRenderer(this.document, null);
    }

    public waitingForLoading(
        key: string,
        windowAttribute: string,
        timeout?: number,
    ): Observable<{ key: string }> {
        /**
         * We calculate the number of iterations we have to do
         * before we consider that the script is not loaded.
         */
        const iterationNumber = Math.ceil(
            (timeout ?? DEFAULT_TIMEOUT) / DEFAULT_TIMEFRAME,
        );

        return this.applicationReference.isStable.pipe(
            filter((isStable) => isStable),
            switchMap(() => interval(DEFAULT_TIMEFRAME)),
            filter(
                (value) =>
                    value >= iterationNumber ||
                    this.markerIsInWindow(windowAttribute),
            ),
            switchMap(() => {
                if (!this.markerIsInWindow(windowAttribute)) {
                    return throwError(
                        () =>
                            new Error(
                                `${key} scripts are not loaded after ${
                                    timeout ?? DEFAULT_TIMEOUT
                                } ms`,
                            ),
                    );
                }
                return of({ key });
            }),
            take(1),
        );
    }

    public markerIsInWindow(windowAttribute: string): boolean {
        return !!this.window[windowAttribute];
    }

    /**
     * We return an Observable that emits when the script is loaded
     */
    public loadScriptElementInHead(
        script: HTMLScriptElement,
        key: string,
        windowAttribute: string,
        timeout?: number,
    ): Observable<
        LoadScriptElementInHeadError | LoadScriptElementInHeadSuccess
    > {
        return new Observable((observer) => {
            this.renderer.listen(script, "load", () => {
                observer.next({ key, windowAttribute, timeout });
                observer.complete();
            });

            this.renderer.listen(script, "onerror", (error) => {
                observer.next({ key, windowAttribute, timeout, error });
                observer.complete();
            });
        });
    }

    /**
     * We return an Observable that emits when the script is loaded
     */
    public listenForCallback(
        key: string,
        windowAttribute: string,
        timeout?: number,
    ): Observable<
        LoadScriptElementInHeadError | LoadScriptElementInHeadSuccess
    > {
        return new Observable<LoadScriptElementInHeadSuccess>((observer) => {
            this.window[this.getFunctionName(key)] = () => {
                this.ngZone.run(() => {
                    observer.next({
                        key,
                        windowAttribute,
                        timeout,
                    });
                });
            };
        }).pipe(
            rxjsTimeout(timeout ?? DEFAULT_TIMEOUT),
            take(1),
            catchError(() =>
                of({
                    key,
                    windowAttribute,
                    timeout,
                    error: "The library took too long to load",
                }),
            ),
        );
    }

    /**
     * Build and add `<script>` element in the document head.
     */
    public addScriptFromJavascript(jsScript: string): void {
        // Building the <script> element
        const script: HTMLScriptElement = this.renderer.createElement("script");
        this.renderer.setAttribute(script, "type", "text/javascript");
        script.text = jsScript;
        // Add it in the document.head
        this.renderer.appendChild(this.document.head, script);
    }

    /**
     * Build and add `<script>` element in the document head.
     */
    public addScriptFromExternalSource(url: string): HTMLScriptElement {
        // Building the <script> element
        const script: HTMLScriptElement = this.renderer.createElement("script");
        this.renderer.setAttribute(script, "type", "text/javascript");
        this.renderer.setAttribute(script, "src", url);
        /**
         * For the moment, the feature does not allow to choose the priority of loading the script.
         * A future version could allow us to introduce priority notions.
         *
         * see https://addyosmani.com/blog/script-priorities/ for more details.
         */
        this.renderer.setAttribute(script, "defer", "true");

        // Add it in the document.head
        this.renderer.appendChild(this.document.head, script);

        return script;
    }

    /**
     * Build and add `<link>` element in the document head.
     */
    public addLinkElementInHead(url: string): HTMLLinkElement {
        const link: HTMLLinkElement = this.renderer.createElement("link");
        this.renderer.setAttribute(link, "rel", "preconnect");
        this.renderer.setAttribute(link, "href", url);

        // Add it in the document.head
        this.renderer.appendChild(this.document.head, link);

        return link;
    }

    /**
     * Add in the dedicated list of the Transfer State, the key of the added script.
     *
     * This will then allow to re-build the State more quickly on the Browser side.
     *
     * /!\ To be used exclusively on the server side.
     */
    public addScriptInTransferState(key: string): void {
        const externalLibraryTransferState = this.transferState.get(
            EXTERNAL_LIBRARY_KEY,
            [],
        );
        externalLibraryTransferState.push(key);
        this.transferState.set(
            EXTERNAL_LIBRARY_KEY,
            externalLibraryTransferState,
        );
    }

    /*
     * Returns a boolean to describe whether the script name is in the Transfer State or not.
     */
    public isInTransferState(key: string): boolean {
        return this.transferState.get(EXTERNAL_LIBRARY_KEY, []).includes(key);
    }

    /**
     * The marker is what will identify the loading flag in the Window object.
     */
    public getMarkerName(key: string): string {
        return `externalLibrary_${key.toLowerCase()}`;
    }

    /**
     * The name of the function is the function that we will give as a script to call
     * to the external library to signify that the loading is finished.
     */
    public getFunctionName(key: string): string {
        return `${this.getMarkerName(key)}_callback`;
    }
}
