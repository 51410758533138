import { ProductTemplateType } from "@hermes/utils/constants";

import {
    BELTKIT,
    BK,
    DOUBLE_PRODUCT_SEPARATOR,
    NOT_SUPPORTED,
    SEPARATOR,
    SILK,
    SMALL_LEATHER_GOODS,
    SMLG,
    SOIE,
} from "../constant/product-personalization.constant";
import {
    SpecialCharacters,
    MarkingRule,
    RadioButtonItemModel,
    RadioButtonModel,
    RadioButtonType,
    CapaPersoNames,
    Color,
    ParsedRoute,
    PersoParsedUrl,
    ProductCategory,
    RulesDescription,
} from "../types/product-personalization.type";

/**
 * Re-format the sku
 *
 * @param sku The sku of the product, for silk it can be something like "H893502S 03"
 */
export const formatSku = (sku: string): string => sku.replace(" ", "v");

/**
 * Return the product category name used by ecom to call capa
 */
export const getCapaPersoNames = (
    productCategory: ProductCategory,
): CapaPersoNames => {
    switch (productCategory) {
        case BELTKIT: {
            return BK;
        }
        case SMALL_LEATHER_GOODS: {
            return SMLG;
        }
        case SILK: {
            return SOIE;
        }
        case NOT_SUPPORTED: {
            throw new Error(
                `we can not find the CapaPersoNames of ${productCategory}`,
            );
        }
    }
};

export const getProductTemplateType = (
    productType: ProductCategory,
): ProductTemplateType | undefined => {
    if (productType === BELTKIT) {
        return ProductTemplateType.PersoBeltkit;
    }
    if (productType === SMALL_LEATHER_GOODS) {
        return ProductTemplateType.PersoSMLG;
    }
    if (productType === SILK) {
        return ProductTemplateType.PersoSilk;
    }
    return undefined;
};

/**
 * Allow to build product url with current configurator infos
 */
export const buildProductPersoUrl = (
    productCategory: ProductCategory,
    urlParsedParameters: PersoParsedUrl,
): string => {
    const firstSku = urlParsedParameters.firstSku ?? "";
    const secondSku = urlParsedParameters.secondSku
        ? `-${urlParsedParameters.secondSku}`
        : "";
    const productDku = urlParsedParameters.productDku
        ? `/${urlParsedParameters.productDku}`
        : "";
    return `product/${productCategory}/config/${urlParsedParameters.slugWithSku}/#${firstSku}${secondSku}${productDku}`;
};

/**
 * Extract all information from the url && set the configurator
 * product/sku/config/type-product/#sku1-[sku2]/dku
 */
export const parsePersoUrl = (parsedRouter: ParsedRoute): PersoParsedUrl => {
    const slugWithSku = parsedRouter.slug;
    // extract default sku
    const defaultSku = parsedRouter.slug?.split(DOUBLE_PRODUCT_SEPARATOR).pop();

    const productCategory = parsedRouter.productCategory;

    let urlParsedParameters: PersoParsedUrl = {
        slugWithSku,
        defaultSku,
        firstSku: defaultSku,
        secondSku: "",
        requireConfCall:
            productCategory === SMALL_LEATHER_GOODS ||
            productCategory === BELTKIT,

        originalUrl: parsedRouter.originalUrl,
    };

    if (parsedRouter.fragment) {
        // extract skus
        const skus = parsedRouter.fragment.split(SEPARATOR)[0];

        if (skus && skus.includes(DOUBLE_PRODUCT_SEPARATOR)) {
            const firstSku = skus.split(DOUBLE_PRODUCT_SEPARATOR)[0];
            const secondSku = skus.split(DOUBLE_PRODUCT_SEPARATOR)[1];
            urlParsedParameters = {
                ...urlParsedParameters,
                firstSku,
                secondSku,
            };
        } else {
            urlParsedParameters = { ...urlParsedParameters, firstSku: skus };
        }

        // extract DKU
        const productDku = parsedRouter.fragment.split(SEPARATOR)[1];

        urlParsedParameters = { ...urlParsedParameters, productDku };
    }
    return urlParsedParameters;
};

/**
 * allow to format the sku in the expected magento format
 */
export const formatSkuForEcomCall = (
    urlParsedParameters: PersoParsedUrl,
    productCategory: ProductCategory,
): string => {
    if (
        productCategory === BELTKIT ||
        productCategory === SMALL_LEATHER_GOODS
    ) {
        const genericSku = urlParsedParameters.defaultSku;
        const secondSku = urlParsedParameters.secondSku
            ? `-${urlParsedParameters.secondSku}`
            : "";
        const firstSku = urlParsedParameters.firstSku;
        return `${genericSku}${secondSku}-${firstSku}`;
    }
    if (productCategory === SILK && urlParsedParameters.firstSku) {
        return urlParsedParameters.firstSku.replace("v", " ");
    }
    return "";
};

export const getRadioButtonModelElement = (
    expandable: boolean,
    type: RadioButtonType,
    name: string,
    items: RadioButtonItemModel[],
    label: boolean | undefined,
    hasDefault: boolean = true,
): RadioButtonModel => {
    const radioButton = {} as RadioButtonModel;
    radioButton.type = type;
    radioButton.expandable = expandable;
    if (label !== undefined) {
        radioButton.label = label;
    }
    radioButton.name = name;
    radioButton.items = items;
    radioButton.hasDefault = hasDefault;
    return radioButton;
};

export const getRadioButtonModelFontStyle = (
    items: RadioButtonItemModel[],
): RadioButtonModel =>
    getRadioButtonModelElement(
        false,
        RadioButtonType.Text,
        "fontStyle",
        items,
        true,
    );

export const getRadioButtonModelFontSize = (
    items: RadioButtonItemModel[],
): RadioButtonModel =>
    getRadioButtonModelElement(
        false,
        RadioButtonType.Text,
        "fontSize",
        items,
        true,
    );

export const getRadioButtonModelFontColor = (
    items: RadioButtonItemModel[],
    hexaColor = false,
): RadioButtonModel => {
    const type = hexaColor ? RadioButtonType.Color : RadioButtonType.Text;
    return getRadioButtonModelElement(true, type, "fontColor", items, true);
};
/**
 * Allow to map a specific leather with color
 */
export const mapColorsForSpecificLeather = (
    colors: string[],
    defaultColor: string,
    checkDefault: boolean,
    unallowedUnicolor: string[],
): Color[] => {
    const mappedColors: Color[] = [];
    colors.forEach((color: string) => {
        mappedColors.push({
            label: color,
            default: checkDefault && color === defaultColor,
            unicolorAllowed: !unallowedUnicolor.includes(color),
        });
    });
    return mappedColors;
};

/**
 * Allow to create leather marking rules
 */
export const getLeatherMarkingRules = (
    fetchedLuckySigns: SpecialCharacters[],
): MarkingRule[] => {
    // Get all values of lucky signs to create regexp
    const luckySignsList: string[] = fetchedLuckySigns.map(
        (luckySign: SpecialCharacters) => luckySign.value,
    );
    return [
        {
            ruleDescription: RulesDescription.GlobalPattern,
            maxOccurrence: 8,
            regExp: new RegExp(`[A-Za-z&.\\-${luckySignsList.join("")}]`, "g"),
            partielPattern: false,
        },
        {
            ruleDescription: RulesDescription.Letter,
            maxOccurrence: 3,
            regExp: /[A-Za-z]/g,
            partielPattern: true,
        },
        {
            ruleDescription: RulesDescription.SpeCharAmpersand,
            maxOccurrence: 1,
            regExp: /&/g,
            partielPattern: true,
        },
        {
            ruleDescription: RulesDescription.SpeCharDot,
            maxOccurrence: 8,
            regExp: /\./g,
            partielPattern: true,
        },
        {
            ruleDescription: RulesDescription.SpeCharHyphen,
            maxOccurrence: 8,
            regExp: /-/g,
            partielPattern: true,
        },
        {
            ruleDescription: RulesDescription.LuckySigns,
            maxOccurrence: 1,
            regExp: new RegExp(`[${luckySignsList.join("")}]`, "g"),
            partielPattern: true,
        },
    ];
};
