import { NgModule } from "@angular/core";

import { FetchCountriesModule } from "@hermes/fragments/fetch-countries";

import { AlternateService } from "./alternate-service";
@NgModule({
    imports: [FetchCountriesModule],
    providers: [AlternateService],
    exports: [],
})
export class AlternatesModule {}
