import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { modalAnimations } from "../../animations/modal.animation";

@Component({
    animations: modalAnimations,
    selector: "h-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
    @Output()
    public closeModal: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public role?: "dialog" | "alertdialog";

    @Input({ required: true })
    public ariaLabelledby!: string;

    public isMobile = !this.breakpointService.mediumBreakpointMatcher();

    constructor(private breakpointService: BreakpointService) {}

    public onClose(): void {
        this.closeModal.emit();
    }
}
