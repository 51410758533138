import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Subscription } from "rxjs";

import { Context, LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { BannerService } from "@hermes/states/shell";
import { IsoCountryCodePipe } from "@hermes/utils-generic/pipes/iso-country-code";

import {
    BannerAnalyticsService,
    CLOSE_CLICK_EVENT,
    STAY_CLICK_EVENT,
    SWITCH_COUNTRY_CLICK_EVENT,
} from "../../services/banner-analytics.service";

const COOKIE_GEO_FILTERING_BANNER = "GeoFilteringBanner";
@Component({
    selector: "h-geo-filtering-banner",
    templateUrl: "./geo-filtering-banner.component.html",
    styleUrls: [
        "./geo-filtering-banner.component.scss",
        "../banner-common.scss",
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoFilteringBannerComponent implements OnInit, OnDestroy {
    public isGeoFilteringBannerVisible$$ = new BehaviorSubject<boolean>(false);
    public isGeoFilteringBannerVisible$ =
        this.isGeoFilteringBannerVisible$$.asObservable();

    public subscription: Subscription = new Subscription();

    @Output()
    public closeBanner: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private bannerService: BannerService,
        private isoCountryPipe: IsoCountryCodePipe,
        private cookieService: CookieService,
        private context: Context,
        private bannerAnalyticsService: BannerAnalyticsService,
        private elementReference: ElementRef,
    ) {}

    @HostListener("document:click", ["$event"])
    private clickOutsideComponent(event: Event) {
        if (!this.elementReference.nativeElement.contains(event.target)) {
            this.closeBanner.emit();
        }
    }

    public ngOnInit(): void {
        if (this.context.isInBrowserMode()) {
            this.checkGeoFilteringBannerVisibility();
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public selectAnotherCountry() {
        this.bannerAnalyticsService.sendGeoFilteringClickEvent(
            SWITCH_COUNTRY_CLICK_EVENT,
        );
        // Timeout to let the event propagate to the hTrayOpener directive before the component is destroyed
        setTimeout(() => {
            this.closeBanner.emit();
        }, 100);
    }

    public continueOnHermes(): void {
        this.bannerAnalyticsService.sendGeoFilteringClickEvent(
            STAY_CLICK_EVENT,
        );
        this.closeBanner.emit();
    }

    public close(): void {
        this.bannerAnalyticsService.sendGeoFilteringClickEvent(
            CLOSE_CLICK_EVENT,
        );
        this.closeBanner.emit();
    }

    private checkGeoFilteringBannerVisibility(): void {
        if (this.cookieService.get(COOKIE_GEO_FILTERING_BANNER) !== "1") {
            this.subscription.add(
                this.bannerService
                    .fetchGeoLocation()
                    .subscribe((userLocationIsoCode) => {
                        this.checkBannerVisibility(userLocationIsoCode);
                    }),
            );
        }
    }

    private checkBannerVisibility(
        userLocationIsoCode: string | null | undefined,
    ): void {
        if (this.isUserDifferentLocationAsLocale(userLocationIsoCode)) {
            this.isGeoFilteringBannerVisible$$.next(true);
            this.cookieService.set(COOKIE_GEO_FILTERING_BANNER, "1", 30);
            this.bannerAnalyticsService.sendGeoFilteringImpressionEvent(
                userLocationIsoCode,
            );
        }
    }

    private isUserDifferentLocationAsLocale(
        userLocationIsoCode: string | null | undefined,
    ): boolean {
        return (
            this.isoCountryPipe
                .transform(this.locale.countryCode)
                .toUpperCase() !== userLocationIsoCode?.toUpperCase()
        );
    }
}
