import { NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sanitize",
})
export class SanitizePipe implements PipeTransform {
    public transform(value: string | undefined): string {
        return (value ?? "")
            .toLowerCase()
            .replace(/[^\da-z]/g, "-") // We replace invalid chars by -
            .replace(/-+/g, "-"); // We replace multiple - by only one
    }
}

@NgModule({
    declarations: [SanitizePipe],
    providers: [SanitizePipe],
    exports: [SanitizePipe],
})
export class SanitizePipeModule {}
