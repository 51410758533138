export enum AssetSourceValue {
    Scene7 = "scene7",
    Platforme = "plateforme",
}

export enum AssetTag {
    ENGRAVING = "engraving",
    WORNSQUARE = "wornsquare",
    THUMBNAIL = "thumbnail",
    COMPOSITE = "composite",
    FRONT = "front",
    INFO = "info",
    WORN = "worn",
    CROP = "crop",
    CONTEXT = "context",
    SPORT = "sport",
    BACK = "back",
    FLAT = "flat",
    FOLDED = "folded",
    SIDE = "side",
    ABOVE = "above",
    BELOW = "below",
    DETAIL = "detail",
    BACKCLOSE = "backclose",
    ZOOM = "zoom",
    BACKOPEN = "backopen",
    FRONTCLOSE = "frontclose",
    FRONTOPEN = "frontopen",
}

export enum AssetMediaType {
    Image = "image",
    Video = "video",
}

export enum ScrollType {
    Pinned = "pinned",
    Fixed = "fixed",
}
