import { createFeatureSelector, createSelector } from "@ngrx/store";

import { HeaderBasketState, State } from "../state/shell.state";

// shell selectors
export const selectFeature = createFeatureSelector<State>("shellModule");

export const selectBasket = createSelector(
    selectFeature,
    (shellState: State) => shellState.basket,
);

export const selectBasketTotalItems = createSelector(
    selectBasket,
    (basketState: HeaderBasketState) => basketState.totalItems,
);

export const selectBanners = createSelector(
    selectFeature,
    (shellState: State) => shellState.banners,
);

export const selectFooterItems = createSelector(
    selectFeature,
    (shellState: State) => shellState.footerItems,
);

export const selectshell = createSelector(
    selectFeature,
    (modeState: State) => modeState.shell.mode,
);
