import { DOCUMENT, Location } from "@angular/common";
import {
    Inject,
    Injectable,
    OnDestroy,
    Renderer2,
    RendererFactory2,
} from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first, map } from "rxjs/operators";

import { INFRA_SETTINGS, LOCALE } from "@hermes/app-core";
import { InfraSettings } from "@hermes/env-infra";
import { Locale } from "@hermes/locale";

import { LinkRelation } from "@hermes/utils-generic/constants";

import { datadome, encodingHtmlCharacters } from "./constant";
import { MetatagBuilder } from "./metatag-builder.class";

export interface LinkDefinition {
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
    as?: string;
    fetchpriority?: string;
}

@Injectable({
    providedIn: "root",
})
export class HeadService implements OnDestroy {
    private renderer: Renderer2;
    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(INFRA_SETTINGS) private settings: InfraSettings,
        @Inject(LOCALE) private locale: Locale,
        private rendererFactory: RendererFactory2,
        private meta: Meta,
        private titleService: Title,
        private translateService: TranslateService,
        private location: Location,
    ) {
        this.renderer = this.rendererFactory.createRenderer(
            this.document,
            // eslint-disable-next-line unicorn/no-null
            null,
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public loadDataDome(): void {
        const datadomeOptionsNode = this.createScriptNode();
        this.renderer.appendChild(this.document.head, datadomeOptionsNode);
        datadomeOptionsNode.innerHTML = datadome.replace(
            "{datadomeJsKey}",
            this.settings.datadomeKey,
        );
        const datadomeScriptNode = this.createScriptNode();
        this.renderer.appendChild(this.document.head, datadomeScriptNode);
        datadomeScriptNode.src = "https://dd.hermes.com/tags.js";
        datadomeScriptNode.async = true;
    }

    public addScript(scriptContent: string, type?: string, id?: string): void {
        if (id) {
            const existingNode = this.document.getElementById(id);

            if (existingNode) {
                this.renderer.removeChild(this.document.head, existingNode);
            }
        }
        const node = this.createScriptNode(type, id);
        this.renderer.appendChild(this.document.head, node);
        node.textContent = scriptContent;
    }

    public addRobotsMetaInformations(content: string | undefined): void {
        if (content) {
            this.createOrUpdateTags(
                new MetatagBuilder().addTag("name", "robots", content).build(),
            );
        }
    }

    /**
     * Add meta information so that google robots do not crawl the page
     */
    public addNoIndexMetaInformation(): void {
        this.createOrUpdateTags(
            new MetatagBuilder()
                .addTag("name", "robots", "noindex, nofollow")
                .build(),
        );
    }

    /**
     * Remove meta information about robots (crawler)
     */
    public removeNoIndexMetaInformation(): void {
        this.removeTags(["name='robots'"]);
    }

    /**
     * Remove meta information about robots (crawler)
     */
    public removeScriptById(id: string): void {
        document
            .querySelectorAll<HTMLScriptElement>(`head > script[id="${id}"]`)
            .forEach((script) => {
                script.remove();
            });
    }

    /**
     * Inject current url as canonical url in head tag
     */
    public addCurrentUrlAsCanonicalUrl(): void {
        const currentURL = `${this.settings.frontend}${
            this.locale.urlPrefix
        }${this.location.path()}`;
        this.addCanonicalUrl(currentURL);
    }

    /**
     * Inject current url without get parameters as canonical url in head tag
     */
    public addCurrentUrlWithoutParametersAsCanonicalUrl(): void {
        const currentURL = this.getCurrentUrlWithoutParameters();
        this.addCanonicalUrl(currentURL);
    }
    /**
     * Inject canonical url in head tag
     *
     * @param canonicalUrl Canonical url to add
     */
    public addCanonicalUrl(canonicalUrl: string): void {
        this.cleanCanonicalUrl();
        this.addTag({
            rel: LinkRelation.CANONICAL,
            href: decodeURI(canonicalUrl),
        });
    }

    /**
     * Remove existing canonical URL from head tag
     */
    public cleanCanonicalUrl(): void {
        this.removeLinks(LinkRelation.CANONICAL);
    }

    /**
     * Inject SEO prev url in head tag
     *
     * @param previousPageNumber previous page number
     */
    public addSeoPreviousPageUrl(previousPageNumber: number): void {
        this.addPaginationUrl(LinkRelation.PREV, previousPageNumber);
    }

    /**
     * Inject SEO next url in head tag
     *
     * @param nextPageNumber next page number
     */
    public addSeoNextPageUrl(nextPageNumber: number): void {
        this.addPaginationUrl(LinkRelation.NEXT, nextPageNumber);
    }

    /**
     * Remove existing SEO pagination URLs from head tag
     */
    public cleanSeoPaginationUrls(): void {
        this.removeLinks(LinkRelation.PREV);
        this.removeLinks(LinkRelation.NEXT);
    }

    /**
     * Add preloaded image url to head tag
     */
    public addPreloadImageLink({
        href,
        fetchpriority,
        media,
    }: {
        href: string;
        fetchpriority?: string;
        media?: string;
    }): void {
        this.addTag({
            rel: LinkRelation.PRELOAD,
            href,
            as: "image",
            fetchpriority,
            media,
        });
    }

    /**
     * Remove existing image preload urls from head tags
     */
    public cleanImagePreloadUrls(): void {
        const selector = 'link[rel="preload"][as="image"]';
        const links = this.document.head.querySelectorAll(selector);
        links.forEach((link) => link.remove());
    }

    /**
     * Put meta description in head tag
     *
     * @param description description to add
     */
    public addDescriptionMetaInformations(description?: string): void {
        this.createOrUpdateTags(
            new MetatagBuilder()
                .addTag(
                    "name",
                    "description",
                    this.cleanHtmlEncoding(description, encodingHtmlCharacters),
                )
                .build(),
        );
    }

    /**
     * Remove existing description metadata from head tag
     */
    public cleanDescriptionMetaInformations(): void {
        this.meta.removeTag("name='description'");
    }

    /**
     * Put itemprop metadata in head tag
     *
     * @param name itemprop name
     * @param description itemprop description
     * @param image itemprop image
     */
    public addItemPropMetaInformations(
        name?: string,
        description?: string,
        image?: string,
    ): void {
        this.createOrUpdateTags(
            new MetatagBuilder()
                .addTag(
                    "itemprop",
                    "name",
                    this.cleanHtmlEncoding(name, encodingHtmlCharacters),
                )
                .addTag("itemprop", "image", image)
                .addTag(
                    "itemprop",
                    "description",
                    this.cleanHtmlEncoding(description, encodingHtmlCharacters),
                )
                .build(),
        );
    }

    /**
     * Remove existing itemprop metadata from head tag
     */
    public cleanItemPropMetaInformations(): void {
        this.removeTags([
            "itemprop='name'",
            "itemprop='image'",
            "itemprop='description'",
        ]);
    }

    /**
     * Pug og metadata in head tag
     *
     * @param type og type
     * @param title og title
     * @param description og description
     * @param url og url
     * @param image og image
     * @param updatedTime og updated_time
     */
    public addOgMetaInformations(
        type?: string,
        title?: string,
        description?: string,
        url?: string,
        image?: string,
        updatedTime?: string,
    ): void {
        this.createOrUpdateTags(
            new MetatagBuilder()
                .addTag("property", "og:type", type)
                .addTag(
                    "property",
                    "og:title",
                    this.cleanHtmlEncoding(title, encodingHtmlCharacters),
                )
                .addTag(
                    "property",
                    "og:description",
                    this.cleanHtmlEncoding(description, encodingHtmlCharacters),
                )
                .addTag("property", "og:url", url)
                .addTag("property", "og:image", image)
                .addTag("property", "og:updated_time", updatedTime)
                .build(),
        );
    }

    /**
     * Remove existing OG metadata from head tag
     */
    public cleanOgMetaInformations(): void {
        this.removeTags([
            "property='og:type'",
            "property='og:title'",
            "property='og:description'",
            "property='og:url'",
            "property='og:image'",
            "property='og:updated_time'",
        ]);
    }

    /**
     * Put Twitter metadata in head tag
     *
     * @param card Twitter card
     * @param title Twitter title
     * @param description Twitter description
     * @param url Twitter url
     * @param image Twitter image
     */
    public addTwitterMetaInformations(
        card?: string,
        title?: string,
        description?: string,
        url?: string,
        image?: string,
    ): void {
        this.createOrUpdateTags(
            new MetatagBuilder()
                .addTag("name", "twitter:card", card)
                .addTag(
                    "name",
                    "twitter:title",
                    this.cleanHtmlEncoding(title, encodingHtmlCharacters),
                )
                .addTag(
                    "name",
                    "twitter:description",
                    this.cleanHtmlEncoding(description, encodingHtmlCharacters),
                )
                .addTag("name", "twitter:url", url)
                .addTag("name", "twitter:image", image)
                .build(),
        );
    }

    /**
     * Remove existing Twitter metadata from head tag
     */
    public cleanTwitterMetaInformations(): void {
        this.removeTags([
            "name='twitter:card'",
            "name='twitter:title'",
            "name='twitter:description'",
            "name='twitter:url'",
            "name='twitter:image'",
        ]);
    }

    /**
     * Set a title for the current page
     *
     * @param title Title to use
     * @param withSuffix Add a suffix to the title : | Hermès ${country} (used by default)
     */
    public setTitle(title: string, withSuffix = true): void {
        this.subscription.add(
            this.translateService
                .get("hermes_country_selector_title")
                .pipe(
                    first(),
                    map((countryTitle: string) => {
                        if (!withSuffix) {
                            return this.cleanHtmlEncoding(
                                title,
                                encodingHtmlCharacters,
                            );
                        }
                        return `${this.cleanHtmlEncoding(
                            title,
                            encodingHtmlCharacters,
                        )} | Hermès ${countryTitle}`;
                    }),
                )
                .subscribe((completeTitle) => {
                    this.titleService.setTitle(completeTitle as string);
                }),
        );
    }

    /**
     * Set the title to `Hermès ${country}` for the current page.
     *
     * Used as a fallback for title when none is available
     */
    public setDefaultTitle(): void {
        this.subscription.add(
            this.translateService
                .get("hermes_country_selector_title")
                .pipe(first())
                .subscribe((countryTitle: string) =>
                    this.titleService.setTitle(`Hermès ${countryTitle}`),
                ),
        );
    }

    /**
     * Replace parts of the input in order to have a formatted string for head tag :
     * - Quotes are escaped
     * - BR tags are replaced with a space
     * - Other HTML tags are deleted
     * - Encoded unbreakable space (ie. nbsp) is replaced with a simple space
     *
     * @param text Input to format
     * @param tags Array contains regExp
     * @returns head tag compliant string
     */
    public cleanHtmlEncoding(
        text: string | undefined,
        tags: Array<[RegExp | string, string]>,
    ): string | undefined {
        if (!text) {
            return text;
        }

        tags.forEach((tag) => {
            text = text?.replace(tag[0], tag[1]);
        });

        return text;
    }

    /**
     * Clean all existing meta data
     */
    public cleanExistingMetas(): void {
        this.setDefaultTitle();
        this.cleanCanonicalUrl();
        this.cleanDescriptionMetaInformations();
        this.cleanItemPropMetaInformations();
        this.cleanOgMetaInformations();
        this.cleanImagePreloadUrls();
        this.cleanTwitterMetaInformations();
        this.removeNoIndexMetaInformation();
    }

    public addTag(linkAttributes: LinkDefinition): void {
        const renderer = this.rendererFactory.createRenderer(
            this.document,
            // eslint-disable-next-line unicorn/no-null
            null,
        );
        const linkElement: HTMLLinkElement = renderer.createElement("link");
        (Object.keys(linkAttributes) as Array<keyof LinkDefinition>).forEach(
            (property) => {
                const attributeValue = linkAttributes[property];
                if (attributeValue) {
                    renderer.setAttribute(
                        linkElement,
                        property,
                        attributeValue,
                    );
                }
            },
        );
        renderer.appendChild(this.document.head, linkElement);
    }

    public removeLinks(attribute: string): void {
        // eslint-disable-next-line unicorn/prefer-spread
        Array.from(this.document.head.getElementsByTagName("link"))
            .filter((link) => link.getAttribute("rel") === attribute)
            .forEach((link) => {
                link.remove();
            });
    }

    /**
     * Remove tags that match selectors from head tag
     */
    public removeTags(tagSelectors: string[]): void {
        for (const selector of tagSelectors) {
            this.meta.removeTag(selector);
        }
    }

    private createScriptNode(type?: string, id?: string): HTMLScriptElement {
        const script = this.renderer.createElement("script");
        if (type) {
            script.setAttribute("type", type);
        }
        if (id) {
            script.setAttribute("id", id);
        }
        return script;
    }

    /**
     * Create a MetaTag or update if it exists
     *
     * @param tags Array of MetaTags build with MetatagBuilder
     */
    private createOrUpdateTags(tags: MetaDefinition[]) {
        tags.forEach((tag) => {
            // Tags with 'itemprop' and without 'name' and 'property' need a selector to be identified and updated
            const selector = tag.itemprop
                ? `itemprop=${tag.itemprop}`
                : undefined;
            this.meta.updateTag(tag, selector);
        });
    }

    private addPaginationUrl(
        attribute: LinkRelation.PREV | LinkRelation.NEXT,
        pageNumber: number,
    ): void {
        const currentURL = this.getCurrentUrlWithoutParameters();
        const pageUrl = `${currentURL}?page=${pageNumber}`;
        this.addTag({
            rel: attribute,
            href: decodeURI(pageUrl),
        });
    }

    private getCurrentUrlWithoutParameters(): string {
        return `${this.settings.frontend}${this.locale.urlPrefix}${
            this.location.path().split("?")[0]
        }`;
    }
}
