import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";

import { BannerItem } from "@hermes/api-model-shell";

@Component({
    selector: "h-notification-banner",
    templateUrl: "./notification-banner.component.html",
    styleUrls: [
        "./notification-banner.component.scss",
        "../banner-common.scss",
    ],
})
export class NotificationBannerComponent implements OnInit, AfterViewInit {
    @ViewChild("closeButton")
    public closeButton!: ElementRef<HTMLButtonElement>;

    @Input()
    public notificationsBanners!: BannerItem[];

    @Output()
    public closeBanner: EventEmitter<void> = new EventEmitter<void>();

    public headerNotificationsMessages: string[] = [];

    public ngOnInit(): void {
        this.headerNotificationsMessages = this.notificationsBanners.map(
            (notificationsBanners) => notificationsBanners.content,
        );
    }

    public ngAfterViewInit(): void {
        if (this.headerNotificationsMessages.length === 1) {
            this.closeButton.nativeElement.focus();
        }
    }

    public close(): void {
        this.closeBanner.emit();
    }
}
