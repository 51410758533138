import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { BasketStateModule } from "@hermes/states/basket";

import { PaymentService } from "./services/payment.service";
import { ValidationService } from "./services/validation.service";

@NgModule({
    imports: [CommonModule],
    providers: [BasketStateModule, PaymentService, ValidationService],
})
export class PaymentServiceModule {}
