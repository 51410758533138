import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import type * as express from "express";

import { NotificationsService } from "@hermes/aphrodite/layout";
import { Context, RESPONSE } from "@hermes/app-core";
import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

@Injectable()
export class ApolloErrorHandler implements ErrorHandler {
    constructor(
        @Inject(LOGGER) private logger: Logger,
        @Inject(Injector) private injector: Injector,
        @Inject(RESPONSE) private response: express.Response,
    ) {}

    public handleError(error: Error | HttpErrorResponse | string): void {
        // we don't inject these in constructor or else we have a cyclic dependency between app and core modules.
        const context: Context = this.injector.get(Context);
        const router: Router = this.injector.get(Router);
        const notificationsService: NotificationsService =
            this.injector.get(NotificationsService);

        // when an error happens on browser side, we show the warning in an overlay/notification
        if (context.isInBrowserMode() && error instanceof HttpErrorResponse) {
            notificationsService.stopNotification();
            notificationsService.technicalError(error.message);
        }

        // log in every case
        if (typeof error === "string") {
            this.logger.error(error);
        } else {
            this.logger.error(error.message, error);
        }

        // on server mode, we display an error page.
        if (context.isInServerMode()) {
            this.response.status(500);
            router.navigate(["500-error"], {
                skipLocationChange: true,
            });
        }
    }
}
